import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Img from "../../assets/image/cover.png";
import CT1editable from "components/CoverLetterTemplate/CT1/CT1editable";
import { useSelector } from "react-redux";
import CoverLetterTemplateJson from "contants/CoverLettertemplate";
export const PreviewModal = ({ show, setShow }) => {
  const handleClose = () => setShow(false);
  const CoverRedux = useSelector(item => item.cover)
  const [CoverTemplatePreview, setCoverTemplatePreview] = useState();

  useEffect(() => {
    setCoverTemplatePreview((CoverTemplatePreview) => CoverTemplatePreview = CoverLetterTemplateJson?.find((item) => item.templateNo === CoverRedux?.template))

  }, [])
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      size="lg"
      className="promotion-modal"
    >
      <div className="bg-light rounded " >
        {
          !!CoverTemplatePreview ? <CoverTemplatePreview.templateEdit /> :
            <CT1editable />
        }
      </div>
    </Modal>
  );
};
