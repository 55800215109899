import { PracticeVideos } from "../components/bestResumePractice/PracticeVideos"
import { SelectedCourse } from "../components/bestResumePractice/SelectedCourse"
import { SidePanelData } from "contants/SidepanelData";
import { NavLink } from "react-router-dom";
import { Navbar } from "components/Navbar/Navbar";
import { SidePanel } from "components/Sidepanel/SidePanel";
import "../styles/bestResumePractice.css"

export function BestResumePractice() {


    return <>

        <div className="d-flex">
            <SidePanel />
            <div className="w-100 main-container-wrapper ">
                <Navbar />

                <div className="main-content  ">
                    <PracticeVideos />
                </div>
            </div>
        </div>
    </>




}