import React, { useState } from 'react';

const CT2non = () => {
  const [fontSize, setfontSize] = useState(0.8);
  const Style = {
    coverWrapper: {
      width: "100%",
      minHeight: "550px",
      containerType: "inline-size",
      padding: "5px",
      // background: "#ffffff",
      padding: `${fontSize * 1}cqw`
    },
    coverHead: {
      fontSize: fontSize
    },
    title: {
      fontSize: `${fontSize * 5}cqw`,
      marginBottom: `${fontSize * 1}cqw`

    },
    common: {
      fontSize: `${fontSize * 3}cqw`,
      marginBottom: `${fontSize * 1}cqw`,
      whiteSpace: "pre-wrap"
    },
    li: {
      fontSize: `${fontSize * 3}cqw`,
      marginBottom: `${fontSize * 0}cqw`
    }
    ,
    headings: {
      fontSize: `${fontSize * 4}cqw`,
      fontWeight: "700",
      marginBottom: `${fontSize * 1}cqw`
    },
    subHeadings: {
      fontSize: `${fontSize * 3}cqw`,
      fontWeight: "700",
      marginBottom: `${fontSize * 1}cqw`
    },
    marginHR: {
      margin: `${fontSize * 2}cqw 0`
    },
    marginUl: {
      margin: `${fontSize * 0}cqw 0`
    }

  }

  return (
    <div style={Style.coverWrapper} className=' flex-column'>
      <div className='text-center'>
        <h1 style={Style.title} className='text-center'>John Doe</h1>
        <h2 style={Style.common}>52 , California ,  UK</h2>
        <p style={Style.common}>demo@gmail.com</p>
      </div>
      <div className='align-self-start'>
        <p style={Style.common}>December 11 , 2020</p>
        <div>
          <p style={Style.common}>Prashant Handa</p>
          <p style={Style.common}>Sales Manager</p>
          <p style={Style.common}>Acme Corp.</p>
          <p style={Style.common}>124 Business Rd</p>
          <p style={Style.common}>Business City , NY 54321</p>
        </div>
        <br />
        <h6 style={Style.subHeadings} >Dear Mr. Brown</h6>
        <p style={Style.common}>
          I am writing to express my strong interest in the Software Engineer position at [Company Name] as advertised on [Source of Job Listing]. With my solid foundation in software development and a passion for crafting innovative solutions, I am confident in my ability to contribute to your team and help drive the success of your projects. {"\n"}
          <br />
          I am a highly skilled software engineer with [number of years] of professional experience in the field. My expertise lies in [mention your areas of specialization, such as front-end development, backend systems, or full-stack development]. Throughout my career, I have successfully designed, developed, and maintained [mention notable software projects or applications you have worked on] by utilizing a combination of technical skills, problem-solving abilities, and a strong attention to detail. {"\n"}
          <br />

          In my previous role at [Current/Previous Company], I played a key role in developing and implementing software solutions for a diverse range of clients. I actively participated in the entire software development lifecycle, from requirements gathering and analysis to coding, testing, and deployment. I am proficient in [list programming languages, frameworks, and tools relevant to the position]. Additionally, I have experience collaborating with cross-functional teams, including product managers, UX designers, and QA engineers, to ensure the delivery of high-quality software products.
        </p>
        <br />
        <p style={Style.common}>Respectfully Yours,</p>
        <p style={Style.common}>Prashant Handa</p>
      </div>
    </div>
  );
}

export default CT2non;
