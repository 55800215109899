import { PageRoutes } from "Routes";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "./App.css";

function App() {
  return <PageRoutes />;
}

export default App;
