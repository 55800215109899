import { setCoverFields, setFieldFocus, setTargetJob, setTo } from "helper/redux/Slice/CoverSlice";
import { Form, FloatingLabel } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from 'react'
import { toast } from "react-hot-toast";
export const TargetJob = ({ handleResponsivePreviewShow, SaveAndNext }) => {
  const [TargetJobFields, setTargetJobFields] = useState({
    position: "",
    company: ""
  });
  const dispatch = useDispatch();
  const CoverData = useSelector(item => item.cover)

  const handleChange = (e) => {
    const { name, value } = e.target
    setTargetJobFields({ ...TargetJobFields, [name]: value });
  }

  const saveToRedux = (e) => {
    e.preventDefault()
    dispatch(setTargetJob(TargetJobFields))
    SaveAndNext()
    toast.success("Target Job Saved Successfully")
  }

  useEffect(() => {
    if (!!CoverData?.targetJob?.position || !!CoverData?.targetJob?.company) {
      setTargetJobFields(CoverData?.targetJob)
    }
  }, [])


  return (
    <div className="create-resume-main">
      <div className="d-flex justify-content-between align-items-center">
        <h3>What Job are you Applying for    </h3>
        <button onClick={handleResponsivePreviewShow} className="align-self-start p-1 responsive-preview-btn d-inline-block d-lg-none">Preview</button>
      </div>
      <p>
        {/* Et pellentesque scelerisque risus blandit quam tempus. Pellentesque
        sodales nec convallis sit ac. */}
      </p>
      <Form className="form-main">
        <FloatingLabel
          label="Position"
          className="mb-3"

        >
          <Form.Control id="position" onChange={handleChange} name="position" value={TargetJobFields?.position} type="text" placeholder="Position" />
        </FloatingLabel>
        <FloatingLabel
          label="Company"
          className="mb-3"

        >
          <Form.Control id="company" onChange={handleChange} name="company" type="text" value={TargetJobFields?.company} placeholder="Company" />
        </FloatingLabel>

        <button className="save-experience" onClick={saveToRedux}>Save </button>
      </Form>
    </div>
  );
};
