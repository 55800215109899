import React, { useRef, useEffect, useState } from 'react';
import Plyr from "plyr-react";
import "plyr/dist/plyr.css";
import Recorder from './Recorder';

const VideoClose = () => {

  const videoSrc = {
    type: "video",
    sources: [
      {
        src: "QFaFIcGhPoM",
        provider: "youtube",
        size: 720,
      }
    ]
  };

  const VideoResumeSteps = [{ head: 'Introduction', subHead: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. " }, { head: 'Professional', subHead: "Lorem ipsum dolor sit amet consectetur, adipisicing elit." }, { head: 'Passion', subHead: "Lorem ipsum dolor sit amet consectetur, adipisicing elit." }, { head: 'Accomplishments', subHead: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. " }, { head: 'Testimonials', subHead: "Lorem ipsum dolor sit amet consectetur, adipisicing elit." }, { head: 'Close', subHead: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. " }]
  return (
    <div className='d-flex col-12'>

      <div className='videoResume-final col-6 my-4'>
        {
          VideoResumeSteps?.map((item) => {
            return <div className='my-5 pe-4'>
              <h3>{item?.head}</h3>
              <p>{item?.subHead}</p>
              <div className='d-flex justify-content-between video-resume-final-left-card'>
                <div className='col-7 rounded'>
                  <Plyr source={videoSrc} />
                </div>
                <button className='align-self-end px-3 py-2 rounded video-resume-final-left-card-btn'>Edit</button>
              </div>
            </div>
          })
        }
      </div>
      <div></div>
      {/* <div className='d-flex justify-content-between py-4'>
        <div className='VideoResume_targetjob pe-4'>
          <h2>Close</h2>
          <p>Sapiente debitis ratione nemo deleniti atque dignissimos quasi blanditiis exercitationem officiis accusamus.</p>

          <Recorder />
        </div>
        <div className='col-4 d-flex flex-column gap-5'>
          <div className='rounded  overflow-hidden'>
            <Plyr source={videoSrc} />
            <div>
            </div>
          </div>

        </div>
      </div> */}


      {/* //editor */}

      {/* <div className='videoResume_editor'>
        <p>Record a video to edit</p>
      </div> */}


    </div>
  );
}

export default VideoClose;
