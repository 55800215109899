import { configureStore } from "@reduxjs/toolkit";
import ResumeSlice from './Slice/ResumeSlice'
import UserSlice from "./Slice/UserSlice";
import { applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { combineReducers } from "redux";
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import TypeSlice from "./Slice/TypeSlice";
import UtilsSlice from "./Slice/UtilsSlice";
import CoverSlice from "./Slice/CoverSlice";
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['types', 'resume'],
};

const rootReducer = combineReducers({
  user: UserSlice,
  resume: ResumeSlice,
  types: TypeSlice,
  utils: UtilsSlice,
  cover: CoverSlice
})

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({ reducer: persistedReducer, middleware: [thunk] });

export const persistor = persistStore(store);
