import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  doctype: "",
  docsubtype: "",
  coverFor: ""
}
let TypeSlice = createSlice({
  name: "resume",
  initialState,
  reducers: {
    setDocType(state, action) {
      state.doctype = action.payload
    },
    setCoverFor(state, action) {
      state.coverFor = action.payload
    },
    setDocSubType(state, action) {
      state.docsubtype = action.payload
    },

  }
})
export const { setDocType, setCoverFor, setDocSubType } = TypeSlice.actions
export default TypeSlice.reducer;