import React, { useState } from 'react';
import { useSelector } from 'react-redux';

const CT2editable = ({ data }) => {
  const [fontSize, setfontSize] = useState(1);
  const Style = {
    coverWrapper: {
      width: "100%",
      minHeight: "100%",
      containerType: "inline-size",
      padding: "5px",
      // background: "#ffffff",
      padding: `${fontSize * 1}cqw`
    },
    coverHead: {
      fontSize: fontSize
    },
    title: {
      fontSize: `${fontSize * 5}cqw`,
      marginBottom: `${fontSize * 1}cqw`

    },
    common: {
      fontSize: `${fontSize * 3}cqw`,
      marginBottom: `${fontSize * 1}cqw`,
      whiteSpace: "pre-wrap",
      wordBreak: "break-word",
      textAlign: "justify"
    },
    li: {
      fontSize: `${fontSize * 3}cqw`,
      marginBottom: `${fontSize * 0}cqw`
    }
    ,
    headings: {
      fontSize: `${fontSize * 4}cqw`,
      fontWeight: "700",
      marginBottom: `${fontSize * 1}cqw`
    },
    subHeadings: {
      fontSize: `${fontSize * 3}cqw`,
      fontWeight: "700",
      marginBottom: `${fontSize * 1}cqw`
    },
    marginHR: {
      margin: `${fontSize * 2}cqw 0`
    },
    marginUl: {
      margin: `${fontSize * 0}cqw 0`
    }

  }

  let CoverData;
  CoverData = useSelector(item => item.cover);
  // UserData = useSelector(item => item.user)
  if (!!data) {
    CoverData = data;
  }
  const today = new Date();
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();

  if (dd < 10) dd = '0' + dd;
  if (mm < 10) mm = '0' + mm;

  const formattedToday = dd + '/' + mm + '/' + yyyy;

  return (
    <div style={Style.coverWrapper} className='flex-column'>
      <div className='text-center'>
        <h1 style={Style.title} className='text-center'>{CoverData?.from?.name}</h1>
        <h2 style={Style.common}>{CoverData?.from?.address}</h2>
        <p style={Style.common}>{CoverData?.from?.email}</p>
      </div>
      <div className='align-self-start'>
        <p style={Style.common}>{formattedToday}</p>
        <div>
          <p style={Style.common}>{CoverData?.to?.name}</p>
          <p style={Style.common}>{CoverData?.to?.position}</p>
          <p style={Style.common}>{CoverData?.to?.email}</p>
          <p style={Style.common}>{CoverData?.to?.company}</p>
          <p style={Style.common}>{CoverData?.to?.address}</p>
        </div>
        <br />
        {/* <h6 style={Style.subHeadings} >Dear {CoverData?.to?.name ?? "Sir"}</h6> */}
        <p style={Style.common}>
          {
            CoverData?.content
          }
        </p>
        <br />
        {/* <p style={Style.common}>Respectfully Yours,</p>
        <p style={Style.common}>{CoverData?.from?.name}</p> */}
      </div>
    </div>
  );
}

export default CT2editable;
