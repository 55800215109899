import { SidePanel } from "components/Sidepanel/SidePanel";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Navbar } from "components/Navbar/Navbar";
import { SidePanelData } from "contants/SidepanelData";
import { StepProgress } from "components/StepProgress/StepProgress";
import { Finishup } from "components/NewResume/Finishup";
import { Skills } from "components/NewResume/Skills";
import { useSelector, useDispatch } from "react-redux";
import { FresherOrExperience } from "components/CoverLetter/FresherOrExperience";
import { TargetJob } from "components/CoverLetter/TargetJob";
import { FromTo } from "components/CoverLetter/FromTo";
import { ExpYears } from "components/CoverLetter/ExpYears";
import { ExpGap } from "components/CoverLetter/ExpGap";
import { Content } from "components/CoverLetter/Content";
import { PreviewModal } from "components/Modal/PreviewModal";
import { Highlight } from "components/CoverLetter/Highlight";
import EditIcon from "../assets/image/icon/edit.png";
import DeleteIcon from "../assets/image/icon/delete.png";
import { setCoverFor } from "helper/redux/Slice/TypeSlice";
import { DeleteCoverCertificate, DeleteCoverEducation, DeleteCoverInvolvement, DeleteCoverProject, DeleteCoverSkill, EditCoverLetter, GetCoverEditData, ResetCoverLetter, UpdateCoverEducation, setCoverCertificateFields, setCoverEducationFields, setCoverExperienceFields, setCoverFields, setCoverInvolvementFields, setCoverProjectFields } from "helper/redux/Slice/CoverSlice";
import CoverTheme from "components/CoverLetterTemplate/CoverTheme";
import { GetAiResume, GetAutomatedResume, GetCoverLetterById, GetTrailResume, PostCoverLetter, PostCoverLetterExperience, PostCoverLetterFresher, UpdateExperienceCoverLetter, UpdateFresherCoverLetter } from "helper/api";
import CoverletterTemplate from "components/CoverLetterTemplate/CoverTemplate1/CoverletterTemplate";
import UseCoverTemplate1 from "components/CoverLetterTemplate/CoverTemplate1/UseCoverTemplate1";
import { setContactFields } from "helper/redux/Slice/ResumeSlice";
import CT1editable from "components/CoverLetterTemplate/CT1/CT1editable";
import ChangeTemplateModal from "./ChangeTemplateModal";
import firework from '../assets/image/icon/firework.png'
import '../styles/cover.css'
import LoadingComponent from "common/LoadingComponent";
import { Button, Modal } from "react-bootstrap";
import CoverLetterTemplateJson from "contants/CoverLettertemplate";
import { setCoverStep } from "helper/redux/Slice/UtilsSlice";
import { Toaster } from "react-hot-toast";
import { RiContactsBookLine } from "react-icons/ri";

export const CreateCover = () => {
  const [step, setStep] = useState(0);
  const [FresherHighlightStep, setFresherHighlightStep] = useState(0);
  const [UpdateRedux, setUpdateRedux] = useState(null);
  const [show, setShow] = useState(false);
  const [spinn, setspinn] = useState(false);
  // Responsive preview modal
  const [responsivePreview, setresponsivePreview] = useState(false);


  const handleResponsivePreviewClose = () => setresponsivePreview(false);
  const handleResponsivePreviewShow = () => setresponsivePreview(true);

  const dispatch = useDispatch();

  const Type = useSelector((item) => item.types);
  const Utils = useSelector(item => item.utils)


  const param = useParams()
  const Doctype = useSelector(item => item.types)
  const Edit = useSelector((item) => item?.cover?.EditData)

  //experience step
  function StepBackExperienceBtn() {
    setStep(step - 1);
    dispatch(setCoverStep(step - 1))

  }
  function StepNextExperienceBtn() {
    setStep(step + 1);
    dispatch(setCoverStep(step + 1))
    if (step === 0) {
      handleSubmitCoverLetter()
    } else {
      EditCoverLetter()
    }

  }

  //fresher step
  function StepBackFresherBtn() {
    if (step === 3 && FresherHighlightStep > 1) {
      setFresherHighlightStep(FresherHighlightStep - 1)
    } else {
      setStep(step - 1);
      dispatch(setCoverStep(step - 1))
    }
  }
  function StepNextFresherBtn() {

    if (step === 3 && FresherHighlightStep <= 4) {
      setFresherHighlightStep(FresherHighlightStep + 1)
    } else {
      setStep(step + 1);
      dispatch(setCoverStep(step + 1))

    }

    if (step === 0) {
      handleSubmitCoverLetter()
    } else {
      EditCoverLetter()
    }
  }
  function direcStepChange(step) {
    setStep(step + 1);
    dispatch(setCoverStep(step + 1))
    // if (step > 0) {
    //   EditCoverLetter()
    // }
  }

  const [resumeData, setResumeData] = useState(null)
  const getResumeData = async () => {
    try {
      let data;
      if (Type?.docsubtype === "trailResume") {
        data = await GetTrailResume();
      } else if (Type?.docsubtype === "aiResume") {
        data = await GetAiResume();
      } else {
        data = await GetAutomatedResume()
      }

      const newdata = [...data?.data?.data]

      newdata[0]?.education?.map((edu, i) => {

        dispatch(setCoverEducationFields(edu))
      })
      newdata[0]?.experiences?.map((exp, i) => {

        dispatch(setCoverExperienceFields(exp))
      })
      newdata[0]?.certification?.map((cert, i) => {

        dispatch(setCoverCertificateFields(cert))
      })
      newdata[0]?.projects?.map((proj, i) => {

        dispatch(setCoverProjectFields(proj))
      })
      newdata[0]?.involvement?.map((inv, i) => {

        dispatch(setCoverInvolvementFields(inv))
      })

    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getResumeData()
  }, [])


  // useEffect(() => {
  //   if (Type?.docsubtype === "automatedResume") {
  //     dispatch(setCoverFor("Experience"));
  //     StepNextFresherBtn();
  //   }
  // }, []);
  const ReviewEdit = useSelector((item) => item.utils.reviewEdit)

  // debugger
  useEffect(() => {
    if (!!param?.id) {
      setStep(1)
      dispatch(setCoverStep(1))
      dispatch(GetCoverEditData(param?.id))
      if (ReviewEdit && Type?.doctype === "cover" && Type?.coverFor === "Fresher") {
        dispatch(setCoverStep(5))
        setStep(5)

      } else if (ReviewEdit && Type?.doctype === "cover" && Type?.coverFor === "Experienced") {
        setStep(7)
        dispatch(setCoverStep(7))

      }
    }
  }, [param?.id])



  const navigate = useNavigate()

  const EditCoverLetter = async () => {
    if (Type?.coverFor === "Fresher" && step == 5) {
      handleFinalResumeShow()
    } else if (Type?.coverFor === "Experienced" && step == 7) {
      handleFinalResumeShow()
    }
    try {
      if (Type?.coverFor === "Fresher") {
        const payload = {
          "name": CoverRedux?.name,
          "template": CoverRedux?.template,
          "color": CoverRedux?.color,
          "type": "fresher",
          "coverId": !!param?.id ? param?.id : localStorage.getItem('coverId'),
          "targetJob": {
            "position": CoverRedux?.targetJob?.position,
            "company": CoverRedux?.targetJob?.company
          },
          "from": {
            "name": CoverRedux?.from?.name,
            "number": CoverRedux?.from?.number,
            "email": CoverRedux?.from?.email,
          },
          "to": {
            "name": CoverRedux?.to?.name,
            "email": CoverRedux?.to?.email,
            "position": CoverRedux?.to?.position,
            "address": CoverRedux?.to?.address,
            "companyName": CoverRedux?.to?.companyName
          },
          "experiences": CoverRedux?.experiences,
          "education": {
            "gap": CoverRedux?.gap,
            "reason": CoverRedux?.reason
          },
          "skills": CoverRedux?.skills,
          "content": CoverRedux?.content,
          "projects": CoverRedux?.projects,
          "certification": CoverRedux?.certification,
          "involvement": CoverRedux?.involvement,
          "completeness": CoverRedux?.completeness,
          "formatting": CoverRedux?.formatting,
          "wordChoice": CoverRedux?.wordChoice,
          "strongSummary": CoverRedux?.strongSummary,
          "noTypos": CoverRedux?.noTypos,
          "length": CoverRedux?.length,
          "optimization": CoverRedux?.optimization,
          "atsCompliance": CoverRedux?.atsCompliance,
          "clearContactInfo": CoverRedux?.clearContactInfo,
        }

        await UpdateFresherCoverLetter(payload);
      } else {
        const payload = {
          "name": CoverRedux?.name,
          "template": CoverRedux?.template,
          "color": CoverRedux?.color,
          "coverId": !!param?.id ? param?.id : localStorage.getItem('coverId'),
          "type": "exp",
          "targetJob": {
            "position": CoverRedux?.targetJob?.position,
            "company": CoverRedux?.targetJob?.company
          },
          "from": {
            "name": CoverRedux?.from?.name,
            "number": CoverRedux?.from?.number,
            "email": CoverRedux?.from?.email,
          },
          "to": {
            "name": CoverRedux?.to?.name,
            "email": CoverRedux?.to?.email,
            "position": CoverRedux?.to?.position,
            "address": CoverRedux?.to?.address,
            "companyName": CoverRedux?.to?.companyName
          },
          "experiences": CoverRedux?.totalExperience,
          "education": {
            "gap": CoverRedux?.gap,
            "reason": CoverRedux?.reason
          },
          "skills": CoverRedux?.skills,
          "content": CoverRedux?.content,
          "completeness": CoverRedux?.completeness,
          "formatting": CoverRedux?.formatting,
          "wordChoice": CoverRedux?.wordChoice,
          "strongSummary": CoverRedux?.strongSummary,
          "noTypos": CoverRedux?.noTypos,
          "length": CoverRedux?.length,
          "optimization": CoverRedux?.optimization,
          "atsCompliance": CoverRedux?.atsCompliance,
          "clearContactInfo": CoverRedux?.clearContactInfo,
        }

        await UpdateExperienceCoverLetter(payload);
      }
      // navigate('/')
    } catch (error) {
      console.log(error)
    }
  }


  //preview
  const FresherTitle = [
    "Education",
    "Experience",
    "Certificates",
    "Project",
    "Volunteering"
  ]

  const SaveAndNext = () => {
    // if (Type?.coverFor === "Fresher") {
    //   StepNextFresherBtn()
    // }
    // else {
    //   StepNextExperienceBtn()
    // }
  }


  // debugger

  const ExpStepList = () => {

    return (
      <>
        {(step === 0 && Doctype?.doctype === "cover") && <FresherOrExperience handleSubmitCoverLetter={handleSubmitCoverLetter} />}
        {Utils?.stepCover === 1 && <TargetJob SaveAndNext={SaveAndNext} handleResponsivePreviewShow={handleResponsivePreviewShow} />}
        {Utils?.stepCover === 2 && <Skills SaveAndNext={SaveAndNext} handleResponsivePreviewShow={handleResponsivePreviewShow} />}
        {Utils?.stepCover === 3 && <ExpYears SaveAndNext={SaveAndNext} handleResponsivePreviewShow={handleResponsivePreviewShow} />}
        {Utils?.stepCover === 4 && <ExpGap SaveAndNext={SaveAndNext} handleResponsivePreviewShow={handleResponsivePreviewShow} />}
        {Utils?.stepCover === 5 && <FromTo SaveAndNext={SaveAndNext} handleResponsivePreviewShow={handleResponsivePreviewShow} />}
        {Utils?.stepCover === 6 && <Content SaveAndNext={SaveAndNext} handleResponsivePreviewShow={handleResponsivePreviewShow} />}
        {Utils?.stepCover === 7 && <Finishup page={"cover"} />}
        {step === 8 && <Finishup />}
      </>
    );
  };


  const CoverRedux = useSelector(item => item.cover)

  const FresherDeleteRedux = (data) => {
    if (FresherHighlightStep === 1) {
      dispatch(DeleteCoverEducation(data))
      setUpdateRedux(null)
    } else if (FresherHighlightStep === 2) {
      dispatch(DeleteCoverEducation(data))
      setUpdateRedux(null)
    } else if (FresherHighlightStep === 3) {
      dispatch(DeleteCoverCertificate(data))
      setUpdateRedux(null)
    } else if (FresherHighlightStep === 4) {
      dispatch(DeleteCoverProject(data))
      setUpdateRedux(null)
    } else if (FresherHighlightStep === 5) {
      dispatch(DeleteCoverInvolvement(data))
      setUpdateRedux(null)

    }
    setUpdateRedux([])
  }

  const ExperienceDeleteRedux = (data) => {
    if (FresherHighlightStep === 1) {
      dispatch(DeleteCoverSkill(data))
      setUpdateRedux(null)
    } else if (FresherHighlightStep === 2) {
      dispatch(DeleteCoverEducation(data))
      setUpdateRedux(null)
    } else if (FresherHighlightStep === 3) {
      dispatch(DeleteCoverCertificate(data))
      setUpdateRedux(null)
    } else if (FresherHighlightStep === 4) {
      dispatch(DeleteCoverProject(data))
      setUpdateRedux(null)
    }
    setUpdateRedux([])
  }

  const UserData = useSelector(item => item.user)
  dispatch(setCoverFields({ type: "name", value: UserData.name }))

  const ExperienceRedux = useSelector(item => item.cover.experiences)
  const ProjectRedux = useSelector(item => item.cover.projects)
  const EducationRedux = useSelector(item => item.cover.education)
  const CertificateRedux = useSelector(item => item.cover.certification)
  const InvolvementRedux = useSelector(item => item.cover.involvement)


  const FresherUpdateRedux = (id) => {
    if (FresherHighlightStep === 1) {
      const newdataEducation = EducationRedux[id]
      setUpdateRedux({ ...newdataEducation, index: id });

    } else if (FresherHighlightStep === 2) {
      const newdataExperience = ExperienceRedux[id]
      setUpdateRedux({ ...newdataExperience, index: id });

    } else if (FresherHighlightStep === 3) {
      const newdataCertificate = CertificateRedux[id]
      setUpdateRedux({ ...newdataCertificate, index: id });

    } else if (FresherHighlightStep === 4) {
      const newdataProject = ProjectRedux[id]
      setUpdateRedux({ ...newdataProject, index: id });

    } else if (FresherHighlightStep === 5) {
      const newdataInvolvement = InvolvementRedux[id]
      setUpdateRedux({ ...newdataInvolvement, index: id });
    }
    console.log(id)
  }

  const ExperienceUpdateRedux = (id) => {
    if (FresherHighlightStep === 1) {
      const newdataEducation = CoverRedux?.education?.filter((item) => item?.id == id)
      setUpdateRedux(newdataEducation);
    } else if (FresherHighlightStep === 2) {
      const newdataExperience = CoverRedux?.experiences?.filter((item) => item?.id == id)
      setUpdateRedux(newdataExperience);
    } else if (FresherHighlightStep === 3) {
      const newdataCertificate = CoverRedux?.certification?.filter((item) => item?.id == id)
      setUpdateRedux(newdataCertificate);
    } else if (FresherHighlightStep == 4) {
      const newdataProject = CoverRedux?.projects?.filter((item) => item?.id == id)
      setUpdateRedux(newdataProject);

    }
  }
  const FresherStepList = () => {
    return (
      <>
        {(step === 0 && Doctype?.doctype === "cover") && <FresherOrExperience />}
        {Utils?.stepCover === 1 && <TargetJob SaveAndNext={SaveAndNext} handleResponsivePreviewShow={handleResponsivePreviewShow} />}
        {Utils?.stepCover === 2 && <FromTo SaveAndNext={SaveAndNext} handleResponsivePreviewShow={handleResponsivePreviewShow} />}
        {Utils?.stepCover === 3 && (
          <Highlight
            SaveAndNext={SaveAndNext}
            handleResponsivePreviewShow={handleResponsivePreviewShow}
            UpdateRedux={UpdateRedux} setUpdateRedux={setUpdateRedux}
            setFresherHighlightStep={setFresherHighlightStep}
            FresherHighlightStep={FresherHighlightStep}
          />
        )}
        {Utils?.stepCover === 4 && <Content SaveAndNext={SaveAndNext} handleResponsivePreviewShow={handleResponsivePreviewShow} />}
        {Utils?.stepCover === 5 && <Finishup />}
        {Utils?.stepCover === 6 && <Finishup />}
      </>
    );
  };

  const handleSubmitCoverLetter = async () => {
    setspinn(true)
    if (Type?.coverFor === "Fresher" && step == 5) {
      handleFinalResumeShow()
    } else if (Type?.coverFor === "Experienced" && step == 7) {
      handleFinalResumeShow()
    }

    try {
      if (Type?.coverFor === "Fresher") {
        const payload = {
          "name": CoverRedux?.name,
          "template": CoverRedux?.template,
          "color": CoverRedux?.color,
          "type": "fresher",
          "targetJob": {
            "position": CoverRedux?.targetJob?.position,
            "company": CoverRedux?.targetJob?.company
          },
          "from": {
            "name": CoverRedux?.from?.name,
            "number": CoverRedux?.from?.number,
            "email": CoverRedux?.from?.email,
          },
          "to": {
            "name": CoverRedux?.to?.name,
            "email": CoverRedux?.to?.email,
            "position": CoverRedux?.to?.position,
            "address": CoverRedux?.to?.address,
            "companyName": CoverRedux?.to?.companyName
          },
          "experiences": CoverRedux?.experiences,
          "education": {
            "gap": CoverRedux?.gap,
            "reason": CoverRedux?.reason
          },
          "skills": CoverRedux?.skills,
          "content": CoverRedux?.content,
          "projects": CoverRedux?.projects,
          "certification": CoverRedux?.certification,
          "involvement": CoverRedux?.involvement,
          "completeness": CoverRedux.completeness,
          "formatting": CoverRedux?.formatting,
          "wordChoice": CoverRedux?.wordChoice,
          "strongSummary": CoverRedux?.strongSummary,
          "noTypos": CoverRedux?.noTypos,
          "length": CoverRedux?.length,
          "optimization": CoverRedux?.optimization,

        }

        const res = await PostCoverLetterFresher(payload);
        localStorage.setItem("coverId", res?.data?.data?._id)
      } else {
        const payload = {
          "name": CoverRedux?.name,
          "template": CoverRedux?.template,
          "color": CoverRedux?.color,
          "type": "exp",
          "targetJob": {
            "position": CoverRedux?.targetJob?.position,
            "company": CoverRedux?.targetJob?.company
          },
          "from": {
            "name": CoverRedux?.from?.name,
            "number": CoverRedux?.from?.number,
            "email": CoverRedux?.from?.email,
          },
          "to": {
            "name": CoverRedux?.to?.name,
            "email": CoverRedux?.to?.email,
            "position": CoverRedux?.to?.position,
            "address": CoverRedux?.to?.address,
            "companyName": CoverRedux?.to?.companyName
          },
          "experiences": CoverRedux?.totalExperience,
          "education": {
            "gap": CoverRedux?.gap,
            "reason": CoverRedux?.reason
          },
          "skills": [...CoverRedux?.skills],
          "content": CoverRedux?.content,
          "completeness": CoverRedux.completeness,
          "formatting": CoverRedux?.formatting,
          "wordChoice": CoverRedux?.wordChoice,
          "strongSummary": CoverRedux?.strongSummary,
          "noTypos": CoverRedux?.noTypos,
          "length": CoverRedux?.length,
          "optimization": CoverRedux?.optimization,

        }
        const res = await PostCoverLetterExperience(payload);
        localStorage.setItem("coverId", res?.data?.data?._id)
      }
      setspinn(false)
      // window.location.replace('/')
    } catch (error) {
      setspinn(false)

    }
  }


  //step change using navbar
  const reduxCoverStep = useSelector((item) => item.utils.stepCover)
  // useEffect(() => {
  //   setStep(Number(reduxCoverStep))
  // }, [reduxCoverStep])
  //finalize modal
  const [FinalResume, setFinalResume] = useState(false);


  const handleFinalResumeClose = () => setFinalResume(false);
  const handleFinalResumeShow = () => setFinalResume(true);

  const handleShowPreview = () => {
    setShow(true)
  }

  //modal
  const [show2, setshow2] = useState(false);
  const TemplateNo = useSelector(item => item.utils.templateNo)
  const HandleShowTemplateModal = () => {
    setshow2(true)
  }
  const HandleHideTemplateModal = () => {
    setshow2(false)
  }
  const EducationRegex = /\((.*?)\)/;

  const [CoverTemplatePreview, setCoverTemplatePreview] = useState();

  useEffect(() => {
    setCoverTemplatePreview((CoverTemplatePreview) => CoverTemplatePreview = CoverLetterTemplateJson?.find((item) => item.templateNo === CoverRedux?.template))
  }, [CoverRedux?.template])

  const Template = () => {
    if (Type?.coverFor === "Fresher" && step > 0) {
      return (
        <>
          {(step === 5) ? null : (
            <div className="previw-card-wrapper ">
              <div className="preview-main h-100">

                <div className="position-relative " style={{ height: "570px" }}>
                  <div className="preview-card">
                    {
                      !!CoverTemplatePreview ? <CoverTemplatePreview.templateEdit /> :
                        <CT1editable />
                    }
                  </div>
                  <div className="d-flex justify-content-between px-3 bg-white change-template">
                    <h4 onClick={HandleShowTemplateModal}>Change Templates</h4>
                    <button onClick={() => setShow(true)}>Preview</button>
                  </div>
                </div>
                {(step === 3) && (
                  <div className="listPreview-container">
                    <p>Your {FresherTitle[FresherHighlightStep - 1]}</p>
                    <ul className="second-item">
                      {(FresherHighlightStep === 1 ? CoverRedux.education : FresherHighlightStep === 2 ? CoverRedux.experiences : FresherHighlightStep === 3 ? CoverRedux?.certification : FresherHighlightStep === 4 ? CoverRedux?.projects : FresherHighlightStep === 5 ? CoverRedux?.involvement : [])?.map((data, id) => {
                        return (
                          <li key={id}>
                            {
                              (FresherHighlightStep === 3) ? (data?.name) : (FresherHighlightStep === 2 || FresherHighlightStep === 5) ? (data?.role) : FresherHighlightStep === 4 ? data?.title : FresherHighlightStep === 1 ? !!data?.degree?.match(EducationRegex) ? data?.degree?.match(EducationRegex)[1] : data?.degree : null
                                (FresherHighlightStep === 1 || FresherHighlightStep === 3) ? (data?.name) : FresherHighlightStep === 2 ? (data?.role) : FresherHighlightStep === 4 ? data?.title : null
                            }
                            <span>
                              <img src={EditIcon} className="cursor-pointer" onClick={() => FresherUpdateRedux(id)} alt="edit" />
                              <img src={DeleteIcon} className="cursor-pointer" onClick={() => FresherDeleteRedux(id)} alt="delete" />
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      );
    } else if (Type?.coverFor === "Experienced") {
      return (
        <>
          {step === 0 || step === 7 ? null : (
            <div className="previw-card-wrapper">
              <div className="preview-main h-100">
                <div className="position-relative " style={{ height: "570px" }}>
                  <div className="preview-card ">
                    {/* <img src={Img} alt="image" width="100%" /> */}
                    {/* <CoverTheme /> */}
                    {
                      !!CoverTemplatePreview ? <CoverTemplatePreview.templateEdit /> :
                        <CT1editable />
                    }
                  </div>
                  <div className="d-flex justify-content-between px-3 bg-white change-template">
                    <h4 onClick={HandleShowTemplateModal}>Change Templates</h4>
                    <button onClick={() => setShow(true)}>Preview</button>
                  </div>
                </div>
                {(step === 2) && (
                  <div className="listPreview-container">
                    <p>Your Skills</p>
                    <ul className="second-item">
                      {(step === 2 ? CoverRedux.skills : [])?.map((data, id) => {
                        return (
                          <li key={id}>
                            {
                              data?.name
                            }
                            <span>
                              {
                                step !== 2 &&
                                <img src={EditIcon} className="cursor-pointer" onClick={() => ExperienceUpdateRedux(id)} alt="edit" />
                              }
                              <img src={DeleteIcon} className="cursor-pointer" onClick={() => ExperienceDeleteRedux(id)} alt="delete" />
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      );
    }
  };

  // console.log(step)


  return (
    <>
      <div className="d-flex">
        <SidePanel />
        <div className="w-100 main-container-wrapper">
          <Navbar />
          <Toaster />
          {
            Edit ? <>
              <LoadingComponent />
            </> : <>
              <div className="main-content  mx-0">
                {step > 0 && (
                  <div className="step-progress-main">
                    <StepProgress
                      step={step}
                      changeStepDirect={direcStepChange}
                      type={"cover"}
                    />
                  </div>
                )}
                <div className="d-flex my-3 w-100 gap-3">
                  <div className="w-100">
                    {Type?.coverFor === "Fresher" ? (
                      <FresherStepList />
                    ) : (
                      <ExpStepList />
                    )}
                    <div className="step-progres-button my-3 ">
                      <button
                        onClick={() => Type?.coverFor === "Fresher" ? StepBackFresherBtn() : StepBackExperienceBtn()}
                        disabled={step === 0 ? true : false}
                      >
                        Back
                      </button>

                      {
                        Type?.coverFor === "Fresher" ?
                          ((step === 5 && !!param?.id) ?
                            <button onClick={EditCoverLetter}>Update</button> :
                            (step === 5 && !param?.id) ? <button onClick={EditCoverLetter}>Submit</button> :
                              <button onClick={StepNextFresherBtn}>Next</button>) :

                          <>
                            {
                              step < 7 ? <button onClick={StepNextExperienceBtn}>Next</button>
                                : !!param?.id ? <button className="mb-3" onClick={EditCoverLetter}>Update</button> : <button className="mb-3" onClick={handleSubmitCoverLetter}>Submit</button>
                            }
                          </>
                      }
                    </div>
                  </div>
                  <div className="preview-wrapper">
                    <Template />
                  </div>

                  <ChangeTemplateModal show={show2} TemplateNo={TemplateNo} HandleShowTemplateModal={HandleShowTemplateModal} HandleHideTemplateModal={HandleHideTemplateModal} />

                </div>
              </div>
            </>
          }
        </div>
      </div >

      <PreviewModal show={show} setShow={setShow} />

      {/* finalize */}
      <Modal
        show={FinalResume}
        onHide={handleFinalResumeClose}
        centered
        keyboard={false}
        backdrop="static"
      >

        <Modal.Body className="experience_modal">
          <div className="experience_modal_container">
            <img src={firework} alt="crown" />
            <h6 className="my-4">Congratulations , you have successfully {!!param?.id ? 'Updated' : 'Created'}  a Cover letter</h6>

          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center ">
          <Button variant="secondary" className="btn_color" onClick={handleShowPreview} >
            View Cover Letter
          </Button>
          <Button className="btn_continue" onClick={() => {
            navigate('/')
            handleFinalResumeClose()
          }} variant="light">Go to dashboard </Button>
        </Modal.Footer>
      </Modal>


      {/* responsive preview modal */}

      <Modal
        show={responsivePreview}
        onHide={handleResponsivePreviewClose}
        centered
        className="rounded"
      >

        <Modal.Body className="d-flex justify-content-center rounded p-2">
          {Template()}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={handleResponsivePreviewClose}> Close</button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
