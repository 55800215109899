
export const SkillsPre = () => {
    return (
        <div className="create-resume-main">
            <h3>Skills</h3>
            <p>
                In a traditional resume, the section labeled "Skills" typically highlights the specific abilities, competencies, or proficiencies that a candidate possesses and can bring to a job or role. This section provides an opportunity for applicants to showcase their relevant skills and qualifications to potential employers.
            </p>
        </div>
    );
};
