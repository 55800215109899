import React, { useState } from 'react';
const RT3non = () => {
  const [fontSize, setfontSize] = useState(1);
  const Style = {
    resumeWrapper: {
      width: "100%",
      minHeight: "100%",
      containerType: "inline-size",
      padding: "5px",
      overflow: "auto"
    },
    resumeHead: {
      fontSize: fontSize
    },
    title: {
      fontSize: `${fontSize * 5}cqw`,
      marginBottom: `${fontSize * 1}cqw`

    },
    common: {
      fontSize: `${fontSize * 2.5}cqw`,
      marginBottom: `${fontSize * 1}cqw`
    },
    li: {
      fontSize: `${fontSize * 2.5}cqw`,
      marginBottom: `${fontSize * 0}cqw`
    }
    ,
    headings: {
      fontSize: `${fontSize * 4}cqw`,
      fontWeight: "700",
      marginBottom: `${fontSize * 1}cqw`
    },
    subHeadings: {
      fontSize: `${fontSize * 3}cqw`,
      fontWeight: "700",
      marginBottom: `${fontSize * 1}cqw`
    },
    marginHR: {
      margin: `${fontSize * 1}cqw 0`
    },
    marginUl: {
      margin: `${fontSize * 0}cqw 0`

    }

  }
  return (
    <div style={Style.resumeWrapper} className=''>
      <p style={Style.title} className='text-center font-weight-bolder'>Jonathan Doe</p>
      <hr style={Style.marginHR} />
      <p style={Style.subHeadings} className='text-center'>Frontend Developer</p>
      <hr style={Style.marginHR} />
      <p style={Style.common} className='text-center'>Hloom City, HM 98760 ★ 555-123-4567 ★ maryhloom@example.com</p>
      <hr style={Style.marginHR} />
      <hr style={Style.marginHR} />

      <div>
        <h5 style={Style.subHeadings}>Profile Summary</h5>
        <p style={Style.common}>Do away with full addresses and faxes. This is a modern era - employers will email any
          important job interview information or offer letter. Focus instead of adding professional social media
          accounts, online portfolios, business websites alongside your phone number and email.</p>
      </div>

      {/* Experience */}

      <hr style={Style.marginHR} />
      <div className='d-flex col-12'>
        <div className='col-6  border-right'>
          <h5 className='text-center ' style={Style.subHeadings}>Experience</h5>
        </div>
        <div className='col-6'>
          <h5 className='text-center ' style={Style.subHeadings}>Education</h5>
        </div>
      </div>
      <hr style={Style.marginHR} />

      <div className='d-flex'>
        <div className='col-6'>
          <div>
            <p style={Style.common}>Frontend Developer , Kylo Apps</p>
            <p style={Style.common}>New Delhi , MM/YYYY</p>
            <ul style={Style.marginUl}>
              <li style={Style.li}> Responsibility or accomplishments. Use statistics, percentages, or additional
                supporting evidence to demonstrate your

              </li>

            </ul>
          </div>
          <br />
        </div>
        <div className='col-6'>
          <div>
            <p style={Style.common}>Frontend Developer , Kylo Apps</p>
            <p style={Style.common}>New Delhi , MM/YYYY</p>
            <ul style={Style.marginUl}>
              <li style={Style.li}> Responsibility or accomplishments. Use statistics, percentages, or additional
                supporting evidence to demonstrate your

              </li>

            </ul>
          </div>
          <br />
        </div>
      </div>



      <hr style={Style.marginHR} />

      <div className='d-flex'>
        <div className="col-6">
          <ul style={Style.marginUl}>
            <li style={Style.li}> Frontend
            </li>
            <li style={Style.li}> ReactJs
            </li>
            <li style={Style.li}> Music
            </li>
          </ul>
        </div>
        <div className="col-6">
          <ul style={Style.marginUl}>
            <li style={Style.li}> Frontend
            </li>
            <li style={Style.li}> ReactJs
            </li>
            <li style={Style.li}> Music
            </li>
          </ul>
        </div>
      </div>

      <hr style={Style.marginHR} />
      <div className='d-flex col-12'>
        <div className='col-6  border-right'>
          <h5 className='text-center ' style={Style.subHeadings}>Projects</h5>
        </div>
        <div className='col-6'>
          <h5 className='text-center ' style={Style.subHeadings}>Certificate</h5>
        </div>
      </div>
      <hr style={Style.marginHR} />


      <div className='d-flex'>
        <div className='col-6'>
          <div>
            <p style={Style.common}>Frontend Developer , Kylo Apps</p>
            <p style={Style.common}>New Delhi , MM/YYYY</p>
            <ul style={Style.marginUl}>
              <li style={Style.li}> Responsibility or accomplishments. Use statistics, percentages, or additional
                supporting evidence to demonstrate your

              </li>

            </ul>
          </div>
          <br />
        </div>
        <div className='col-6'>
          <div>
            <p style={Style.common}>Frontend Developer , Kylo Apps</p>
            <p style={Style.common}>New Delhi , MM/YYYY</p>
            <ul style={Style.marginUl}>
              <li style={Style.li}> Responsibility or accomplishments. Use statistics, percentages, or additional
                supporting evidence to demonstrate your

              </li>
            </ul>
          </div>
          <br />
        </div>
      </div>

      <hr style={Style.marginHR} />
      <div className='d-flex col-12'>
        <div className='col-12  border-right'>
          <h5 className='text-center ' style={Style.subHeadings}>Voluntary</h5>
        </div>

      </div>
      <hr style={Style.marginHR} />
      <div className='col-12'>
        <div>
          <p style={Style.common}>Frontend Developer , Kylo Apps</p>
          <p style={Style.common}>New Delhi , MM/YYYY</p>
          <ul style={Style.marginUl}>
            <li style={Style.li}> Responsibility or accomplishments. Use statistics, percentages, or additional
              supporting evidence to demonstrate your

            </li>

          </ul>
        </div>
        <br />
      </div>
      <hr style={Style.marginHR} />
      <div className='d-flex col-12'>
        <div className='col-6  border-right'>
          <h5 className='text-center ' style={Style.subHeadings}>Skills</h5>
        </div>
        <div className='col-6'>
          <h5 className='text-center ' style={Style.subHeadings}>Hobbies</h5>
        </div>
      </div>
    </div  >
  );
}

export default RT3non;




