import styled from "styled-components";
export const NavbarStyled = styled.div`
  color: ${({ theme }) => theme.white};
  height: 100px;
  display: flex;
  padding: 0px 20px;
  justify-content: space-between;
  align-items: center;
  background: #fff;
`;
