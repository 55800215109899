import React from "react";
import RT1non from "components/ResumeTemplate/T1/RT1non";
import T2non from "components/ResumeTemplate/T2/RT2non";
import RT2editable from "components/ResumeTemplate/T2/RT2editable";
import RT3non from "components/ResumeTemplate/T3/RT3non";
import RT3editable from "components/ResumeTemplate/T3/RT3editable";
import RT4non from "components/ResumeTemplate/T4/RT4non";
import RT4editable from "components/ResumeTemplate/T4/RT4editable";
import RT5non from "components/ResumeTemplate/T5/RT5non";
import RT6non from "components/ResumeTemplate/T6/RT6non";
import RT7non from "components/ResumeTemplate/T7/RT7non";
import RT5editable from "components/ResumeTemplate/T5/RT5editable";
import RT6editable from "components/ResumeTemplate/T6/RT6editable";
import RT7editable from "components/ResumeTemplate/T7/RT7editable";

const ResumeTemplateJson = [
  {
    templateNo: 0,
    template: T2non,
    templateEdit: RT2editable,
  },
  {
    templateNo: 1,
    template: RT4non,
    templateEdit: RT4editable,
  },
  {
    templateNo: 2,
    template: RT5non,
    templateEdit: RT5editable,
  },
  {
    templateNo: 3,
    template: RT6non,
    templateEdit: RT6editable,
  },
  {
    templateNo: 4,
    template: RT7non,
    templateEdit: RT7editable,
  },
];
export default ResumeTemplateJson;
