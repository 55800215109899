import React, { useState } from "react";
import ai from "assets/image/ai.png";
import "./aibutton.css";
import { setAiCredits } from "helper/redux/Slice/UtilsSlice";
import { useDispatch, useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";

export const AiButton = ({ disabled, SetAi, summarycred }) => {
  const dispatch = useDispatch();
  const [HoverWidth, setHoverWidth] = useState(false);
  const [Attempt, setAttempt] = useState(0);
  const handleAiExpandShow = () => {
    setHoverWidth("19vw");
  };
  const handleAiExpandHide = () => {
    setHoverWidth("");
  };
  const AiCredits = useSelector((item) => item?.utils?.AICredits);

  // onMouseEnter={handleAiExpandShow} onMouseLeave={handleAiExpandHide}
  // const AIData = useSelector(item => item.utils.AICredits)

  const handleClick = () => {
    // if (!disabled && Attempt < 4) {
    //   SetAi()
    //   setAttempt(Attempt + 1)
    // } else if (Attempt > 3) {
    //   toast.error("Ai Button is only 3 times clickable", {
    //     duration: 4000,
    //     position: 'top-right',
    //     style: { backgroundColor: "#521986" },
    //     className: " text-white me-3",
    //     iconTheme: {
    //       primary: "#ffa303"
    //     }
    //   })
    // }
    if (!disabled) {
      SetAi();
      setAttempt(Attempt + 1);
    }
  };

  return (
    <>
      <div
        style={{ width: HoverWidth }}
        className={`${
          disabled && Number(AiCredits) > 0
            ? `ai-button-wrapper-disabled`
            : `ai-button-wrapper-active`
        } ai-button-wrapper`}
        onClick={handleClick}
      >
        {HoverWidth === "19vw" && (
          <div className="d-flex expand">
            <button>Add More</button>
            <button>Re-Generate</button>
          </div>
        )}
        {/* <span className="circle px-2">{AiCredits}</span>
         */}
        {summarycred === "3" ? (
          <span className="circle px-2">3/{AiCredits}</span>
        ) : (
          <span className="circle px-2">2/{AiCredits}</span>
        )}
        <img src={ai} height={20} width={14} alt="icon" />
        <label style={{ color: "white" }}>AI</label>
      </div>
      <Toaster />
    </>
  );
};
