import React from 'react';
import { useSelector } from 'react-redux';

const UseContactInfo = () => {

  let contactValue = 0;
  const ResumeRedux = useSelector(state => state.resume)
  const CoverRedux = useSelector(state => state.cover)
  const types = useSelector(state => state.types)

  if (types?.doctype === "resume") {
    if (!!ResumeRedux?.contact?.firstName) {
      contactValue += 14.2
    }
    if (!!ResumeRedux?.contact?.number) {
      contactValue += 14.2
    }
    if (!!ResumeRedux?.contact?.email) {
      contactValue += 14.2
    }
    if (!!ResumeRedux?.contact?.title) {
      contactValue += 14.2
    }
    if (!!ResumeRedux?.contact?.country) {
      contactValue += 14.2
    }
    if (!!ResumeRedux?.contact?.city) {
      contactValue += 14.2
    }
    if (!!ResumeRedux?.contact?.pincode) {
      contactValue += 14.2
    }
  } else if (types?.doctype === "cover") {
    if (!!CoverRedux?.to?.name) {
      contactValue += 20
    }
    if (!!CoverRedux?.to?.position) {
      contactValue += 20
    }
    if (!!CoverRedux?.to?.address) {
      contactValue += 20
    }
    if (!!CoverRedux?.to?.email) {
      contactValue += 20
    }
    if (!!CoverRedux?.to?.companyName) {
      contactValue += 20
    }
  }
  return contactValue
}

export default UseContactInfo;

