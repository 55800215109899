import {
  ButtonBlue,
  Input,
} from "components/common/CommonStyles";
import React, { useEffect, useState } from "react";
import { AiOutlineUser } from "react-icons/ai";
import { MdOutlineQrCodeScanner } from "react-icons/md";
import { ToastContainer } from "react-toastify";


export const ForgetPassword = ({ setSelect }) => {
  
 

  return (
    <>
      <ToastContainer />
      <div className="mb-3 mt-3">
        <div className="d-flex align-items-center">
          <MdOutlineQrCodeScanner
            className="input-icon"
            color="#ffa303"
            size={28}
          />
          <Input
            placeholder="Enter Mobile No"
            name="mobileNo"
            type="tel"
            className="mb-3 px-5 br-5"
          ></Input>
        </div>
        <div id="recaptcha-container"></div>
        <ButtonBlue className="w-100 h-40 box-shadow" >
          Get Otp
        </ButtonBlue>
        <div className="d-flex align-items-center">
          <AiOutlineUser className="input-icon" color="#ffa303" size={28} />
          <Input
            placeholder="Enter Otp"
            name="otp"
            type="text"
            className="mb-3 px-5 br-5"
          ></Input>
        </div>

        <div className="d-flex justify-content-end my-2">
          <ButtonBlue
            color="white"
            className="w-10 h-40 box-shadow px-3"
          >
            Submit
          </ButtonBlue>
        </div>
      </div>
    </>
  );
};
