import { AiButton } from "components/AiButton/AiButton";
import GptModal from "components/NewResume/GptModal";
import { ChatGPTAPI } from "helper/api";
import { GettingSummary, setContent, setCoverFields, setFieldFocus } from "helper/redux/Slice/CoverSlice";
import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";

export const CollapseInput = ({ title, SaveAndNext }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [DisableTextBox, setDisableTextBox] = useState(true);
  const [loadSpinner, setloadSpinner] = useState(false);
  const dispatch = useDispatch()
  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };
  const [PremiumFeature, setPremiumFeature] = useState(false);

  const handlePremiumFeatureClose = () => {
    setPremiumFeature(false);
  };
  const type = useSelector(item => item.types)
  const CoverData = useSelector(item => item.cover)
  const [contentFields, setcontentFields] = useState("");
  const AiCredit = useSelector(state => state.user.user.aicredits)

  const PostChatGPTData = async () => {
    if (AiCredit < 2) {
      setPremiumFeature(true)
      return;
    }
    setloadSpinner(true)

    let payload;
    type?.coverFor === "Fresher" ?
      payload = {
        question: `on the basis of  ${CoverData?.experiences.length > 0 ? ' Experience includes' + CoverData?.experiences.map((item) => {
          return `Role - ${item?.role} at company ${item?.company} from ${item?.start} to ${!!item.end ? item.end : "present"} at ${item?.location} `
        }) : ""}  , ${CoverData?.education?.length > 0 ? ' ,Education includes' + CoverData?.education.map((item => {
          return ` degree - ${item?.name} from institiute- ${item?.institute} located at ${item?.location} on ${item?.date} having score ${item?.gpa} `
        })) : ""} , ${CoverData?.certification?.length > 0 ? ',Certificate includes' + CoverData?.certification?.map((item) => {
          return `certificate name -${item?.name} provided by ${item?.org} on date ${item?.date} related to ${item?.proof}`
        }) : ""} , ${CoverData?.projects?.length > 0 ? ', project includes ' + CoverData?.projects?.map((item) => {
          return `title - ${item?.title} build at company ${item?.company} for the duration starting from ${item?.start} to ${!!item?.end ? item?.end : "present"} `
        }) : ""} .write cover letter content and replace "your name" in the end with ${CoverData?.from?.name}`
      } :
      payload = {
        question: `on the basis of opportunity of job for the role ${CoverData?.targetJob?.position} in ${CoverData?.targetJob?.company} and skills includes - ${CoverData?.skills?.map((item) => item.name)} and experience of ${CoverData?.totalExperience} and having a education gap for the reason ${CoverData?.reason}  .write cover letter content and replace "your name" in the end with ${CoverData?.from?.name}`
      }
    try {
      const data = await ChatGPTAPI(AiCredit, payload)
      setDisableTextBox(false)
      setloadSpinner(false)
      const text = data?.data?.text.replaceAll("\n", "")

      setcontentFields(text)
      // dispatch(setCoverFields({ type: "content", value: data?.data?.text }))
    } catch (error) {
      setloadSpinner(false)
      setDisableTextBox(false)
      console.log(error)
    }
  }

  const saveToRedux = () => {
    if (!!contentFields) {
      dispatch(setContent(contentFields))
      SaveAndNext()
      toast.success("Content Saved Successfully")
      if (!CoverData?.isAnalysisData) {
        StrongSummary()
        ATSCompliance()
      }
    }
  }



  useEffect(() => {
    if (!!CoverData?.content) {
      setcontentFields(CoverData?.content)
    }
  }, [])


  const StrongSummary = () => {
    let Experience = "";
    CoverData?.experiences?.map((item) => Experience += item?.role + " ,")
    const CoverLetterStrongSummary = {
      question: `Just Give me a score out of 100 for this summary "${contentFields}" in the context of a perfect summary for a resume of a ${Experience}`
    }
    // if (types?.doctype === "resume" && ResumeRedux?.isAnalysisData === false) {
    // }
    // } else if (CoverRedux?.isAnalysisData === false && types?.doctype === "cover") {
    dispatch(GettingSummary({
      AiCredit,
      CoverLetterStrongSummary
    }))
    // }
  }
  const ATSCompliance = () => {
    const CoverletterATS = {
      question: `Just Give me a score out of 100 for this content of cover letter - "${contentFields}" ,  in the context of a ATS Compliance for the profession of ${CoverData?.targetJob?.position}`
    }
    // if (types?.doctype === "resume" && ResumeRedux?.isAnalysisData === false) {
    // }
    // } else if (CoverRedux?.isAnalysisData === false && types?.doctype === "cover") {
    dispatch(GettingSummary({
      AiCredit,
      CoverletterATS
    }))
    // }
  }


  return (
    <div className={`collapseInput-main ${collapsed ? "collapsed" : "open"}`}>
      <div className="firstComp" onClick={toggleCollapse}>
        <div className="d-flex justify-content-between align-items-center w-100">
          <h3>{title}</h3>
          <div >
            <AiButton SetAi={PostChatGPTData} />
          </div>
        </div>
        {/* <span className="ms-3">
          {collapsed ? (
            <RiArrowDownSLine size="24" />
          ) : (
            <RiArrowUpSLine size="24" />
          )}
        </span> */}
      </div>


      {
        loadSpinner ?
          <div className="d-flex justify-content-center my-5">
            <Spinner animation="border" variant="secondary" />
          </div> :

          <div className={`collapse-content`}>
            <textarea
              name="content"
              rows={8}
              id="content"
              value={contentFields}
              onChange={(e) => setcontentFields(e.target.value)}
            // onInput={(e) => {
            //   e.target.style.height = "auto";
            //   e.target.style.height = `${ e.target.scrollHeight } px`;
            // }}
            />
          </div>
      }
      <button className="save-experience" onClick={saveToRedux}>Save </button>
      <GptModal
        PremiumFeature={PremiumFeature}
        handlePremiumFeatureClose={handlePremiumFeatureClose}
      />
    </div>
  );
};
