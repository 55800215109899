import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getAuth, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDkiUkayrKaG2rsdFcPPV4tKYmYiv57UPY",
  authDomain: "viosa-211d0.firebaseapp.com",
  projectId: "viosa-211d0",
  storageBucket: "viosa-211d0.appspot.com",
  messagingSenderId: "365202615608",
  appId: "1:365202615608:web:6ca92ac2d3a79f981c25cd",
  measurementId: "G-NZWQ78ZRXQ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export default app;
// export const db = getFirestore(app);
export const storage = getStorage(app);
export const googleAuthProvider = new GoogleAuthProvider();

export const auth = getAuth();
