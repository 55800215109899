import React, { useState } from 'react';
import './RT2.css'
const RT2non = () => {
  const [fontSize, setfontSize] = useState(1);
  const Style = {
    resumeWrapper: {
      width: "100%",
      minHeight: "550px",
      containerType: "inline-size",
      padding: "5px",
      // background: "#f5f5f5",
      borderTop: "5px solid grey"

    },
    resumeHead: {
      fontSize: fontSize
    },
    title: {
      fontSize: `${fontSize * 5}cqw`,
      marginBottom: `${fontSize * 1}cqw`

    },
    common: {
      fontSize: `${fontSize * 3}cqw`,
      marginBottom: `${fontSize * 1}cqw`,
      lineHeight: "15px"
    },
    li: {
      fontSize: `${fontSize * 3}cqw`,
      marginBottom: `${fontSize * 0}cqw`
    }
    ,
    headings: {
      fontSize: `${fontSize * 4}cqw`,
      fontWeight: "700",
      marginBottom: `${fontSize * 1}cqw`
    },
    subHeadings: {
      fontSize: `${fontSize * 3}cqw`,
      fontWeight: "700",
      marginBottom: `${fontSize * 1}cqw`
    },
    marginHR: {
      margin: `${fontSize * 1}cqw 0`
    },
    marginUl: {
      margin: `${fontSize * 0}cqw 0`

    }

  }
  return (
    <div style={Style.resumeWrapper}>
      <div className="resume-container" >
        <div className="resume-head d-flex justify-content-between">
          <div className="">
            <h1 style={Style.title}>Jonathan Doe</h1>
            <h2 style={Style.common}>Web Designer, Director</h2>
          </div>
          <div class="yui-u">
            <div class="contact-info">
              <h3 style={Style.common}>name@yourdomain.com</h3>
              <h3 style={Style.common}>(313) - 867-5309</h3>
            </div>
          </div>
        </div>
        <hr className='w-100' style={Style?.marginHR} />
        <div>
          <div >
            <div >
              <div class="yui-gf">
                <div class="yui-u first">
                  <h2 style={Style.headings}>Profile</h2>
                </div>
                <div class="yui-u">
                  <p style={Style.common}>
                    Progressively evolve cross-platform ideas before impactful infomediaries. Energistically visualize tactical initiatives before cross-media catalysts for change.
                  </p>
                </div>
              </div>
              <hr className='w-100' style={Style?.marginHR} />

              <div class="yui-gf ">
                <div class="yui-u first">
                  <h2 style={Style.headings}>Skills</h2>
                </div>
                <div class="yui-u d-flex">
                  <ul style={Style.marginUl} >
                    <li style={Style.li}>XHTML</li>
                    <li style={Style.li}>CSS</li>
                    <li style={Style.li}>Javascript</li>
                    <li style={Style.li}>XHTML</li>
                  </ul>

                  <ul style={Style.marginUl}>
                    <li style={Style.li}>CSS</li>
                    <li style={Style.li}>XHTML</li>
                    <li style={Style.li}>Javascript</li>
                    <li style={Style.li}>CSS</li>
                  </ul>
                </div>
              </div>
              <hr className='w-100' style={Style?.marginHR} />

              <div class="yui-gf">

                <div class="yui-u first">
                  <h2 style={Style.headings}>Experience</h2>
                </div>

                <div class="yui-u">

                  <div class="job">
                    <h2 style={Style.subHeadings}>Facebook</h2>
                    <div className='d-flex gap-4'>
                      <p style={Style.subHeadings}>Senior Interface Designer</p>
                      <p style={Style.common}>2005-2007</p>
                    </div>
                    <p style={Style.common}>Intrinsicly enable optimal core competencies through corporate relationships. Phosfluorescently implement worldwide vortals and client-focused imperatives.  </p>
                  </div>
                  <div class="job">
                    <h2 style={Style.subHeadings}>Facebook</h2>
                    <div className='d-flex gap-4'>
                      <p style={Style.subHeadings}>Senior Interface Designer</p>
                      <p style={Style.common}>2005-2007</p>
                    </div>
                    <p style={Style.common}>Intrinsicly enable optimal core competencies through corporate relationships. Phosfluorescently implement worldwide vortals and client-focused imperatives.  </p>
                  </div>



                </div>
              </div>

              <hr className='w-100' style={Style?.marginHR} />

              <div class="yui-gf last">
                <div class="yui-u first">
                  <h2 style={Style.headings}>Education</h2>
                </div>
                <div className=''>
                  <div class="yui-u">
                    <h2 style={Style.subHeadings}>Indiana University - Bloomington, Indiana</h2>
                    <p style={Style.common}>Course    , GPA - 7</p>
                  </div>
                  <div class="yui-u">
                    <h2 style={Style.subHeadings}>Indiana University - Bloomington, Indiana</h2>
                    <p style={Style.common}>Course    , GPA - 7</p>
                  </div>
                  <div class="yui-u">
                    <h2 style={Style.subHeadings}>Indiana University - Bloomington, Indiana</h2>
                    <p style={Style.common}>Course    , GPA - 7</p>
                  </div>
                </div>
              </div>
              <hr className='w-100' style={Style?.marginHR} />

              <div class="yui-gf last">
                <div class="yui-u first">
                  <h2 style={Style.headings}>Certificates</h2>
                </div>
                <div className=''>
                  <div class="yui-u ">
                    <h2 style={Style.subHeadings}>Indiana University - Bloomington, Indiana</h2>
                    <p style={Style.common}>Course    , GPA - 7</p>
                  </div>
                  <div class="yui-u ">
                    <h2 style={Style.subHeadings}>Indiana University - Bloomington, Indiana</h2>
                    <p style={Style.common}>Course    , GPA - 7</p>
                  </div>
                  <div class="yui-u">
                    <h2 style={Style.subHeadings}>Indiana University - Bloomington, Indiana</h2>
                    <p style={Style.common}>Course    , GPA - 7</p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>


      </div>


    </div >
  );
}

export default RT2non;




