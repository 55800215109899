import { setDocSubType } from 'helper/redux/Slice/TypeSlice';
import React from 'react';
import { AiOutlineVideoCameraAdd } from 'react-icons/ai';
import { MdOutlineFileUpload } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';

const VideoSection = () => {
  const dispatch = useDispatch()
  const Type = useSelector(item => item.types)
  return (
    <>
      <div className="create-resume">
        <div
          className={`create-resume-optionsBar ${Type.docsubtype === "trialResume" ? "active" : ""
            }`}
          style={styl}
          onClick={() => dispatch(setDocSubType("trialResume"))}
        >
          <AiOutlineVideoCameraAdd size="20" className="icon" />
          Video Resume
        </div>
        <div
          className={`create-resume-optionsBar ${Type.docsubtype === "automatedResume" ? "active" : ""
            }`}
          onClick={() => dispatch(setDocSubType("automatedResume"))}
          style={styl}
        >
          <MdOutlineFileUpload size="20" className="icon" />{" "}
          Upload Video Resume
        </div>
      </div>

    </>
  );
}

export default VideoSection;


const styl = {
  margin: "0",
  marginRight: "25px",
  fontSize: "13px",
  fontWeight: "700",
  padding: "20px 15px",
  textAlign: "left",
  cursor: "pointer",
};