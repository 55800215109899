import { Form, FloatingLabel } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setCertificateFields,
  UpdateCertificateFields,
} from "helper/redux/Slice/ResumeSlice";
import { setdisableNext } from "helper/redux/Slice/UtilsSlice";
import {
  UpdateCoverCertificate,
  setCoverCertificateFields,
} from "helper/redux/Slice/CoverSlice";
import { toast } from "react-hot-toast";

export const Certificate = ({
  UpdateRedux,
  setUpdateRedux,
  handleResponsivePreviewShow,
}) => {
  const dispatch = useDispatch();
  const CertificateData = useSelector((item) => item.resume.certification);
  const DocType = useSelector((item) => item.types.doctype);

  const [CertificateObj, setCertificateObj] = useState({
    id: CertificateData?.length,
    name: "",
    org: "",
    date: "",
    minor: "",
    proof: "",
  });

  const HandleChange = (e) => {
    const { name, value } = e.target;
    setCertificateObj({ ...CertificateObj, [name]: value });
  };
  const CoverCertificateData = useSelector((item) => item.cover.certification);

  const handleCertificationSubmit = (e) => {
    e.preventDefault();
    if (DocType === "cover") {
      if (!!UpdateRedux) {
        dispatch(UpdateCoverCertificate(CertificateObj, CertificateObj?.index));
        toast.success("Certificate Updated Successfully");
        setUpdateRedux(null);
      } else if (!!CertificateObj?.name) {
        dispatch(setCoverCertificateFields(CertificateObj));
        toast.success("Certificate Saved Successfully");
      }
    } else {
      if (!!UpdateRedux) {
        dispatch(
          UpdateCertificateFields(CertificateObj, CertificateObj?.index)
        );
        setUpdateRedux(null);
        toast.success("Certificate Updated Successfully");
      } else if (!!CertificateObj?.name) {
        dispatch(setCertificateFields(CertificateObj));
        toast.success("Certificate Saved Successfully");
      }
    }
  };

  useEffect(() => {
    if (!!UpdateRedux) {
      setCertificateObj(UpdateRedux);
    }
  }, [UpdateRedux]);

  const ResumeType = useSelector((item) => item.resume);

  //disable next btn
  useEffect(() => {
    if (CertificateData?.length > 0) {
      dispatch(setdisableNext({ type: "certificate", value: false }));
    } else {
      dispatch(setdisableNext({ type: "certificate", value: true }));
    }
  }, [CertificateData]);

  return (
    <div className="create-resume-main">
      <div className="d-flex justify-content-between">
        <div className="d-flex w-100 justify-content-between align-items-center my-1">
          <h3>Certificates, Workshops & Seminars </h3>
          <div>
            <button
              onClick={handleResponsivePreviewShow}
              className="align-self-start p-1 responsive-preview-btn d-inline-block d-lg-none"
            >
              Preview
            </button>
          </div>
        </div>
      </div>
      <div className="text-end pe-2 mb-2">
        <small>
          {DocType === "resume"
            ? !!ResumeType?.profession
              ? "Job Specific"
              : "Generic"
            : ""}
        </small>
      </div>
      {/* <p>
        Et pellentesque scelerisque risus blandit quam tempus. Pellentesque
        sodales nec convallis sit ac.
      </p> */}
      <Form
        className="form-main"
        onSubmit={(e) => handleCertificationSubmit(e)}
      >
        <FloatingLabel
          controlId="floatingInput"
          label={
            <span className="required-label">
              What was the certificate/workshop name?
            </span>
          }
          className="mb-3"
        >
          <Form.Control
            type="text"
            name="name"
            required
            onChange={HandleChange}
            value={CertificateObj?.name}
            placeholder="What was the certificate/workshop name?*"
          />
        </FloatingLabel>
        <FloatingLabel
          controlId="floatingInput"
          label="Company/Institute name"
          className="mb-3"
        >
          <Form.Control
            type="text"
            name="org"
            onChange={HandleChange}
            value={CertificateObj?.org}
            placeholder="Certificate/Workshop issued by?"
          />
        </FloatingLabel>
        <FloatingLabel
          controlId="floatingInput"
          label={<span>Date of certificate/workshop?</span>}
          className="mb-3"
        >
          <Form.Control
            type="date"
            name="date"
            onChange={HandleChange}
            value={CertificateObj?.date}
            placeholder="Date of certificate/workshop?"
          />
        </FloatingLabel>
        <FloatingLabel
          controlId="floatingInput"
          label="Key learnings from workshop/certificate/Seminars."
          className="mb-3"
        >
          <Form.Control
            type="text"
            name="proof"
            onChange={HandleChange}
            value={CertificateObj?.proof}
            placeholder="Key learnings from workshop/ certificate."
          />
        </FloatingLabel>

        <button className="save-experience" type="submit">
          Save To Certificate / Workshop List
        </button>
      </Form>
    </div>
  );
};
