
export const ExperiencePre = () => {
  return (
    <div className="create-resume-main">
      <h3>Experiences</h3>
      <p>
        In a resume, the "Experience" section typically provides a summary of your professional work history. It is a crucial part of your resume as it showcases your past roles, responsibilities, and accomplishments to potential employers
      </p>
    </div>
  );
};
