import { AiFillBook, AiFillHome } from "react-icons/ai";
import { MdGroup, MdContacts, MdSchool } from "react-icons/md";
import { BsFillPersonFill } from "react-icons/bs";
import { create_UUID } from "helper/uuid";

export const SidePanelData = [
  {
    id: create_UUID(),
    name: "Dashboard",
    path: "/",
    icon: <AiFillHome />,
  },
  {
    id: create_UUID(),
    name: "Sample Templates",
    path: "/templates",
    icon: <AiFillBook />,
  },
  {
    id: create_UUID(),
    path: "/best-resume-practice",
    name: "Best Practices",
    icon: <MdGroup />,
  },
  {
    id: create_UUID(),
    path: "/review-resume",
    name: "Analytics",
    icon: <MdSchool />,
  },
  {
    id: create_UUID(),
    path: "/my-profile",
    name: "My Profile",
    icon: <BsFillPersonFill />,
  },
  // {
  //   id: create_UUID(),
  //   path: "/subscription",
  //   name: "Subscription",
  //   icon: <RiMoneyDollarBoxLine />,
  // },
];
