import React, { useState, useEffect, useRef } from "react";
import CommonDashboardCard from "common/CommonDashboardCard";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteAiResume,
  DeleteAutomatedResume,
  DeleteTrailResume,
  GetAiResume,
  GetAutomatedResume,
  GetTrailResume,
} from "helper/api";
import rankbar from "../../../assets/image/rankbar.png";
import LoadingComponent from "common/LoadingComponent";
import { setDocSubType } from "helper/redux/Slice/TypeSlice";
import { toast } from "react-hot-toast";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const ResumeSection = ({ handleStep }) => {
  const Type = useSelector((item) => item.types);
  const dispatch = useDispatch();
  const [Loading, setLoading] = useState(true);
  const [datanotPresent, setdatanotPresent] = useState(false);
  const [ResumeCardData, setResumeCardData] = useState([]);

  //crud apis
  //getting resume data subdoctype wise
  const getResumeData = async () => {
    setLoading(true);
    try {
      let data;
      if (Type?.docsubtype === "trailResume") {
        data = await GetTrailResume();
        if (data?.data?.data?.length === 0) {
          setdatanotPresent(true);
        }
        setResumeCardData(data?.data?.data);
      } else if (Type?.docsubtype === "automatedResume") {
        data = await GetAutomatedResume();
        console.log(data);
        if (data?.data?.data?.length === 0) {
          setdatanotPresent(true);
        } else {
          setResumeCardData(data?.data?.data);
        }
      } else {
        data = await GetAiResume();
        if (data?.data?.data?.length === 0) {
          setdatanotPresent(true);
        }
        setResumeCardData(data?.data?.data);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getResumeData();
  }, [Type?.docsubtype]);

  if (Type?.docsubtype === "") {
    dispatch(setDocSubType("trailResume"));
  }

  const handleDeleteResume = async (id) => {
    try {
      const payload = {
        id: id,
      };
      setResumeCardData(() =>
        ResumeCardData?.filter((item) => item._id !== id)
      );
      if (Type?.docsubtype === "trailResume") {
        await DeleteTrailResume(payload);
        toast.success("Resume is successfully deleted.");
      } else if (Type?.docsubtype === "aiResume") {
        await DeleteAiResume(payload);
      } else {
        await DeleteAutomatedResume(payload);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className="d-flex  col-12  justify-content-between">
        <div className="w-100   ">
          {/* sub btn */}
          <div className=" d-flex overflow-auto my-4 align-items-center">
            {/* <div onClick={() => dispatch(setDocSubType("trailResume"))} className={`${Type?.docsubtype === "trailResume" ? 'resume-sub-btn-active' : "resume-sub-btn-inactive"} resume-sub-btn`}>
              <span className='d-flex align-items-center'><AiOutlinePlusCircle size="22" className="icon me-1" /> New Ai Resume</span>
            </div> */}
            {/* <div onClick={() => dispatch(setDocSubType("automatedResume"))} className={`${Type?.docsubtype === "automatedResume" ? 'resume-sub-btn-active' : "resume-sub-btn-inactive"} resume-sub-btn`}>
              <span className='d-flex align-items-center'><AiOutlinePlusCircle size="22" className="icon me-1" /> Automated Resume</span>
            </div> */}
            {/* <div onClick={() => dispatch(setDocSubType("aiResume"))} className={`${Type?.docsubtype === "aiResume" ? 'resume-sub-btn-active' : "resume-sub-btn-inactive"} resume-sub-btn`}>
              <span className='d-flex align-items-center'><AiOutlinePlusCircle size="22" className="icon me-1" /> New Ai Resume</span>
            </div> */}
          </div>

          {/* <div className="create-resume">
            <div
              className={` p-2 justify-content-center cursor-pointer align-items-center d-flex ${Type.docsubtype === "trailResume" ? "active" : ""
                }`}
              style={styl}
              onClick={() => dispatch(setDocSubType("trailResume"))}
            >
              <AiOutlinePlusCircle size="20" className="icon" />
              <p className='mb-0 '>
                Trial Resume
              </p>
            </div>
            <div
              className={`create-resume-optionsBar p-4 cursor-pointer justify-content-center align-items-center d-flex ${Type.docsubtype === "automatedResume" ? "active" : ""
                }`}
              onClick={() => dispatch(setDocSubType("automatedResume"))}
              style={styl}
            >
              <AiOutlinePlusCircle size="20" className="icon" />{" "}
              <p className='mb-0 '>
                Automated Resume
              </p>
            </div>
            <div
              className={`create-resume-optionsBar p-4 cursor-pointer justify-content-center align-items-center d-flex  ${Type.docsubtype === "aiResume" ? "active" : ""
                }`}
              onClick={() => dispatch(setDocSubType("aiResume"))}
              style={styl}
            >
              <AiOutlinePlusCircle size="20" className="icon" />
              <p className='mb-0 '>
                New Trial Resume
              </p>
            </div>
          </div> */}

          {/* dashboard main card section           */}

          <div className="col-12  d-flex justify-content-between position-relative my-4">
            <div
              className="  flex-wrap w-100 justify-content-center justify-content-sm-start p-2 d-flex  gap-3 "
              style={{ minHeight: "60vh" }}
            >
              {!Loading ? (
                <>
                  <div onClick={handleStep}>
                    <CommonDashboardCard cardFor={"create"} />
                  </div>
                  {/* <CommonDashboardCard cardFor={"upload"} /> */}
                  {ResumeCardData.length > 0
                    ? ResumeCardData?.map((data, index) => (
                        <div key={index}>
                          <CommonDashboardCard
                            data={data}
                            handleDeleteResume={handleDeleteResume}
                          />
                        </div>
                      ))
                    : datanotPresent && (
                        <div className="mt-4" style={{ fontWeight: "700" }}>
                          No history Found
                        </div>
                      )}
                </>
              ) : (
                <div className="h-100 d-flex w-100 flex-column justify-content-center">
                  <LoadingComponent />
                </div>
              )}
            </div>
            {/* <div className="col-1   h-50" style={{ position: "sticky", top: "20%" }}>
              <div className="rank-container border   justify-content-end">
                <div className="rank-sidebar  ">
                  <p>Rank</p>
                  <p>10/100</p>
                  <div className="image-container">
                    <img src={rankbar} height={300} alt="rankbar"></img>
                  </div>

                  <p>Expand</p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResumeSection;

const styl = {};
