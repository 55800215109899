import React, { useState, useEffect, useRef } from "react";
import "../styles/common/dashboardCard.css";
import { useDispatch, useSelector } from "react-redux";
import {
  AiOutlineDownload,
  AiOutlineEye,
  AiOutlinePlusCircle,
} from "react-icons/ai";
import { FiEdit, FiUpload } from "react-icons/fi";
import RT1non from "components/ResumeTemplate/T1/RT1non";
import { MdDeleteOutline } from "react-icons/md";
import { Button, Modal } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import ResumeTemplate1 from "components/ResumeTemplate/ResumeTemplate1";
import RT1editable from "components/ResumeTemplate/T1/RT1editable";
import CT1editable from "components/CoverLetterTemplate/CT1/CT1editable";
import { EditCoverLetter } from "helper/redux/Slice/CoverSlice";
import { setCoverFor } from "helper/redux/Slice/TypeSlice";
import RT2editable from "components/ResumeTemplate/T2/RT2editable";
import ResumeTemplateJson from "contants/ResumeTemplateJson";
import CoverLetterTemplateJson from "contants/CoverLettertemplate";
import RT3editable from "components/ResumeTemplate/T3/RT3editable";
import RT4editable from "components/ResumeTemplate/T4/RT4editable";
import RT4non from "components/ResumeTemplate/T4/RT4non";
import {
  PDFDownloadLink,
  Document,
  Page,
  View,
  Text,
} from "@react-pdf/renderer";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import CT2editable from "components/CoverLetterTemplate/CT2/CT2editable";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import {
  setTemplateNo,
  setfinishUpOption,
} from "helper/redux/Slice/UtilsSlice";
import {
  GettingSummary,
  SetAnalysisCover,
  setCoverFields,
} from "helper/redux/Slice/CoverSlice";
import {
  GettingResumeStrongSummary,
  SetAnalysis,
  SetAnalysisResume,
  setCreateResume,
} from "helper/redux/Slice/ResumeSlice";

import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { pdfjs } from "react-pdf";
import { saveAs } from "file-saver";

const CommonDashboardCard = ({
  cardFor,
  data,
  handleDeleteResume,
  handleDeleteCover,
  forReview,
}) => {
  const container = React.useRef(null);
  const exportPDFWithMethod = () => {
    let element = container.current || document.body;
    savePDF(element, {
      paperSize: "auto",
      margin: 40,
      fileName: `Report for ${new Date().getFullYear()}`,
    });
  };

  const Types = useSelector((item) => item.types);
  const [opacity, setopacity] = useState(0);
  const [ResumeTemplate, setResumeTemplate] = useState(null);
  console.log(ResumeTemplate);
  const [CoverTemplate, setCoverTemplate] = useState(null);

  const [showPreview, setshowPreview] = useState(false);
  const [showDelete, setshowDelete] = useState(false);
  const handlePreviewClose = () => setshowPreview(false);
  const handlePreviewShow = () => setshowPreview(true);
  const handleDeleteClose = () => setshowDelete(false);
  const handleDeleteShow = () => setshowDelete(true);

  const [Review, setReview] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const param = useParams();

  const matchResumeTemplate = () => {
    const templateObj = ResumeTemplateJson?.find(
      (item) => Number(item.templateNo) === Number(data?.template)
    );
    setResumeTemplate(templateObj);
    console.log();
  };

  const matchCoverTemplate = () => {
    const templateObj = CoverLetterTemplateJson?.find(
      (item) => Number(item.templateNo) === Number(data?.template)
    );
    setCoverTemplate(templateObj);
  };
  useEffect(() => {
    if (Types?.doctype === "resume") {
      matchResumeTemplate();
    } else {
      matchCoverTemplate();
    }
  }, []);
  const ResumeRedux = useSelector((item) => item?.resume);
  let ResumeData = useSelector((item) => item?.resume);
  if (!!data) {
    ResumeData = data;
  }
  const CoverRedux = useSelector((item) => item.cover);
  let CoverData = useSelector((item) => item?.cover);
  if (!!data) {
    CoverData = data;
  }
  const Coverref = useRef();
  useEffect(() => {
    if (types?.doctype === "resume") {
      const ResumeHeight = Resumeref?.current?.clientHeight;
      dispatch(
        SetAnalysisResume({
          type: "length",
          value: Number(
            ResumeHeight / 925 >= 1 ? (ResumeHeight / 925).toFixed(0) : 0
          ),
        })
      );
    } else {
      const coverHeight = Coverref?.current?.clientHeight;
      dispatch(
        SetAnalysisCover({
          type: "length",
          value: Number(
            coverHeight / 925 >= 1 ? (coverHeight / 925).toFixed(0) : 0
          ),
        })
      );
    }
  }, [ResumeRedux?.length, CoverRedux?.finishUp?.length]);

  useEffect(() => {
    if (types?.doctype === "resume") {
      setTemplate(ResumeRedux?.template);
    } else {
      setTemplate(CoverRedux?.template);
    }
  }, []);

  const ref = React.createRef();

  const user = useSelector((item) => item.user.user);
  //handle template chooose
  const Resumeref = useRef();
  const handlePrint = () => {
    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: "a4",
      marginLeft: 10,
      marginRight: 10,
      marginTop: 10,
      marginBottom: 10,
    });

    const content = Resumeref.current;

    const fontSize = 14;

    // Calculate the zoom level to fit content within the page
    const pageWidth = pdf.internal.pageSize.getWidth() - 3; // Adjust for left and right margins
    const contentWidth = content.clientWidth;
    const scale = pageWidth / contentWidth;

    // Set the font size and scale the content
    pdf.setFontSize(fontSize);
    pdf.html(content, {
      callback: function (pdf) {
        pdf.save("document.pdf");
      },
      x: 2, // Set the x-coordinate where the content starts
      y: 3, // Set the y-coordinate where the content starts
      html2canvas: { scale }, // Set the scale to adjust content size
    });
  };
  // onClick={() => navigate(`${Types.doctype === `cover` ? `/create-cover-letter` : `/create-resume`}`)}

  const [Template, setTemplate] = useState({});
  const types = useSelector((item) => item.types);
  // const CoverData = useSelector((item) => item.cover);
  const pdfExportComponent = React.useRef(null);
  // const exportPDFWithComponent = () => {
  //   if (pdfExportComponent.current) {
  //     pdfExportComponent.current.save();
  //   }
  // };
  // const exportPDFWithComponent = async () => {
  //   // console.log("ResumeData:", ResumeData);
  //   // console.log("CoverData:", CoverData);
  //   // console.log("CoverData2:", data);

  //   // console.log("Template ID:", data?.template);
  //   const Template = data?.template;
  //   // console.log("Document Type (doctype):", types?.doctype);
  //   const response = await fetch(
  //     "https://resumatic-api.viosa.in/pdf/generate-pdf",
  //     // "http://localhost:4000/pdf/generate-pdf",
  //     {
  //       // Adjust the URL based on your server configuration
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         ResumeData,
  //         Template,
  //         doctype: types?.doctype,
  //         CoverData,
  //       }),
  //     }
  //   );

  //   if (response.ok) {
  //     // Handle the PDF response (e.g., open in a new window or provide a download link)
  //     const pdfBlob = await response.blob();
  //     const pdfUrl = URL.createObjectURL(pdfBlob);

  //     // Example: Open PDF in a new window
  //     window.open(pdfUrl, "_blank");
  //   } else {
  //     // Handle error
  //     const errorMessage = await response.text();
  //     console.error("Failed to generate PDF,", errorMessage);
  //   }
  // };
  // const exportPDFWithComponent = async () => {
  //   const response = await fetch(
  //     "https://resumatic-api.viosa.in/pdf/generate-pdf",
  //     {
  //       // Adjust the URL based on your server configuration
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         ResumeData,
  //         Template,
  //         doctype: types?.doctype,
  //         CoverData,
  //       }),
  //     }
  //   );

  //   if (response.ok) {
  //     // Handle the PDF response (e.g., open in a new window or provide a download link)
  //     const pdfBlob = await response.blob();
  //     const pdfUrl = URL.createObjectURL(pdfBlob);

  //     // Example: Open PDF in a new window
  //     window.open(pdfUrl, "_blank");
  //   } else {
  //     // Handle error
  //     const errorMessage = await response.text();
  //     console.error("Failed to generate PDF,", errorMessage);
  //   }
  // };

  const [loading, setLoading] = useState(false);

  const [pdfUrl, setPdfUrl] = useState(null); // New state to store PDF URL
  const [showPdfModal, setShowPdfModal] = useState(false);
  const [pdfBlob, setPdfBlob] = useState(null);
  const [fullpdfurl, setFullPdfUrl] = useState("");
  const exportPDFWithComponent = async () => {
    try {
      // console.log("ResumeData:", ResumeData);
      // console.log("CoverData:", CoverData);
      // console.log("CoverData2:", data);

      // console.log("Template ID:", data?.template);
      const Template = data?.template;
      // console.log("Document Type (doctype):", types?.doctype);
      const response = await fetch(
        // "https://resumatic-api.viosa.in/pdf/generate-pdf",
        "https://api.viosa.in/resumatic/pdf/generate-pdf",
        // "http://localhost:3200/resumatic/pdf/generate-pdf",
        // "http://localhost:4000/pdf/generate-pdf",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ResumeData,
            Template,
            doctype: types?.doctype,
            CoverData,
          }),
        }
      );

      if (response.ok) {
        // const pdfBlob = await response.blob();
        // setPdfBlob(pdfBlob);
        const { pdfUrl } = await response.json();
        // const fullpdfurl = "https://resumatic-api.viosa.in" + pdfUrl;
        const fullpdfurl = "https://api.viosa.in" + pdfUrl;
        // const fullpdfurl = "http://localhost:3200" + pdfUrl;
        console.log("PDFURL", pdfUrl);
        console.log("Full PDFURL", fullpdfurl);
        setShowPdfModal(true);
        setFullPdfUrl(fullpdfurl);
        // // Create a link element
        // const downloadLink = document.createElement("a");
        // // Set the download link attributes
        // downloadLink.href = fullpdfurl;
        // downloadLink.download = "document.pdf";
        // // Set the Content-Type header
        // downloadLink.type = "application/pdf";
        // // downloadLink.target = "_blank"; // Open the link in a new tab
        // // Append the link to the document body
        // document.body.appendChild(downloadLink);
        // // Trigger a click on the link to start the download
        // downloadLink.click();
        // // Remove the link from the document
        // document.body.removeChild(downloadLink);
        // console.log("PDF downloaded successfully");
        // saveAs(`https://resumatic-api.viosa.in${pdfUrl}`, "document.pdf");
        // window.location.href = pdfUrl;
      } else {
        // Handle error
        const errorMessage = await response.text();
        console.error("Failed to generate PDF,", errorMessage);
      }
    } catch (error) {
      console.error("Error during PDF generation:", error);
    }
  };

  useEffect(() => {
    const TempTemplate = ResumeTemplateJson?.filter(
      (item, index) => Number(item?.templateNo) === Number(data?.template)
    );
    setResumeTemplate(TempTemplate[0]);
  }, [data?.template]);
  return (
    <>
      {/* PDF Modal */}
      <Modal show={showPdfModal} onHide={() => setShowPdfModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Resume PDF Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {fullpdfurl && (
            <Worker
              workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}
            >
              <Viewer fileUrl={fullpdfurl} />
            </Worker>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowPdfModal(false)}>
            Close
          </Button>
          {fullpdfurl && (
            <Button variant="primary" download="document.pdf" href={fullpdfurl}>
              Download PDF
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      <div
        className="common-dashbooard-card cursor-pointer  rounded border d-flex flex-column"
        onMouseOver={() => setopacity(1)}
        onMouseLeave={() => setopacity(0)}
      >
        {/* overlaybuttons */}
        {cardFor !== "create" && cardFor !== "upload" && !forReview && (
          <div
            style={{ opacity, zIndex: "100" }}
            className={`${cardFor !== "create" &&
              cardFor !== "upload" &&
              `card-for-common-overlay`
              } d-flex justify-content-center rounded align-items-center  gap-3`}
          >
            <button
              className="d-flex  gap-2 btn btn-secondary"
              onClick={handlePreviewShow}
            >
              {" "}
              <AiOutlineEye fontSize={25} />{" "}
            </button>

            <button
              className="d-flex  gap-2 btn btn-info"
              onClick={() => {
                {
                  data?.type === "exp" && dispatch(setCoverFor("Experienced"));
                }
                {
                  data?.type === "fresher" && dispatch(setCoverFor("Fresher"));
                }

                navigate(
                  `${Types.doctype === `cover`
                    ? data?.type === "exp"
                      ? `/create-cover-letter/experience/${data?._id}`
                      : `/create-cover-letter/fresher/${data?._id}`
                    : Types?.docsubtype === "automatedResume"
                      ? `/create-resume/automated/${data?._id}`
                      : Types?.docsubtype === "trailResume"
                        ? `/create-resume/trail/${data?._id}`
                        : `/create-resume/ai/${data?._id}`
                  }`
                );
              }}
            >
              {" "}
              <FiEdit fontSize={25} />
            </button>

            <button
              className="d-fdlex  gap-2 btn btn-danger"
              onClick={handleDeleteShow}
            >
              <MdDeleteOutline fontSize={25} />{" "}
            </button>
            <button
              className="d-fdlex  gap-2 btn btn-success"
              onClick={exportPDFWithComponent}
            >
              <AiOutlineDownload fontSize={25} />{" "}
            </button>
            {/* <PDFDownloadLink document={!!ResumeTemplate && <ResumeTemplate.templateEdit data={data} />} fileName="resume.pdf">
              {({ blob, url, loading, error }) =>
                loading ? 'Loading document...' : 'Download Resume'
              }
            </PDFDownloadLink> */}
          </div>
        )}

        {/* cards */}
        {cardFor === "create" ? (
          <>
            <div className="card-for-common align-items-center">
              <AiOutlinePlusCircle fontSize={50} />
            </div>
          </>
        ) : cardFor === "upload" ? (
          <>
            <div className="card-for-common " style={{ background: "#ebebeb" }}>
              <FiUpload fontSize={45} />
            </div>
          </>
        ) : (
          <PDFExport
            paperSize="A4"
            margin={12}
            fileName={`Document`}
            author="KendoReact Team"
            ref={pdfExportComponent}
          >
            <div
              ref={container}
              className={`card-for-common pdf-container ${window.location.pathname.includes("review-resume")
                ? "overflow-auto"
                : "overflow-hidden"
                }`}
            >
              {Types.doctype === "resume" ? (
                <>
                  {/* rfvg */}
                  {!!ResumeTemplate ? (
                    <ResumeTemplate.templateEdit data={data} />
                  ) : (
                    <RT2editable data={data} />
                  )}
                </>
              ) : (
                <>
                  {!!CoverTemplate ? (
                    <CoverTemplate.templateEdit data={data} />
                  ) : (
                    <CT1editable data={data} />
                  )}
                </>
              )}
            </div>
          </PDFExport>
        )}

        {/* footer */}
        <div className="border-top d-flex justify-content-between common-dashbooard-card-footer  justify-self-end p-3">
          {cardFor === "create" ? (
            <>
              <h6>Create New</h6>
              {/* <span className="rounded-pill">Free</span> */}
            </>
          ) : cardFor === "upload" ? (
            <>
              <h6 className="text-center w-100">Import From Linkedin</h6>
            </>
          ) : (
            <>
              <small>{data?.profession}</small>
              <span className="rounded-pill" style={{ background: "orange" }}>
                {Types?.doctype === "resume"
                  ? !!data?.profession
                    ? "Job Specific"
                    : "Generic"
                  : data?.type === "exp"
                    ? "Experienced"
                    : "Fresher"}
              </span>
            </>
          )}
        </div>
      </div>

      {/* previewmmodal */}
      <DeleteModal
        showDelete={showDelete}
        handleDeleteResume={handleDeleteResume}
        handleDeleteCover={handleDeleteCover}
        handleDeleteClose={handleDeleteClose}
        data={data}
      />
      <PreviewModal
        handlePreviewClose={handlePreviewClose}
        Types={Types}
        data={data}
        showPreview={showPreview}
        children={<RT1non />}
      />
    </>
  );
};

export default CommonDashboardCard;

const PreviewModal = ({
  showPreview,
  handlePreviewClose,
  children,
  Types,
  data,
}) => {
  const getTemplateComponent = () => {
    const templateArray =
      Types.doctype === "resume" ? ResumeTemplateJson : CoverLetterTemplateJson;

    // console.log("Selected Template:", data?.template);
    // console.log("Types.doctype:", Types.doctype);
    // console.log("Resume Template Array:", ResumeTemplateJson);

    const selectedTemplate = templateArray.find(
      (item) => Number(item.templateNo) === Number(data?.template)
    );
    console.log(selectedTemplate);
    if (selectedTemplate) {
      const TemplateComponent = selectedTemplate.templateEdit;
      // console.log("changed template component" , data)
      // return <TemplateComponent data={data} />;
      return <TemplateComponent data={data} />;
    }

    // Handle the case when the template is not found
    return <div>Template not found</div>;
  };

  return (
    <>
      <Modal
        show={showPreview}
        size="lg"
        scrollable
        onHide={handlePreviewClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>A4 View Of Resume</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: "16px !important" }}>
          {/* {Types.doctype === "resume" ? (
            <>
              {data?.template === 0 ? (
                <RT2editable data={data} />
              ) : data?.template === 1 ? (
                <RT4editable data={data} />
              ) : (
                <RT3editable data={data} />
              )}
            </>
          ) : (
            <>
              {data?.template === 0 ? (
                <CT1editable data={data} />
              ) : (
                <>
                  <CT2editable data={data} />
                </>
              )}
            </>
          )} */}
          {getTemplateComponent()}
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

const DeleteModal = ({
  showDelete,
  handleDeleteClose,
  data,
  handleDeleteResume,
  handleDeleteCover,
}) => {
  const Type = useSelector((item) => item?.types);
  // () => {
  //   Types.doctype === "resume" ? handleDeleteResume(data?._id) : handleDeleteCover(data?._id, data?.type)
  // }
  const handleDelete = () => {
    try {
      Type.doctype === "resume"
        ? handleDeleteResume(data?._id)
        : handleDeleteCover(data?._id, data?.type);
      handleDeleteClose();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Modal show={showDelete} onHide={handleDeleteClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Do You really Want to delete this{" "}
          {Type.doctype === "resume" ? "Resume" : "Cover"}?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDeleteClose}>
            Close
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
