import { CoverStep } from "components/CoverLetter/CoverStep";
import { NewResumeStep } from "components/NewResume/NewResumeStep";
import VideoResumeStep from "components/VideoResume/VideoResumeStep";
import React from "react";

export const StepProgress = ({ step, changeStepDirect, type }) => {


  return (
    <>
      {type === "resume" ? (
        <NewResumeStep step={step} changeStepDirect={changeStepDirect} />
      ) : (
        type === 'cover' ?
          <CoverStep step={step} changeStepDirect={changeStepDirect} /> :
          <VideoResumeStep step={step} changeStepDirect={changeStepDirect} />
      )}
    </>
  );
};
