import React, { useState } from 'react';
import Plyr from "plyr-react";
import "plyr/dist/plyr.css";
const VideoDescription = ({ setStep }) => {
  const [Check, setCheck] = useState(false);
  const videoSrc = {
    type: "video",
    sources: [
      {
        src: "QFaFIcGhPoM",
        provider: "youtube",
        size: 720,
      }
    ]
  };
  const handleCheck = () => {
    setCheck(!Check)
  }
  return (

    <div className='videoDescription_wrapper d-flex'>
      <div className='pe-4'>
        <h2>Dear Sameer Khatri</h2>
        <p>
          As part of the online interview, you are required to complete a video interview for the role of Business development Sales with Viosa.
        </p>
        <section className='videoDescription_Instruction'>
          <h3>Instruction :</h3>
          <ul>
            <li>This video assessment consists of 4 question(s) and should take you 4 minutes to complete, depending on the number of questions you are assigned.</li>
            <li>You are required to complete your assessment within a single sitting.</li>
            <li>Once you are presented with your first question, an automated timer starts. You will be given a preparation time of 45 seconds , followed by a recording time limit that may vary for each question.</li>
            <li>Remember to keep track of the timer while preparing or recording your response. Should you find yourself ready before the time limit, you can choose to either start recording your responses or to submit them beforehand.</li>
            <li>Don't worry, you will be able to review your video responses at the end of the entire assessment. If you feel that your responses are unsatisfactory, you have the choice to retake the entire assessment</li>
          </ul>
          <p>However, the order and nature of questions are subject to change to ensure you come across as natural and spontaneous during the video interview.</p>
          <hr />
          <div className='d-flex align-items-center videoDescription_Instruction_footer  gap-2'>
            <input onChange={handleCheck} type="checkbox" className='videoDescription_Instruction_checkbox' />
            <span>By applying, you agree with <a href="#"> VIOSA.AI Candidate Terms of Use</a></span>
          </div>
          <div className='step-progres-button'>

            <button
              onClick={() => setStep(1)}
              disabled={!Check ? true : false}
            >
              Next
            </button>
          </div>
        </section>
      </div>
      <div className='col-4 d-flex flex-column gap-5'>
        <div className=''>
          <Plyr source={videoSrc} />
        </div>
        <div className=''>
          <Plyr source={videoSrc} />
        </div>
      </div>
    </div>
  );
}

export default VideoDescription;
