import ResumeTemplate1 from "components/ResumeTemplate/ResumeTemplate1";
import ResumeTemplate2 from "components/ResumeTemplate/ResumeTemplate2";
import RT1non from "components/ResumeTemplate/T1/RT1non";
import CoverLetterTemplateJson from "contants/CoverLettertemplate";
import ResumeTemplateJson from "contants/ResumeTemplateJson";
import { setCoverFields } from "helper/redux/Slice/CoverSlice";
import { setCreateResume } from "helper/redux/Slice/ResumeSlice";
import { setTemplateNo } from "helper/redux/Slice/UtilsSlice";
import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { GetTemplateNoByCollegeId } from "helper/api";

const ChangeTemplateModal = ({
  show,
  HandleHideTemplateModal,
  HandleShowTemplateModal,
  ChangeTemplateNo,
}) => {
  const [TemplateNO, setTemplateNO] = useState(true);
  const [filteredTemplates, setFilteredTemplates] = useState([]);

  const dispatch = useDispatch();
  const type = useSelector((item) => item.types);
  let cookiedata;

  if (!!localStorage.getItem("user2")) {
    cookiedata = JSON.parse(localStorage.getItem("user2"));
  } else {
    cookiedata = JSON.parse(Cookies.get("user"));
  }
  console.log(cookiedata);
  // Assuming collegeid is available in cookiedata
  // useEffect(() => {
  //   const collegeid = cookiedata.collegeid;
  //   console.log(collegeid);
  //   // List of college IDs that should only see template number 2
  //   const restrictedCollegeIds = [
  //     "661f7afde9d17f043bb7c569",
  //     "65f7ff121a524e23670482a5",
  //     "65f7fefe1a524e23670482a0",
  //     "65f7fee71a524e2367048298",
  //     "65f7fead1a524e236704828f",
  //   ];
  //   // Filter templates based on college ID
  //   // const collegeId = parseInt(cookiedata.collegeId); // Convert collegeId to number
  //   if (restrictedCollegeIds.includes(collegeid)) {
  //     // If collegeId matches, show only templateNo 2
  //     const matchedTemplates = ResumeTemplateJson.filter(
  //       (template) => template.templateNo === 3
  //     );
  //     console.log("Matched Templates:", matchedTemplates);
  //     setFilteredTemplates(matchedTemplates);
  //   } else {
  //     // Otherwise, show all templates
  //     setFilteredTemplates(ResumeTemplateJson);
  //     console.log("not matched");
  //   }
  // }, [cookiedata.collegeId]);
  const collegeid = cookiedata.collegeid;
  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        // const response = await axios.get(`/api/user-templates/${collegeid}`);
        const response = await GetTemplateNoByCollegeId(collegeid);
        console.log(response);
        const { templateNos } = response.data;
        console.log("Fetched Template Nos:", templateNos);

        const matchedTemplates = ResumeTemplateJson.filter((template) =>
          templateNos.includes(template.templateNo)
        );
        setFilteredTemplates(matchedTemplates);
      } catch (error) {
        console.error("Error fetching college data:", error);
      }
    };

    fetchTemplates();
  }, [cookiedata.collegeid]);
  return (
    <>
      <Modal show={show} scrollable onHide={HandleHideTemplateModal} centered>
        <Modal.Header closeButton>
          <Modal.Title className="template_modal_head">Templates</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal_Scroll">
          {type.doctype === "resume" ? (
            <div className="d-flex flex-column gap-4">
              {/* {ResumeTemplateJson?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="border rounded"
                    onClick={() => {
                      dispatch(setTemplateNo(index));
                      dispatch(
                        setCreateResume({ type: "template", value: index })
                      );
                      HandleHideTemplateModal();
                    }}
                  >
                    <item.template />
                  </div>
                );
              })} */}
              {filteredTemplates.map((item, index) => (
                <div
                  key={index}
                  className="border rounded"
                  onClick={() => {
                    dispatch(setTemplateNo(index));
                    dispatch(
                      setCreateResume({ type: "template", value: index })
                    );
                    HandleHideTemplateModal();
                  }}
                >
                  <item.template />
                </div>
              ))}
            </div>
          ) : (
            <>
              {CoverLetterTemplateJson?.map((item, index) => {
                return (
                  <div
                    className="border rounded mb-3"
                    key={index}
                    onClick={() => {
                      dispatch(setTemplateNo(index));
                      dispatch(
                        setCoverFields({ type: "template", value: index })
                      );
                      HandleHideTemplateModal();
                    }}
                  >
                    <item.template />
                  </div>
                );
              })}
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ChangeTemplateModal;
