import React from "react";
import { Button, Modal } from "react-bootstrap";
import CrownImg from "../../assets/image/icon/crown.png";
import { MdStars } from "react-icons/md";
import { useNavigate } from "react-router";
import { FiArrowRight } from "react-icons/fi";

const GptModal = ({ PremiumFeature, handlePremiumFeatureClose }) => {
  const navigate = useNavigate();
  return (
    <div>
      <Modal
        show={PremiumFeature}
        onHide={handlePremiumFeatureClose}
        centered
        keyboard={false}
        backdrop="static"
      >
        <Modal.Body className="experience_modal">
          <div className="experience_modal_container">
            {/* <img src={CrownImg} alt="crown" /> */}
            <h6>Sorry! You don't have any credits left</h6>
            <div
              className="experience_modal_feature_contatiner d-flex justify-content-between "
              style={{ flexWrap: "wrap" }}
            ></div>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center ">
          {/* <Button
            variant="secondary"
            onClick={() => navigate("/pricing")}
            className="btn_color"
          >
            Premium Features <MdStars />
          </Button> */}
          <Button
            className="btn_continue"
            onClick={() => {
              // dispatch(setPremiumFeature(false))
              handlePremiumFeatureClose();
            }}
            variant="light"
          >
            Continue Manually <FiArrowRight />
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default GptModal;
