import { Navbar } from "components/Navbar/Navbar";
import { SidePanel } from "components/Sidepanel/SidePanel";
import React, { useState, useEffect } from "react";
import "../../styles/subscription.css";
import { CreateOrderId, GetAllPlan, PostOrderId } from "helper/api";
import LoadingComponent from "common/LoadingComponent";
import loaderLogo from "../../assets/image/loader2.mp4";

const Subscription2 = () => {
  const [AllPlans, setAllPlans] = useState([]);
  const [selectedPlan, setselectedPlan] = useState();
  const [selectedPrice, setselectedPrice] = useState();
  const [step, setstep] = useState(1);
  const [spinn, setspinn] = useState(false);
  const [loading, setloading] = useState(false);
  const [LoadingData, setLoadingData] = useState(false);
  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  useEffect(() => {
    if (!!selectedPlan) {
      setstep(2);
    }
  }, [selectedPlan]);

  const displayRazorpay = async (price) => {
    setspinn(true);
    setstep(3);
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }
    const payload = {
      price: price,
    };
    const result = await CreateOrderId(payload);
    console.log(result);

    if (!result) {
      alert("Server error. Are you online?");
      return;
    }

    const { amount, id, currency } = result?.data?.data;
    const options = {
      key: "rzp_test_ntOq4ysxmOfFpK", // Enter the Key ID generated from the Dashboard
      amount: amount.toString(),
      currency: currency,
      name: "Viosa",
      description: "Test Transaction",
      // image: { logo },
      // order_id: id,
      handler: async function (response) {
        const payload = {
          orderCreationId: id,
          razorpayPaymentId: response.razorpay_payment_id,
          // razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
        };
        try {
          const result = await PostOrderId(payload);
          console.log(result);
        } catch (error) {
          console.log(error);
        }
        // alert(result.data.msg);
      },
      prefill: {
        name: "Viosa",
        email: "viosa@gmail.com",
        contact: "8700061620",
      },
      notes: {
        address: "Viosa ",
      },
      theme: {
        color: "#602a94",
      },
    };
    setspinn(false);
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  const GettingPlans = async () => {
    setloading(true);
    try {
      const res = await GetAllPlan();
      console.log(res);
      setloading(false);
      setAllPlans(res?.data?.data);
    } catch (error) {
      setloading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    GettingPlans();
  }, []);

  return (
    <>
      <div className="d-flex col-12 ">
        <SidePanel />
        <div className="w-100 main-container-wrapper ">
          <Navbar />
          <div className="subscription-wrapper ">
            <div className="text-center subscription2-box p-3 py-4 rounded">
              <h2>Automated Resume Builder</h2>
              {/* <p> repellat reiciendis, voluptatem doloremque nesciunt. Aut ex consequuntur impedit soluta, laboriosam voluptatum.</p> */}
            </div>
            <div
              className="subscription2-box my-5 rounded p-3 d-flex h-100 align-items-center"
              style={{ minHeight: "40vh" }}
            >
              {/* <div className='col-2'>
                <h3>Let's Get Started</h3>
                <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit.</p>
              </div> */}

              <div className="d-flex justify-content-center align-content-center flex-lg-row flex-column w-100 overflow-none overflow-lg-auto gap-4   align-self-stretch">
                {loading ? (
                  <video
                    style={{ width: "50%", marginLeft: "-20px" }}
                    loop
                    src={loaderLogo}
                    autoplay="true"
                    muted="muted"
                    alt="logo"
                  />
                ) : (
                  AllPlans?.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className=" plan-box-cmn text-dark  d-flex py-3"
                      >
                        <small>{item?.name}</small>
                        <div className=" plan-box-money">
                          {" "}
                          <sub>₹</sub> <span>{item?.price}</span>{" "}
                        </div>
                        <h4 className="mb-0">{item?.aicredits} AI Credit</h4>
                        <div className="w-75 ps-5 plan-box-resume-cover my-3">
                          <div>
                            <span className="me-2">✓</span>{" "}
                            <span>{item?.totalResume} Resume</span>{" "}
                          </div>
                          <div>
                            <span className="me-2">✓</span>{" "}
                            <span>{item?.totalCover} Cover letter</span>{" "}
                          </div>
                        </div>

                        <button
                          className="plan-box-btn"
                          onClick={() => {
                            setselectedPrice(item?.price);
                            displayRazorpay(item?.price);
                          }}
                        >
                          Buy Now
                        </button>
                      </div>
                    );
                  })
                )}

                {/* <div className='col-3 plan-box-cmn text-dark d-flex  '>
                  <small>Starter</small>
                  <h3 className='py-2 '>299</h3>
                  <h4 className='mb-0'>10 AI Credit</h4>
                  <small>Lorem ipsum dolor sit.</small>
                  <button className='plan-box-btn'>Buy Now</button>
                </div>

                <div className='col-3 plan-box-cmn text-dark d-flex  '>
                  <small>Starter</small>
                  <h3 className='py-2 '>299</h3>
                  <h4 className='mb-0'>10 AI Credit</h4>
                  <small>Lorem ipsum dolor sit.</small>
                  <button className='plan-box-btn'>Buy Now</button>
                </div> */}

                {/* <div className='col-3 plan-box-cmn'>
                  800
                </div> */}
              </div>
            </div>
            <div className="w-100 d-flex justify-content-end pay-btn">
              {/* {
                !!selectedPrice &&
                <button className='' onClick={displayRazorpay}>Proceed to Pay {spinn && <div className="spinner-border spinner-border-sm text-light " role="status">
                  <span className="sr-only"></span>
                </div>}</button>
              } */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Subscription2;
