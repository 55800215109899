import { useState, useEffect, useRef } from "react";
import { SidePanel } from "components/Sidepanel/SidePanel";
import React from "react";
import { NavLink } from "react-router-dom";
import { Navbar } from "components/Navbar/Navbar";
import { SidePanelData } from "contants/SidepanelData";
import profile from "../assets/image/avatar.png";
import "../styles/profile.css";
import { changePassword, updateProfile } from "helper/api";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "helper/redux/Slice/UserSlice";

// import { UserInfo } from "helper/redux/exportAllAction";
import {
  AiFillEye,
  AiFillEyeInvisible,
  AiFillLock,
  AiOutlineUser,
} from "react-icons/ai";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { Input } from "components/common/CommonStyles";

export const Profile = ({ title }) => {
  const uploadInput = useRef(null);
  const [percentage, setPercentage] = useState(0);
  const [loadingiconupload, setLoadingIconupload] = useState(null);
  const user = useSelector((state) => state?.user?.user);
  const [showpassword, setShowPassword] = useState({
    current: false,
    newp: false,
    cnew: false,
  });
  const [input, setInput] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    profileImg: "",
    address1: "",
    address2: "",
    pincode: "",
  });

  const [password, setPassword] = useState({ current: "", newp: "", cnew: "" });
  const dispatch = useDispatch();
  // console.log(input);

  const handleChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };
  const handlePassword = (e) => {
    setPassword({ ...password, [e.target.name]: e.target.value });
  };

  const uploadIcon = async (e) => {
    if (e.target.files[0]) {
      setLoadingIconupload(true);
      const config = {
        onUploadProgress: (progress) => {
          const percentange = Math.round(
            (progress.loaded / progress.total) * 100
          );
          setPercentage(percentange);
          if (percentange === 100) {
            setLoadingIconupload(false);
          }
        },
      };
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      const rootUrl =
        process.env.NODE_ENV === "production"
          ? "https://uploader.viosa.in/api"
          : "https://uploader.viosa.in/api";
      setLoadingIconupload(true);
      axios
        .post(`${rootUrl}/v2/upload/other`, formData, config)
        .then((data) => {
          console.log(data.data.link);
          setInput({ ...input, [e.target.name]: data.data.link });

          UpdateData(e, "profile", data.data.link)
          setLoadingIconupload(false);
          alert("Image Uploaded Successfully");
        })
        .catch((error) => {
          alert("Try again");
          console.error(error);
          setLoadingIconupload(false);
        });
    }
  };
  const removeProfilePhoto = (e) => {
    setInput({ ...input, profileImg: "" });
    UpdateData(e, "profile", "")
    alert("Profile Image Removed Successfully");
  };

  const [Loading, setLoading] = useState(0);

  const UpdateData = async (e, name, profilePicLink) => {
    e.preventDefault();
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      profileImg,
      address1,
      address2,
      pincode,
    } = input;

    const formdata = {
      name: `${firstName}${!!lastName ? " " + lastName : ""}`,
      email,
      phoneNumber,
      profileImage: profilePicLink,
      address1,
      address2,
      pincode,
      isNameEdit: true
    };
    try {
      if (name === "contact") {
        setLoading(1)
      } else if (name === "address") {
        setLoading(2)
      }

      const res = await updateProfile(user._id, formdata);
      setLoading(0)
      console.log(res)
      dispatch(setUser(res?.data?.data));

      // dispatch(setUser(res?.data?.user));
      // alert("Profile updated successfully");
    } catch (error) {
      setLoading(0)
      console.log(error);
      const { response } = error;
      alert(response.data);
    }
  };

  const UpdatePassword = async (e) => {
    e.preventDefault();
    setLoading(3)
    const { newp, current, cnew } = password;
    if (cnew === newp) {
      const formdata = {
        password: current,
        newpassword: newp,
      };
      try {
        console.log(formdata)
        const res = await changePassword(user._id, formdata);
        setLoading(0)
        // console.log(res.data.data);
        // dispatch(UserInfo(res?.data?.data?.result));
      } catch (error) {
        console.log(error);
        setLoading(0)
        const { response } = error;
        alert(response.data);
      }
    } else {
      alert("password not match");
    }

    setPassword({ current: "", newp: "", cnew: "" });
  };
  useEffect(() => {
    const lengthOfName = user?.name?.split(" ")
    console.log(lengthOfName)
    let newFirstName = "";
    let newLastName = "";
    if (lengthOfName?.length === 1) {
      newFirstName = user?.name
    } else if (lengthOfName?.length === 2) {
      newFirstName = user?.name?.split(" ")[0]
      newLastName = user?.name?.split(" ")[1]
    } else if (lengthOfName?.length > 2) {
      const newarr = lengthOfName.slice(0, lengthOfName.length - 1);
      newFirstName = newarr?.reduce((name, item) => name + " " + item)
      newLastName = user?.name?.split(" ")[lengthOfName.length - 1]
    }
    setInput({
      ...input,
      firstName: newFirstName,
      lastName: newLastName,
      email: user?.email,
      phoneNumber: user?.phoneNumber,
      profileImg: user?.profileImage ? user?.profileImage : "",
      address1: user?.address1 ? user.address1 : "",
      address2: user?.address2 ? user.address2 : "",
      pincode: user?.pincode ? user.pincode : "",
    });
  }, []);



  return (
    <div className="d-flex">
      <SidePanel />

      <div className="w-100 main-container-wrapper">
        <Navbar />

        <div className="main-content ">
          <div className="my-profile-container">
            <div className="profile-subscription-container flex-column flex-lg-row gap-4">

              <div className="profile-container w-100">
                <h3>Your Profile</h3>
                <p>
                  Update your account's email address and profile information
                </p>

                <div className="profile-imageInfo-container">
                  <div className="profile-imageInfo-image-container">
                    <img
                      src={input.profileImg || profile}
                      alt="profile pic"
                    />
                    <input
                      ref={uploadInput}
                      type="file"
                      hidden
                      name="profileImg"
                      onChange={uploadIcon}
                    />
                  </div>
                  <div className="profile-imageInfo-button-container">
                    {loadingiconupload ? (
                      <button> {percentage}% Uploading...</button>
                    ) : (
                      <button onClick={() => uploadInput.current.click()}>
                        Select a new photo
                      </button>
                    )}
                    <button onClick={removeProfilePhoto}>Remove photo</button>
                  </div>
                </div>

                <div className="profile-name-container">
                  <input
                    type="text"
                    placeholder="First Name"
                    name="firstName"
                    
                    value={input.firstName}
                    disabled={true}
                    onChange={handleChange}
                  />
                  <input
                    type="text"
                    placeholder="Last Name"
                    name="lastName"
                    disabled={true}
                    value={input.lastName}
                    onChange={handleChange}
                  />
                </div>

                <input
                  type="email"
                  placeholder="Email ID"
                  name="email"
                  value={input.email}
                  onChange={handleChange}
                />
                <input
                  type="number"
                  placeholder="Phone Number"
                  name="phoneNumber"
                  value={input.phoneNumber}
                  onChange={handleChange}
                />
                <div className="subscription-button-container">
                  {
                    user?.isNameEdit ? null :
                      <button onClick={(e) => UpdateData(e, "contact")}>Update
                        {
                          Loading === 1 && <Spinner className="mx-2" variant="white" size="sm" />
                        }
                      </button>
                  }
                </div>
              </div>

              <div className="contactInfo-container  w-100">
                <h3>Contact Information</h3>
                <p>Update your account's contact information</p>

                <input
                  type="text"
                  placeholder="Address Line 1"
                  name="address1"
                  value={input.address1}
                  onChange={handleChange}
                />
                <input
                  type="text"
                  placeholder="Address Line 2"
                  name="address2"
                  value={input.address2}
                  onChange={handleChange}
                />
                <input
                  type="text"
                  placeholder="Pincode"
                  name="pincode"
                  value={input.pincode}
                  onChange={handleChange}
                />
                <div className="button-container">
                  <button onClick={(e) => UpdateData(e, "address")}>Update
                    {
                      Loading === 2 && <Spinner className="mx-2" variant="white" size="sm" />
                    }
                  </button>
                </div>
              </div>
              {/* 
              <div className="subscription-container">
                <h3>Your Subscription</h3>
                <p>Subscription Details</p>
                <input type="text" placeholder="Subscription Name" />
                <input type="text" placeholder="Start Date" />
                <input type="text" placeholder="End Date" />
                <div className="subscription-button-container">
                  <button style={{ backgroundColor: "#ffa303" }}>
                    Buy Credits
                  </button>
                  <button disabled>Upgrade</button>
                </div>
              </div> */}
            </div>
            {/* 
            <div className="contactInfo-changePassword-container">
              <form
                className="changePassword-container "
                onSubmit={UpdatePassword}
              >
                <h3>Change Password</h3>
                <p>Update your account's password</p>
                <div className="d-flex align-items-center position-relative">
                  <input
                    type={showpassword.current ? "text" : "password"}
                    placeholder="Current Password"
                    name="current"
                    minLength={8}
                    required
                    value={password.current}
                    onChange={handlePassword}
                  />
                  {showpassword.current ? (
                    <AiFillEyeInvisible
                      onClick={() =>
                        setShowPassword({ ...showpassword, current: false })
                      }
                      className="password-eye-2 "
                      color="#ffa303"
                      size={28}
                    />
                  ) : (
                    <AiFillEye
                      onClick={() =>
                        setShowPassword({ ...showpassword, current: true })
                      }
                      className="password-eye-2 "
                      color="#ffa303"
                      size={28}
                    />
                  )}
                </div>
                <div className="d-flex align-items-center position-relative">
                  <input
                    type={showpassword.newp ? "text" : "password"}
                    placeholder="New Password"
                    name="newp"
                    minLength={8}
                    required
                    value={password.newp}
                    onChange={handlePassword}
                  />
                  {showpassword.newp ? (
                    <AiFillEyeInvisible
                      onClick={() =>
                        setShowPassword({ ...showpassword, newp: false })
                      }
                      className="password-eye-2 "
                      color="#ffa303"
                      size={28}
                    />
                  ) : (
                    <AiFillEye
                      onClick={() =>
                        setShowPassword({ ...showpassword, newp: true })
                      }
                      className="password-eye-2 "
                      color="#ffa303"
                      size={28}
                    />
                  )}
                </div>
                <div className="d-flex align-items-center position-relative">
                  <input
                    placeholder="Confirm Password"
                    name="cnew"
                    minLength={8}
                    type={showpassword.cnew ? "text" : "password"}
                    required
                    value={password.cnew}
                    onChange={handlePassword}
                  />
                  {showpassword.cnew ? (
                    <AiFillEyeInvisible
                      onClick={() =>
                        setShowPassword({ ...showpassword, cnew: false })
                      }
                      className="password-eye-2 "
                      color="#ffa303"
                      size={28}
                    />
                  ) : (
                    <AiFillEye
                      onClick={() =>
                        setShowPassword({ ...showpassword, cnew: true })
                      }
                      className="password-eye-2 "
                      color="#ffa303"
                      size={28}
                    />
                  )}
                </div>
                <div className="button-container">
                  <button type="submit">Save
                    {
                      Loading === 3 && <Spinner className="mx-2" variant="white" size="sm" />
                    }
                  </button>
                </div>
              </form>
            </div> */}
          </div>

        </div>
      </div>
    </div>
  );
};
