import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { setUser } from "./redux/Slice/UserSlice";

export const ProtectedRoutes = ({ children }) => {
  // const token = Cookies.get("@token");

  const token = useSelector(item => item?.user?.user)
  const token2 = useSelector(item => item?.user)
  return (!!token || token2 !== {}) ? children : false;
};
