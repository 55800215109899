import { Form, FloatingLabel, Spinner } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setInvolvementFields,
  UpdateInvolvementFields,
} from "helper/redux/Slice/ResumeSlice";
import { setAiCredits, setdisableNext } from "helper/redux/Slice/UtilsSlice";
import { ChatGPTAPI, CutAiCredits } from "helper/api";
import { AiButton } from "components/AiButton/AiButton";
import {
  UpdateCoverInvolvement,
  setCoverInvolvementFields,
} from "helper/redux/Slice/CoverSlice";
import GptModal from "./GptModal";
import { toast } from "react-hot-toast";

export const Involvement = ({
  UpdateRedux,
  setUpdateRedux,
  handleResponsivePreviewShow,
}) => {
  const dispatch = useDispatch();
  const InvolvementData = useSelector((item) => item.resume.involvement);
  const CoverInvolvementData = useSelector((item) => item.cover.involvement);

  const [loadSpinner, setloadSpinner] = useState(false);
  const [PremiumFeature, setPremiumFeature] = useState(false);
  const [InvolvementObj, setInvolvementObj] = useState({
    id: InvolvementData?.length,
    role: "",
    company: "",
    location: "",
    origin: "",
    start: "",
    end: "",
    isWorking: false,
    description: "",
  });

  const HandleChange = (e) => {
    const { name, value } = e.target;
    setInvolvementObj({ ...InvolvementObj, [name]: value });
  };

  const handleEndData = () => {
    setInvolvementObj({
      ...InvolvementObj,
      isWorking: !InvolvementObj?.isWorking,
      end: "",
    });
  };

  const DocType = useSelector((item) => item.types.doctype);
  const handleInvolvementSubmit = (e) => {
    e.preventDefault();
    if (DocType === "cover") {
      if (!!UpdateRedux) {
        dispatch(UpdateCoverInvolvement(InvolvementObj, InvolvementObj?.index));
        setUpdateRedux(null);
        toast.success("Voluntary Updated Successfully");
      } else if (
        !!InvolvementObj?.description &&
        !!InvolvementObj?.role &&
        !!InvolvementObj?.company &&
        !!InvolvementObj?.start &&
        (InvolvementObj?.isWorking || !!InvolvementObj?.end)
      ) {
        dispatch(setCoverInvolvementFields(InvolvementObj));
        toast.success("Voluntary Saved Successfully");
      }
    } else {
      if (!!UpdateRedux) {
        dispatch(
          UpdateInvolvementFields(InvolvementObj, InvolvementObj?.index)
        );
        toast.success("Voluntary Updated Successfully");
        setUpdateRedux(null);
      } else if (
        !!InvolvementObj?.description &&
        !!InvolvementObj?.role &&
        !!InvolvementObj?.company &&
        !!InvolvementObj?.start &&
        (InvolvementObj?.isWorking || !!InvolvementObj?.end)
      ) {
        dispatch(setInvolvementFields(InvolvementObj));
        toast.success("Voluntary Saved Successfully");
      }
    }
  };

  useEffect(() => {
    if (!!UpdateRedux) {
      setInvolvementObj(UpdateRedux);
    }
  }, [UpdateRedux]);

  const ResumeType = useSelector((item) => item.resume);

  //disable next btn
  useEffect(() => {
    if (InvolvementData?.length > 0) {
      dispatch(setdisableNext({ type: "voluntary", value: false }));
    } else {
      dispatch(setdisableNext({ type: "voluntary", value: true }));
    }
  }, [InvolvementData]);

  const [DisableAiBtn, setDisableAiBtn] = useState(true);

  useEffect(() => {
    if (!!InvolvementObj?.company && !!InvolvementObj?.role) {
      setDisableAiBtn(false);
    } else {
      setDisableAiBtn(true);
    }
  }, [InvolvementObj?.role, InvolvementObj?.company]);

  const handlePremiumFeatureClose = () => {
    setPremiumFeature(false);
  };
  const AiCredits = useSelector((item) => item?.user?.user?.aicredits);
  // const AiCredits = useSelector((item) => item?.user?.user?.aicredits)

  const PostChatGPTData = async () => {
    if (AiCredits < 2) {
      setPremiumFeature(true);
      return;
    }
    // if (!!ProjectObj?.title && !!ProjectObj?.company) {
    setloadSpinner(true);
    const temperature = Math.random() * (0.4 - 0.1) + 0.1;
    const payload = {
      question: `Generate one ATS-compliant sentence in past tense describing a key activity, work, or task for the role of ${InvolvementObj.role} at ${InvolvementObj.company}. Ensure the description is concise, grammatically correct, and optimized for ATS systems.`,
      temperature: temperature.toFixed(1), // Limit to one decimal place
    };
    console.log("Before making ChatGPTAPI call");
    try {
      const data = await ChatGPTAPI(AiCredits, payload);
      console.log("After successful ChatGPTAPI call", data);

      if (!data) {
        setPremiumFeature(true);
        setloadSpinner(false);
        return;
      }
      // const newText = data?.data?.text.replace(/[\r\n]+/g, " "); // Replace newline characters
      // const updatedDescription =
      //   InvolvementObj.description && InvolvementObj.description.trim() // Check for existing description
      //     ? `${InvolvementObj.description}\n${newText}` // Append with a newline
      //     : newText;
      // Split the response into bullet points (if multiple sentences)
      const bulletPoints = data?.data?.text.split(/\r?\n|\.\s/).filter(Boolean);
      const formattedDescription = bulletPoints
        .map((point) => `• ${point.trim()}`)
        .join("\n");

      // Append the new bullet points to the existing description (if any)
      const updatedDescription =
        InvolvementObj.description && InvolvementObj.description.trim()
          ? `${InvolvementObj.description}\n${formattedDescription}` // Append with newline
          : formattedDescription; // Use the generated bullet point if no description exists

      setInvolvementObj({
        ...InvolvementObj,
        description: updatedDescription,
      });
      // setInvolvementObj({
      //   ...InvolvementObj,
      //   description: !!InvolvementObj.description
      //     ? InvolvementObj.description + "\n" + data?.data?.text
      //     : data?.data?.text,
      // });
      const aiCreditPayload = {
        credit: 2,
        used: "Voluntary",
      };
      toast.success("Your 2 AI credits has been used.");
      setloadSpinner(false);
      dispatch(setAiCredits(aiCreditPayload?.credit));
      await CutAiCredits(aiCreditPayload);
    } catch (error) {
      setloadSpinner(false);
      console.log(error);
    }
    // }
  };

  const handleTextFieldChange = (e) => {
    setInvolvementObj({
      ...InvolvementObj,
      description:
        InvolvementObj?.description?.length === 0 ||
        InvolvementObj?.description === undefined
          ? "• " + e.target.value
          : e.target.value,
    });
  };
  const checkEnter = (e) => {
    if (e.key === "Enter") {
      setInvolvementObj({
        ...InvolvementObj,
        description: InvolvementObj.description + "• ",
      });
    }
  };
  return (
    <div className="create-resume-main">
      <div className="d-flex justify-content-between">
        <div className="d-flex w-100 justify-content-between align-items-center my-1">
          <h3>Extra Curricular, Co-Curricular & Voluntary </h3>
          <div>
            <button
              onClick={handleResponsivePreviewShow}
              className="align-self-start p-1 responsive-preview-btn d-inline-block d-lg-none"
            >
              Preview
            </button>
          </div>
        </div>
      </div>
      <div className="text-end pe-2 mb-2">
        <small>
          {DocType === "resume"
            ? !!ResumeType?.profession
              ? "Job Specific"
              : "Generic"
            : ""}
        </small>
      </div>
      {/* <p>
                Et pellentesque scelerisque risus blandit quam tempus. Pellentesque
                sodales nec convallis sit ac.
            </p> */}
      <Form className="form-main" onSubmit={handleInvolvementSubmit}>
        <FloatingLabel
          controlId="floatingInput"
          label={
            <span className="required-label">
              Give a short description on the role at the organisation
            </span>
          }
          className="mb-3" //////////////////////////
        >
          <Form.Control
            type="text"
            name="role"
            onChange={HandleChange}
            required
            value={InvolvementObj?.role}
            placeholder="What was your role at the organization?"
          />
        </FloatingLabel>

        <FloatingLabel
          controlId="floatingInput"
          label={
            <span className="required-label">
              For which organization did you work?
            </span>
          }
          className="mb-3"
        >
          <Form.Control
            type="text"
            required
            name="company"
            onChange={HandleChange}
            value={InvolvementObj?.company}
            place
            holder="For which organization did you work? *"
          />
        </FloatingLabel>

        <div className="row">
          <div className="col-lg-6">
            <FloatingLabel
              controlId="floatingPassword"
              label={
                <span className="required-label">
                  When did you start volunteering?
                </span>
              }
              className="mb-3"
            >
              <Form.Control
                type="date"
                required
                name="start"
                onChange={HandleChange}
                value={InvolvementObj?.start}
                placeholder="How long were you with the company? "
              />
            </FloatingLabel>
          </div>

          <div className="col-lg-6">
            <FloatingLabel
              controlId="floatingPassword"
              label="When did you finished the volunteering ? "
              className="mb-1"
            >
              <Form.Control
                name="end"
                onChange={HandleChange}
                value={InvolvementObj?.end}
                min={InvolvementObj?.start}
                disabled={!InvolvementObj?.isWorking ? false : true}
                type="date"
                placeholder="End Date"
              />
            </FloatingLabel>
            <div className="checkboxs mb-2">
              <label>Currently working </label>
              <input
                type="checkbox"
                onChange={handleEndData}
                value={InvolvementObj?.isWorking}
                className=""
              />
            </div>
          </div>
        </div>
        <div className="add-experience-container">
          <div className="mb-0 add-experience-heading">
            <p className="mb-0 add-experience-heading ">
              {
                <span className="required-label">
                  What did you volunteer for?
                </span>
              }
            </p>
            <AiButton disabled={DisableAiBtn} SetAi={PostChatGPTData} />
          </div>

          {loadSpinner ? (
            <div className="d-flex justify-content-center my-5">
              <Spinner animation="border" variant="secondary" />
            </div>
          ) : (
            <Form.Control
              as="textarea"
              value={InvolvementObj?.description}
              onChange={handleTextFieldChange}
              onKeyUp={checkEnter}
              name="description"
              className="px-4"
              required
              placeholder="Type or Use 'AI' Button"
              style={{ height: "100px" }}
            />
          )}
        </div>
        <button className="save-experience" type="submit">
          Save To Voluntary List
        </button>
      </Form>

      {/* premium modal */}
      <GptModal
        PremiumFeature={PremiumFeature}
        handlePremiumFeatureClose={handlePremiumFeatureClose}
      />
    </div>
  );
};
