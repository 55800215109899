import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null
}

const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    removedata(state, action) {
      state.user = null
    }
  }
})

export const { setUser, removedata } = UserSlice.actions
export default UserSlice.reducer;


