import { setCoverFields, setFieldFocus } from "helper/redux/Slice/CoverSlice";
import { setCoverFor } from "helper/redux/Slice/TypeSlice";
import React, { useState, useEffect } from "react";
import { Form, FloatingLabel } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
export const ExpGap = () => {
  const [coverType, setCoverType] = useState("Fresher");
  const dispatch = useDispatch()
  const GapData = useSelector(item => item.cover)
  const [selected, setSelected] = useState("x");
  const styled1 = {

    backgroundColor: "white",
    color: "#521986",
    width: "100%",
    margin: "5px 10px",
    padding: "15px"

  }

  const styled2 = {

    backgroundColor: "#521986",
    color: "white",
    width: "100%",
    margin: "5px 10px",
    padding: "15px"

  }

  useEffect(() => {
    if (!!GapData?.Field) {
      document.querySelector(`#${GapData?.Field}`)?.focus()
    }
  }, [GapData])

  const handleChange = (e) => {
    const { name } = e.target
    dispatch(setFieldFocus(name))
    dispatch(setCoverFields({ type: "reason", value: e.target.value }))

  }
  return (
    <div className="create-resume-main">
      <div>
        <h3>
          Do you have a gap in you work history that you want to explain in you
          letter
        </h3>
        <p>
          {/* Et pellentesque scelerisque risus blandit quam tempus. Pellentesque
          sodales nec convallis sit ac. */}
        </p>

        <div
          className="form-main form-main-2 d-flex flex-row expGap-button-container"
          style={{ minHeight: "100%", borderBottomLeftRadius: "0", borderBottomRightRadius: "0", border: "none" }}
        >
          <button style={GapData.gap === true ? styled2 : styled1} onClick={() => {
            dispatch(setCoverFields({ type: "gap", value: true }))
          }}>
            Yes
          </button>
          <button style={GapData.gap === false ? styled2 : styled1} onClick={() => {
            dispatch(setCoverFields({ type: "gap", value: false }))
          }}>
            No
          </button>
        </div>


        {GapData.gap === true && <Form className="form-main" style={{ border: "none", borderTopLeftRadius: "0", borderTopRightRadius: "0" }}>
          <FloatingLabel
            controlId="floatingInput"
            label="Reason for gap"
            className="mb-3"
          >
            <Form.Control id="reason" name="reason" value={GapData?.reason} onChange={handleChange} type="text" placeholder="e.g. COVID" />
          </FloatingLabel>
        </Form>}
      </div>


    </div>
  );
};
