import { useState } from "react";
import { Education } from "../NewResume/Education";
import { Experiences } from "../NewResume/Experiences";
import { Certificate } from "../NewResume/Certificate";
import { Projects } from "../NewResume/Projects";
import { Involvement } from "../NewResume/Involvement";

export function Highlight({
  addToProjectList,
  handleResponsivePreviewShow,
  addToCertificateList,
  FresherHighlightStep,
  UpdateRedux,
  setUpdateRedux,
  addToEducationList,
  setFresherHighlightStep,
}) {
  const tabs = [
    "Education",
    "Experience",
    "Certificates",
    "Project",
    "Volunteering",
  ];
  const selectedStyle = {
    color: "white",
    backgroundColor: "#521986",
  };

  return (
    <>
      <div className="highlight-container col-12">
        <div className="highlight-tabs-container  overflow-auto gap-4">
          {tabs?.map((val, index) => {
            return (
              <span
                key={index}
                onClick={() => setFresherHighlightStep(index + 1)}
                style={FresherHighlightStep === index + 1 ? selectedStyle : {}}
              >
                {val}
              </span>
            );
          })}
        </div>

        <div className="highlight-body-container">
          {FresherHighlightStep === 1 && (
            <Education
              handleResponsivePreviewShow={handleResponsivePreviewShow}
              add={addToEducationList}
              UpdateRedux={UpdateRedux}
              setUpdateRedux={setUpdateRedux}
            />
          )}
          {FresherHighlightStep === 2 && (
            <Experiences
              handleResponsivePreviewShow={handleResponsivePreviewShow}
              UpdateRedux={UpdateRedux}
              setUpdateRedux={setUpdateRedux}
            />
          )}
          {FresherHighlightStep === 3 && (
            <Certificate
              handleResponsivePreviewShow={handleResponsivePreviewShow}
              add={addToCertificateList}
              UpdateRedux={UpdateRedux}
              setUpdateRedux={setUpdateRedux}
            />
          )}
          {FresherHighlightStep === 4 && (
            <Projects
              handleResponsivePreviewShow={handleResponsivePreviewShow}
              add={addToProjectList}
              UpdateRedux={UpdateRedux}
              setUpdateRedux={setUpdateRedux}
            />
          )}
          {FresherHighlightStep === 5 && (
            <Involvement
              handleResponsivePreviewShow={handleResponsivePreviewShow}
              UpdateRedux={UpdateRedux}
              setUpdateRedux={setUpdateRedux}
            />
          )}
        </div>
      </div>
    </>
  );
}
