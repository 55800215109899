import { SidePanel } from "components/Sidepanel/SidePanel";
import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Navbar } from "components/Navbar/Navbar";
import { SidePanelData } from "contants/SidepanelData";
import { Finishup } from "components/NewResume/Finishup";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { AiOutlineFileAdd, AiOutlineFilePdf } from "react-icons/ai";

import { BsFillLightningFill } from "react-icons/bs";
import "../../styles/review.css";
import Card from "components/Card/Card";
import {
  ChatGPTAPI,
  GetAiResume,
  GetAutomatedResume,
  GetCoverLetter,
  GetResume,
  GetTrailResume,
} from "helper/api";
import { useDispatch, useSelector } from "react-redux";
import CommonDashboardCard from "common/CommonDashboardCard";
import {
  setCoverFor,
  setDocSubType,
  setDocType,
} from "helper/redux/Slice/TypeSlice";
import AnalysisFeature from "common/AnalysisFeature";
import ResumeTemplateJson from "contants/ResumeTemplateJson";
import { setReviewEdit, setTemplateNo } from "helper/redux/Slice/UtilsSlice";
import {
  UpdateContactRedux,
  setCreateResume,
} from "helper/redux/Slice/ResumeSlice";
import CoverLetterTemplateJson from "contants/CoverLettertemplate";
import { setCoverFields, setFrom } from "helper/redux/Slice/CoverSlice";
import Cookies from "js-cookie";

export function Review() {
  const [step, setStep] = useState(1);
  const [selected, setSelcted] = useState("resume");
  const [selectedIndex, setselectedIndex] = useState(0);
  const [selectedTemplate, setselectedTemplate] = useState();
  console.log(selectedTemplate);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const Type = useSelector((item) => item?.types);

  const analyticsData = [
    "Completeness",
    // "Formatiing",
    // "Word Choice",
    // "Clear Contact Info",
    "ATS Compliance ",
    "Strong Summary",
    "No Typos",
    "Length",
  ];

  const selectedSyle = {
    color: "white",
    backgroundColor: "#521986",
  };
  const [ResumeCardData, setResumeCardData] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [datanotPresent, setdatanotPresent] = useState(false);
  const [filteredTemplates, setFilteredTemplates] = useState([]);
  const getResumeData = async () => {
    setLoading(true);
    try {
      let data;
      console.log("Data: ", data);
      if (Type?.doctype === "resume") {
        if (Type?.docsubtype === "trailResume") {
          data = await GetTrailResume();
        } else if (Type?.docsubtype === "aiResume") {
          data = await GetAiResume();
        } else {
          data = await GetAutomatedResume();
        }
      } else {
        data = await GetCoverLetter();
      }
      if (data?.data?.data.length == 0) {
        setdatanotPresent(true);
      }
      const newdata = [...data?.data?.data];
      setselectedTemplate(newdata[0]);
      setResumeCardData(newdata);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  dispatch(setReviewEdit(false));
  useEffect(() => {
    if (!!selectedTemplate) {
      if (!!selectedTemplate?.type && selectedTemplate?.type === "exp") {
        dispatch(setCoverFor("Experienced"));
      } else if (
        !!selectedTemplate?.type &&
        selectedTemplate?.type === "fresher"
      ) {
        dispatch(setCoverFor("Fresher"));
      }
    }
  }, [selectedTemplate]);

  console.log(selectedTemplate);
  useEffect(() => {
    getResumeData();
  }, [Type?.doctype, Type?.docsubtype]);

  const UserData = useSelector((item) => item?.user?.user);

  // const handleNewDoc = () => {
  //     if (Type.doctype === "cover") {
  //         navigate('/create-cover')
  //     } else if (Type.doctype === "resume") {
  //         navigate('/create-resume')
  //     }
  // }

  let cookiedata;

  if (!!localStorage.getItem("user2")) {
    cookiedata = JSON?.parse(localStorage?.getItem("user2"));
  } else {
    cookiedata = JSON?.parse(Cookies.get("user"));
  }
  console.log(cookiedata);
  // Assuming collegeid is available in cookiedata
  useEffect(() => {
    const collegeid = cookiedata.collegeid;
    console.log(collegeid);
    // List of college IDs that should only see template number 2
    const restrictedCollegeIds = [
      "661f7afde9d17f043bb7c569",
      "65f7ff121a524e23670482a5",
      "65f7fefe1a524e23670482a0",
      "65f7fee71a524e2367048298",
      "65f7fead1a524e236704828f",
    ];
    // Filter templates based on college ID
    // const collegeId = parseInt(cookiedata.collegeId); // Convert collegeId to number
    if (restrictedCollegeIds.includes(collegeid)) {
      // If collegeId matches, show only templateNo 2
      const matchedTemplates = ResumeTemplateJson.filter(
        (template) => template.templateNo === 3
      );
      console.log("Matched Templates:", matchedTemplates);
      setFilteredTemplates(matchedTemplates);
    } else {
      // Otherwise, show all templates
      setFilteredTemplates(ResumeTemplateJson);
      console.log("not matched");
    }
  }, [cookiedata.collegeId]);
  return (
    <div className="d-flex">
      <SidePanel>
        <div className="px-4 py-4">
          {SidePanelData.map((item) => (
            <NavLink to={item.path} className="sidebar__link" key={item.id}>
              <div className="d-flex align-items-center py-2 wrapper__sidebar">
                {item.icon}
                <h6 className="sidebar__heading px-2 mt-2">{item.name}</h6>
              </div>
            </NavLink>
          ))}
        </div>
      </SidePanel>
      <div className="w-100 main-container-wrapper">
        <Navbar />

        {step === 1 && (
          <div className="main-content  review-page-container">
            <div className="review-page-header-container">
              <h3>Enhance your Cover letter/Resume</h3>
            </div>

            <div className="review-page-body-container-one flex-column flex-lg-row">
              {/* <img alt="selected-template" src={selectedImage} /> */}
              {Loading ? (
                <div className="col-3">
                  <div className="d-flex justify-content-center w-100 mt-5">
                    <div
                      class="spinner-border text-secondary mt-5"
                      role="status"
                    >
                      <span class="sr-only"></span>
                    </div>
                  </div>
                </div>
              ) : ResumeCardData?.length === 0 ? (
                <div className="d-flex col-12 justify-content-center flex-column align-items-center p-2 bg-white col-lg-4 ">
                  <strong className="mb-3">
                    {Type?.doctype === "cover"
                      ? "No Cover Letter Present add one"
                      : "No Resume Present add one"}
                  </strong>
                  <div onClick={() => setStep(2)}>
                    <CommonDashboardCard cardFor={"create"} />
                  </div>
                </div>
              ) : (
                <div className="bg-white review-selected-template-container w-100 md:me-5 me-2">
                  <CommonDashboardCard
                    type={selected}
                    template={selectedTemplate?.template}
                    jobType={selectedTemplate?.template}
                    data={selectedTemplate}
                    reviewType={selected}
                    showFooter={true}
                    forReview={true}
                  />
                </div>
              )}

              <div className=" col-12 col-lg-6 analytics-card-container justify-content-center d-flex flex-wrap h-50 gap-2 md:me-5 me-2 pt-4 p-sm-0">
                <AnalysisFeature
                  data={selectedTemplate}
                  customizeWidth={"32%"}
                />
              </div>

              <div className="review-info-container col-lg-2 col-12">
                <h4>
                  Your selected <span>{selected}</span>
                </h4>
                <p>Now you can customise it for any job you want.</p>
                <button
                  onClick={() => {
                    dispatch(setReviewEdit(true));
                    Type?.doctype === "resume"
                      ? Type?.docsubtype === "trail"
                        ? navigate(
                            `/create-resume/trail/${selectedTemplate?._id}`
                          )
                        : navigate(
                            `/create-resume/automated/${selectedTemplate?._id}`
                          )
                      : Type?.doctype === "cover" &&
                        Type?.coverFor === "Experienced"
                      ? navigate(
                          `/create-cover-letter/experience/${selectedTemplate?._id}`
                        )
                      : navigate(
                          `/create-cover-letter/fresher/${selectedTemplate?._id}`
                        );
                  }}
                >
                  Improve Now <BsFillLightningFill />
                </button>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="practice-videos-buttonTab">
                <button
                  onClick={() => {
                    dispatch(setDocType("resume"));
                  }}
                  style={Type?.doctype === "resume" ? selectedSyle : {}}
                >
                  <AiOutlineFileAdd size="20" className="icon" />
                  Resume
                </button>
                <button
                  onClick={() => {
                    dispatch(setDocType("cover"));
                  }}
                  style={Type?.doctype === "cover" ? selectedSyle : {}}
                >
                  <AiOutlineFilePdf size="20" className="icon" />
                  Cover Letter
                </button>
              </div>
              {/* {
                            Type?.doctype === "resume" &&

                            <select class="form-select form-select w-25" onChange={(e) => { dispatch(setDocSubType(e.target.value)) }} aria-label=".form-select-sm example">
                                <option selected>Select Subtype</option>
                                <option value="trailResume">Ai Resume</option>
                                <option value="automatedResume">Automated</option>
                            </select>


                        } */}
            </div>
            <div />

            <div className="resume-cover-template-container">
              <div className="d-flex gap-4 col-12 mb-5"></div>

              <div className="d-flex flex-wrap gap-2 mb-5">
                {!Loading ? (
                  ResumeCardData?.map((data, index) => {
                    return (
                      <div
                        onClick={() => {
                          setselectedTemplate(data);
                          setselectedIndex(index);

                          Type?.doctype === "cover" && data?.type === "exp"
                            ? dispatch(setCoverFor("Experienced"))
                            : dispatch(setCoverFor("Fresher"));
                        }}
                        className="rounded cursor-pointer h-auto"
                        style={{
                          border: `${
                            selectedIndex === index ? `3px solid #521986` : " "
                          } `,
                        }}
                      >
                        {" "}
                        <CommonDashboardCard
                          type={data.type}
                          template={data?.template}
                          jobType={data.profession}
                          image={data.image}
                          data={data}
                          reviewType={selected}
                          showFooter={true}
                          forReview={true}
                          profession={data?.profession}
                        />
                      </div>
                    );
                  })
                ) : (
                  <div className="d-flex justify-content-center w-100 mt-5">
                    <div
                      class="spinner-border text-secondary mt-5"
                      role="status"
                    >
                      <span class="sr-only"></span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {step === 2 && (
          <div className="px-0 px-lg-4">
            <div className="template-title">
              <h4>
                Here are the <span>Best Templates</span> for you
              </h4>
              <p>They’re great for students with little experience </p>
            </div>
            <div className="d-flex gap-4 flex-wrap">
              {Type.doctype === "resume" ? (
                <>
                  {/* {ResumeTemplateJson?.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="resume-card-template border rounded cursor-pointer"
                        onClick={() => {
                          dispatch(setTemplateNo(item.templateNo));
                          dispatch(
                            setCreateResume({
                              type: "template",
                              value: Number(item.templateNo),
                            })
                          );
                          dispatch(
                            UpdateContactRedux({
                              type: "firstName",
                              value: UserData.name,
                            })
                          );
                          dispatch(
                            UpdateContactRedux({
                              type: "email",
                              value: UserData.email,
                            })
                          );
                          dispatch(
                            UpdateContactRedux({
                              type: "number",
                              value: UserData.phoneNumber,
                            })
                          );
                          navigate("/create-resume");
                        }}
                      >
                        <item.template />
                      </div>
                    );
                  })} */}
                  {filteredTemplates.map((item, index) => (
                    <div
                      key={index}
                      className="resume-card-template border rounded cursor-pointer"
                      onClick={() => {
                        dispatch(setTemplateNo(item.templateNo));
                        dispatch(
                          setCreateResume({
                            type: "template",
                            value: Number(item.templateNo),
                          })
                        );
                        dispatch(
                          UpdateContactRedux({
                            type: "firstName",
                            value: UserData.name,
                          })
                        );
                        dispatch(
                          UpdateContactRedux({
                            type: "email",
                            value: UserData.email,
                          })
                        );
                        dispatch(
                          UpdateContactRedux({
                            type: "number",
                            value: UserData.phoneNumber,
                          })
                        );
                        navigate("/create-resume");
                      }}
                    >
                      <item.template />
                    </div>
                  ))}
                </>
              ) : Type.doctype === "cover" ? (
                <>
                  <div className="d-flex gap-4 flex-wrap">
                    {CoverLetterTemplateJson?.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className=" border resume-card-template rounded cursor-pointer"
                          onClick={() => {
                            dispatch(setTemplateNo(item.templateNo));
                            dispatch(
                              setFrom({ type: "name", value: UserData?.name })
                            );
                            dispatch(
                              setFrom({ type: "email", value: UserData?.email })
                            );
                            dispatch(
                              setFrom({
                                type: "number",
                                value: UserData?.phoneNumber,
                              })
                            );
                            dispatch(
                              setFrom({
                                type: "address",
                                value: UserData?.address2,
                              })
                            );
                            dispatch(
                              setCoverFields({
                                type: "template",
                                value: Number(item.templateNo),
                              })
                            );
                            navigate("/create-cover-letter");
                          }}
                        >
                          <item.template />
                        </div>
                      );
                    })}
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
