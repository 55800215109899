import React, { useRef, useEffect, useState } from 'react';
import Plyr from "plyr-react";
import "plyr/dist/plyr.css";
import Recorder from './Recorder';
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";

let ffmpeg;
const VideoIntroduction = () => {
  const [Editable, setEditable] = useState(false);
  const [videoDuration, setVideoDuration] = useState(0);
  const [endTime, setEndTime] = useState(0);
  const [startTime, setStartTime] = useState(0);
  const [videoSrc, setVideoSrc] = useState('');
  const [videoFileValue, setVideoFileValue] = useState('');
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [videoTrimmedUrl, setVideoTrimmedUrl] = useState('');
  const videoRef = useRef();
  let initialSliderValue = 0;


  //Created to load script by passing the required script and append in head tag
  const loadScript = (src) => {
    return new Promise((onFulfilled, _) => {
      const script = document.createElement('script');
      let loaded;
      script.async = 'async';
      script.defer = 'defer';
      script.setAttribute('src', src);
      script.onreadystatechange = script.onload = () => {
        if (!loaded) {
          onFulfilled(script);
        }
        loaded = true;
      };
      script.onerror = function () {
        console.log('Script failed to load');
      };
      document.getElementsByTagName('head')[0].appendChild(script);
    });
  };

  const handleFileUpload = (mediaBlobURL) => {
    // const file = event.target.files[0];
    // const blobURL = URL.createObjectURL(file);
    // setVideoFileValue(file);
    setVideoSrc(mediaBlobURL);
  };

  const convertToHHMMSS = (val) => {
    const secNum = parseInt(val, 10);
    let hours = Math.floor(secNum / 3600);
    let minutes = Math.floor((secNum - hours * 3600) / 60);
    let seconds = secNum - hours * 3600 - minutes * 60;

    if (hours < 10) {
      hours = '0' + hours;
    }
    if (minutes < 10) {
      minutes = '0' + minutes;
    }
    if (seconds < 10) {
      seconds = '0' + seconds;
    }
    let time;
    // only mm:ss
    if (hours === '00') {
      time = minutes + ':' + seconds;
    } else {
      time = hours + ':' + minutes + ':' + seconds;
    }
    return time;
  };


  useEffect(() => {
    //Load the ffmpeg script
    loadScript(
      'https://cdn.jsdelivr.net/npm/@ffmpeg/ffmpeg@0.11.2/dist/ffmpeg.min.js',
    ).then(() => {
      if (typeof window !== 'undefined') {
        // creates a ffmpeg instance.
        ffmpeg = window.FFmpeg.createFFmpeg({ log: true });
        //Load ffmpeg.wasm-core script
        ffmpeg.load();
        //Set true that the script is loaded
        setIsScriptLoaded(true);
      }
    });
  }, []);


  // getting duration of the video
  useEffect(() => {
    if (videoRef && videoRef.current) {
      const currentVideo = videoRef.current;
      currentVideo.onloadedmetadata = () => {
        setVideoDuration(currentVideo.duration);
        setEndTime(currentVideo.duration);
      };
    }
  }, [videoSrc]);

  console.log(videoDuration)
  console.log(videoSrc)


  // media player
  const videoSrcPlayer = {
    type: "video",
    sources: [
      {
        src: "QFaFIcGhPoM",
        provider: "youtube",
        size: 720,
      }
    ]
  };


  return (
    <div>
      <div className='d-flex justify-content-between py-4'>
        <div className='VideoResume_targetjob pe-4'>
          <h2>Introduction</h2>
          <p>Sapiente debitis ratione nemo deleniti atque dignissimos quasi blanditiis exercitationem officiis accusamus.</p>

          <Recorder handleFileUpload={handleFileUpload} videoRef={videoRef} />
        </div>
        <div className='col-4 d-flex flex-column gap-5'>
          <div className='rounded  overflow-hidden'>
            <Plyr source={videoSrcPlayer} />
            <div>
            </div>
          </div>

        </div>
      </div>


      {/* //editor */}

      <div className='videoResume_editor '>
        {/* <div className='videoResume_trimmer'>
          <button className='videoResume_trimmer_btn1'>| |</button>
          <div style={{ width: "30vw", backgroundColor: "white" }}></div>
          <button className='videoResume_trimmer_btn2' draggable >| |</button>
        </div> */}
        <div className='col-8'>

          <Nouislider
            behaviour="tap-drag"
            step={3}
            margin={3}
            limit={99}
            range={{ min: 0, max: 100 }}
            start={[0, 2]}
            connect
          // onUpdate={updateOnSliderChange}
          />
        </div>

        {/* <p>Record a video to edit</p> */}
      </div>
    </div>
  );
}



export default VideoIntroduction;


