import { setDocSubType } from "helper/redux/Slice/TypeSlice";
import React, { useState, useEffect } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import rankbar from "../../../assets/image/rankbar.png";
import CommonDashboardCard from "common/CommonDashboardCard";
import {
  DeleteFresherCoverLetter,
  DeleteExperienceCoverLetter,
  GetCoverLetter,
} from "helper/api";
import LoadingComponent from "common/LoadingComponent";
import { toast } from "react-hot-toast";
import { setCoverStep } from "helper/redux/Slice/UtilsSlice";

const CoverSection = ({ handleStep }) => {
  const dispatch = useDispatch();
  const [Loading, setLoading] = useState(false);
  const [CoverCardData, setCoverCardData] = useState([]);
  const [datanotPresent, setdatanotPresent] = useState(false);
  const Type = useSelector((item) => item.types);
  dispatch(setCoverStep(0));
  const handleDeleteCover = async (id, type) => {
    try {
      const payload = {
        coverId: id,
      };
      toast.success("Cover Letter is successfully deleted.");
      setCoverCardData(() => CoverCardData?.filter((item) => item._id !== id));
      if (type === "exp") {
        await DeleteExperienceCoverLetter(payload);
      } else {
        await DeleteFresherCoverLetter(payload);
      }

      // const newarr = ResumeCardData?.filter((item) => item._id !== id)
    } catch (error) {
      console.log(error);
    }
  };

  const GettingCoverData = async () => {
    setLoading(true);
    try {
      const data = await GetCoverLetter();
      if (data?.data?.data?.length === 0) {
        setdatanotPresent(true);
      }
      setCoverCardData(data?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  useEffect(() => {
    GettingCoverData();
  }, []);
  return (
    <>
      <div className="create-resume">
        {/* <div
          className={`create-resume-optionsBar ${Type?.docsubtype === "trialResume" ? "active" : ""
            }`}
          style={styl}
          onClick={() => dispatch(setDocSubType("trialResume"))}
        >
          <AiOutlinePlusCircle size="20" className="icon" /> AI
          Cover letter
        </div> */}
        {/* <div
                  className={`create-resume-optionsBar ${types?.docsubtype === "automatedResume" ? "active" : ""
                    }`}
                  onClick={() => dispatch(setDocSubType("automatedResume"))}
                  style={styl}
                >
                  <AiOutlinePlusCircle size="20" className="icon" />{" "}
                  Upload from Resume
                </div> */}
      </div>

      <div className="col-12  d-flex justify-content-between position-relative my-4">
        <div
          className="  flex-wrap w-100 justify-content-center justify-content-sm-start  pe-2 d-flex  gap-3 "
          style={{ minHeight: "60vh" }}
        >
          {!Loading ? (
            <>
              <div onClick={handleStep}>
                <CommonDashboardCard cardFor={"create"} />
              </div>
              {/* <CommonDashboardCard cardFor={"upload"} /> */}
              {CoverCardData?.length > 0
                ? CoverCardData?.map((data, index) => (
                    <div key={index}>
                      <CommonDashboardCard
                        data={data}
                        handleDeleteCover={handleDeleteCover}
                      />
                    </div>
                  ))
                : datanotPresent && (
                    <div className="mt-4" style={{ fontWeight: "700" }}>
                      No history Found
                    </div>
                  )}
            </>
          ) : (
            <div className="h-100 d-flex w-100  flex-column justify-content-center">
              <LoadingComponent />
            </div>
          )}
        </div>
        {/* <div className="col-1   h-50" style={{ position: "sticky", top: "20%" }}>
          <div className="rank-container border   justify-content-end">
            <div className="rank-sidebar  ">
              <p>Rank</p>
              <p>10/100</p>
              <div className="image-container">
                <img src={rankbar} height={300} alt="rankbar"></img>
              </div>

              <p>Expand</p>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default CoverSection;

const styl = {
  margin: "0",
  marginRight: "25px",
  fontSize: "13px",
  fontWeight: "700",
  padding: "20px 15px",
  textAlign: "left",
  cursor: "pointer",
};
