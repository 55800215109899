import React, { useRef, useState, useEffect } from "react";
import { SidePanelPrimaryBox, SidePanelSecondaryBox } from "./SidepanelStyled";
import logo from "assets/image/logo.svg";
import homeLogo from "../../assets/image/Vector (3).svg";
import logo2 from "assets/image/logo-small.png";
import coin from "../../assets/image/dollar.png";
import { useDispatch, useSelector } from "react-redux";
import { SidePanelData } from "contants/SidepanelData";
import { NavLink, useNavigate } from "react-router-dom";
import "../../styles/common/sidebar.css";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import {
  GettingAiCredits,
  setSidebarExpand,
} from "helper/redux/Slice/UtilsSlice";
import { Button, Modal, Spinner } from "react-bootstrap";
import DataTable from "react-data-table-component";
import {
  BsFillArrowLeftCircleFill,
  BsFillArrowRightCircleFill,
} from "react-icons/bs";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import sidelogo from "../../assets/image/sidelogo.mp4";
import { GetAllCreditLogs } from "helper/api";
import LoadingComponent from "common/LoadingComponent";


export const SidePanel = ({ children }) => {
  const [dropDownMenu, setDropDownMenu] = useState(false);

  const UtilsData = useSelector((item) => item?.utils);
  const sidebarExpand = useSelector((item) => item.utils.sidebarExpand);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const handleSidebarExpand = () => {
    if (sidebarExpand) {
      dispatch(setSidebarExpand(false));
    } else {
      dispatch(setSidebarExpand(true));
    }
  };

  const [showAiModal, setshowAiModal] = useState();

  const handleClose = () => {
    setshowAiModal(false);
  };
  const handleShow = () => {
    setshowAiModal(true);
  };

  const columns = [
    {
      name: "AI Debits",
      selector: (row) => row.credit,
    },
    {
      name: "Feature",
      selector: (row) => row.used,
    },
    {
      name: "Time",
      cell: (row) => format(row.createdAt),
    },
  ];

  const UserData = useSelector((item) => item.user?.user);

  useEffect(() => {
    dispatch(GettingAiCredits(UserData?._id));
  }, [UserData]);

  // table data
  const [CreditLogs, setCreditLogs] = useState([]);
  const [Loading, setLoading] = useState(true);
  const fetchingData = async () => {
    try {
      const data = await GetAllCreditLogs();
      console.log(data);
      setCreditLogs(data?.data?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  useEffect(() => {
    if (showAiModal) {
      fetchingData();
    }
  }, [showAiModal]);

  const data = CreditLogs;
  const tableData = {
    columns,
    data,
  };

  function format(dateString) {
    let date, month, year, hour, minute;
    const inputDate = new Date(dateString);

    date = inputDate.getDate();
    month = inputDate.getMonth() + 1;
    year = inputDate.getFullYear();
    hour = inputDate?.getHours();
    minute = inputDate?.getMinutes();
    date = date.toString().padStart(2, "0");

    month = month.toString().padStart(2, "0");

    return `${date}/${month}/${year} - ${hour}:${minute} ${hour > 12 ? "PM" : "AM"
      }`;
  }

  return (
    <>
      <div
        className="sidebar-container d-none d-lg-inline-block"
        style={{
          width: `${sidebarExpand ? "5vw" : "17vw"}`,
          transition: "0.1s",
        }}
      >
        <div className="aligncenter sidebar-head">
          {sidebarExpand ? (
            <img src={logo2} alt="logo" />
          ) : (
            !!sidelogo && (
              <img
                src={homeLogo}
                alt=""
                onClick={() => {
                  navigate("/");
                }}
              />
            )
          )}
        </div>
        <div
          className="w-100 sidebar-wrapper  position-relative"
          style={{ background: "#602a94", borderRadius: "0 6px 0 0" }}
        >
          <div className="px-2 py-4  w-100">
            {SidePanelData.map((item) => (
              <>
                <NavLink to={item.path} className="sidebar__link" key={item.id}>
                  <div
                    className={`d-flex align-items-center px-2 py-2 wrapper__sidebar ${sidebarExpand && `justify-content-center`
                      }`}
                  >
                    {item.icon}
                    {sidebarExpand ? (
                      ""
                    ) : (
                      <h6 className="sidebar__heading px-2 mt-2">
                        {item.name}
                      </h6>
                    )}
                  </div>
                </NavLink>
                {item?.name === "My Profile" && (
                  <hr className="border  border-light" />
                )}
              </>
            ))}
          </div>

          <div className="sidebar-Pointsdropdown-container">
            <button
              className="sub-navbar-button"
              onClick={handleShow}
              style={{ borderRadius: dropDownMenu ? "10px 10px 0 0" : "10px" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div className="d-flex align-items-center">
                  <img alt="coin" src={coin} />
                  {sidebarExpand ? "" : "AI Credits"}
                </div>
                <span
                  style={{ fontSize: `${sidebarExpand ? "0.7rem" : "0.9rem"}` }}
                >
                  {UtilsData?.AICreditsLoadingState ? (
                    <Spinner size="sm" style={{ color: "indigo" }} />
                  ) : (
                    UtilsData?.AICredits
                  )}
                </span>
              </div>
            </button>
          </div>

          <div
            className=" sidepanel-expand-btn cursor-pointer"
            onClick={handleSidebarExpand}
          >
            {sidebarExpand ? (
              <BsFillArrowRightCircleFill fontSize={28} />
            ) : (
              <>
                <BsFillArrowLeftCircleFill fontSize={28} />
              </>
            )}
          </div>
        </div>
      </div>
      <Modal
        scrollable
        show={showAiModal}
        onHide={handleClose}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>AI Credit Logs</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {Loading ? (
            <>
              <LoadingComponent />
            </>
          ) : (
            <DataTableExtensions {...tableData}>
              <DataTable
                pagination
                defaultSortField="id"
                defaultSortAsc={false}
                noHeader
                paginationPerPage={5}
                paginationRowsPerPageOptions={[5, 25, 50, 100, 200]}
                highlightOnHover
              />
            </DataTableExtensions>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};
