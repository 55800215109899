import { setCoverStep, setResumeStep } from "helper/redux/Slice/UtilsSlice";
import React from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

const StepsResponsive = () => {
  const Type = useSelector((item) => item.types);
  const resumeNormal = [
    "Contact",
    "Experience",
    "Project",
    "Education",
    "Certificate",
    "Voluntary",
    "Skills",
    "Hobby",
    "Others",
    "Summary",
    "Finishup",
  ];
  const resumeAutomated = [
    "Basic info",
    "Experience",
    "Education",
    "Skills",
    "Hobby",
    "Finishup",
  ];

  const fresherCover = [
    "Fresher or Experienced",
    "Target Job",
    "Recipient",
    "Highlight",
    "Content",
    "Finalize",
  ];

  const experienceCover = [
    "Fresher or Experienced",
    "Target Job",
    "Strengths/Skills",
    "Experience",
    "Education Gap",
    "Recipient",
    "Content",
    "Finalize",
  ];
  const dispatch = useDispatch();
  const stepCover = useSelector((item) => item.utils.stepCover);

  const handleCoverSteps = (e) => {
    dispatch(setCoverStep(Number(e.target.value)));
  };
  const handleResumeSteps = (e) => {
    dispatch(setResumeStep(e.target.value));
  };
  return (
    <div>
      <Form.Select
        size="md"
        onChange={(e) =>
          Type?.doctype === "resume"
            ? handleResumeSteps(e)
            : handleCoverSteps(e)
        }
        className="w-75 ms-3"
      >
        {(Type?.doctype === "resume"
          ? Type?.docsubtype === "automatedResume"
            ? resumeAutomated
            : resumeNormal
          : Type?.coverFor === "Fresher"
          ? fresherCover
          : experienceCover
        ).map((item, index) => {
          return (
            <option
              key={item}
              className={`${
                stepCover === index ? "bg-secondary text-white" : ""
              }`}
              value={index}
            >
              {item}
            </option>
          );
        })}
      </Form.Select>
    </div>
  );
};

export default StepsResponsive;
