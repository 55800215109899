import {
  ButtonBlue,
  ButtonPurpleOutline,
  ButtonWhite,
  Input,
  Select,
} from "components/common/CommonStyles";
import React, { useEffect, useState } from "react";
import { Form, InputGroup, Spinner } from "react-bootstrap";
import {
  AiFillEye,
  AiFillEyeInvisible,
  AiOutlineMail,
  AiOutlineMobile,
} from "react-icons/ai";
import ReactPhoneInput from "react-phone-input-2";
import { FaLessThanEqual, FaUserFriends, FaUsers } from "react-icons/fa";
import { useNavigate } from "react-router";
import { ToastStyles } from "components/common/ToastStyle";
import { auth } from "helper/firebase";
import { signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
import { SignUpAPI } from "helper/api";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
// import { UserInfo } from "helper/redux/exportAllAction";
export const Signup = ({ setSelect }) => {
  const dispatch = useDispatch();
  const [showpassword, setShowPassword] = useState(false);
  const [showconfirmpassword, setShowConfirmPassword] = useState(false);
  const [signupPage, setSignupPage] = useState(1);
  const [show, setshow] = useState(false);
  const [final, setfinal] = useState("");
  const [loading, setLoading] = useState(false);
  const [signuploading, setsignupLoading] = useState(false);
  const [resendotp, setResendotp] = useState(false);
  const [resendActive, setResendActive] = useState(false);
  const [appVerifier, setAppverifier] = useState();
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [otploading, setotploading] = useState(false);
  const [data, setdata] = useState({
    phoneNumber: "",
    role: "",
    verificationCode: "",
    name: "",
    email: "",
    confirmemail: "",
    password: "",
    confirmpassword: "",
    company: "",
    dob: "",
    age: "",
    interviewExp: "",
    testskill: "",
    firebaseIdToken: "",
  });
  const navigate = useNavigate();
  const handleChange = (e) => {
    setdata({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const getOtp = () => {
    console.log(data.phoneNumber.length);
    if (data.phoneNumber.length < 12) {
      alert("Please Enter a valid Number", ToastStyles);
    } else {
      // setResendotp(false);
      getOtpFun();
    }
  };
  const getOtpFun = () => {
    setotploading(true);
    let appverifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, "+" + data.phoneNumber, appverifier)
      .then((result) => {
        console.log(result);
        setfinal(result);
        alert("Otp sent Successfully");
        setAppverifier(appverifier);
        // toast.success("Verification Code sent", ToastStyles);
        setResendotp(true);
        setshow(true);
        setResendActive(true);
        setotploading(false);
        setMinutes(1);
        setSeconds(30);
      })
      .catch((err) => {
        console.log(err);
        alert(err, ToastStyles);
        setotploading(false);
        // window.location.reload();
      });
  };
  const resendOtp = () => {
    if (data.phoneNumber.length < 12) {
      alert("Please Enter a valid Number", ToastStyles);
    } else {
      setotploading(true);
      signInWithPhoneNumber(auth, "+" + data.phoneNumber, appVerifier)
        .then((result) => {
          setfinal(result);
          alert("Otp sent Successfully");

          // toast.success("Verification Code sent", ToastStyles);
          setResendotp(true);
          setshow(true);
          setResendActive(true);
          setotploading(false);
          setMinutes(1);
          setSeconds(30);
        })
        .catch((err) => {
          console.log(err);
          setotploading(false);
          // window.location.reload();
        });
    }
  };
  const ValidateOtp = () => {
    if (data.verificationCode === null || final === null) return;

    setLoading(true);
    final
      .confirm(data.verificationCode)
      .then((result) => {
        console.log(result);
        setdata({ ...data, firebaseIdToken: result._tokenResponse.idToken });
        setSignupPage(2);
        setLoading(FaLessThanEqual);
      })
      .catch((err) => {
        alert("Incorrect Otp", ToastStyles);
        setLoading(false);
      });
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    if (data.password !== data.confirmpassword) {
      alert("Password doesn't Match");
      return;
    }
    setsignupLoading(true);
    const { phoneNumber, name, email, password, firebaseIdToken } = data;

    const formdata = {
      phoneNumber,
      password,
      email,
      name,
      firebaseIdToken,
    };
    try {
      // debugger;
      const res = await SignUpAPI(formdata);
      if (res?.data?.status === 400) {
        alert("Email already Exist");
      } else {
        console.log(res);
        setSignupPage(false);
        Cookies.set("@token", res?.data?.token);
        // dispatch(UserInfo(res?.data?.data?.result));
        navigate("/");
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
      const { response } = error;
      alert(response.data);

      setsignupLoading(false);
    }

    setsignupLoading(false);
  };
  // console.log(data.phoneNumber);
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          setResendActive(false);
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  useEffect(() => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        // callback: getOtpFun,
      },
      auth
    );
  }, []);

  return (
    <div className="mt-4">
      <div id="recaptcha-container"></div>
      {signupPage == 1 ? (
        <>
          <div className="">
            <ReactPhoneInput
              country="in"
              style={{
                width: "100%",
                borderRadius: "12px",
              }}
              enableSearch
              name="phoneNumber"
              value={data.phoneNumber || ""}
              onChange={(e) => {
                setdata({ ...data, phoneNumber: e });
                setResendotp(false);
                setMinutes(0);
                setSeconds(0);
              }}
              className="mb-3 br-5"
            />
          </div>
          <div className="d-flex justify-content-between mb-0">
            <div className="d-flex align-items-center me-1">
              <AiOutlineMail className="input-icon" color="#ffa303" size={28} />
              <Input
                type="tel"
                name="verificationCode"
                className="mb-3 px-5 br-5"
                value={data.verificationCode}
                onChange={(e) => handleChange(e)}
                placeholder="Verification code"
              ></Input>
            </div>

            {resendotp ? (
              <>
                {otploading ? (
                  <ButtonWhite className=" br-5">
                    <Spinner animation="border" size="sm" />
                  </ButtonWhite>
                ) : (
                  <ButtonWhite
                    disabled={resendActive}
                    onClick={resendOtp}
                    className=" br-5 text-white"
                    style={{ backgroundColor: "#521986" }}>
                    Resend
                  </ButtonWhite>
                )}
              </>
            ) : (
              <>
                {otploading ? (
                  <ButtonWhite className=" br-5">
                    <Spinner animation="border" size="sm" />
                  </ButtonWhite>
                ) : (
                  <ButtonWhite onClick={getOtp} className="w-50 p-0 box-shadow br-5 text-white"
                    type="submit"
                    style={{ backgroundColor: "#521986" }}>
                    Send Code
                  </ButtonWhite>
                )}
              </>
            )}
          </div>
          {(minutes > 0 || seconds > 0) && (
            <>
              <p className="time-remaining">
                Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                {seconds < 10 ? `0${seconds}` : seconds}
              </p>
            </>
          )}
          {show && (
            <div className="mt-3">
              {loading ? (
                <>
                  <ButtonBlue disabled className="w-100 h-40 box-shadow">
                    <Spinner animation="border" size="sm" />
                  </ButtonBlue>
                </>
              ) : (
                <>
                  <ButtonWhite
                    onClick={ValidateOtp}
                    style={{ backgroundColor: "#521986" }}
                    className="w-100 h-40 box-shadow text-white rounded"
                  >
                    Next
                  </ButtonWhite>
                </>
              )}
            </div>
          )}
        </>
      ) : signupPage === 2 ? (
        <>
          <Form onSubmit={handleSignup}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label className="form-title">Name</Form.Label>
              <div className="d-flex justify-content-between">
                <Form.Control
                  className="form-input"
                  type="text"
                  name="name"
                  required
                  value={data.name}
                  onChange={handleChange}
                  placeholder="Full Name"
                />
              </div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label className="form-title">Email</Form.Label>
              <Form.Control
                className="form-input"
                type="email"
                required
                name="email"
                value={data.email}
                onChange={handleChange}
                placeholder="Email"
              />
            </Form.Group>
            <Form.Group className="mb-0" controlId="formBasicEmail">
              <Form.Label className="form-title">Password</Form.Label>
              <Form.Control
                className="form-input"
                type={showpassword ? "text" : "password"}
                name="password"
                required
                minLength={8}
                value={data.password}
                onChange={handleChange}
                placeholder="Enter Password"
              />
              {showpassword ? (
                <div className="eye-position">
                  <AiFillEyeInvisible
                    onClick={() => setShowPassword(false)}
                    className="password-eye1 pe-1"
                    color="#ffa303"
                    size={28}
                  />
                </div>
              ) : (
                <div className="eye-position">
                  <AiFillEye
                    onClick={() => setShowPassword(true)}
                    className="password-eye1 pe-1"
                    color="#ffa303"
                    size={28}
                  />
                </div>
              )}
            </Form.Group>
            <Form.Group className="my-3">
              <Form.Control
                className="form-input"
                type={showconfirmpassword ? "text" : "password"}
                required
                minLength={8}
                name="confirmpassword"
                value={data.confirmpassword}
                onChange={handleChange}
                placeholder="Re-enter Password"
              />
              {showconfirmpassword ? (
                <div className="eye-position">
                  <AiFillEyeInvisible
                    onClick={() => setShowConfirmPassword(false)}
                    className="password-eye1 pe-1"
                    color="#ffa303"
                    size={28}
                  />
                </div>
              ) : (
                <div className="eye-position">
                  <AiFillEye
                    onClick={() => setShowConfirmPassword(true)}
                    className="password-eye1 pe-1"
                    color="#ffa303"
                    size={28}
                  />
                </div>
              )}
            </Form.Group>
            {signuploading ? (
              <>
                <ButtonBlue className="w-100 h-40 box-shadow">
                  <Spinner animation="border" size="sm" />
                </ButtonBlue>
              </>
            ) : (
              <>
                <ButtonWhite type="submit" className="w-100 h-40 box-shadow text-white rounded" style={{ backgroundColor: "#521986" }} >
                  Sign Up
                </ButtonWhite>
              </>
            )}
          </Form>
        </>
      ) : signupPage === 3 ? (
        <>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label className="form-title">Date of Birth</Form.Label>
            <div className="d-flex justify-content-between">
              <Form.Control
                style={{ width: "48%" }}
                className="form-input"
                type="date"
                name="dob"
                value={data.dob}
                onChange={handleChange}
                placeholder="DD-MM-YYYY"
              />
              <Form.Control
                className="form-input"
                type="number"
                style={{ width: "48%" }}
                name="age"
                value={data.age}
                onChange={handleChange}
                placeholder="Age Auto calculated"
              />
            </div>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label className="form-title">Interview Experience</Form.Label>

            <Form.Select
              id="disabledSelect"
              name="interviewExp"
              onChange={handleChange}
              className="form-input"
            >
              <option default value="">
                Experience
              </option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
            </Form.Select>
          </Form.Group>
          <div className="d-flex justify-content-end my-3">
            <ButtonBlue
              className="w-10 h-40 box-shadow px-3"
              onClick={() => setSignupPage(4)}
            >
              Next
            </ButtonBlue>
          </div>
        </>
      ) : signupPage === 4 ? (
        <>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label className="form-title">Test Your Skills</Form.Label>

            <Form.Select
              id="disabledSelect"
              name="testskill"
              onChange={handleChange}
              className="form-input"
            >
              <option default value="">
                Experience
              </option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
            </Form.Select>
          </Form.Group>
          <div className="d-flex justify-content-center my-3">
            <ButtonBlue
              className="w-10 h-40 box-shadow px-5"
              onClick={() => setSignupPage(5)}
            >
              Test
            </ButtonBlue>
          </div>
        </>
      ) : signupPage === 5 ? (
        <>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label className="form-title">Test Your Skills</Form.Label>

            <Form.Select
              id="disabledSelect"
              name="testskill"
              onChange={handleChange}
              className="form-input"
            >
              <option default value="">
                Experience
              </option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
            </Form.Select>
          </Form.Group>
          <div className="d-flex justify-content-evenly my-3">
            <ButtonBlue className="w-10 h-40 box-shadow px-5">
              Free Test
            </ButtonBlue>
            <ButtonPurpleOutline
              className="w-10 h-40 box-shadow px-5"
              onClick={() => setSelect(0)}
            >
              Login
            </ButtonPurpleOutline>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
