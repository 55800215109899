// import { CoverFor } from "helper/redux/exportAllAction";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCoverFor } from "helper/redux/Slice/TypeSlice";
import { useParams } from "react-router";
import { setCoverFields } from "helper/redux/Slice/CoverSlice";
export const FresherOrExperience = ({ handleSubmitCoverLetter }) => {
  const dispatch = useDispatch();
  const types = useSelector((state) => state.types);
  const handleType = (e) => {
    dispatch(setCoverFor(e));
  };

  return (
    <div className="create-resume-main form-main-2">
      <h3>Are you a Fresher or Experienced</h3>
      <p className="mt-2">
        {/* Et pellentesque scelerisque risus blandit quam tempus. Pellentesque
        sodales nec convallis sit ac. */}
      </p>

      <div className="form-main col-lg-6 col-12 my-2">
        <button
          className={`${types.coverFor === "Fresher" ? "active" : ""} w-100`}
          onClick={() => {
            handleType("Fresher")
            dispatch(setCoverFields({ type: "type", value: "Fresher" }))
          }}
        >
          I am a Fresher
        </button>
        <button
          className={`${types.coverFor === "Experienced" ? "active" : ""} w-100`}
          onClick={() => {
            handleType("Experienced")
            dispatch(setCoverFields({ type: "type", value: "Experienced" }))
          }}
        >
          I am Experienced
        </button>
      </div>
    </div >
  );
};
