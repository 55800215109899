import React, { useRef, useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import res1 from "../../assets/image/res1.png";
import res2 from "../../assets/image/res2.png";
import res3 from "../../assets/image/res3.png";
import scrollbar from "../../assets/image/scrollbar.png";
import progress from "../../assets/image/progress.png";
import rankbar from "../../assets/image/rankbar.png";
import toggler from "../../assets/image/toggler.png";
import meter from "../../assets/image/meter.png";
import Img from "../../assets/image/resume1.png";
import ResumeTemplate1 from "components/ResumeTemplate/ResumeTemplate1";
import ResumeTemplate2 from "components/ResumeTemplate/ResumeTemplate2";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useDispatch, useSelector } from "react-redux";
import TypeSlice from "helper/redux/Slice/TypeSlice";
import CoverletterTemplate from "components/CoverLetterTemplate/CoverTemplate1/CoverletterTemplate";
import jsPDF from "jspdf";
import ReactToPrint from "react-to-print";
import RT1editable from "components/ResumeTemplate/T1/RT1editable";
import CT1editable from "components/CoverLetterTemplate/CT1/CT1editable";
import { pdfFromReact } from "generate-pdf-from-react-html";
import RT1non from "components/ResumeTemplate/T1/RT1non";
import RT2editable from "components/ResumeTemplate/T2/RT2editable";
import {
  ChatGPTAPI,
  GetAiResumeById,
  GetAutomatedResumeById,
  GetExperienceCoverLetterById,
  GetFresherCoverLetterById,
  GetTrailResumeById,
} from "helper/api";
import {
  GettingCompleteness,
  GettingFormatting,
  GettingNotypo,
  GettingSummary,
  GettingWordChoice,
  setCoverFields,
} from "helper/redux/Slice/CoverSlice";
import { SidePanel } from "components/Sidepanel/SidePanel";
import { SidePanelData } from "contants/SidepanelData";
import { NavLink, useParams } from "react-router-dom";
import { Navbar } from "components/Navbar/Navbar";
import LoadingComponent from "common/LoadingComponent";
import AnalysisFeature from "common/AnalysisFeature";
import CoverLetterTemplateJson from "contants/CoverLettertemplate";
import ResumeTemplateJson from "contants/ResumeTemplateJson";
import { setTemplateNo } from "helper/redux/Slice/UtilsSlice";
import { setCreateResume } from "helper/redux/Slice/ResumeSlice";

export const ReviewFinishup = ({ page }) => {
  // const doc = new jsPDF();
  let reportTemplateRef = useRef();

  const [isModal, setIsModal] = useState(false);
  const [adjustments, setAdjustments] = useState(false);
  const [downloads, setDownloads] = useState(false);
  const [analytics, setAnalytics] = useState(true);
  const [color, setColor] = useState(false);
  const [templates, setTemplates] = useState(false);
  const [TemplateData, setTemplateData] = useState();
  const [dropDownMenu, setDropDownMenu] = useState(false);
  const [Loading, setLoading] = useState(false);
  const selectedStyle = { backgroundColor: "#521986", color: "white" };

  const percentage = 66;
  function handleDropDownMenu() {
    setAdjustments(false);
    setColor(false);

    setDropDownMenu(!dropDownMenu);
  }

  function handleIsModal(action) {
    if (action === "open") {
      setIsModal(true);
    } else {
      setIsModal(false);
    }
  }

  function handleColor() {
    setDropDownMenu(false);
    setAdjustments(false);
    setColor(!color);
  }
  function handleAdjustments() {
    setDropDownMenu(false);
    setColor(false);
    setAdjustments(!adjustments);
  }

  function handleAnalytics() {
    setDropDownMenu(false);
    setAdjustments(false);
    setColor(false);
    setTemplates(false);
    setAnalytics(true);
  }

  function handleTemplates() {
    setDropDownMenu(false);
    setAdjustments(false);
    setAnalytics(false);
    setColor(false);
    setTemplates(true);
  }

  const UserData = useSelector((item) => item.user);
  const types = useSelector((item) => item.types);
  const TemplateNo = useSelector((item) => item.utils.templateNo);
  const handleGenerateDOCX = async () => {
    // await HTMLtoDOCX(reportTemplateRef.current)
  };

  const CoverRedux = useSelector((item) => item.cover);

  const [AnalysisKey, setAnalysisKey] = useState([
    "Completeness",
    // "Formatting",
    // "Word Choice",
    // "Strong Summary",
    "No Typos",
    "Clear Contact Info",
    "ATS Compliance",
    "Demo",
  ]);
  const tempref = useRef();
  const dispatch = useDispatch();

  // useEffect(() => {
  //   // AnalyticsFunction()
  //   const payloadComplete = {
  //     question: `Just Give me a score out of 100 for completeness don't give me any explanation "${CoverRedux?.content}" `
  //   }
  //   const payloadFormatting = {
  //     question: `Just Give me a score out of 100 for Formatting don't give me any explanation "${CoverRedux?.content}" `
  //   }
  //   const payloadWordChoice = {
  //     question: `Just Give me a score out of 100 for Word Choice don't give me any explanation "${CoverRedux?.content}" `
  //   }
  //   const payloadStrongSummary = {
  //     question: `Just Give me a score out of 100 for strong summary don't give me any explanation "${CoverRedux?.content}" `
  //   }
  //   const payloadNotypo = {
  //     question: `Just Give me a score out of 100 for No typos don't give me any explanation "${CoverRedux?.content}" `
  //   }
  //   if (CoverRedux?.isAnalysisData === false && types?.doctype === "cover" && CoverRedux?.finishUp?.completeness === 0) {
  //     // dispatch(GettingCompleteness(payloadComplete))
  //     // dispatch(GettingFormatting(payloadFormatting))
  //     // dispatch(GettingWordChoice(payloadWordChoice))
  //     // dispatch(GettingSummary(payloadStrongSummary))
  //     // dispatch(GettingNotypo(payloadNotypo))
  //   }
  // }, [dispatch, CoverRedux?.isAnalysisData])

  const param = useParams();
  useEffect(() => {
    gettingTemplateData();
  }, [param?.id]);

  const gettingTemplateData = async () => {
    setLoading(true);
    let response;
    try {
      if (types?.doctype === "resume") {
        if (types?.docsubtype === "aiResume") {
          response = await GetAiResumeById(param?.id);
        } else if (types?.docsubtype === "trailResume") {
          response = await GetTrailResumeById(param?.id);
        } else {
          response = await GetAutomatedResumeById(param?.id);
        }
      } else {
        if (types?.coverFor === "Fresher") {
          response = await GetFresherCoverLetterById(param?.id);
        } else if (types?.coverFor === "Experienced") {
          response = await GetExperienceCoverLetterById(param?.id);
        }
      }
      setTemplateData(response?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      {isModal && (
        <div className="preview-modal-container">
          <div className="preview-modal-image-container">
            <div>
              {TemplateNo === 0 ? <ResumeTemplate1 /> : <ResumeTemplate2 />}
            </div>

            <button
              className="preview-modal-image-closeButton"
              onClick={() => {
                handleIsModal("close");
              }}
            >
              X
            </button>
          </div>
        </div>
      )}
      {/* <p onClick={() => pdfFromReact(".element-to-print", "My-file", "p", true, false)}>EXPORT.PDF</p> */}
      <>
        <div className="d-flex">
          <SidePanel />

          <div className="w-100 main-container-wrapper ">
            <Navbar />
            <div className="create-resume-main px-4">
              {Loading ? (
                <LoadingComponent />
              ) : (
                <>
                  <div className="sub-navbar-main">
                    <div className="sub-navbar-options">
                      <div align="center">
                        <Button
                          variant="primary"
                          className="sub-navbar-button"
                          style={analytics ? selectedStyle : {}}
                          onClick={handleAnalytics}
                        >
                          Analytics
                        </Button>
                      </div>
                      {/* <Col align="center"><Button variant="primary" className="sub-navbar-button" style={color ? selectedStyle : {}} onClick={handleColor}>Color</Button></Col> */}
                      {/* <Col align="center"><Button variant="primary" className="sub-navbar-button" style={adjustments ? selectedStyle : {}} onClick={handleAdjustments}>Adjustment</Button></Col> */}
                      <div align="center">
                        <Button
                          variant="primary"
                          className="sub-navbar-button"
                          style={templates ? selectedStyle : {}}
                          onClick={handleTemplates}
                        >
                          Templates
                        </Button>
                      </div>
                      <div className="finish-download-dropdown-container">
                        <button
                          className="sub-navbar-button"
                          style={dropDownMenu ? selectedStyle : {}}
                          onClick={handleDropDownMenu}
                        >
                          Download
                        </button>
                        {dropDownMenu && (
                          <div className="finish-download-dropdownMenu-container">
                            <ReactToPrint
                              trigger={() => {
                                return <p>EXPORT.PDF</p>;
                              }}
                              content={() => tempref.current}
                            />

                            <p onClick={handleGenerateDOCX}>EXPORT.DOCX</p>
                            <p>SAVE TO DRIVE</p>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="sub-navbar-toggleMenu">
                      {/* {color && <div className="color-tray">


                            <span style={{ backgroundColor: "#facd48" }}></span>
                            <span style={{ backgroundColor: "#0462f6" }}></span>
                            <span style={{ backgroundColor: "#335c52" }}></span>
                            <span style={{ backgroundColor: "#ffbebe" }}></span>
                            <span style={{ backgroundColor: "#facd48" }}></span>
                        </div>} */}

                      {adjustments && (
                        <div className="adjustment-tray">
                          {/* <span>
                                    <p>SHOW PHOTOS</p>
                                    <div className="adjustment-tray-toggler"><img src={toggler}></img></div>
                                </span> */}
                          <span>
                            <p>SHOW ICONS</p>
                            <div className="adjustment-tray-toggler">
                              <img src={toggler}></img>
                            </div>
                          </span>
                          <span>
                            <p>LINE HEIGHT :</p>
                            <div className="adjustment-tray-meter">
                              <img src={meter}></img>
                            </div>
                          </span>
                          <span>
                            <p>FONT SIZE :</p>
                            <div className="adjustment-tray-meter">
                              <img src={meter}></img>
                            </div>
                          </span>
                          <span>
                            <p>ZOOM :</p>
                            <div className="adjustment-tray-meter">
                              <img src={meter}></img>
                            </div>
                          </span>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="sub-content-main">
                    <div className="template-preview-main align-self-start">
                      <div className="preview-header">
                        <p className="preview-header-name">
                          [{UserData?.name}]
                        </p>
                        {/* <p className="preview-header-options">More Options</p> */}
                      </div>
                      <div className="" ref={reportTemplateRef}>
                        {/* <img src={previewTemplate} alt="image" width="100%" /> */}

                        {types.doctype === "resume" ? (
                          <div
                            ref={tempref}
                            className="element-to-print  rounded"
                            style={{ objectFit: "cover", height: "100vh" }}
                          >
                            {TemplateData?.template === "0" ? (
                              <RT1editable data={TemplateData} />
                            ) : (
                              <RT2editable data={TemplateData} />
                            )}
                          </div>
                        ) : (
                          <div
                            style={{ objectFit: "contain" }}
                            className="bg-white rounded"
                          >
                            <CT1editable data={TemplateData} />
                          </div>
                        )}
                      </div>
                    </div>

                    {analytics && (
                      <div className="analytics-card-container d-flex col-12 flex-wrap gap-3 h-50">
                        <AnalysisFeature data={TemplateData} />
                      </div>
                    )}

                    {templates && (
                      <div className="template-container px-2 col-12 col-lg-3 m-0 my-4 m-lg-auto">
                        <div className="template-header">
                          <span>TEMPLATES</span>
                          <span>X</span>
                        </div>
                        <div className="d-flex flex-column gap-4    ">
                          {types.doctype === "resume" ? (
                            <div className="d-flex flex-column gap-4">
                              {ResumeTemplateJson?.map((item, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="border rounded"
                                    onClick={() => {
                                      dispatch(setTemplateNo(index));
                                      dispatch(
                                        setCreateResume({
                                          type: "template",
                                          value: index,
                                        })
                                      );
                                    }}
                                  >
                                    <item.template />
                                  </div>
                                );
                              })}
                            </div>
                          ) : (
                            <>
                              {CoverLetterTemplateJson?.map((item, index) => {
                                return (
                                  <div
                                    className="border rounded mb-3"
                                    key={index}
                                    onClick={() => {
                                      dispatch(setTemplateNo(index));
                                      dispatch(
                                        setCoverFields({
                                          type: "template",
                                          value: index,
                                        })
                                      );
                                    }}
                                  >
                                    <item.template />
                                  </div>
                                );
                              })}
                            </>
                          )}
                        </div>
                      </div>
                    )}

                    {/* <div className="rank-sidebar">
                        <p>Rank</p>
                        <p>10/100</p>
                        <div className="image-container"><img src={rankbar}></img></div>

                        <p>Expand</p>
                      </div> */}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    </>
  );
};
