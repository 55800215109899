import { UpdateAllLinks, setAllLinks } from "helper/redux/Slice/ResumeSlice";
import React, { useEffect, useState } from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";

function AllLinks({ UpdateRedux, setUpdateRedux }) {
  const LinkRedux = useSelector((item) => item.resume.links);
  const dispatch = useDispatch();
  const [AllLinksObj, setAllLinksObj] = useState({
    name: "",
    id: LinkRedux?.length,
  });

  const handleChange = (e) => {
    const { value } = e.target;
    setAllLinksObj({ ...AllLinksObj, name: value });
  };
  const handleSubmitToRedux = (e) => {
    e.preventDefault();
    if (!!UpdateRedux) {
      dispatch(UpdateAllLinks(AllLinksObj, AllLinksObj?.index));
      setUpdateRedux(null);
      toast.success("Links Updated Successfully");
    } else {
      if (!!AllLinksObj?.name) {
        dispatch(setAllLinks(AllLinksObj));
        toast.success("Link Saved Successfully");
      } else {
        return;
      }
    }
  };
  useEffect(() => {
    if (!!UpdateRedux?.name) {
      setAllLinksObj(UpdateRedux);
    }
  }, [UpdateRedux]);
  return (
    <Form className="form-main">
      <div className="d-flex gap-3 justify-content-between">
        <FloatingLabel
          controlId="floatingInput"
          label="Add Link (e.g. LinkedIn, Behance, Github, etc.)"
          className="mb-3 w-100"
        >
          <Form.Control
            type="text"
            value={AllLinksObj?.name}
            name="name"
            onChange={handleChange}
            placeholder="Add Link (LinkedIn, Behance, Github, etc.)"
          />
        </FloatingLabel>
      </div>

      <button className="save-experience" onClick={handleSubmitToRedux}>
        Save to Link List
      </button>
    </Form>
  );
}

export default AllLinks;
