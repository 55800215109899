import React, { useEffect, useState } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { useSelector } from "react-redux";

const VideoResumeStep = ({ step, changeStepDirect }) => {
  const types = useSelector((state) => state.types);
  const isFresher = types.coverfor;
  const type = types.docsubtype;
  const VideoResume = [
    "Introduction",
    "Professional",
    "Passion",
    "Accomplishments",
    "Testimonials",
    "Close",
  ];

  function changeStep(id) {
    if (type === "automatedResume") {
      return step - 1 >= 0;
    }
    return step >= id + 1;
  }

  // useEffect(() => {
  //   debugger;
  //   if (type === "automatedResume") {
  //     changeStepDirect(7);
  //     changeStep(7);
  //     debugger;
  //   }
  // }, [type]);

  function changeProgress() {
    let progress = ((step) * 100) / VideoResume.length;
    if (progress > 95) return 95;
    else return progress;
  }

  return (
    <div className="steps-progress-bar container">
      {VideoResume?.map((e, id) => {
        return (
          e.length > 0 && (
            <div
              className={changeStep(id) ? "step-circle-orange" : "step-circle"}
            >
              <span
                onClick={() => {
                  changeStepDirect(id + 1);
                }}
              >
                {changeStep(id) ? <AiOutlineCheck /> : id + 1}
              </span>
              <h4
                onClick={() => {
                  changeStepDirect(id + 1);
                }}
              >
                {e}
              </h4>
            </div>
          )
        );
      })}
      <div className="step-line">vk</div>
      <div
        className="step-line-orange"
        style={{ width: `${changeProgress()}%` }}
      >
        vk
      </div>
    </div>
  );
};
export default VideoResumeStep