import React, { useState, useEffect } from "react";
import { NavbarStyled } from "./NavbarStyles";
import user from "../../assets/image/avatar.png";
import { useDispatch, useSelector } from "react-redux";
import { removedata, setUser } from "helper/redux/Slice/UserSlice";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { FaHamburger } from "react-icons/fa";
import { AiOutlineMenu } from "react-icons/ai";
import { RiMenu2Line } from "react-icons/ri";
import { Form, Offcanvas } from "react-bootstrap";
import { SidePanel } from "components/Sidepanel/SidePanel";
import ResponsiveSIdebar from "./ResponsiveSIdebar";
import sidelogo from "../../assets/image/sidelogo.mp4";
import logo2 from "../../assets/image/logo.svg";
import StepsResponsive from "./StepsResponsive";

export const Navbar = () => {
  const { profileImage } = useSelector((state) => state.user);
  const [ShowMenu, setShowMenu] = useState(false);
  const dispatch = useDispatch();
  const handlelogout = () => {
    Cookies.remove("token");
    Cookies.remove("user");
    localStorage.removeItem("user2");
    dispatch(removedata());
    // window.location.reload();
    // Redirect to https://viosa.in after logout
    window.location.href = "https://viosa.in";
  };

  const handleToggle = () => {
    if (ShowMenu) {
      setShowMenu(false);
    } else {
      setShowMenu(true);
    }
  };

  //responsive sidebar
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const UserData = useSelector((item) => item.user.user);
  useEffect(() => {
    // Add a scroll event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Remove the event listener when the component unmounts
    // return () => {
    //   window.removeEventListener('scroll', handleScroll);
    // };
  }, []);
  const handleScroll = () => {
    console.log(window.scrollY);
    if (window.scrollY > 0) {
      setShowMenu(false);
    }
  };
  return (
    <div
      className="w-100 position-relative px-0 px-lg-4 d-flex justify-content-lg-end justify-content-between container align-items-center"
      style={{ height: "12vh" }}
    >
      <div className="d-inline-block d-lg-none" onClick={handleShow}>
        <RiMenu2Line fontSize={32} />
      </div>
      <div className="nav-sec-1">
        {(window.location.pathname.includes("/create-resume") ||
          window.location.pathname.includes("/create-cover")) && (
          <StepsResponsive />
        )}
      </div>

      <div className="d-flex justify-content-end align-items-center nav-sec-2">
        {ShowMenu && (
          <div
            className="border me-3 navbar-logout-popup bg-white "
            style={{ zIndex: "10" }}
          >
            <Link
              className=" cursor-pointer py-2 px-4 mb-0 profile-header"
              to={"/my-profile"}
            >
              My Profile
            </Link>
            <p
              className="text-dark cursor-pointer py-2 px-4 mb-0"
              onClick={handlelogout}
            >
              Logout
            </p>
          </div>
        )}
        <div className="user-img-cover cursor-pointer" onClick={handleToggle}>
          <img
            className="user-img border"
            width={50}
            height={50}
            src={!!UserData?.profileImage ? UserData?.profileImage : user}
            alt="user"
          />
        </div>
      </div>

      <Offcanvas show={show} onHide={handleClose} style={{ width: "80%" }}>
        <Offcanvas.Header className="p-0 d-flex justify-content-center align-items-center">
          <div className="d-flex justify-content-center py-4 w-100 ">
            <img src={logo2} alt="logo" />
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0 h-100">
          <ResponsiveSIdebar />
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};
