import React, { useRef } from 'react';
import { AiOutlineMail } from 'react-icons/ai'
import { BsFillTelephoneFill } from 'react-icons/bs';
import './RT1.css'
import jsPDF from 'jspdf';
import img1 from '../../../assets/image/profile.png'
const RT1non = () => {

  const reportTemplateRef = useRef()
  const handleGeneratePdf = () => {
    const doc = new jsPDF({
      format: 'a4',
      unit: 'px',
    });

    // Adding the fonts.
    // doc.setFont('Inter-Regular', 'normal');
    doc.html(reportTemplateRef.current, {
      async callback(doc) {
        doc.save('document');
      },
    });

  };
  return (
    <div ref={reportTemplateRef} className='container-fluid d-flex flex-column resume-temp-1-wrapper align-items-center justify-content-center px-3'>
      <section className='resume-temp-1-head w-100'>
        <h1>Demo Client</h1>
        <h2>Graphic Designer</h2>
        <div className='d-flex  resume-temp-1-email justify-content-between w-100'>
          <div className='d-flex gap-2 align-items-center'>
            <AiOutlineMail />
            demo@gmail.com
          </div>
          <div className='d-flex gap-2 align-items-center'>
            <BsFillTelephoneFill />
            887878788787
          </div>
        </div>
      </section>

      <hr className='w-100' />


      <section className='d-flex col-12 d-flex resume-temp-1-summary justify-content-between align-items-center '>
        <div className='col-3'>
          <img src={img1} alt="Profile" className='' />
        </div>
        <div className='col-9'>
          <p>Earum tempora sequi quod maiores, cupiditate ullam labore eius omnis aut, voluptatum quas asperiores, culpa ipsa ducimus molestias voluptatibus. Perspiciatis, voluptatem officiis? </p>
        </div>
      </section>

      <hr className='w-100' />
      <section className='d-flex col-12 resume-temp-1-content'>
        <aside className='col-4'>
          <div className=''>
            <h3>Education</h3>
            <div>
              <h6>Masters in Product Design</h6>
              <p>San Dan University</p>
              <p>2017 - 2014</p>
              <p>Score - 9</p>
            </div>
            <div>
              <h6>Masters in Product Design</h6>
              <p>San Dan University</p>
              <p>2017 - 2014</p>
              <p>Score - 9</p>
            </div>
          </div>

          <div className=''>
            <h3>Skills</h3>
            <p>Time Management</p>
            <p>Time Management</p>
            <p>Time Management</p>
          </div>

          <div className=''>
            <h3>Hobby</h3>
            <p>Time Management</p>
            <p>Time Management</p>
            <p>Time Management</p>
          </div>
        </aside>
        <span className=' partition-hr' />
        <aside className='col-7  px-sm-2'>
          <div className=''>
            <h3>Experience</h3>
            <div>
              <h5>Frontend Developer</h5>
              <strong>Kylo Apps | 2023</strong>
              <ul>
                <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, maiores!</li>
              </ul>
            </div>
            <div>
              <h5>Frontend Developer</h5>
              <strong>Kylo Apps | 2023</strong>
              <ul>
                <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, maiores!</li>

              </ul>
            </div>
          </div>

          <div className=''>
            <h3>Projects</h3>
            <div>
              <h5>Frontend Developer</h5>
              <strong>Kylo Apps | 2023</strong>
              <ul>
                <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, maiores!</li>
              </ul>
            </div>
            <div>
              <h5>Frontend Developer</h5>
              <strong>Kylo Apps | 2023</strong>
              <ul>
                <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, maiores!</li>
              </ul>
            </div>
          </div>

          <div className='mb-4'>

            <h3>Voluntary Work</h3>
            <div>
              <h5>Frontend Developer</h5>
              <strong>Kylo Apps | 2023</strong>
              <ul>
                <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, maiores!</li>

              </ul>
            </div>
          </div>
        </aside>
      </section>
      <hr className='w-100' />

      <section className='col-12 resume-temp-1-content'>
        <h3>Ceritificates</h3>
        <div>
          <h6>Masters in Product Design</h6>
          <p className='mb-1'>San Dan University</p>
          <p className='mb-1'>2017 - 2014</p>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel harum voluptate incidunt voluptatem? Quidem quo, eaque quos, libero vel inventore, iure optio laudantium ullam nesciunt sapiente nihil sit dolorum nam!</p>
        </div>


      </section>


    </div>
  );
}

export default RT1non;
