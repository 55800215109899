import { Form, FloatingLabel, Spinner } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setProjectFields,
  setResearchPapersFields,
  UpdateProjectFields,
  UpdateResearchPapersFields,
} from "helper/redux/Slice/ResumeSlice";
import { AiButton } from "components/AiButton/AiButton";
import { ChatGPTAPI, CutAiCredits } from "helper/api";
import { setAiCredits, setdisableNext } from "helper/redux/Slice/UtilsSlice";
import {
  UpdateCoverProject,
  setCoverProjectFields,
} from "helper/redux/Slice/CoverSlice";
import GptModal from "./GptModal";
import { toast } from "react-hot-toast";
import { ButtonGroup, Button } from "react-bootstrap";

export const Projects = ({
  UpdateRedux,
  setUpdateRedux,
  projectAndOtherState,
  setprojectAndOtherState,
  handleResponsivePreviewShow,
}) => {
  const dispatch = useDispatch();
  const Project = useSelector((item) => item.resume.projects);
  const [loadSpinner, setloadSpinner] = useState(false);
  const [GptData, setGptData] = useState();
  const [DisableAiBtn, setDisableAiBtn] = useState(true);
  const [ProjectObj, setProjectObj] = useState({
    id: Project?.length,
    title: "",
    company: "",
    aidescription: "",
    start: "",
    end: "",
    isWorking: false,
    description: "",
  });

  const handleEndData = () => {
    setProjectObj({
      ...ProjectObj,
      isWorking: !ProjectObj?.isWorking,
      end: "",
    });
  };

  const HandleChange = (e) => {
    const { name, value } = e.target;
    setProjectObj({ ...ProjectObj, [name]: value });
  };
  const DocType = useSelector((item) => item.types.doctype);
  const CoverPorjectData = useSelector((item) => item.cover.projects);

  const handleProjectSubmit = (e) => {
    e.preventDefault();
    if (DocType === "cover") {
      if (!!UpdateRedux) {
        dispatch(UpdateCoverProject(ProjectObj, ProjectObj?.index));
        setUpdateRedux(null);
        toast.success("Project Updated Successfully");
      } else if (
        !!ProjectObj?.title &&
        !!ProjectObj?.description &&
        !!ProjectObj?.start &&
        (ProjectObj?.isWorking || !!ProjectObj?.end)
      ) {
        if (projectAndOtherState === 0) {
          toast.success("Project Saved Successfully");
          dispatch(setProjectFields(ProjectObj));
        } else if (projectAndOtherState === 1) {
          toast.success("Research Paper Saved Successfully");
          dispatch(setResearchPapersFields(ProjectObj));
        }
      }
    } else {
      if (!!UpdateRedux) {
        if (projectAndOtherState === 0) {
          toast.success("Project Updated Successfully");
          dispatch(UpdateProjectFields(ProjectObj, ProjectObj?.index));
        } else if (projectAndOtherState === 1) {
          toast.success("Research Paper Updated Successfully");
          dispatch(UpdateResearchPapersFields(ProjectObj, ProjectObj?.index));
        }
        setUpdateRedux(null);
      } else if (
        !!ProjectObj?.title &&
        !!ProjectObj?.description &&
        !!ProjectObj?.start &&
        (ProjectObj?.isWorking || !!ProjectObj?.end)
      ) {
        if (projectAndOtherState === 0) {
          toast.success("Project Saved Successfully");
          dispatch(setProjectFields(ProjectObj));
        } else if (projectAndOtherState === 1) {
          toast.success("Research Paper Saved Successfully");
          dispatch(setResearchPapersFields(ProjectObj));
        }
      }
    }
  };

  useEffect(() => {
    if (!!UpdateRedux) {
      setProjectObj(UpdateRedux);
    }
  }, [UpdateRedux]);

  useEffect(() => {
    if (!!ProjectObj?.title) {
      setDisableAiBtn(false);
    } else {
      setDisableAiBtn(true);
    }
  }, [ProjectObj]);

  //chatgpt
  const [PremiumFeature, setPremiumFeature] = useState(false);

  const handlePremiumFeatureClose = () => {
    setPremiumFeature(false);
  };

  const AiCredits = useSelector((item) => item?.user?.user?.aicredits);

  const PostChatGPTData = async () => {
    if (AiCredits < 2) {
      setPremiumFeature(true);
      return;
    }
    if (!!ProjectObj?.title) {
      setloadSpinner(true);

      let responseData;
      let attempts = 0;
      const maxAttempts = 5;
      const delayBetweenAttempts = 1000; // 1000 milliseconds (1 second)
      const temperature = Math.random() * (0.4 - 0.1) + 0.1;

      // Define prompt based on the value of projectAndOtherState
      let prompt = "";
      if (projectAndOtherState === 0) {
        prompt = `Generate an ATS-compliant sentence in past tense that describes a key responsibility or accomplishment for a project titled "${ProjectObj?.title}". The sentence should be concise, grammatically correct, and optimized for ATS systems, focusing on a significant contribution to the project.`;
      } else if (projectAndOtherState === 1) {
        prompt = `Generate an ATS-compliant sentence in past tense summarizing a key responsibility or achievement related to writing or contributing to a research paper titled "${ProjectObj?.title}". The sentence should be concise, grammatically correct, and optimized for ATS systems. It should highlight a specific aspect of the research paper process, such as conducting literature reviews, data analysis, or drafting sections of the paper, with a focus on measurable contributions or results where applicable.`;
      }

      while (
        (!responseData || !responseData.data.text.trim()) &&
        attempts < maxAttempts
      ) {
        const payload = {
          question: prompt, // Use the selected prompt
          temperature: temperature.toFixed(1), // Limit to one decimal place
        };

        console.log("Before making ChatGPTAPI call");
        try {
          const data = await ChatGPTAPI(AiCredits, payload);
          responseData = data;
          console.log("API Response Status:", data.status);

          console.log("API Response Structure:", responseData);
          console.log("After successful ChatGPTAPI call", data);

          if (!data) {
            setPremiumFeature(true);
            setloadSpinner(false);
            return;
          }

          // Check if the generated text is not empty
          if (data?.data?.text && data?.data?.text.trim()) {
            // const newText = data?.data?.text.replace(/[\r\n]+/g, " "); // Replace newline characters
            // const updatedDescription =
            //   ProjectObj.description && ProjectObj.description.trim() // Check for existing description
            //     ? `${ProjectObj.description}\n${newText}` // Append with a newline
            //     : newText;
            // Split response into bullet points (based on new lines or periods)
            const bulletPoints = data?.data?.text
              .split(/\r?\n|\.\s/)
              .filter(Boolean);

            // Create formatted bullet points list
            const formattedDescription = bulletPoints
              .map((point) => `• ${point.trim()}`)
              .join("\n");

            // Append the new bullet points to existing description (if any)
            const updatedDescription =
              ProjectObj.description && ProjectObj.description.trim()
                ? `${ProjectObj.description}\n${formattedDescription}`
                : formattedDescription;

            setProjectObj({
              ...ProjectObj,
              description: updatedDescription,
            });
            // setProjectObj({
            //   ...ProjectObj,
            //   description: !!ProjectObj.description
            //     ? ProjectObj.description + "\n" + data?.data?.text
            //     : data?.data?.text,
            // });
          }
        } catch (error) {
          console.error("Error in ChatGPTAPI:", error);
          setloadSpinner(false);
          return;
        }

        attempts++;

        // Introduce a delay before the next attempt
        await new Promise((resolve) =>
          setTimeout(resolve, delayBetweenAttempts)
        );
      }

      // Cut AI credits only if there is valid text
      if (responseData && responseData.data.text.trim()) {
        const aiCreditPayload = {
          credit: 2,
          used: "Project",
        };

        toast.success("Your 2 AI credits have been used.");
        dispatch(setAiCredits(aiCreditPayload?.credit));
        await CutAiCredits(aiCreditPayload);
      }

      setloadSpinner(false);
    }
  };

  const ResumeType = useSelector((item) => item.resume);

  //disable next btn
  useEffect(() => {
    if (Project?.length > 0) {
      dispatch(setdisableNext({ type: "project", value: false }));
    } else {
      dispatch(setdisableNext({ type: "project", value: true }));
    }
  }, [Project]);

  const handleTextFieldChange = (e) => {
    setProjectObj({
      ...ProjectObj,
      description:
        ProjectObj?.description?.length === 0 ||
        ProjectObj?.description === undefined
          ? "" + e.target.value
          : e.target.value,
    });
  };
  const checkEnter = (e) => {
    if (e.key === "Enter") {
      setProjectObj({
        ...ProjectObj,
        description: ProjectObj.description + "",
      });
    }
  };

  const handleUpdateNavigateState = (value) => {
    setprojectAndOtherState(value);
  };

  return (
    <div className="create-resume-main">
      <div className="d-flex justify-content-between">
        <div className="d-flex w-100 justify-content-between align-items-center my-1">
          <ButtonGroup aria-label="Basic example" className="gap-2">
            <Button
              style={{
                background: `${
                  projectAndOtherState === 0 ? "#521986" : "lightgrey"
                }`,
                border: "none",
              }}
              className=""
              onClick={() => handleUpdateNavigateState(0)}
            >
              Project
            </Button>
            {/* <Button style={{ background: `${projectAndOtherState === 1 ? "#521986" : "lightgrey"}`, border: "none" }} onClick={() => handleUpdateNavigateState(1)}>Internship</Button> */}
            <Button
              style={{
                background: `${
                  projectAndOtherState === 1 ? "#521986" : "lightgrey"
                }`,
                border: "none",
              }}
              onClick={() => handleUpdateNavigateState(1)}
            >
              Research Paper
            </Button>
          </ButtonGroup>

          <div>
            <button
              onClick={handleResponsivePreviewShow}
              className="align-self-start p-1 responsive-preview-btn d-inline-block d-lg-none"
            >
              Preview
            </button>
          </div>
        </div>
      </div>
      <div className="text-end pe-2 mb-2">
        <small>
          {DocType === "resume"
            ? !!ResumeType?.profession
              ? "Job Specific"
              : "Generic"
            : ""}
        </small>
      </div>
      {/* <p>
        Et pellentesque scelerisque risus blandit quam tempus. Pellentesque
        sodales nec convallis sit ac.
      </p> */}
      <Form className="form-main" onSubmit={(e) => handleProjectSubmit(e)}>
        <FloatingLabel
          controlId="floatingInput"
          label={
            <span className="required-label">
              {projectAndOtherState === 0
                ? "Project Title"
                : "Title of Research Paper"}
            </span>
          }
          className="mb-3"
        >
          <Form.Control
            onChange={HandleChange}
            name="title"
            type="text"
            required
            placeholder="Give a title"
            value={ProjectObj?.title}
          />
        </FloatingLabel>
        {projectAndOtherState === 0 && (
          <FloatingLabel
            controlId="floatingInput"
            label="Company Name"
            className="mb-3"
          >
            <Form.Control
              type="text"
              onChange={HandleChange}
              name="company"
              value={ProjectObj?.company}
              placeholder="Give a short description on the above-mentioned title"
            />
          </FloatingLabel>
        )}
        <FloatingLabel
          controlId="floatingInput"
          label="Give a short description on the above-mentioned title"
          className="mb-3"
        >
          <Form.Control
            type="text"
            onChange={HandleChange}
            name="aidescription"
            value={ProjectObj?.aidescription}
            placeholder="Give a short description on the above-mentioned title"
          />
        </FloatingLabel>

        <div className="row">
          <div className="col-lg-6">
            <FloatingLabel
              controlId="floatingPassword"
              label={<span className="required-label">When you started?</span>}
              className="mb-3"
            >
              <Form.Control
                type="month"
                onChange={HandleChange}
                name="start"
                value={ProjectObj?.start}
                required
                placeholder="How long have you worked? "
              />
            </FloatingLabel>
          </div>
          <div className="col-lg-6">
            <FloatingLabel
              controlId="floatingPassword"
              label="When you finished?"
              className="mb-1"
            >
              <Form.Control
                type="month"
                min={ProjectObj?.start}
                name="end"
                required
                onChange={HandleChange}
                value={ProjectObj?.end}
                disabled={!ProjectObj?.isWorking ? false : true}
                placeholder="End Date"
              />
            </FloatingLabel>

            <div className="checkboxs mb-2">
              <label>Currently working </label>
              <input
                type="checkbox"
                onChange={handleEndData}
                checked={ProjectObj?.isWorking}
                className=""
              />
            </div>
          </div>
        </div>

        <div className="add-experience-container">
          <div className="mb-0 add-experience-heading">
            <p className="mb-0 add-experience-heading ">
              {<span className="required-label">Short description</span>}
            </p>
            <AiButton disabled={DisableAiBtn} SetAi={PostChatGPTData} />
          </div>

          {loadSpinner ? (
            <div className="d-flex justify-content-center my-5">
              <Spinner animation="border" variant="secondary" />
            </div>
          ) : (
            <Form.Control
              as="textarea"
              value={ProjectObj?.description}
              name="description"
              className="px-4"
              required
              onChange={handleTextFieldChange}
              onKeyUp={checkEnter}
              placeholder="Type or Use 'AI' Button"
              style={{ height: "100px" }}
            />
          )}
        </div>
        <button className="save-experience" type="submit">
          Save To{" "}
          {projectAndOtherState === 0 ? "Project List" : "Research Papers"}
        </button>
      </Form>

      {/* premium modal */}
      <GptModal
        PremiumFeature={PremiumFeature}
        handlePremiumFeatureClose={handlePremiumFeatureClose}
      />
    </div>
  );
};
