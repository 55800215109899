import Card from "components/Card/Card";
import { setDocSubType, setDocType } from "helper/redux/Slice/TypeSlice";
import React, { useEffect, useState } from "react";
import { AiOutlineFileAdd, AiOutlineFilePdf } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import ResumeSection from "./ResumeSection/ResumeSection";
import CoverSection from "./CoverSection/CoverSection";
import VideoSection from "./VideoSection/VideoSection";
import ResumeTemplateJson from "contants/ResumeTemplateJson";
import CoverLetterTemplateJson from "contants/CoverLettertemplate";
import { setTemplateNo } from "helper/redux/Slice/UtilsSlice";
import { setCoverFields, setFrom } from "helper/redux/Slice/CoverSlice";
import { useNavigate } from "react-router";
import {
  UpdateContactRedux,
  setContactRedux,
  setCreateResume,
} from "helper/redux/Slice/ResumeSlice";
import CommonDashboardCard from "common/CommonDashboardCard";
import { BsCameraVideo } from "react-icons/bs";
import Cookies from "js-cookie";
import { BiArrowBack } from "react-icons/bi";
import { GetTemplateNoByCollegeId } from "helper/api";

const DashboardData = ({ setIsModal, setTemplates }) => {
  const [step, setstep] = useState(1);
  const navigate = useNavigate();
  //redux
  const UserData = useSelector((item) => item?.user?.user);
  const Type = useSelector((item) => item.types);
  const CoverData = useSelector((item) => item?.cover);
  const dispatch = useDispatch();
  const [filteredTemplates, setFilteredTemplates] = useState([]);

  const handleStep = () => {
    setstep(2);
  };

  let cookiedata;

  if (!!localStorage.getItem("user2")) {
    cookiedata = JSON?.parse(localStorage?.getItem("user2"));
  } else {
    cookiedata = JSON?.parse(Cookies.get("user"));
  }
  console.log(cookiedata);
  // Assuming collegeid is available in cookiedata
  // useEffect(() => {
  //   const collegeid = cookiedata.collegeid;
  //   console.log(collegeid);
  //   // List of college IDs that should only see template number 2
  //   const restrictedCollegeIds = [
  //     "661f7afde9d17f043bb7c569",
  //     "65f7ff121a524e23670482a5",
  //     "65f7fefe1a524e23670482a0",
  //     "65f7fee71a524e2367048298",
  //     "65f7fead1a524e236704828f",
  //   ];
  //   // Filter templates based on college ID
  //   // const collegeId = parseInt(cookiedata.collegeId); // Convert collegeId to number
  //   if (restrictedCollegeIds.includes(collegeid)) {
  //     // If collegeId matches, show only templateNo 2
  //     const matchedTemplates = ResumeTemplateJson.filter(
  //       (template) => template.templateNo === 3
  //     );
  //     console.log("Matched Templates:", matchedTemplates);
  //     setFilteredTemplates(matchedTemplates);
  //   } else {
  //     // Otherwise, show all templates
  //     setFilteredTemplates(ResumeTemplateJson);
  //     console.log("not matched");
  //   }
  // }, [cookiedata.collegeId]);
  const collegeid = cookiedata.collegeid;
  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        // const response = await axios.get(`/api/user-templates/${collegeid}`);
        const response = await GetTemplateNoByCollegeId(collegeid);
        console.log(response);
        const { templateNos } = response.data;
        console.log("Fetched Template Nos:", templateNos);

        const matchedTemplates = ResumeTemplateJson.filter((template) =>
          templateNos.includes(template.templateNo)
        );
        setFilteredTemplates(matchedTemplates);
      } catch (error) {
        console.error("Error fetching college data:", error);
      }
    };

    fetchTemplates();
  }, [cookiedata.collegeid]);
  return (
    <div>
      {step === 1 ? (
        <div className="main-content  ">
          <div className="resume-selector">
            <div
              className={Type?.doctype === "resume" ? "active" : ""}
              onClick={() => dispatch(setDocType("resume"))}
            >
              <AiOutlineFileAdd size="20" className="icon inline-block" />{" "}
              Resume
            </div>
            <div
              className={Type?.doctype === "cover" ? "active" : ""}
              onClick={() => dispatch(setDocType("cover"))}
            >
              <AiOutlineFilePdf size="20" className="icon inline-block" /> Cover
              Letter
            </div>
            {/* 
              <div className={Type.doctype === "video" ? "active" : ""} onClick={() => dispatch(setDocType('video'))}>
                <BsCameraVideo size="20" className="icon" />Video Resume
              </div> */}
          </div>
          {Type.doctype === "resume" ? (
            <ResumeSection handleStep={handleStep} />
          ) : Type.doctype === "cover" ? (
            <CoverSection handleStep={handleStep} />
          ) : (
            <VideoSection />
          )}
        </div>
      ) : (
        <>
          <div className="px-0 px-lg-4">
            <button
              className="p-2 rounded text-white"
              style={{ background: "#602a94" }}
              onClick={() => setstep(1)}
            >
              <BiArrowBack /> Back
            </button>
            <div className="template-title">
              <h4>
                <span>Select Your Templates</span>
              </h4>
              {/* <p>They’re great for students with little experience </p> */}
            </div>
            <div className="d-flex gap-4 flex-wrap">
              {Type.doctype === "resume" ? (
                <>
                  {/* {ResumeTemplateJson?.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="resume-card-template border rounded cursor-pointer"
                        onClick={() => {
                          dispatch(setTemplateNo(item.templateNo));
                          dispatch(
                            setCreateResume({
                              type: "template",
                              value: Number(item.templateNo),
                            })
                          );
                          dispatch(
                            UpdateContactRedux({
                              type: "firstName",
                              value: cookiedata.name,
                            })
                          );
                          dispatch(
                            UpdateContactRedux({
                              type: "email",
                              value: cookiedata.email,
                            })
                          );
                          dispatch(
                            UpdateContactRedux({
                              type: "number",
                              value: cookiedata.phoneNumber,
                            })
                          );
                          navigate("/create-resume");
                        }}
                      >
                        <item.template />
                      </div>
                    );
                  })} */}
                  {filteredTemplates.map((item, index) => (
                    <div
                      key={index}
                      className="resume-card-template border rounded cursor-pointer"
                      onClick={() => {
                        dispatch(setTemplateNo(item.templateNo));
                        dispatch(
                          setCreateResume({
                            type: "template",
                            value: Number(item.templateNo),
                          })
                        );
                        dispatch(
                          UpdateContactRedux({
                            type: "firstName",
                            value: cookiedata.name,
                          })
                        );
                        dispatch(
                          UpdateContactRedux({
                            type: "email",
                            value: cookiedata.email,
                          })
                        );
                        dispatch(
                          UpdateContactRedux({
                            type: "number",
                            value: cookiedata.phoneNumber,
                          })
                        );
                        navigate("/create-resume");
                      }}
                    >
                      <item.template />
                    </div>
                  ))}
                </>
              ) : Type.doctype === "cover" ? (
                <>
                  <div className="d-flex gap-4 flex-wrap">
                    {CoverLetterTemplateJson?.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className=" border resume-card-template rounded cursor-pointer"
                          onClick={() => {
                            dispatch(setTemplateNo(item.templateNo));
                            dispatch(
                              setFrom({ type: "name", value: cookiedata?.name })
                            );
                            dispatch(
                              setFrom({
                                type: "email",
                                value: cookiedata?.email,
                              })
                            );
                            dispatch(
                              setFrom({
                                type: "number",
                                value: cookiedata?.phoneNumber,
                              })
                            );
                            // dispatch(setFrom({ type: "address", value: cookiedata?.address2 }))
                            dispatch(
                              setCoverFields({
                                type: "template",
                                value: Number(item.templateNo),
                              })
                            );
                            navigate("/create-cover-letter");
                          }}
                        >
                          <item.template />
                        </div>
                      );
                    })}
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DashboardData;
