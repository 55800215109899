import { AiOutlineCheck } from "react-icons/ai";
import { useSelector } from "react-redux";
export const NewResumeStep = ({ step, changeStepDirect }) => {
  const types = useSelector((state) => state.types);
  const isAI = types.docsubtype;
  const Resumedata = [
    "Contact",
    "Experience",
    `Project`,
    "Education",
    "Certificate",
    "Voluntary",
    "Skills",
    "Hobby",
    "Others",
    "Summary",
    "Finish",
  ];

  const AIdata = [
    "Basic info",
    "Experience",
    "Education",
    "Skills",
    "Hobby",
    "Others",
    "Finishup",
  ];
  const data = isAI === "automatedResume" ? AIdata : Resumedata;
  function changeStep(id) {
    return step >= id + 0.5;
  }

  function changeProgress() {
    let progress = (step * 100) / data.length;
    if (progress > 95) return 95;
    else return progress;
  }
  return (
    <div className="steps-progress-bar container">
      {data.map((e, id) => {
        return (
          <div
            className={changeStep(id) ? "step-circle-orange" : "step-circle"}
          >
            <span
              onClick={() => {
                changeStepDirect(id);
              }}
            >
              {changeStep(id) ? <AiOutlineCheck /> : id + 1}
            </span>
            <h4
              onClick={() => {
                changeStepDirect(id);
              }}
            >
              {e}
            </h4>
          </div>
        );
      })}
      <div className="step-line">vk</div>
      <div
        className="step-line-orange"
        style={{ width: `${changeProgress()}%` }}
      >
        vk
      </div>
    </div>
  );
};
