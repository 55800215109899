import { Form, FloatingLabel } from "react-bootstrap";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setEducationFields,
  UpdateEducationFields,
} from "helper/redux/Slice/ResumeSlice";
import { setdisableNext } from "helper/redux/Slice/UtilsSlice";
import {
  UpdateCoverEducation,
  setCoverEducationFields,
} from "helper/redux/Slice/CoverSlice";
import {
  BachelorsData,
  SecondaryData,
  MastersData,
} from "components/JSON/EducationJSON";
import { ref } from "firebase/storage";
import { toast } from "react-hot-toast";

export const Education = ({
  UpdateRedux,
  setUpdateRedux,
  handleResponsivePreviewShow,
}) => {
  const dispatch = useDispatch();
  const DocType = useSelector((item) => item.types.doctype);

  //resume
  const EducationData = useSelector((item) => item.resume.education);

  //coverletter
  const CoverEducationData = useSelector((item) => item.cover.education);
  const [recentDegree, setRecentDegree] = useState("");
  const [EducationObj, setEducationObj] = useState({
    id: EducationData?.length,
    status: "",
    degree: "",
    specialization: "",
    institute: "",
    location: "",
    date: "",
    minor: "",
    gpa: "",
    percentage: "",
  });

  const HandleChange = (e) => {
    const { name, value } = e.target;
    setEducationObj({ ...EducationObj, [name]: value });
  };

  const handleEducationSubmit = (e) => {
    e.preventDefault();
    if (!EducationObj?.degree) {
      toast.error("Please Fill Course Field");
    }
    if (DocType === "cover") {
      if (!!UpdateRedux) {
        dispatch(UpdateCoverEducation(EducationObj, EducationObj?.index));
        toast.success("Education Updated Successfully");
        setUpdateRedux(null);
      } else if (!!EducationObj?.degree) {
        dispatch(setCoverEducationFields(EducationObj));
        toast.success("Education Saved Successfully");
      }
    } else {
      if (!!UpdateRedux) {
        dispatch(UpdateEducationFields(EducationObj, EducationObj?.index));
        setUpdateRedux(null);
        toast.success("Education Updated Successfully");
      } else if (!!EducationObj?.degree) {
        dispatch(setEducationFields(EducationObj));
        toast.success("Education Saved Successfully");
      }
    }
  };

  useEffect(() => {
    if (!!UpdateRedux) {
      setEducationObj(UpdateRedux);
    }
  }, [UpdateRedux]);

  const ResumeType = useSelector((item) => item.resume);

  //disable next btn
  useEffect(() => {
    if (EducationData?.length > 0) {
      dispatch(setdisableNext({ type: "education", value: false }));
    } else {
      dispatch(setdisableNext({ type: "education", value: true }));
    }
  }, [EducationData]);

  //educational dropdown

  const [SuggestionShow, setSuggestionShow] = useState(false);
  const InputRef = useRef();

  const handleDegree = (value) => {
    // if (!!value) {
    //   setEducationObj({ ...EducationObj, name: value })
    //   setSuggestionShow(false)
    //   console.log("ok1")
    // } else {
    //   console.log("ok")
    //   setSuggestionShow(false)
    // }
    if (value === "others") {
      InputRef.current.focus();
      setSuggestionShow(false);
    } else if (!!value && value !== "others") {
      setEducationObj({ ...EducationObj, degree: value });
      setSuggestionShow(false);
    }
  };

  //searching course
  const [SearchField, setSearchField] = useState("");
  const [BachelorArray, setBachelorArray] = useState();
  const [SecondaryArray, setSecondaryArray] = useState();
  const [MasterArray, setMasterArray] = useState();
  const handleSearch = (e) => {
    setSearchField(e.target.value);
  };
  const FilteringBachelorArray = () => {
    if (SearchField !== "") {
      const filterArray = BachelorArray?.filter((item) =>
        item.toLowerCase().includes(SearchField.toLowerCase())
      );
      setBachelorArray(filterArray);
    } else {
      setBachelorArray(BachelorsData);
    }
  };
  const FilteringSecondaryArray = () => {
    if (SearchField !== "") {
      const filterArray = SecondaryArray?.filter((item) =>
        item.toLowerCase().includes(SearchField.toLowerCase())
      );
      setSecondaryArray(filterArray);
    } else {
      setSecondaryArray(SecondaryData);
    }
  };
  const FilteringMasterArray = () => {
    if (SearchField !== "") {
      const filterArray = MasterArray?.filter((item) =>
        item.toLowerCase().includes(SearchField.toLowerCase())
      );
      setMasterArray(filterArray);
    } else {
      setMasterArray(MastersData);
    }
  };
  useEffect(() => {
    FilteringBachelorArray();
    FilteringSecondaryArray();
    FilteringMasterArray();
  }, [SearchField]);

  const EducationRegex = /\((.*?)\)/;

  useEffect(() => {
    setBachelorArray(BachelorsData);
    setSecondaryArray(SecondaryData);
    setMasterArray(MastersData);
  }, []);
  useEffect(() => {
    //   if (EducationData?.length > 0) {
    //     const mostRecentDegree = EducationData[EducationData.length - 1]?.degree;
    //     console.log("Most recent degree fetched:", mostRecentDegree);
    //     setRecentDegree(mostRecentDegree);
    //   }
    // }, [EducationData]);
    if (EducationData?.length > 0) {
      // Create a copy of EducationData before sorting
      const sortedEducationData = [...EducationData];

      // Sort the copied array based on completion dates in descending order
      sortedEducationData.sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateB - dateA;
      });

      // Get the most recent degree
      const mostRecentDegree = sortedEducationData[0]?.degree;
      console.log("Most recent degree fetched:", mostRecentDegree);
      setRecentDegree(mostRecentDegree);
    }
  }, [EducationData]);

  return (
    <div className="create-resume-main">
      <div className="d-flex justify-content-between">
        <div className="d-flex w-100 justify-content-between align-items-center my-1">
          <h3>Education </h3>
          <div>
            <button
              onClick={handleResponsivePreviewShow}
              className="align-self-start p-1 responsive-preview-btn d-inline-block d-lg-none"
            >
              Preview
            </button>
          </div>
        </div>
      </div>
      <div className="text-end pe-2 mb-2">
        <small>
          {DocType === "resume"
            ? !!ResumeType?.profession
              ? "Job Specific"
              : "Generic"
            : ""}
        </small>
      </div>
      {/* <p>
        Et pellentesque scelerisque risus blandit quam tempus. Pellentesque
        sodales nec convallis sit ac.
      </p> */}
      <Form className="form-main" onSubmit={(e) => handleEducationSubmit(e)}>
        <FloatingLabel label="Educational Status" className="mb-3">
          <Form.Select
            aria-label="Default select example"
            name="status"
            onChange={HandleChange}
            style={{
              color: "black",
              fontSize: "12px",
              fontWeight: "600",
              height: "50px",
              borderBottom: "0.1px solid #ffa303",
              border: "none",
            }}
          >
            {/* <option value="" >{"~" + ContactFields?.title + "~" || "Select a title"}</option> */}
            <option value="">
              {!!EducationObj?.status
                ? "~" + EducationObj?.status + "~"
                : "Select a option"}
            </option>

            <option value="Secondary Education">Secondary Education</option>
            <option value="Bachelor's Degree">Bachelor's Degree</option>
            <option value="Master's Degree">Master's Degree</option>
            <option value="PhD">PhD</option>
            <option value="High School Diploma or Equivalent">
              High School Diploma or Equivalent
            </option>
            <option value="Associate Degree">Associate Degree</option>
            <option value="Diploma Programs">Diploma Programs</option>
            <option value="Certificate Programs">Certificate Programs</option>
            <option value="Honorary Doctorate">Honorary Doctorate</option>
          </Form.Select>
        </FloatingLabel>

        <div className="d-flex flex-lg-row flex-column  justify-content-between gap-4">
          <FloatingLabel
            controlId="floatingInput"
            label="Course *"
            className="mb-lg-3 mb-0 w-100 position-relative"
          >
            <Form.Control
              type="text"
              name="degree"
              onChange={HandleChange}
              required
              disabled={!!EducationObj?.status ? false : true}
              ref={InputRef}
              value={EducationObj?.degree}
              // onBlur={() => handleDegree()}
              onClick={() => setSuggestionShow(true)}
              // onBlur={() => !!EducationObj?.name && setSuggestionShow(false)}
              placeholder="WHAT IS YOUR DEGREE OR OTHER QUALIFICATION AND MAJOR?*"
            />
            {SuggestionShow && (
              <div className="bg-white p-2 border rounded suggestion-box">
                <input
                  type="text"
                  name="name"
                  className="border form-control form-control-sm"
                  placeholder="Search your Course"
                  onChange={handleSearch}
                  autoFocus
                  required
                  size="sm"
                  value={SearchField}
                />
                <ul className="list-style-none px-1 mt-2">
                  {EducationObj?.status === "Secondary Education"
                    ? SecondaryArray?.map((item, index) => {
                      return (
                        <li onClick={() => handleDegree(item)} key={index}>
                          {item}
                        </li>
                      );
                    })
                    : EducationObj?.status === "Bachelor's Degree"
                      ? BachelorArray?.map((item, index) => {
                        return (
                          <li onClick={() => handleDegree(item)} key={index}>
                            {" "}
                            {item}
                          </li>
                        );
                      })
                      : EducationObj?.status === "Master's Degree"
                        ? MasterArray?.map((item, index) => {
                          return (
                            <li onClick={() => handleDegree(item)} key={index}>
                              {" "}
                              {item}
                            </li>
                          );
                        })
                        : "No Course Found"}
                  <li onClick={() => handleDegree("others")}>Others</li>
                </ul>
              </div>
            )}
          </FloatingLabel>

          <FloatingLabel
            controlId="floatingInput"
            label="Specialization "
            className="mb-3 w-100 position-relative"
          >
            <Form.Control
              type="text"
              name="specialization"
              onChange={HandleChange}
              value={EducationObj?.specialization}
              // onBlur={() => handleDegree()}
              // onBlur={() => !!EducationObj?.name && setSuggestionShow(false)}
              placeholder="What's your specialization?"
            />
            {/* {
              SuggestionShow &&
              <div className="bg-white p-2 border rounded suggestion-box">
                <input
                  type="text"
                  name="name"
                  className="border form-control form-control-sm"
                  placeholder="Search your Course"
                  onChange={(e) => setSearchField(e.target.value)}
                  autoFocus
                  required
                  size="sm"
                  value={SearchField}
                />
                <ul className="list-style-none px-1 mt-2">
                  {
                    EducationObj?.educationalStatus === "Secondary Education" ?
                      SecondaryData?.map((item, index) => {
                        return <li onClick={() => handleDegree(item)} key={index}>{item}</li>
                      }) :
                      EducationObj?.educationalStatus === "Bachelor's Degree" ?
                        BachelorsData?.map((item, index) => {
                          return <li onClick={() => handleDegree(item)} key={index} > {item}</li>
                        }) : "No Course Found"
                  }
                  <li onClick={() => handleDegree("others")} >Others</li>
                </ul>
              </div>
            } */}
          </FloatingLabel>
        </div>
        <FloatingLabel
          controlId="floatingInput"
          label="Institute"
          className="mb-3"
        >
          <Form.Control
            type="text"
            placeholder="Institute"
            name="institute"
            onChange={HandleChange}
            value={EducationObj?.institute}
          />
        </FloatingLabel>

        <FloatingLabel
          controlId="floatingInput"
          label="Where is the institution located?"
          className="mb-3"
        >
          <Form.Control
            type="text"
            name="location"
            onChange={HandleChange}
            value={EducationObj?.location}
            placeholder="Where is the institution located?"
          />
        </FloatingLabel>
        <FloatingLabel
          controlId="floatingInput"
          label="Date *"
          className="mb-3"
        >
          <Form.Control
            type="month"
            name="date"
            required
            onChange={HandleChange}
            value={EducationObj?.date}
            placeholder="Date"
          />
        </FloatingLabel>
        <FloatingLabel
          controlId="floatingInput"
          label="Did you minor in anything?"
          className="mb-3"
        >
          <Form.Control
            type="text"
            name="minor"
            onChange={HandleChange}
            value={EducationObj?.minor}
            placeholder="Did you minor in anything?"
          />
        </FloatingLabel>

        <FloatingLabel
          controlId="floatingInput"
          label="GPA (IF APPLICABLE)"
          className="mb-3"
        >
          <Form.Control
            type="text"
            name="gpa"
            onChange={HandleChange}
            value={EducationObj?.gpa}
            placeholder="percentage %"
          />
        </FloatingLabel>

        <FloatingLabel
          controlId="floatingInput"
          label="Percentage % (IF APPLICABLE)"
          className="mb-3"
        >
          <Form.Control
            type="text"
            name="percentage"
            onChange={HandleChange}
            value={EducationObj?.percentage}
            placeholder="percentage %"
          />
        </FloatingLabel>

        <button className="save-experience" type="submit">
          Save To Education List
        </button>
      </Form>
    </div>
  );
};
