import { Form, FloatingLabel, Spinner } from "react-bootstrap";
import { useState, useEffect, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ButtonGroup, Button } from "react-bootstrap";
import Calendar from "react-calendar";

import {
  setExperienceFields,
  setInternshipFields,
  UpdateExperienceField,
} from "helper/redux/Slice/ResumeSlice";
import { AiButton } from "components/AiButton/AiButton";
import axios from "axios";
import { ChatGPTAPI, CutAiCredits, getProfile } from "helper/api";
import { setAiCredits, setdisableNext } from "helper/redux/Slice/UtilsSlice";
import {
  UpdateCoverExperience,
  setCoverExperienceFields,
} from "helper/redux/Slice/CoverSlice";
import { AiFillLock, AiFillUnlock } from "react-icons/ai";
import GptModal from "./GptModal";
import { setUser } from "helper/redux/Slice/UserSlice";
import { toast } from "react-hot-toast";

import "react-datepicker/dist/react-datepicker.css";
import { BiCalendar } from "react-icons/bi";
import DateSelector from "common/DateSelector";
export const Experiences = ({
  UpdateRedux,
  setUpdateRedux,
  handleResponsivePreviewShow,
  setExperienceAndOtherState,
  ExperienceAndOtherState,
}) => {
  const dispatch = useDispatch();
  const Experience = useSelector((item) => item.resume.experiences);
  const [loadSpinner, setloadSpinner] = useState(false);
  const [GptData, setGptData] = useState();
  const [DisableAiBtn, setDisableAiBtn] = useState(true);
  const DocType = useSelector((item) => item.types.doctype);

  const [ExperienceObj, setExperienceObj] = useState({
    role: "",
    company: "",
    jobtype: "",
    location: "",
    start: "",
    end: "",
    isWorking: false,
    totalexperience: "",
    description: "",
  });

  //disable next btn

  useEffect(() => {
    if (Experience?.length > 0) {
      dispatch(setdisableNext({ type: "experience", value: false }));
    } else {
      dispatch(setdisableNext({ type: "experience", value: true }));
    }
  }, [Experience]);

  const handleEndData = () => {
    setExperienceObj({
      ...ExperienceObj,
      isWorking: !ExperienceObj?.isWorking,
      end: "",
    });
  };
  const CoverExperienceData = useSelector((item) => item.cover.experiences);

  const handleExperienceSubmit = (e) => {
    e.preventDefault();
    const start = new Date(ExperienceObj?.start);
    const end = !!ExperienceObj?.end
      ? new Date(ExperienceObj?.end)
      : new Date();
    const monthsDiff =
      (end.getFullYear() - start.getFullYear()) * 12 +
      (end.getMonth() - start.getMonth());
    if (DocType === "cover") {
      if (!!UpdateRedux) {
        dispatch(UpdateCoverExperience(ExperienceObj, ExperienceObj?.index));
        setUpdateRedux([]);
        toast.success("Experience Updated Successfully");
      } else if (
        !!ExperienceObj.role &&
        !!ExperienceObj?.location &&
        !!ExperienceObj?.start &&
        !!ExperienceObj?.company &&
        (ExperienceObj?.isWorking || !!ExperienceObj?.end)
      ) {
        toast.success("Experience Saved Successfully");
        dispatch(
          setCoverExperienceFields({
            ...ExperienceObj,
            totalexperience: monthsDiff,
          })
        );
      }
    } else {
      if (!!UpdateRedux) {
        dispatch(UpdateExperienceField(ExperienceObj, ExperienceObj?.index));
        setUpdateRedux(null);
        toast.success("Experience Updated Successfully");
      } else if (
        !!ExperienceObj.role &&
        !!ExperienceObj?.location &&
        !!ExperienceObj?.start &&
        !!ExperienceObj?.company &&
        (ExperienceObj?.isWorking || !!ExperienceObj?.end)
      ) {
        toast.success("Experience Saved Successfully");
        dispatch(
          setExperienceFields({ ...ExperienceObj, totalexperience: monthsDiff })
        );
      }
    }
  };

  const HandleChange = (e) => {
    const { name, value } = e.target;
    if (name === "role") {
      setExperienceObj({
        ...ExperienceObj,
        role: value.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
          letter.toUpperCase()
        ),
      });
    } else {
      setExperienceObj({ ...ExperienceObj, [name]: value });
    }
  };
  console.log(ExperienceObj);
  useEffect(() => {
    if (!!UpdateRedux) {
      setExperienceObj(UpdateRedux);
    }
  }, [UpdateRedux]);

  useEffect(() => {
    if (!!ExperienceObj?.company && !!ExperienceObj?.role) {
      setDisableAiBtn(false);
    } else {
      setDisableAiBtn(true);
    }
  }, [ExperienceObj?.role, ExperienceObj?.company]);

  // const getYears = () => {
  //   const syear = ExperienceObj?.start.split('-')[0]
  //   const smonth = ExperienceObj?.start.split('-')[1]
  //   const eyear = ExperienceObj?.end.split('-')[0]
  //   const emonth = ExperienceObj?.end.split('-')[1]
  //   if (eyear - syear > 0) {
  //     return eyear - syear
  //   } else {
  //     return `0.${emonth - smonth}`
  //   }
  // }
  const [PremiumFeature, setPremiumFeature] = useState(false);

  const handlePremiumFeatureClose = () => {
    setPremiumFeature(false);
  };
  const UserData = useSelector((item) => item?.user?.user);
  const PostChatGPTData = async () => {
    if (!!ExperienceObj.role && !!ExperienceObj?.company) {
      // const year = getYears()
      const temperature = Math.random() * (0.4 - 0.1) + 0.1;
      const payload = {
        question: `Give only one ATS compliant work experience description point in past tense for role of a ${ExperienceObj?.role} at ${ExperienceObj?.company}, with the job type being ${ExperienceObj?.jobtype}.The description should highlight key responsibilities, accomplishments, or skills demonstrated in the role.The description should highlight a key responsibility, accomplishment, or skill demonstrated in the role. Ensure the sentence is concise, free of grammatical errors, and optimized for ATS parsing. Tailor the sentence based on the job type (internship, part-time, or full-time), focusing on relevant skills, achievements, or learning experiences suited to the specific role type. `,
        temperature: temperature.toFixed(1), // Limit to one decimal place
      };
      try {
        setloadSpinner(true);
        const data = await ChatGPTAPI(UserData?.aicredits, payload);

        console.log(data);

        if (!data) {
          setPremiumFeature(true);
          setloadSpinner(false);
          return;
        }
        const aiCreditPayload = {
          credit: 2,
          used: "Experience",
        };
        toast.success("Your 2 AI credits has been used.");
        // const newText = data?.data?.text.replaceAll(/[\r\n]+/g, " "); // Replace newline characters

        // const updatedDescription =
        //   ExperienceObj.description && ExperienceObj.description.trim() // Check for existing description
        //     ? `${ExperienceObj.description}\n${newText}` // Append with a newline
        //     : newText;
        // Split response text into bullet points based on new lines or periods
        const bulletPoints = data?.data?.text
          .split(/\r?\n|\.\s/)
          .filter(Boolean);

        // Create formatted bullet points list
        const formattedDescription = bulletPoints
          .map((point) => `• ${point.trim()}`)
          .join("\n");
        // Append the new bullet points to existing description (if any)
        const updatedDescription =
          ExperienceObj.description && ExperienceObj.description.trim()
            ? `${ExperienceObj.description}\n${formattedDescription}`
            : formattedDescription;
        setExperienceObj({
          ...ExperienceObj,
          description: updatedDescription,
        });
        // setExperienceObj({
        //   ...ExperienceObj,
        //   description: !!ExperienceObj.description
        //     ? ExperienceObj.description + "\n" + data?.data?.text
        //     : data?.data?.text,
        // });
        setGptData(data?.data?.text);
        dispatch(setAiCredits(aiCreditPayload?.credit));
        await CutAiCredits(aiCreditPayload);
        setloadSpinner(false);
        // setTempTextData()
        // // dispatch(setUser(Profile?.data?.data))
      } catch (error) {
        setloadSpinner(false);
        console.log(error);
      }
    }
  };
  const ResumeType = useSelector((item) => item.resume);

  const handleTextFieldChange = (e) => {
    setExperienceObj({
      ...ExperienceObj,
      description:
        ExperienceObj?.description?.length === 0 ||
        ExperienceObj?.description === undefined
          ? "" + e.target.value
          : e.target.value,
    });
  };
  const checkEnter = (e) => {
    if (e.key === "Enter") {
      setExperienceObj({
        ...ExperienceObj,
        description: ExperienceObj.description + "",
      });
    }
  };

  const handleUpdateNavigateState = (value) => {
    setExperienceAndOtherState(value);
  };
  return (
    <div className="create-resume-main">
      <div className="d-flex justify-content-between">
        <div className="d-flex w-100 justify-content-between align-items-center my-1">
          <div className="d-flex w-100 justify-content-between align-items-center my-1">
            <h3>Experience </h3>

            {/* <ButtonGroup aria-label="Basic example" className="gap-2">
              <Button style={{ background: `${ExperienceAndOtherState === 0 ? "#521986" : "lightgrey"}`, border: "none" }} className='' onClick={() => handleUpdateNavigateState(0)}>Internship</Button>
              <Button style={{ background: `${ExperienceAndOtherState === 1 ? "#521986" : "lightgrey"}`, border: "none" }} onClick={() => handleUpdateNavigateState(1)}>Full-time</Button>

            </ButtonGroup> */}
          </div>

          <div>
            <button
              onClick={handleResponsivePreviewShow}
              className="align-self-start p-1 responsive-preview-btn d-inline-block d-lg-none"
            >
              Preview
            </button>
          </div>
        </div>
      </div>
      <div className="text-end pe-2 mb-2">
        <small>
          {DocType === "resume"
            ? !!ResumeType?.profession
              ? "Job Specific"
              : "Generic"
            : ""}
        </small>
      </div>

      <Form
        className="form-main"
        onSubmit={(e) => {
          handleExperienceSubmit(e);
        }}
      >
        <div className="d-flex gap-3">
          <FloatingLabel
            controlId="floatingInput"
            label={
              <span className="required-label">
                What was your role at the company? e.g. Consultant, Sales
                Executive
              </span>
            }
            className="mb-3 w-100"
          >
            <Form.Control
              type="text"
              name="role"
              required
              placeholder="What was your role at the company?"
              value={ExperienceObj?.role}
              onChange={HandleChange}
            />
          </FloatingLabel>

          <FloatingLabel
            label="Job Type"
            className="mb-3"
            style={{ width: "100%" }}
          >
            <Form.Select
              aria-label="Default select example"
              name="jobtype"
              onChange={HandleChange}
              value={ExperienceObj?.jobtype}
              style={{
                color: "black",
                fontSize: "12px",
                fontWeight: "600",
                height: "50px",
                borderBottom: "0.1px solid #ffa303",
                border: "none",
              }}
            >
              <option value="">{"Select Job Type"}</option>
              <option value="Part-Time">Part-Time</option>
              <option value="Full-Time">Full-Time</option>
              <option value="Internship">Internship</option>
              <option value="Consultant">Consultant</option>
            </Form.Select>
          </FloatingLabel>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <FloatingLabel
              controlId="floatingInput"
              label={
                <span className="required-label">
                  For which company did you work?
                </span>
              }
              className="mb-3"
            >
              <Form.Control
                type="text"
                name="company"
                required
                value={ExperienceObj?.company}
                onChange={HandleChange}
                placeholder="For which company did you work? *"
              />
            </FloatingLabel>

            <FloatingLabel
              controlId="floatingPassword"
              label={
                <span className="required-label">
                  When did you join the company?
                </span>
              }
              className="mb-3"
            >
              <Form.Control
                type="month"
                name="start"
                required
                value={ExperienceObj?.start}
                onChange={HandleChange}
                placeholder="When did you joined the company? "
              />
            </FloatingLabel>
          </div>
          <div className="col-lg-6 flex-column-reverse d-flex flex-lg-column">
            <FloatingLabel
              controlId="floatingPassword"
              label={
                <span className="required-label">
                  Where was the company located?
                </span>
              }
              className="mb-3"
            >
              <Form.Control
                type="text"
                required
                name="location"
                placeholder="Where was the company located?"
                value={ExperienceObj?.location}
                onChange={HandleChange}
              />
            </FloatingLabel>
            <div>
              <FloatingLabel
                controlId="floatingPassword"
                label="When did you leave the company?"
                className="mb-1"
              >
                <Form.Control
                  required
                  disabled={!ExperienceObj?.isWorking ? false : true}
                  type="month"
                  value={ExperienceObj?.end}
                  onChange={HandleChange}
                  min={ExperienceObj?.start}
                  name="end"
                  placeholder="End Date"
                />
              </FloatingLabel>

              <div className="checkboxs mb-2 px-2">
                <label>Currently working </label>
                <input
                  onChange={handleEndData}
                  checked={ExperienceObj?.isWorking}
                  type="checkbox"
                  className=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="add-experience-container">
          <div className="mb-0 add-experience-heading">
            <p className="mb-0 add-experience-heading ">
              What did you do at the company?{" "}
            </p>
            <div className="d-flex">
              <AiButton disabled={DisableAiBtn} SetAi={PostChatGPTData} />
              {/* <button className={`p-2 rounded-pill px-3 ms-2 textfield-lock ${!ExperienceObj?.description ? 'textfield-lock-disabled' : 'textfield-lock-active'}`} onClick={(e) => {
                e.preventDefault()
                setFieldLock(true)
                // handleTextLock()
              }}> {FieldLock ? <AiFillLock fontSize={24} /> : <AiFillUnlock fontSize={24} />} </button> */}
            </div>
          </div>

          {loadSpinner ? (
            <div className="d-flex justify-content-center my-5">
              <Spinner animation="border" variant="secondary" />
            </div>
          ) : (
            <Form.Control
              as="textarea"
              value={ExperienceObj?.description}
              onChange={handleTextFieldChange}
              onKeyUp={checkEnter}
              name="description"
              className="px-4"
              placeholder="Type or Use 'AI' Button"
              style={{ height: "100px" }}
            />
          )}
        </div>
        <button className="save-experience" type="submit">
          Save To Experience List
        </button>
      </Form>

      {/* premium modal */}
      <GptModal
        PremiumFeature={PremiumFeature}
        handlePremiumFeatureClose={handlePremiumFeatureClose}
      />
    </div>
  );
};
