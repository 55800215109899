import React from "react";
import sidelogo from "../../assets/image/sidelogo.mp4";
import { SidePanelData } from "contants/SidepanelData";
import { NavLink } from "react-router-dom";
import coin from "../../assets/image/dollar.png";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { BsFillPersonFill } from "react-icons/bs";

const ResponsiveSIdebar = () => {
  const UtilsData = useSelector((item) => item?.utils);
  return (
    <>
      <div className="sidebar-container ">
        <div
          className="w-100 sidebar-wrapper   position-relative"
          style={{ background: "#602a94", borderRadius: "0 6px 0 0" }}
        >
          <div className=" py-4  w-100 px-3">
            {SidePanelData.map((item) => (
              <>
                <NavLink to={item.path} className="sidebar__link" key={item.id}>
                  <div
                    className={`d-flex align-items-center px-2 py-2 wrapper__sidebar `}
                  >
                    {item.icon}
                    <h6 className="sidebar__heading px-2 mt-2">{item.name}</h6>
                  </div>
                </NavLink>
                {item?.name === "My Profile" && (
                  <hr className="border  border-light" />
                )}
              </>
            ))}
          </div>

          <div className="sidebar-Pointsdropdown-container">
            <button
              className="sub-navbar-button"
              // onClick={handleShow}
              // style={{ borderRadius: dropDownMenu ? "10px 10px 0 0" : "10px" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div className="d-flex align-items-center">
                  <img alt="coin" src={coin} />
                  AI Credits
                </div>
                <span style={{ fontSize: "0.9rem" }}>
                  {UtilsData?.AICreditsLoadingState ? (
                    <Spinner size="sm" style={{ color: "indigo" }} />
                  ) : (
                    UtilsData?.AICredits
                  )}
                </span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResponsiveSIdebar;
