
export const EducationPre = () => {
  return (
    <div className="create-resume-main">
      <h3>Education</h3>
      <p>
        he section for "Education" typically includes information about your academic background, such as your degrees, diplomas, certifications, and the institutions where you obtained them. It highlights your formal education and demonstrates your level of qualification and expertise in a specific field.
      </p>
    </div>
  );
};
