import React from "react";
import { useSelector } from "react-redux";

const UseATScompliance = () => {
  let ATSscore = 0;

  const ResumeRedux = useSelector((item) => item.resume);
  const UtilsRedux = useSelector((item) => item.utils);
  console.log(UtilsRedux?.jobSpecificSkill);

  const checkStringForWord = (string, wordArray) => {
    for (let i = 0; i < wordArray.length; i++) {
      if (
        string?.includes(wordArray[i]) ||
        string?.includes(wordArray[i].toLowerCase()) ||
        string?.includes(wordArray[i].toUpperCase())
      ) {
        return (ATSscore += 9.5);
      }
    }
  };

  if (ResumeRedux.experiences.length > 0) {
    ResumeRedux?.experiences?.map((item) => {
      checkStringForWord(item.description, UtilsRedux?.jobSpecificSkill);
    });
  }
  if (ResumeRedux?.projects?.length > 0) {
    ResumeRedux?.projects.map((item) => {
      checkStringForWord(item?.description, UtilsRedux?.jobSpecificSkill);
    });
  }
  if (ResumeRedux?.involvement?.length > 0) {
    ResumeRedux.involvement.map((item) => {
      checkStringForWord(item?.description, UtilsRedux?.jobSpecificSkill);
    });
  }
  if (ResumeRedux.skills.length > 0) {
    ResumeRedux.skills.map((item) => {
      checkStringForWord(item.name, UtilsRedux?.jobSpecificSkill);
    });
  }

  if (!!ResumeRedux?.summary) {
    checkStringForWord(ResumeRedux?.summary, UtilsRedux?.jobSpecificSkill);
  }

  return ATSscore;
};

export default UseATScompliance;
