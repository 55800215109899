import styled from "styled-components";
export const SidePanelPrimaryBox = styled.div`
  width: 250px;
  color: ${({ theme }) => theme.white};
  height: 100vh;
  background: ${({ theme }) => theme.primary};
  position: sticky;
  top: 0;
  overflow: hidden;
`;

export const SidePanelSecondaryBox = styled.div`
  width: 230px;
  color: ${({ theme }) => theme.white};

  height: 100px;
  &.aligncenter {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
