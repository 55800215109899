import React, { useRef, useEffect, useState } from 'react';
import { useReactMediaRecorder } from "react-media-recorder";
import { ReactMediaRecorder } from "react-media-recorder";

const Recorder = ({ handleFileUpload }) => {
  const [RecordingStatus, setRecordingStatus] = useState(false);
  const [medialUrl, setmedialUrl] = useState(undefined);
  const { status, startRecording, stopRecording, mediaBlobUrl, previewStream } =
    useReactMediaRecorder({ video: true, audio: false });

  const videoRef = useRef(null);
  useEffect(() => {

    if (videoRef.current && previewStream) {
      videoRef.current.srcObject = previewStream;
    }
  }, [previewStream]);

  const [VideoInterval, setVideoInterval] = useState(0);
  const [IntervalID, setIntervalID] = useState(undefined);

  const handleTimer = () => {
    const id = setInterval(() => {
      setVideoInterval(VideoInterval => VideoInterval + 1);
    }, (1000));
    setIntervalID(id);
  }
  const handleFile = (event) => {
    console.log(event.target.files[0])
  }


  const handleTrimmm = () => {
  }

  return (
    <>
      <div className='d-flex flex-column w-100 h-100 VideoResume_recorder_wrapper'>
        <div className='VideoResume_recorder mb-4'>
          <input type="file" onChange={handleFile} />
          {
            !!mediaBlobUrl &&
            <video style={{ height: "100%", width: "100%" }} src={mediaBlobUrl} controls autoPlay loop />
          }
          {
            !mediaBlobUrl &&
            <video style={{ height: "100%", width: "100%" }} ref={videoRef} controls autoPlay />
          }
        </div>
        {
          RecordingStatus ?
            <button onClick={() => {
              setRecordingStatus(false)
              stopRecording()
              handleFileUpload(mediaBlobUrl)
              clearInterval(IntervalID)
            }}>Stop Recording</button> :

            <button onClick={() => {
              setRecordingStatus(true)
              startRecording()
              handleTimer()
            }}>Record</button>
        }
        <button onClick={handleTrimmm}>Trim</button>
      </div>
    </>
  );
}

export default Recorder