import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  ChatGPTAPI,
  GetAiResumeById,
  GetAiResumeId,
  GetAutomatedResumeById,
  GetTrailResumeById,
} from "helper/api";
import { useSelector } from "react-redux";

export const GetTrailResumeEditData = createAsyncThunk(
  "resume/GetTrailResumeEditData",
  async (id) => {
    try {
      const response = await GetTrailResumeById(id); // call your async API function
      console.log(response);
      return response.data; // return the data from the API response
    } catch (error) {
      throw Error(error.message); // throw an error if API call fails
    }
  }
);
export const GetAiResumeEditData = createAsyncThunk(
  "resume/GetAiResumeEditData",
  async (id) => {
    try {
      const response = await GetAiResumeById(id); // call your async API function
      console.log(response);
      return response.data; // return the data from the API response
    } catch (error) {
      throw Error(error.message); // throw an error if API call fails
    }
  }
);
export const GetAutomatedResumeEditData = createAsyncThunk(
  "resume/GetAutomatedResumeEditData",
  async (id) => {
    try {
      const response = await GetAutomatedResumeById(id); // call your async API function
      console.log(response);
      return response.data; // return the data from the API response
    } catch (error) {
      throw Error(error.message); // throw an error if API call fails
    }
  }
);

export const GettingResumeStrongSummary = createAsyncThunk(
  "resume/GettingResumeStrongSummary",
  async (payload) => {
    console.log(payload);
    try {
      const response = await ChatGPTAPI(
        payload?.AiCredit,
        payload?.ResumeStrongSummary
      ); // call your async API function
      return response.data; // return the data from the API response
    } catch (error) {
      throw Error(error.message); // throw an error if API call fails
    }
  }
);

const initialState = {
  name: "",
  template: "",
  resumeId: "",
  color: "",
  type: "",
  profession: "",
  jobProfile: "",
  desiredCompany: "",
  EditData: false,
  contact: {
    picture: "",
    firstName: "",
    lastName: "",
    number: "",
    email: "",
    title: "",
    city: "",
    address: "",
    pincode: "",
    country: "",
  },
  SkillChatGpt: [],
  experiences: [],
  internships: [],
  researchPapers: [],
  achievements: [],
  languagesKnown: [],
  links: [],
  projects: [],
  education: [],
  certification: [],
  involvement: [],
  hobby: [],
  skills: [],
  summary: "",
  completeness: 0,
  formatting: 0,
  wordChoice: 0,
  clearContactInfo: 0,
  measurableResult: 0,
  strongSummary: 0,
  atsCompliance: 0,
  noTypos: 0,
  length: 0,
  rank: 0,
  optimization: 0,
  isAnalysisData: false,
};

let ResumeSlice = createSlice({
  name: "resume",
  initialState,
  reducers: {
    setCreateResume(state, action) {
      state[action.payload.type] = action.payload.value;
    },
    setContactRedux: (state, action) => {
      state.contact = action.payload;
    },
    UpdateContactRedux: (state, action) => {
      state.contact = {
        ...state.contact,
        [action.payload.type]: action.payload.value,
      };
    },
    // experiences
    setExperienceFields(state, action) {
      state.experiences.push(action.payload);
    },
    DeleteExperienceField(state, action) {
      state.experiences = state?.experiences?.filter(
        (item, index) => index !== action.payload
      );
    },
    UpdateExperienceField(state, action) {
      let index = action?.payload?.index;
      state.experiences[index] = action.payload;
    },

    // Internships
    setInternshipFields(state, action) {
      state.internships.push(action.payload);
    },
    DeleteInternshipField(state, action) {
      state.internships = state?.internships?.filter(
        (item, index) => index !== action.payload
      );
    },
    UpdateInternshipField(state, action) {
      let index = action?.payload?.index;
      state.internships[index] = action.payload;
    },

    // Project
    setProjectFields(state, action) {
      state.projects.push(action.payload);
    },
    DeleteProjectFields(state, action) {
      state.projects = state?.projects?.filter(
        (item, index) => index !== action.payload
      );
    },
    UpdateProjectFields(state, action) {
      let index = action?.payload?.index;
      state.projects[index] = action.payload;
    },

    // Reasearch Paper
    setResearchPapersFields(state, action) {
      state.researchPapers.push(action.payload);
    },
    DeleteResearchPapersFields(state, action) {
      state.researchPapers = state?.researchPapers?.filter(
        (item, index) => index !== action.payload
      );
    },
    UpdateResearchPapersFields(state, action) {
      let index = action?.payload?.index;
      state.researchPapers[index] = action.payload;
    },

    // Education
    setEducationFields(state, action) {
      state.education.push(action.payload);
    },
    DeleteEducationFields(state, action) {
      state.education = state?.education?.filter(
        (item, index) => index !== action.payload
      );
    },
    UpdateEducationFields(state, action) {
      let index = action?.payload?.index;
      state.education[index] = action.payload;
    },

    // Certificate
    setCertificateFields(state, action) {
      state.certification.push(action.payload);
    },
    DeleteCertificateFields(state, action) {
      state.certification = state?.certification?.filter(
        (item, index) => index !== action.payload
      );
    },
    UpdateCertificateFields(state, action) {
      let index = action?.payload?.index;
      state.certification[index] = action.payload;
    },

    // Involvement
    setInvolvementFields(state, action) {
      state.involvement.push(action.payload);
    },
    DeleteInvolvementFields(state, action) {
      state.involvement = state?.involvement?.filter(
        (item, index) => index !== action.payload
      );
    },
    UpdateInvolvementFields(state, action) {
      let index = action?.payload?.index;
      state.involvement[index] = action.payload;
    },

    // Hobbies
    setHobbyFields(state, action) {
      state.hobby.push(action.payload);
    },
    DeleteHobbyFields(state, action) {
      state.hobby = state?.hobby?.filter(
        (item, index) => index !== action.payload
      );
    },
    UpdateHobbyFields(state, action) {
      let index = action?.payload?.index;
      state.hobby[index] = action.payload;
    },

    // achievements
    setAchievementsFields(state, action) {
      state.achievements.push(action.payload);
    },
    DeleteAchievementsFields(state, action) {
      state.achievements = state?.achievements?.filter(
        (item, index) => index !== action.payload
      );
    },
    UpdateAchievementsFields(state, action) {
      let index = action?.payload?.index;
      state.achievements[index] = action.payload;
    },

    // Languages
    setlanguagesKnown(state, action) {
      state.languagesKnown.push(action.payload);
    },
    DeletelanguagesKnown(state, action) {
      state.languagesKnown = state?.languagesKnown?.filter(
        (item, index) => index !== action.payload
      );
    },
    UpdatelanguagesKnown(state, action) {
      let index = action?.payload?.index;
      state.languagesKnown[index] = action.payload;
    },

    // Links
    setAllLinks(state, action) {
      state.links.push(action.payload);
    },
    DeleteAllLinks(state, action) {
      state.links = state?.links?.filter(
        (item, index) => index !== action.payload
      );
    },
    UpdateAllLinks(state, action) {
      let index = action?.payload?.index;
      state.links[index] = action.payload;
    },
    // skills
    setSkillFields(state, action) {
      state.skills = [...state.skills, action.payload];
    },
    DeleteSkillFields(state, action) {
      state.skills = state?.skills?.filter(
        (item, index) => index !== action.payload
      );
    },

    //jobspecific
    setJobSpecificFields: (state, action) => {
      const { jobProfile, profession, desiredCompany } = action.payload;
      state.jobProfile = jobProfile;
      state.profession = profession;
      state.desiredCompany = desiredCompany;
    },
    //summary
    setSummaryFields(state, action) {
      state.summary = action.payload;
    },
    setSkillChatGpt(state, action) {
      state.SkillChatGpt = action.payload;
    },
    SetAnalysisResume: (state, action) => {
      if (action.payload?.type === "length") {
        if (action.payload.value === 0) {
          state.length = 100;
        } else if (action.payload.value === 1) {
          state.length = 75;
        } else if (action.payload?.value === 2) {
          state.length = 50;
        } else if (action.payload.value === 3) {
          state.length = 25;
        }
      } else {
        state[action.payload.type] = action.payload.value;
      }
    },
    ResetResumeTemplate: (state, action) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetTrailResumeEditData?.pending, (state) => {
        state.EditData = true;
      })
      .addCase(GetTrailResumeEditData?.fulfilled, (state, action) => {
        state.EditData = false;
        console.log(action.payload);
        const {
          contact,
          skills,
          template,
          atsCompliance,
          clearContactInfo,
          completeness,
          optimization,
          strongSummary,
          summary,
          profession,
          desiredCompany,
          jobProfile,
          hobby,
          education,
          internships,
          experiences,
          certification,
          projects,
          involvement,
          achievements,
          languagesKnown,
          links,
          researchPapers
        } = action?.payload?.data;
        state.contact = contact;
        state.skills = skills;
        state.profession = profession;
        state.jobProfile = jobProfile;
        state.desiredCompany = desiredCompany;
        state.summary = summary;
        state.education = education;
        state.languagesKnown = languagesKnown;
        state.links = links;
        state.achievements = achievements;
        state.hobby = hobby;
        state.experiences = experiences;
        state.internships = internships;
        state.projects = projects;
        state.researchPapers = researchPapers;
        state.involvement = involvement;
        state.certification = certification;
        state.template = Number(template);
        state.optimization = optimization;
        state.strongSummary = strongSummary;
        state.completeness = completeness;
        state.atsCompliance = atsCompliance;
        state.clearContactInfo = clearContactInfo;
        // state = { ...state, content, from, to, targetJob, education, experiences, certification, projects, involvement }
      })
      .addCase(GetTrailResumeEditData?.rejected, (state) => {
        state.EditData = false;
      })

      .addCase(GetAiResumeEditData?.pending, (state) => {
        state.EditData = true;
      })
      .addCase(GetAiResumeEditData?.fulfilled, (state, action) => {
        state.EditData = false;
        const {
          contact,
          skills,
          atsCompliance,
          completeness,
          clearContactInfo,
          optimization,
          strongSummary,
          template,
          profession,
          jobProfile,
          desiredCompany,
          summary,
          hobby,
          education,
          experiences,
          internships,
          certification,
          projects,
          involvement,
          achievements,
          languagesKnown,
          links,
          researchPapers
        } = action?.payload?.data;
        state.contact = contact;
        state.skills = skills;
        state.summary = summary;
        state.profession = profession;
        state.jobProfile = jobProfile;
        state.achievements = achievements;
        state.languagesKnown = languagesKnown;
        state.links = links;
        state = desiredCompany;
        state.hobby = hobby;
        state.education = education;
        state.experiences = experiences;
        state.internships = internships;
        state.projects = projects;
        state.researchPapers = researchPapers;
        state.involvement = involvement;
        state.certification = certification;
        state.template = Number(template);
        state.optimization = optimization;
        state.strongSummary = strongSummary;
        state.completeness = completeness;
        state.atsCompliance = atsCompliance;
        state.clearContactInfo = clearContactInfo;
        // state = { ...state, content, from, to, targetJob, education, experiences, certification, projects, involvement }
      })
      .addCase(GetAiResumeEditData?.rejected, (state) => {
        state.EditData = false;
      })
      .addCase(GetAutomatedResumeEditData?.pending, (state) => {
        state.EditData = true;
      })
      .addCase(GetAutomatedResumeEditData?.fulfilled, (state, action) => {
        state.EditData = false;
        let {
          contact,
          skills,
          template,
          profession,
          atsCompliance,
          clearContactInfo,
          completeness,
          optimization,
          strongSummary,
          jobProfile,
          desiredCompany,
          summary,
          hobby,
          education,
          experiences,
          certification,
          projects,
          involvement,
          achievements,
          languagesKnown,
          links,
        } = action?.payload?.data;

        state.contact = contact;
        state.skills = skills;
        state.profession = profession;
        state.jobProfile = jobProfile;
        // state = desiredCompany
        state.hobby = hobby?.length === 0 ? [] : hobby;
        state.education = education?.length === 0 ? [] : education;
        state.experiences = experiences;
        state.projects = projects;
        state.achievements = achievements;
        state.languagesKnown = languagesKnown;
        state.links = links;
        state.involvement = involvement;
        state.certification = certification;
        state.template = template;
        state.optimization = optimization;
        state.strongSummary = strongSummary;
        state.completeness = completeness;
        state.atsCompliance = atsCompliance;
        state.clearContactInfo = clearContactInfo;
      })
      .addCase(GetAutomatedResumeEditData?.rejected, (state) => {
        state.EditData = false;
      })
      .addCase(GettingResumeStrongSummary?.pending, (state, action) => {
        state.isAnalysisData = true;
      })
      .addCase(GettingResumeStrongSummary?.fulfilled, (state, action) => {
        state.isAnalysisData = false;
        const match = action.payload?.text?.match(/(\d+)/);
        console.log(action.payload);
        state.strongSummary = match[0];
      })
      .addCase(GettingResumeStrongSummary?.rejected, (state, action) => {
        state.isAnalysisData = false;
      });
  },
});
export const {
  setCreateResume,
  SetAnalysisResume,
  setJobSpecificFields,
  setSkillChatGpt,
  ResetResumeTemplate,
  UpdateContactRedux,
  setContactRedux,
  setExperienceFields,
  DeleteExperienceField,
  UpdateExperienceField,
  setProjectFields,
  DeleteProjectFields,
  UpdateProjectFields,
  setEducationFields,
  DeleteEducationFields,
  UpdateEducationFields,
  setCertificateFields,
  DeleteCertificateFields,
  UpdateCertificateFields,
  setInvolvementFields,
  DeleteInvolvementFields,
  UpdateInvolvementFields,
  setHobbyFields,
  DeleteHobbyFields,
  UpdateHobbyFields,
  setSummaryFields,
  DeleteAchievementsFields,
  setSkillFields,
  UpdateSkillFields,
  UpdateAchievementsFields,
  setAchievementsFields,
  setlanguagesKnown,
  DeletelanguagesKnown,
  DeleteSkillFields,
  UpdatelanguagesKnown,
  setAllLinks,
  DeleteAllLinks,
  UpdateAllLinks,
  setInternshipFields,
  DeleteInternshipField,
  UpdateInternshipField,
  setResearchPapersFields,
  UpdateResearchPapersFields,
  DeleteResearchPapersFields

} = ResumeSlice.actions;
export default ResumeSlice.reducer;
