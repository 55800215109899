import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, FloatingLabel } from "react-bootstrap";
import {
  setHobbyFields,
  UpdateHobbyFields,
} from "helper/redux/Slice/ResumeSlice";
import { setdisableNext } from "helper/redux/Slice/UtilsSlice";
import { toast } from "react-hot-toast";

export const Hobby = ({
  UpdateRedux,
  setUpdateRedux,
  handleResponsivePreviewShow,
}) => {
  const dispatch = useDispatch();
  const HobbyData = useSelector((item) => item.resume.hobby);

  const [HobbyObj, setHobbyObj] = useState({
    id: HobbyData?.length,
    name: "",
  });

  const HandleChange = (e) => {
    const { name, value } = e.target;
    setHobbyObj({ ...HobbyObj, [name]: value });
  };

  const handleHobbySubmit = (e) => {
    e.preventDefault();
    if (!!UpdateRedux) {
      dispatch(UpdateHobbyFields(HobbyObj, HobbyObj?.index));
      setUpdateRedux(null);
      toast.success("Hobby Updated Successfully");
    } else {
      if (!!HobbyObj?.name) {
        dispatch(setHobbyFields(HobbyObj));
        toast.success("Hobby Saved Successfully");
      } else {
        return;
      }
    }
  };

  useEffect(() => {
    if (!!UpdateRedux) {
      setHobbyObj(UpdateRedux);
    }
  }, [UpdateRedux]);

  const ResumeType = useSelector((item) => item.resume);
  const DocType = useSelector((item) => item.types.doctype);
  //disable next btn
  useEffect(() => {
    if (HobbyData?.length > 0) {
      dispatch(setdisableNext({ type: "hobby", value: false }));
    } else {
      dispatch(setdisableNext({ type: "hobby", value: true }));
    }
  }, [HobbyData]);

  return (
    <div className="create-resume-main">
      <div className="d-flex justify-content-between">
        <div className="d-flex w-100 justify-content-between align-items-center my-1">
          <h3>Hobby </h3>
        </div>
      </div>
      <div className="text-end pe-2 mb-2">
        <small>
          {DocType === "resume"
            ? !!ResumeType?.profession
              ? "Job Specific"
              : "Generic"
            : ""}
        </small>
      </div>
      {/* <p>
        Et pellentesque scelerisque risus blandit quam tempus. Pellentesque
        sodales nec convallis sit ac.
      </p> */}
      <Form className="form-main">
        <FloatingLabel
          controlId="floatingInput"
          label="Add your hobby"
          className="mb-3"
        >
          <Form.Control
            type="text"
            value={HobbyObj?.name}
            name="name"
            onChange={HandleChange}
            placeholder="Number"
          />
        </FloatingLabel>
        <button className="save-experience" onClick={handleHobbySubmit}>
          Save To hobby List
        </button>
      </Form>
    </div>
  );
};
