import React, { useState, useEffect } from "react";
import videoPlayer from "../../assets/image/video player.png";
import cardPreview from "../../assets/image/card preview.png";
import { useNavigate, useParams } from "react-router";
import { AiOutlineHeart } from "react-icons/ai";
import { BsArrowLeft } from "react-icons/bs";
import { AiTwotoneLike } from "react-icons/ai";
import { SidePanelData } from "contants/SidepanelData";
import { NavLink } from "react-router-dom";
import { Navbar } from "components/Navbar/Navbar";
import { SidePanel } from "components/Sidepanel/SidePanel";
import { GetPracticeVideosById } from "helper/api";
import LoadingComponent from "common/LoadingComponent";
import Plyr from "plyr-react";
import "plyr/dist/plyr.css";

export function SelectedCourse() {
  const navigate = useNavigate();
  const param = useParams();
  const [SingleVideo, setSingleVideo] = useState({});
  const [Loading, setLoading] = useState(true);

  const gettingSingleVideo = async () => {
    try {
      const data = await GetPracticeVideosById(param?.id);
      setSingleVideo(data?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (!!param?.id) {
      gettingSingleVideo();
    }
  }, [param?.id]);
  const videoSrcPlayer = {
    type: "video",
    sources: [
      {
        source: undefined,
        src: SingleVideo?.video,
        // provider: "youtube",
        size: 720,
      },
    ],
  };

  return (
    <div className="d-flex">
      <SidePanel>
        <div className="px-4 py-4">
          {SidePanelData.map((item) => (
            <NavLink to={item.path} className="sidebar__link" key={item.id}>
              <div className="d-flex align-items-center py-2 wrapper__sidebar">
                {item.icon}
                <h6 className="sidebar__heading px-2 mt-2">{item.name}</h6>
              </div>
            </NavLink>
          ))}
        </div>
      </SidePanel>
      <div className="w-100">
        <Navbar />

        {Loading ? (
          <LoadingComponent />
        ) : (
          <div className="main-content container px-3 px-lg-5">
            <div className="selected-course-recommendations-container">
              <div className="">
                <div className="d-flex align-items-center gap-4 bestpractice-back">
                  <span
                    onClick={() => {
                      navigate("/best-resume-practice");
                    }}
                  >
                    {<BsArrowLeft fontSize={28} />}
                  </span>
                  <h3>{SingleVideo?.title}</h3>
                </div>
                <p>{SingleVideo?.subtitle}</p>

                <Plyr source={videoSrcPlayer} />
                {/* <img alt="video player" src={videoPlayer} /> */}
                <div className="selected-course-video-container"></div>
                <div className="selected-video-discription-container mt-4">
                  <h3 style={{ marginLeft: "0" }}>Description</h3>
                  <p>{SingleVideo?.description}</p>
                </div>
              </div>

              {/* <div className="recommendations-container">
                            <div className="recommendations-container-header">
                                <p >Recommended</p>
                                <span><AiTwotoneLike size="17" /></span>
                            </div>
                            <div className="recommended-videos-container mb-4">
                                <div className="practiceVideos-video-preview-card" >
                                    <img alt="card-preview" src={cardPreview}></img>
                                    <h4>Cover Letter</h4>
                                    <p>Should have eye for clean and artful design and process super UI skills and translate</p>
                                    <span>READ MORE</span>
                                    <div className="practiceVideos-video-preview-card-button-container">
                                        <button onClick={() => { }}>Watch Video</button>
                                        <span><AiOutlineHeart size="25" /></span>
                                    </div>
                                </div>
                                <div className="practiceVideos-video-preview-card" onClick={() => { navigate("/best-resume-practice/course") }}>
                                    <img alt="card-preview" src={cardPreview}></img>
                                    <h4>Cover Letter</h4>
                                    <p>Should have eye for clean and artful design and process super UI skills and translate</p>
                                    <span>READ MORE</span>
                                    <div className="practiceVideos-video-preview-card-button-container">
                                        <button onClick={() => { }}>Watch Video</button>
                                        <span><AiOutlineHeart size="25" /></span>
                                    </div>
                                </div>
                            </div>
                        </div> */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
