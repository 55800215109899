import React, { useEffect, useRef, useState } from "react";
import logo from "../../../assets/image/logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { SetAnalysis, SetAnalysisResume } from "helper/redux/Slice/ResumeSlice";

const RT2editable = ({ data }) => {
  const [fontSize, setfontSize] = useState(0.7);
  const Style = {
    resumeWrapper: {
      width: "100%",
      padding: "5px",
      minHeight: "100%",
      // background: "#f5f5f5",
      borderTop: "5px solid grey",
      overflow: "auto",
      containerType: "inline-size",
      wordSpacing: "-1px", // Adjusted for reduced word spacing
      color: "#000",
    },
    resumeHead: {
      fontSize: fontSize,
    },
    title: {
      fontSize: `${fontSize * 5}cqw`,
      marginBottom: `${fontSize * 1}cqw`,
    },
    common: {
      fontSize: `${fontSize * 3}cqw`,
      marginBottom: `${fontSize * 0.5}cqw`,
      wordBreak: "break-word",
      whiteSpace: "break-spaces",
      textAlign: "justify",
      wordSpacing: "-1px", // Adjusted for reduced word spacing
      color: "#000",
    },
    li: {
      fontSize: `${fontSize * 3}cqw`,
      marginBottom: `${fontSize * 0}cqw`,
    },
    headings: {
      fontSize: `${fontSize * 4}cqw`,
      fontWeight: "700",
      marginBottom: `${fontSize * 1}cqw`,
    },
    subHeadings: {
      fontSize: `${fontSize * 3}cqw`,
      fontWeight: "700",
      marginBottom: `${fontSize * 1}cqw`,
      color: "#000",
    },
    marginHR: {
      margin: `${fontSize * 1}cqw 0`,
    },
    marginUl: {
      margin: `${fontSize * 0}cqw 0`,
    },
  };
  let ResumeData = useSelector((item) => item?.resume);
  if (!!data) {
    ResumeData = data;
  }

  const monthYear = (date) => {
    if (!date) {
      // Handle the case when date is undefined
      return "Unknown Date";
    }
    if (date.length === 8) {
      let month = date.slice(5, 7);
      let year = date.slice(0, 4);
      switch (month) {
        case "1":
          return `Jan-${year}`;
        case "2":
          return `Feb-${year}`;
        case "3":
          return `March-${year}`;
        case "4":
          return `April-${year}`;
        case "5":
          return `May-${year}`;
        case "6":
          return `June-${year}`;
        case "7":
          return `July-${year}`;
        case "8":
          return `Aug-${year}`;
        case "9":
          return `Sep-${year}`;
        case "10":
          return `Oct-${year}`;
        case "11":
          return `Nov-${year}`;
        case "12":
          return `Dec-${year}`;
        // break;
        default:
          return date;
        // break;
      }
    } else {
      let day = date.slice(8, 10);
      let month = date.slice(5, 7);
      let year = date.slice(0, 4);
      switch (month) {
        case "1":
          return `${day}-Jan-${year}`;
        case "2":
          return `${day}-Feb-${year}`;
        case "3":
          return `${day}-March-${year}`;
        case "4":
          return `${day}-April-${year}`;
        case "5":
          return `${day}-May-${year}`;
        case "6":
          return `${day}-June-${year}`;
        case "7":
          return `${day}-July-${year}`;
        case "8":
          return `${day}-Aug-${year}`;
        case "9":
          return `${day}-Sep-${year}`;
        case "10":
          return `${day}-Oct-${year}`;
        case "11":
          return `${day}-Nov-${year}`;
        case "12":
          return `${day}-Dec-${year}`;
        // break;
        default:
          return date;
        // break;
      }
    }
  };

  return (
    <>
      <div style={Style.resumeWrapper}>
        <div id="resume-container" className="resume-container">
          <div className="resume-head d-flex justify-content-between">
            <div className="">
              {/* <img src={logo} width={100} alt="profile" /> */}
              <h1 style={Style.title}>
                {`${ResumeData?.contact?.title} ${ResumeData?.contact?.firstName} ${ResumeData?.contact?.lastName}`}{" "}
              </h1>
              <h2 style={Style.common}>{ResumeData?.profession}</h2>
            </div>
            <div class="yui-u">
              <div class="contact-info">
                <h4 style={Style.common}>{ResumeData?.contact?.email}</h4>
                <h4 style={Style.common}>{ResumeData?.contact?.number}</h4>
              </div>
            </div>
          </div>
          <hr className="w-100" style={Style?.marginHR} />
          <div>
            <div>
              <div>
                {!!ResumeData?.summary && (
                  <div class="yui-gf">
                    <div class="yui-u first">
                      <h2 style={Style.headings}>Profile</h2>
                    </div>
                    <div class="yui-u">
                      <p style={Style.common}>{ResumeData?.summary}</p>
                    </div>
                  </div>
                )}
                {ResumeData?.skills?.length > 0 && (
                  <>
                    <hr className="w-100" style={Style?.marginHR} />
                    <div class="yui-gf ">
                      <div class="yui-u first ">
                        <h2 style={Style.headings} className="mb-0">
                          Skills
                        </h2>
                      </div>
                      <div class="yui-u d-flex">
                        <div style={Style.marginUl}>
                          {ResumeData?.skills?.map((item, index) => {
                            return (
                              <span key={index} style={Style?.common}>
                                {" "}
                                {item?.name}{" "}
                                {index < ResumeData?.skills?.length - 1 && ","}{" "}
                              </span>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {ResumeData?.experiences?.length > 0 && (
                  <>
                    <hr className="w-100" style={Style?.marginHR} />
                    <div class="yui-gf">
                      <div class="yui-u first">
                        <h2 style={Style.headings}>Experience</h2>
                      </div>
                      <div class="yui-u ">
                        {ResumeData?.experiences?.map((item) => {
                          return (
                            <div class="job">
                              <h2 style={Style?.subHeadings}>
                                {item?.company} ,{" "}
                                <small>{item?.location}</small>
                              </h2>
                              <div className="d-flex gap-4">
                                <p style={Style.subHeadings}>{item?.role}</p>
                                <p style={Style.common}>
                                  {monthYear(item?.start)} -{" "}
                                  {item?.isWorking
                                    ? "Present"
                                    : monthYear(item?.end)}
                                </p>
                              </div>
                              <p style={Style.common}>{item?.description}</p>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </>
                )}

                {ResumeData?.projects?.length > 0 && (
                  <>
                    <hr className="w-100" style={Style?.marginHR} />
                    <div class="yui-gf">
                      <div class="yui-u first">
                        <h2 style={Style.headings}>
                          Projects/ Internships & Research Papers
                        </h2>
                      </div>
                      <div class="yui-u ">
                        {ResumeData?.projects?.map((item) => {
                          return (
                            <div class="job">
                              <h2 style={Style.subHeadings}>{item?.title} </h2>
                              <div className="d-flex gap-4">
                                <p style={Style.common}>
                                  {monthYear(item?.start)}
                                </p>
                              </div>
                              <p style={Style.common}>{item?.description}</p>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </>
                )}

                {ResumeData?.education?.length > 0 && (
                  <>
                    <hr className="w-100" style={Style?.marginHR} />

                    <div class="yui-gf last">
                      <div class="yui-u first">
                        <h2 style={Style.headings}>Education</h2>
                      </div>
                      {ResumeData?.education?.map((item) => {
                        return (
                          <div className="d-flex">
                            <div class="yui-u">
                              <h2 style={Style.subHeadings}>
                                {item?.institute} - {item?.location}
                              </h2>
                              <p style={Style.common}>
                                {item?.degree} - {item?.specialization} ,
                                {monthYear(item?.date)}{" "}
                              </p>
                              {/* <p style={Style.common}>SGPA - {item?.gpa}</p> */}
                              {item?.gpa !== undefined &&
                                item?.gpa !== null &&
                                item?.gpa !== "" && (
                                  <p style={Style.common}>SGPA - {item?.gpa}</p>
                                )}
                              {!!item?.percentage && (
                                <p style={Style.common}>
                                  Percentage - {item?.percentage}
                                </p>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}

                {ResumeData?.certification?.length > 0 && (
                  <>
                    <hr className="w-100" style={Style?.marginHR} />
                    <div class="yui-gf last">
                      <div class="yui-u first">
                        <h2 style={Style.headings}>Certificates/Workshops</h2>
                      </div>
                      <div>
                        {ResumeData?.certification?.map((item) => {
                          return (
                            <div class="yui-u">
                              <h2 style={Style.subHeadings}>{item?.name}</h2>
                              <p style={Style.common}>
                                {item?.org} , {monthYear(item?.date)}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </>
                )}
                {ResumeData?.achievements?.length > 0 && (
                  <>
                    <hr className="w-100" style={Style?.marginHR} />
                    <div class="yui-gf last">
                      <div class="yui-u first">
                        <h2 style={Style.headings}>Achievements</h2>
                      </div>
                      <div>
                        {ResumeData?.achievements?.map((item) => {
                          return (
                            <div class="yui-u">
                              <h2 style={Style.subHeadings}>{item?.role} </h2>
                              <p style={Style.common}>
                                {monthYear(item?.date)}
                              </p>
                              <p style={Style.common}>{item?.description}</p>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </>
                )}
                {ResumeData?.involvement?.length > 0 && (
                  <>
                    <hr className="w-100" style={Style?.marginHR} />
                    <div class="yui-gf last">
                      <div class="yui-u first">
                        <h2 style={Style.headings}>Voluntary</h2>
                      </div>
                      <div>
                        {ResumeData?.involvement?.map((item) => {
                          return (
                            <div class="yui-u">
                              <h2 style={Style.subHeadings}>{item?.role} </h2>
                              <h2 style={Style.common}>
                                {item?.company} - {item?.location}
                              </h2>
                              <p style={Style.common}>
                                {monthYear(item?.start)} -{" "}
                                {item?.isWorking
                                  ? "Present"
                                  : monthYear(item?.end)}
                              </p>
                              <p style={Style.common}>{item?.description}</p>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </>
                )}
                {ResumeData?.hobby?.length > 0 && (
                  <>
                    <hr className="w-100" style={Style?.marginHR} />
                    <div class="yui-gf ">
                      <div class="yui-u first ">
                        <h2 style={Style.headings} className="mb-0">
                          Hobby
                        </h2>
                      </div>
                      <div class="yui-u d-flex">
                        <div style={Style.marginUl}>
                          {ResumeData?.hobby?.map((item, index) => {
                            return (
                              <span key={index} style={Style?.common}>
                                {" "}
                                {item?.name}{" "}
                                {index < ResumeData?.hobby?.length - 1 && ","}{" "}
                              </span>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {ResumeData?.languagesKnown?.length > 0 && (
                  <>
                    <hr className="w-100" style={Style?.marginHR} />
                    <div class="yui-gf ">
                      <div class="yui-u first ">
                        <h2 style={Style.headings} className="mb-0">
                          Languages Known
                        </h2>
                      </div>
                      <div class="yui-u d-flex">
                        <div style={Style.marginUl}>
                          {ResumeData?.languagesKnown?.map((item, index) => {
                            return (
                              <span key={index} style={Style?.common}>
                                {" "}
                                {item?.name}{" "}
                                {index <
                                  ResumeData?.languagesKnown?.length - 1 &&
                                  ","}{" "}
                              </span>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {ResumeData?.links?.length > 0 && (
                  <>
                    <hr className="w-100" style={Style?.marginHR} />
                    <div class="yui-gf ">
                      <div class="yui-u first ">
                        <h2 style={Style.headings} className="mb-0">
                          Links
                        </h2>
                      </div>
                      <div class="yui-u d-flex">
                        <div style={Style.marginUl}>
                          {ResumeData?.links?.map((item, index) => {
                            return (
                              <span key={index} style={Style?.common}>
                                {" "}
                                {item?.name}{" "}
                                {index < ResumeData?.links?.length - 1 && ","}{" "}
                              </span>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RT2editable;
