import React from "react";
import loader from "../assets/image/loading.gif";
import { Spinner } from "react-bootstrap";
const LoadingComponent = ({ color, width }) => {
  const spinnerStyle = color ? { borderColor: color } : {}; // Use style prop for color
  return (
    <div
      className="d-flex justify-content-center w-100 "
      style={{
        touchAction: "none",
        overflow: "hidden",
        WebkitOverflowScrolling: "touch",
        WebkitUserSelect: "none",
        userSelect: "none",
      }}
    >
      {/* <div className={`spinner-border ${!!color ? "text-white" : "text-secondary"}  mt-5`} role="status">
        <span className="sr-only"></span>
      </div> */}
      {color ? (
        <div className="showLoader">
          <Spinner style={spinnerStyle} animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <img
          className="notShowLoader"
          src={loader}
          alt="loader"
          style={{ width: width ?? "15%" }}
        />
      )}
    </div>
  );
};

export default LoadingComponent;
