import React, { useState, useEffect } from "react";
import { Navigate, NavLink, useNavigate, useParams } from "react-router-dom";
import { SidePanel } from "components/Sidepanel/SidePanel";
import { Navbar } from "components/Navbar/Navbar";
import { SidePanelData } from "contants/SidepanelData";
import { StepProgress } from "components/StepProgress/StepProgress";
import { Experiences } from "components/NewResume/Experiences";
import { Finishup } from "components/NewResume/Finishup";
import { Summary } from "components/NewResume/Summary";
import { Skills } from "components/NewResume/Skills";
import { SkillsPre } from "components/NewResume/SkillsPre";
import { EducationPre } from "components/NewResume/EducationPre";
import { ExperiencePre } from "components/NewResume/ExperiencePre";
import { HobbyPre } from "components/NewResume/HobbyPre";
import { ProjectsPre } from "components/NewResume/ProjectsPre";
import { CertificatePre } from "components/NewResume/CertificatePre";
import { SummaryPre } from "components/NewResume/SummaryPre";
import { InvolvementPre } from "components/NewResume/InvolvementPre";
import Contact from "components/NewResume/Contact";
import { Hobby } from "components/NewResume/Hobby";
import { Involvement } from "components/NewResume/Involvement";
import Img from "../../assets/image/resume1.png";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Projects } from "components/NewResume/Projects";
import EditIcon from "../../assets/image/icon/edit.png";
import DeleteIcon from "../../assets/image/icon/delete.png";
import { Education } from "components/NewResume/Education";
import { Certificate } from "components/NewResume/Certificate";
import ModalBox from "components/Modal/ModalBox";
import { Form, FloatingLabel, ToastContainer } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteAchievementsFields,
  DeleteAllLinks,
  DeleteCertificateFields,
  DeleteEducationFields,
  DeleteExperienceField,
  DeleteHobbyFields,
  DeleteLanguageFields,
  DeleteInvolvementFields,
  DeleteProjectFields,
  DeleteSkillFields,
  DeletelanguagesKnown,
  GetAiResumeEditData,
  GetAutomatedResumeEditData,
  GetTrailResumeEditData,
  setContactFields,
  setCreateResume,
  DeleteResearchPapersFields,
} from "helper/redux/Slice/ResumeSlice";
import {
  PostAiResume,
  PostAutomatedResume,
  PostTrailResume,
  UpdateAiResume,
  UpdateAutomatedResume,
  UpdateTrailResume,
} from "helper/api";
import ChangeTemplateModal from "../ChangeTemplateModal";
import CrownImg from "../../assets/image/icon/crown.png";
import firework from "../../assets/image/icon/firework.png";
import { MdStars } from "react-icons/md";
import { FiArrowRight } from "react-icons/fi";
import { Link } from "react-router-dom";
import RT1editable from "components/ResumeTemplate/T1/RT1editable";
import LoadingComponent from "common/LoadingComponent";
import T2non from "components/ResumeTemplate/T2/RT2non";
import RT2editable from "components/ResumeTemplate/T2/RT2editable";
import { Toaster, toast } from "react-hot-toast";
import {
  GettingSkillSetJobSpecific,
  setPremiumFeatureRedux,
} from "helper/redux/Slice/UtilsSlice";
import ResumeTemplateJson from "contants/ResumeTemplateJson";
import RT3editable from "components/ResumeTemplate/T3/RT3editable";
import RT4editable from "components/ResumeTemplate/T4/RT4editable";
import RT5editable from "components/ResumeTemplate/T5/RT5editable";
import RT6editable from "components/ResumeTemplate/T6/RT6editable";
import RT7editable from "components/ResumeTemplate/T7/RT7editable";
import JobSpecificModal from "./JobSpecificModal";
import Cookies from "js-cookie";
import Others from "components/NewResume/Others/Others";

export const CreateResume = () => {
  const types = useSelector((state) => state.types);

  const [step, setStep] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [educationList, setEducationList] = useState([]);
  const [isEntryModal, setIsEntryModal] = useState(true);
  const [stepModal, setStepModal] = useState(1);
  const [resumeType, setResumeType] = useState("");

  const Edit = useSelector((item) => item.resume.EditData);
  const param = useParams();
  const dispatch = useDispatch();
  const data = useSelector((item) => item.user);
  const navigate = useNavigate();
  //resume preview
  const [showPreview, setshowPreview] = useState(false);
  const handleClosePreview = () => setshowPreview(false);
  const handleShowPreview = () => {
    setshowPreview(true);
  };
  const ResumeData = useSelector((item) => item.resume);
  // Responsive preview modal
  const [responsivePreview, setresponsivePreview] = useState(false);

  const handleResponsivePreviewClose = () => setresponsivePreview(false);
  const handleResponsivePreviewShow = () => setresponsivePreview(true);

  // sending prefilled data to contact fields

  function nextStepModal() {
    if ((stepModal === 1 && resumeType === "resume") || stepModal === 2) {
      setIsEntryModal(false);
      return;
    }
    if (stepModal === 2) {
    }
    setStepModal(stepModal + 1);
  }
  function prevStepModal() {
    if (stepModal > 1) {
      setStepModal(stepModal - 1);
    } else {
      navigate("/");
    }
  }
  const user = useSelector((state) => state.user.user);

  const Payload = {
    aiCredit: user?.aicredits,
    profession: ResumeData?.profession,
  };
  const CheckJobSpecific = async () => {
    dispatch(GettingSkillSetJobSpecific(Payload));
  };
  useEffect(() => {
    if (!!ResumeData?.profession) {
      CheckJobSpecific();
    }
  }, [ResumeData?.profession]);

  function StepBackBtn() {
    if (step === 9 || step === 10) setStep(step - 1);
    else setStep(step - 1);
  }
  function StepNextBtn() {
    handleUpdateResume();
    if (step === 8 || step === 9) setStep(step + 1);
    else setStep(step + 1);
  }
  function direcStepChange(step) {
    setStep(step);
  }
  const [otherNavigationState, setotherNavigationState] = useState(0);
  const [ExperienceAndOtherState, setExperienceAndOtherState] = useState(0)
  const [projectAndOtherState, setprojectAndOtherState] = useState(0)

  const Title = [
    "Experience",
    "Project",
    "Education",
    "Certificate",
    "Voluntary",
    "Skills",
    "Hobby",
    "Achievements",
    "Summary",
    "Finishup",
  ];
  const AutomateTitle = [
    "Experience",
    "Education",
    "Skills",
    "Hobby",
    "Certificate",
    "Voluntary",
    // "Others",
    "Summary",
    "Finishup",
  ];

  //step change using navbar
  const reduxResumeStep = useSelector((item) => item.utils.stepResume);

  useEffect(() => {
    setStep(Number(reduxResumeStep));
  }, [reduxResumeStep]);

  const ReviewEdit = useSelector((item) => item.utils.reviewEdit);
  useEffect(() => {
    if (
      ReviewEdit &&
      types?.doctype === "resume" &&
      types?.docsubtype === "trailResume"
    ) {
      setStep(9);
    } else if (
      ReviewEdit &&
      types?.doctype === "resume" &&
      types?.docsubtype === "automatedResume"
    ) {
      setStep(5);
    }
  }, []);
  //posting Resume
  const [spinn, setspinn] = useState(false);

  //posting

  const PostData = async () => {
    setspinn(true);
    if (step === 9) {
      handleFinalResumeShow();
    }
    const payload = {
      name: ResumeData.contact.firstName,
      template: ResumeData?.template,
      color: ResumeData.color,
      type: ResumeData.firstName,
      profession: ResumeData?.profession,
      jobProfile: ResumeData?.jobProfile,
      desiredCompany: ResumeData?.desiredCompany,
      contact: {
        picture: ResumeData?.contact?.picture,
        firstName: ResumeData?.contact?.firstName,
        lastName: ResumeData?.contact?.lastName,
        number: ResumeData?.contact?.number,
        email: ResumeData?.contact?.email,
        title: ResumeData?.contact?.title,
        city: ResumeData?.contact?.city,
        address: ResumeData?.contact?.address,
        pincode: ResumeData?.contact?.pincode,
        country: ResumeData?.contact?.country,
      },
      experiences: ResumeData?.experiences,
      achievements: ResumeData?.achievements,
      links: ResumeData?.links,
      languagesKnown: ResumeData?.languagesKnown,
      projects: ResumeData?.projects,
      researchPapers: ResumeData?.researchPapers,
      education: ResumeData?.education,
      certification: ResumeData?.certification,
      involvement: ResumeData?.involvement,
      hobby: ResumeData?.hobby,
      languagesKnown: ResumeData?.languagesKnown,
      skills: ResumeData?.skills,
      summary: ResumeData?.summary,
      formatting: 80,
      wordChoice: ResumeData?.wordChoice,
      noTypos: 80,
      completeness: ResumeData?.completeness,
      optimization: ResumeData?.optimization,
      atsCompliance: ResumeData?.atsCompliance,
      clearContactInfo: ResumeData?.clearContactInfo,
      strongSummary: ResumeData?.strongSummary,
      length: ResumeData?.length,
    };
    try {
      if (types?.docsubtype === "trailResume") {
        const res = await PostTrailResume(payload);
        console.log(res);
        localStorage.setItem("resumeId", res?.data?.data?._id);
      } else if (types?.docsubtype === "automatedResume") {
        await PostAutomatedResume(payload);
      } else {
        await PostAiResume(payload);
      }

      setspinn(false);
    } catch (error) {
      console.log(error);
      setspinn(false);
    }
  };

  //editing
  const handleUpdateResume = async () => {
    setspinn(true);
    if (step === 10) {
      handleFinalResumeShow();
    }
    const payload = {
      resumeId: !!param?.id ? param?.id : localStorage.getItem("resumeId"),
      name: ResumeData.firstName + ResumeData?.lastName || "",
      template: ResumeData?.template,
      color: ResumeData.color,
      type: ResumeData.firstName,
      profession: ResumeData?.profession,
      jobProfile: ResumeData?.jobProfile,
      desiredCompany: ResumeData?.desiredCompany,
      contact: ResumeData?.contact,
      experiences: ResumeData?.experiences,
      projects: ResumeData?.projects,
      researchPapers: ResumeData?.researchPapers,
      education: ResumeData?.education,
      certification: ResumeData?.certification,
      involvement: ResumeData?.involvement,
      hobby: ResumeData?.hobby,
      languagesKnown: ResumeData?.languagesKnown,
      skills: ResumeData?.skills,
      summary: ResumeData?.summary,
      achievements: ResumeData?.achievements,
      links: ResumeData?.links,
      languagesKnown: ResumeData?.languagesKnown,
      noTypos: 80,
      completeness: ResumeData?.completeness,
      optimization: ResumeData?.optimization,
      atsCompliance: ResumeData?.atsCompliance,
      clearContactInfo: ResumeData?.clearContactInfo,
      strongSummary: ResumeData?.strongSummary,
      length: ResumeData?.length,
    };
    console.log("Update Payload:", payload); // Log the payload
    try {
      if (types?.docsubtype === "trailResume") {
        await UpdateTrailResume(payload);
      } else if (types?.docsubtype === "automatedResume") {
        await UpdateAutomatedResume(payload);
      } else {
        await UpdateAiResume(payload);
      }

      setspinn(false);
    } catch (error) {
      console.log(error);
      setspinn(false);
    }
  };

  useEffect(() => {
    if (!!param?.id && types?.docsubtype === "trailResume") {
      dispatch(GetTrailResumeEditData(param?.id));
      setIsEntryModal(false);
    } else if (!!param?.id && types?.docsubtype === "automatedResume") {
      dispatch(GetAutomatedResumeEditData(param?.id));
      setIsEntryModal(false);
    } else if (!!param?.id && types?.docsubtype === "aiResume") {
      dispatch(GetAiResumeEditData(param?.id));
      setIsEntryModal(false);
    }
  }, [param?.id, dispatch]);

  const EditResume = () => {
    console.log(param);
  };

  //calling redux for preview
  const [UpdateRedux, setUpdateRedux] = useState(null); ///used for updation of data uysgc

  // getting data

  const ExperinceRedux = useSelector((item) => item.resume.experiences);
  const ResearchPaperRedux = useSelector((item) => item.resume.researchPapers);
  const ProjectRedux = useSelector((item) => item.resume.projects);
  const EducationRedux = useSelector((item) => item.resume.education);
  const CertificateRedux = useSelector((item) => item.resume.certification);
  const InvolvementRedux = useSelector((item) => item.resume.involvement);
  const HobbyRedux = useSelector((item) => item.resume.hobby);
  const SkillRedux = useSelector((item) => item?.resume?.skills);
  const AchievementsRedux = useSelector((item) => item?.resume?.achievements);
  const LanguageRedux = useSelector((item) => item?.resume?.languagesKnown);
  const LinksRedux = useSelector((item) => item?.resume?.links);
  console.log(LanguageRedux);
  // handling deletion
  const handleDeleteRedux = (data) => {
    if (step === 1) {
      dispatch(DeleteExperienceField(data));
      setUpdateRedux(null);
    } else if (step === 2) {
      if (projectAndOtherState === 0) {
        dispatch(DeleteProjectFields(data));
      } else if (projectAndOtherState === 1) {
        dispatch(DeleteResearchPapersFields(data));
      }
      setUpdateRedux(null);
    } else if (step === 3) {
      dispatch(DeleteEducationFields(data));
      setUpdateRedux(null);
    } else if (step === 4) {
      dispatch(DeleteCertificateFields(data));
      setUpdateRedux(null);
    } else if (step === 5) {
      dispatch(DeleteInvolvementFields(data));
      setUpdateRedux(null);
    } else if (step === 6) {
      dispatch(DeleteSkillFields(data));
      setUpdateRedux(null);
    } else if (step === 7) {
      dispatch(DeleteHobbyFields(data));
      setUpdateRedux(null);
    } else if (step === 8 && otherNavigationState === 0) {
      dispatch(DeleteAchievementsFields(data));
      setUpdateRedux(null);
    } else if (step === 8 && otherNavigationState === 1) {
      console.log(data);
      dispatch(DeletelanguagesKnown(data));
      setUpdateRedux(null);
    } else if (step === 8 && otherNavigationState === 2) {
      dispatch(DeleteAllLinks(data));
      setUpdateRedux(null);
    }
  };

  useEffect(() => {
    setUpdateRedux(null);
  }, [projectAndOtherState])

  const handleUpdateRedux = (id) => {
    if (step === 1) {
      const newdataExperience = ExperinceRedux[id];
      setUpdateRedux({ ...newdataExperience, index: id });
    } else if (step === 2) {
      if (projectAndOtherState === 0) {
        const newdataProject = ProjectRedux[id];
        setUpdateRedux({ ...newdataProject, index: id });
      } else if (projectAndOtherState === 1) {
        const newdataResearchPaper = ResearchPaperRedux[id];
        setUpdateRedux({ ...newdataResearchPaper, index: id });
      }
    } else if (step === 3) {
      const newdataEducation = EducationRedux[id];
      setUpdateRedux({ ...newdataEducation, index: id });
    } else if (step === 4) {
      const newdataCertificate = CertificateRedux[id];
      setUpdateRedux({ ...newdataCertificate, index: id });
    } else if (step === 5) {
      const newdataInvolvement = InvolvementRedux[id];
      setUpdateRedux({ ...newdataInvolvement, index: id });
    } else if (step === 7) {
      const newdataHobby = HobbyRedux[id];
      setUpdateRedux({ ...newdataHobby, index: id });
    } else if (step === 6) {
      const newdataSkill = SkillRedux[id];
      setUpdateRedux({ ...newdataSkill, index: id });
    } else if (step == 8 && otherNavigationState === 0) {
      const newdataAchievement = AchievementsRedux[id];
      setUpdateRedux({ ...newdataAchievement, index: id });
    } else if (step == 8 && otherNavigationState === 1) {
      const newdataLanguageRedux = LanguageRedux[id];
      setUpdateRedux({ ...newdataLanguageRedux, index: id });
    } else if (step == 8 && otherNavigationState === 2) {
      const newdataLinksRedux = LinksRedux[id];
      setUpdateRedux({ ...newdataLinksRedux, index: id });
    }
  };

  //template modal
  const [show2, setshow2] = useState(false);
  const HandleShowTemplateModal = () => {
    setshow2(true);
  };
  const HandleHideTemplateModal = () => {
    setshow2(false);
  };
  const DisableNext = useSelector((item) => item.utils.disableNext);
  const AIStepList = () => {
    return (
      <>
        {step === 0 && (
          <Contact
            StepNextBtn={StepNextBtn}
            handleResponsivePreviewShow={handleResponsivePreviewShow}
          />
        )}
        {/* {step === 0.5 && <ExperiencePre />} */}
        {step === 1 && (
          <Experiences
            handleResponsivePreviewShow={handleResponsivePreviewShow}
            UpdateRedux={UpdateRedux}
            setUpdateRedux={setUpdateRedux}
            setExperienceAndOtherState={setExperienceAndOtherState}
            ExperienceAndOtherState={ExperienceAndOtherState}
          />
        )}
        {/* {step === 1.5 && <ProjectsPre />} */}
        {step === 2 && (
          <Projects
            handleResponsivePreviewShow={handleResponsivePreviewShow}
            UpdateRedux={UpdateRedux}
            setUpdateRedux={setUpdateRedux}
            setprojectAndOtherState={setprojectAndOtherState}
            projectAndOtherState={projectAndOtherState}
          />
        )}
        {/* {step === 2.5 && <EducationPre />} */}
        {step === 3 && (
          <Education
            handleResponsivePreviewShow={handleResponsivePreviewShow}
            UpdateRedux={UpdateRedux}
            setUpdateRedux={setUpdateRedux}
          />
        )}
        {/* {step === 3.5 && <CertificatePre />} */}
        {step === 4 && (
          <Certificate
            handleResponsivePreviewShow={handleResponsivePreviewShow}
            UpdateRedux={UpdateRedux}
            setUpdateRedux={setUpdateRedux}
          />
        )}
        {/* {step === 4.5 && <InvolvementPre />} */}
        {step === 5 && (
          <Involvement
            handleResponsivePreviewShow={handleResponsivePreviewShow}
            UpdateRedux={UpdateRedux}
            setUpdateRedux={setUpdateRedux}
          />
        )}
        {/* {step === 5.5 && <SkillsPre />} */}
        {step === 6 && (
          <Skills
            handleResponsivePreviewShow={handleResponsivePreviewShow}
            UpdateRedux={UpdateRedux}
            setUpdateRedux={setUpdateRedux}
          />
        )}
        {/* {step === 6.5 && <HobbyPre />} */}
        {step === 7 && (
          <Hobby
            handleResponsivePreviewShow={handleResponsivePreviewShow}
            UpdateRedux={UpdateRedux}
            setUpdateRedux={setUpdateRedux}
          />
        )}
        {/* {step === 8 && (
          <Others
            handleResponsivePreviewShow={handleResponsivePreviewShow}
            UpdateRedux={UpdateRedux}
            setUpdateRedux={setUpdateRedux}
          />
        )} */}
        {/* {step === 7.5 && <SummaryPre />} */}
        {step === 8 && (
          <Others
            StepNextBtn={StepNextBtn}
            UpdateRedux={UpdateRedux}
            setUpdateRedux={setUpdateRedux}
            setotherNavigationState={setotherNavigationState}
            otherNavigationState={otherNavigationState}
          />
        )}
        {step === 9 && <Summary StepNextBtn={StepNextBtn} />}
        {/* {step === 10 && (
          <Finishup
            handleResponsivePreviewShow={handleResponsivePreviewShow}
            TemplateNo={TemplateNo}
          />
        )} */}
        {step === 10 && (
          <Finishup
            handleResponsivePreviewShow={handleResponsivePreviewShow}
            TemplateNo={TemplateNo}
          />
        )}
      </>
    );
  };
  const StepList = () => {
    return (
      <>
        {step === 0 && (
          <Contact handleResponsivePreviewShow={handleResponsivePreviewShow} />
        )}
        {step === 0.5 && <ExperiencePre />}
        {step === 1 && (
          <Experiences
            handleResponsivePreviewShow={handleResponsivePreviewShow}
            UpdateRedux={UpdateRedux}
            setUpdateRedux={setUpdateRedux}
          />
        )}

        {step === 1.5 && <EducationPre />}
        {step === 2 && (
          <Education
            handleResponsivePreviewShow={handleResponsivePreviewShow}
            UpdateRedux={UpdateRedux}
            setUpdateRedux={setUpdateRedux}
          />
        )}

        {step === 2.5 && <SkillsPre />}
        {step === 3 && (
          <Skills
            handleResponsivePreviewShow={handleResponsivePreviewShow}
            UpdateRedux={UpdateRedux}
            setUpdateRedux={setUpdateRedux}
          />
        )}
        {step === 3.5 && <HobbyPre />}
        {step === 4 && (
          <Hobby
            handleResponsivePreviewShow={handleResponsivePreviewShow}
            UpdateRedux={UpdateRedux}
            setUpdateRedux={setUpdateRedux}
          />
        )}

        {step === 4.5 && <Finishup TemplateNo={TemplateNo} />}
        {step === 5 && (
          <Finishup
            handleResponsivePreviewShow={handleResponsivePreviewShow}
            TemplateNo={TemplateNo}
          />
        )}
      </>
    );
  };
  const isAI = types.docsubtype;
  const TemplateNo = useSelector((item) => item.utils.templateNo);
  const ResumeEditTemplateNo = useSelector((item) => item.resume.template);

  const EducationRegex = /\((.*?)\)/;

  const ResumeRedux = useSelector((item) => item.resume);
  const [ResumeTemplatePreview, setResumeTemplatePreview] = useState();

  useEffect(() => {
    setResumeTemplatePreview(
      (ResumeTemplatePreview) =>
      (ResumeTemplatePreview = ResumeTemplateJson?.find(
        (item) => item.templateNo === ResumeRedux?.template
      ))
    );
  }, [ResumeRedux?.template]);
  console.log(ExperienceAndOtherState);

  const ChangeTemplate = () => {
    if (isAI === "trailResume" || isAI === "aiResume") {
      return (
        <>
          {step === 10 || (isAI === "automatedResume" && step === 5) ? null : (
            <div className="previw-card-wrapper">
              <div className="preview-main h-100 position-relative">
                <div className="position-relative" style={{ height: "570px" }}>
                  <div className="preview-card">
                    {/* {
                      TemplateNo === 0 ? <RT1editable /> : TemplateNo === 1 ? <RT2editable /> : <></>
                    } */}
                    {!!ResumeTemplatePreview ? (
                      <ResumeTemplatePreview.templateEdit />
                    ) : (
                      <RT2editable />
                    )}
                    {/* <RT2editable /> */}
                  </div>

                  <div className="d-flex justify-content-between change-template ">
                    <h4 onClick={HandleShowTemplateModal}>Change Templates </h4>
                    <button onClick={handleShowPreview}>Preview</button>
                  </div>
                </div>
                {(
                  step === 1 ||
                  step === 2 ||
                  step === 3 ||
                  step === 4 ||
                  step === 5 ||
                  step === 6 ||
                  step === 7 ||
                  step === 8 ||
                  step === 9) && (
                    <div className="listPreview-container">
                      <p>
                        Your{" "}
                        {step === 8
                          ? otherNavigationState === 0
                            ? "Achievements"
                            : otherNavigationState === 1
                              ? "Languages"
                              : "Links"
                          : step === 2 && projectAndOtherState === 0 ? "Projects" : step === 2 && projectAndOtherState === 1 ? "Research Paper" :
                            Title[step - 1]}
                      </p>
                      <ul className="second-item">
                        {(step == 1
                          ? ExperinceRedux
                          : step === 2
                            ? projectAndOtherState === 0 ? ProjectRedux : ResearchPaperRedux
                            : step === 3
                              ? EducationRedux
                              : step === 4
                                ? CertificateRedux
                                : step === 5
                                  ? InvolvementRedux
                                  : step === 6
                                    ? SkillRedux
                                    : step === 7
                                      ? HobbyRedux
                                      : step === 8
                                        ? otherNavigationState === 0
                                          ? AchievementsRedux
                                          : otherNavigationState === 1
                                            ? LanguageRedux
                                            : otherNavigationState === 2 && LinksRedux
                                        : []
                        )?.map((data, id) => {
                          return (
                            <li key={id}>
                              {step === 1 || step === 5
                                ? data?.role
                                : (step === 2) | (step === 8) &&
                                  otherNavigationState === 0
                                  ? data?.title
                                  : step === 4 ||
                                    step === 7 ||
                                    step === 6 ||
                                    (step === 8 && otherNavigationState === 1) ||
                                    (step === 8 && otherNavigationState === 2)
                                    ? data?.name
                                    : step === 3
                                      ? !!data?.degree?.match(EducationRegex)
                                        ? data?.degree?.match(EducationRegex)[1]
                                        : data?.degree
                                      : null}

                              <span>
                                <img
                                  src={EditIcon}
                                  className="cursor-pointer"
                                  onClick={() => {
                                    handleResponsivePreviewClose();
                                    handleUpdateRedux(id);
                                  }}
                                  alt="edit"
                                />
                                <img
                                  src={DeleteIcon}
                                  className="cursor-pointer"
                                  onClick={() => handleDeleteRedux(id)}
                                  alt="delete"
                                />
                              </span>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}
              </div>
            </div>
          )}
        </>
      );
    } else {
      return (
        <>
          {step === 4.5 ||
            step === 9 ||
            step === 10 ||
            (isAI === "automatedResume" && step === 5) ? null : (
            <div className="previw-card-wrapper">
              <div className="preview-main h-100">
                <div className="position-relative" style={{ height: "570px" }}>
                  <div className="preview-card ">
                    {/* TemplateNo === 0 ? <RT1editable /> : TemplateNo === 1 ? <RT2editable /> : <></> */}
                    {!!ResumeTemplatePreview ? (
                      <ResumeTemplatePreview.templateEdit />
                    ) : (
                      <RT2editable />
                    )}
                    <div className="px-2 d-flex justify-content-between change-template">
                      <h4
                        className="cursor-pointer"
                        onClick={HandleShowTemplateModal}
                      >
                        Change Templates
                      </h4>
                      <button onClick={handleShowPreview}>Preview </button>
                    </div>
                  </div>
                </div>
                {(step === 1 ||
                  step === 2 ||
                  step === 3 ||
                  step === 4 ||
                  step === 5 ||
                  step === 6 ||
                  step === 7) && (
                    <div className="listPreview-container">
                      <p>Your {AutomateTitle[step - 1]}</p>
                      <ul className="second-item">
                        {(step == 1
                          ? ExperinceRedux
                          : step === 2
                            ? EducationRedux
                            : step === 3
                              ? SkillRedux
                              : step === 4
                                ? HobbyRedux
                                : []
                        )?.map((data, id) => {
                          return (
                            <li key={id}>
                              {step === 1 || step === 5
                                ? data?.role
                                : step === 3 ||
                                  step === 4 ||
                                  step === 7 ||
                                  step === 6
                                  ? data?.name
                                  : step === 2
                                    ? !!data?.degree?.match(EducationRegex)
                                      ? data?.degree?.match(EducationRegex)[1]
                                      : data?.degree
                                    : null}

                              <span>
                                <img
                                  src={EditIcon}
                                  className="cursor-pointer"
                                  onClick={() => {
                                    handleResponsivePreviewClose();
                                    handleUpdateRedux(id);
                                  }}
                                  alt="edit"
                                />
                                <img
                                  src={DeleteIcon}
                                  className="cursor-pointer"
                                  onClick={() => handleDeleteRedux(id)}
                                  alt="delete"
                                />
                              </span>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}
              </div>
            </div>
          )}
        </>
      );
    }
  };

  const selectedButtonStyle = {
    backgroundColor: "#521986",
    color: "white",
  };

  const [PremiumFeature, setPremiumFeature] = useState(false);

  const handlePremiumFeatureClose = () => setPremiumFeature(false);
  const handlePremiumFeatureShow = () => setPremiumFeature(true);

  // useEffect(() => {
  //   if (step == 2 && types.docsubtype == "trailResume") {
  //     handlePremiumFeatureShow()
  //   }
  // }, [step])

  const [FinalResume, setFinalResume] = useState(false);

  const handleFinalResumeClose = () => setFinalResume(false);
  const handleFinalResumeShow = () => setFinalResume(true);

  const handleSkipResumeSection = () => {
    setStep(step + 1);
  };

  return (
    <>
      {!param.id && <JobSpecificModal PostData={PostData} />}
      <Toaster />
      <div className="d-flex">
        <SidePanel />
        <div className="w-100 main-container-wrapper">
          <Navbar />
          {Edit ? (
            <>
              <LoadingComponent />
            </>
          ) : (
            <>
              <div className="main-content  px-0 px-lg-2">
                <div className="step-progress-main">
                  <StepProgress
                    step={step}
                    changeStepDirect={direcStepChange}
                    type={"resume"}
                  />
                </div>

                <div className="d-flex my-3  gap-3">
                  <div className=" w-100">
                    {isAI === "automatedResume" ? <StepList /> : <AIStepList />}

                    <div className="step-progres-button my-3">
                      <button
                        onClick={StepBackBtn}
                        disabled={step === 0 ? true : false}
                      >
                        Back
                      </button>
                      <div className="d-flex flex-column align-items-center ">
                        {types?.docsubtype === "automatedResume" ? (
                          step < 5 ? (
                            <button onClick={StepNextBtn}>Next</button>
                          ) : !!param?.id ? (
                            <button onClick={handleUpdateResume}>Update</button>
                          ) : (
                            <button onClick={handleUpdateResume}>
                              Submit
                              {spinn && (
                                <div
                                  class="spinner-border spinner-border-sm text-light "
                                  role="status"
                                >
                                  <span class="sr-only"></span>
                                </div>
                              )}
                            </button>
                          )
                        ) : step < 10 ? (
                          <button onClick={StepNextBtn}>Next</button>
                        ) : !!param?.id ? (
                          <button onClick={handleUpdateResume}>Update</button>
                        ) : (
                          <button onClick={handleUpdateResume}>
                            Submit
                            {spinn && (
                              <div
                                class="spinner-border spinner-border-sm text-light "
                                role="status"
                              >
                                <span class="sr-only"></span>
                              </div>
                            )}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="preview-wrapper">
                    <ChangeTemplate />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <ModalBox show={showModal} setShow={setShowModal} />

      {/* resume preview         */}

      <Modal
        size="lg"
        centered
        show={showPreview}
        scrollable={true}
      // backdrop="static"
      >
        <Modal.Header closeButton onClick={handleClosePreview}>
          {/* <Modal.Title id="contained-modal-title-vcenter">
            Modal heading
          </Modal.Title> */}
        </Modal.Header>
        <div className="overflow-auto px-3">
          {
            /* TemplateNo === 0 ? <RT2editable /> : <RT3editable /> */
            ResumeEditTemplateNo === 0 ? (
              <RT2editable />
            ) : ResumeEditTemplateNo === 1 ? (
              <RT4editable />
            ) : ResumeEditTemplateNo === 2 ? (
              <RT5editable />
            ) : ResumeEditTemplateNo === 3 ? (
              <RT6editable />
            ) : ResumeEditTemplateNo === 4 ? (
              <RT7editable />
            ) : null
          }
        </div>

        <Modal.Footer>
          <Button onClick={handleClosePreview}>Close</Button>
        </Modal.Footer>
      </Modal>

      <ChangeTemplateModal
        show={show2}
        TemplateNo={TemplateNo}
        HandleShowTemplateModal={HandleShowTemplateModal}
        HandleHideTemplateModal={HandleHideTemplateModal}
      />

      {/* experience modal */}

      <Modal
        show={PremiumFeature}
        onHide={handlePremiumFeatureClose}
        centered
        keyboard={false}
        backdrop="static"
      >
        <Modal.Body className="experience_modal">
          <div className="experience_modal_container">
            <img src={CrownImg} alt="crown" />
            <h6>Sorry! You dont have any credits left</h6>
            <div
              className="experience_modal_feature_contatiner d-flex justify-content-between "
              style={{ flexWrap: "wrap" }}
            >
              {/* <div className="">
                <span>
                  <MdStars />
                </span>
                <p>Feature 1</p>
              </div>
              <div>
                <span>
                  <MdStars />
                </span>
                <p>Feature 2</p>
              </div>
              <div>
                <span>
                  <MdStars />
                </span>
                <p>Feature 3</p>
              </div>
              <div>
                <span>
                  <MdStars />
                </span>
                <p>Feature 4</p>
              </div> */}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center ">
          {/* <Button
            variant="secondary"
            onClick={() => navigate("/pricing")}
            className="btn_color"
          >
            Premium Features <MdStars />
          </Button> */}
          <Button
            className="btn_continue"
            onClick={() => {
              dispatch(setPremiumFeatureRedux(false));
              handlePremiumFeatureClose();
            }}
            variant="light"
          >
            Continue Manually <FiArrowRight />
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Submission  modal */}

      <Modal
        show={FinalResume}
        onHide={handleFinalResumeClose}
        centered
        keyboard={false}
        backdrop="static"
      >
        <Modal.Body className="experience_modal">
          <div className="experience_modal_container">
            <img src={firework} alt="crown" />
            <h6 className="my-4">
              Congratulations , you have successfully{" "}
              {!!param?.id ? "Updated" : "Created"} a resume
            </h6>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center ">
          <Button
            variant="secondary"
            className="btn_color"
            onClick={handleShowPreview}
          >
            View resume
          </Button>
          <Button
            className="btn_continue"
            onClick={() => {
              navigate("/");
              handleFinalResumeClose();
            }}
            variant="light"
          >
            Go to dashboard{" "}
          </Button>
        </Modal.Footer>
      </Modal>
      <Toaster />

      {/* responsive preview modal */}

      <Modal
        show={responsivePreview}
        onHide={handleResponsivePreviewClose}
        centered
        className="rounded"
      >
        <Modal.Body className="d-flex justify-content-center rounded p-2">
          {ChangeTemplate()}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={handleResponsivePreviewClose}
          >
            {" "}
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
