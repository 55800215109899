import { SidePanel } from "components/Sidepanel/SidePanel";
import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Navbar } from "components/Navbar/Navbar";
import { AiOutlineDown } from "react-icons/ai";
import Card from "components/Card/Card";
import { create_UUID } from "helper/uuid";
import { SidePanelData } from "contants/SidepanelData";
import Temp1 from "../assets/image/template/template(1).png";
import Temp2 from "../assets/image/template/template(2).png";
import Temp3 from "../assets/image/template/template(3).png";
import Temp4 from "../assets/image/template/template(4).png";
import Temp5 from "../assets/image/template/template(5).png";
import Temp6 from "../assets/image/template/template(6).png";
import Temp7 from "../assets/image/template/template(7).png";
import Temp8 from "../assets/image/template/template(8).png";
import cardPreview from "../assets/image/card preview.png";
import { AiOutlineHeart } from "react-icons/ai";
import {
  AiOutlineFileAdd,
  AiOutlinePlusCircle,
  AiOutlineFilePdf,
} from "react-icons/ai";
import { useNavigate } from "react-router";
import ResumeTemplate1 from "components/ResumeTemplate/ResumeTemplate1";
import ResumeTemplate2 from "components/ResumeTemplate/ResumeTemplate2";
import CoverletterTemplate from "components/CoverLetterTemplate/CoverTemplate1/CoverletterTemplate";
import RT1non from "components/ResumeTemplate/T1/RT1non";
import TemplateJson from "../contants/ResumeTemplateJson";
import CT1non from "components/CoverLetterTemplate/CT1/CT1non";
import CoverLetterTemplateJson from "contants/CoverLettertemplate";
import ResumeTemplateJson from "../contants/ResumeTemplateJson";
import { useDispatch } from "react-redux";
import { setTemplateNo } from "helper/redux/Slice/UtilsSlice";
import {
  UpdateContactRedux,
  setCreateResume,
} from "helper/redux/Slice/ResumeSlice";
import Cookies from "js-cookie";
import { setCoverFields, setFrom } from "helper/redux/Slice/CoverSlice";
import { GetTemplateNoByCollegeId } from "helper/api";

export const Templates = () => {
  const navigate = useNavigate();
  const [type, setType] = useState("resume");
  const [templates, setTemplates] = useState([]);
  const [filteredTemplates, setFilteredTemplates] = useState([]);

  function Type(e) {
    setType(e);
  }

  const selectedStyle = {
    backgroundColor: "#521986",
    color: "rgba(255, 255, 255, 0.864)",
  };

  const CardData = [
    { type: "select", image: Temp1 },
    { type: "select", image: Temp2 },
    { type: "select", image: Temp3 },
    { type: "select", image: Temp4 },
    { type: "select", image: Temp5 },
    { type: "select", image: Temp6 },
    { type: "select", image: Temp7 },
    { type: "select", image: Temp8 },
  ];
  const dispatch = useDispatch();
  let cookiedata;

  if (!!localStorage.getItem("user2")) {
    cookiedata = JSON.parse(localStorage.getItem("user2"));
  } else {
    cookiedata = JSON.parse(Cookies.get("user"));
  }
  console.log(cookiedata);
  // Assuming collegeid is available in cookiedata
  // useEffect(() => {
  //   const collegeid = cookiedata.collegeid;
  //   console.log(collegeid);
  //   // List of college IDs that should only see template number 2
  //   const restrictedCollegeIds = [
  //     "661f7afde9d17f043bb7c569",
  //     "65f7ff121a524e23670482a5",
  //     "65f7fefe1a524e23670482a0",
  //     "65f7fee71a524e2367048298",
  //     "65f7fead1a524e236704828f",
  //   ];
  //   // Filter templates based on college ID
  //   // const collegeId = parseInt(cookiedata.collegeId); // Convert collegeId to number
  //   if (restrictedCollegeIds.includes(collegeid)) {
  //     // If collegeId matches, show only templateNo 2
  //     const matchedTemplates = ResumeTemplateJson.filter(
  //       (template) => template.templateNo === 3
  //     );
  //     console.log("Matched Templates:", matchedTemplates);
  //     setFilteredTemplates(matchedTemplates);
  //   } else {
  //     // Otherwise, show all templates
  //     setFilteredTemplates(ResumeTemplateJson);
  //     console.log("not matched");
  //   }
  // }, [cookiedata.collegeId]);

  const collegeid = cookiedata.collegeid;
  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        // const response = await axios.get(`/api/user-templates/${collegeid}`);
        const response = await GetTemplateNoByCollegeId(collegeid);
        console.log(response);
        const { templateNos } = response.data;
        console.log("Fetched Template Nos:", templateNos);

        const matchedTemplates = ResumeTemplateJson.filter((template) =>
          templateNos.includes(template.templateNo)
        );
        setFilteredTemplates(matchedTemplates);
      } catch (error) {
        console.error("Error fetching college data:", error);
      }
    };

    fetchTemplates();
  }, [cookiedata.collegeid]);

  const handleTemplateSelection = (templateNo) => {
    dispatch(setTemplateNo(templateNo));
    dispatch(setCreateResume({ type: "template", value: templateNo }));
    dispatch(UpdateContactRedux({ type: "firstName", value: cookiedata.name }));
    dispatch(UpdateContactRedux({ type: "email", value: cookiedata.email }));
    dispatch(
      UpdateContactRedux({ type: "number", value: cookiedata.phoneNumber })
    );
    navigate("/create-resume");
  };
  return (
    <>
      <div className="d-flex">
        <SidePanel />
        <div className="w-100 main-container-wrapper ">
          <Navbar />

          <div className="practice-videos-wrapper">
            <h3> Get Started</h3>
            {/* <p >They are good for students with little experience</p> */}

            <div className="practice-videos-buttonTab">
              <button
                onClick={() => Type("resume")}
                style={type === "resume" ? selectedStyle : {}}
              >
                <AiOutlineFileAdd size="20" className="icon " />
                Resume
              </button>
              <button
                onClick={() => Type("cover")}
                style={type === "cover" ? selectedStyle : {}}
              >
                <AiOutlineFilePdf size="20" className="icon " /> Cover Letter
              </button>
            </div>

            <div className="">
              <div className="d-flex flex-lg-row flex-column gap-4 overflow-auto col-12 mb-5 flex-wrap">
                {type === "resume" ? (
                  <>
                    {/* {ResumeTemplateJson.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="resume-card-template border rounded overflow-auto cursor-pointer"
                          onClick={() => {
                            dispatch(setTemplateNo(item.templateNo));
                            dispatch(
                              setCreateResume({
                                type: "template",
                                value: item.templateNo,
                              })
                            );
                            dispatch(
                              UpdateContactRedux({
                                type: "firstName",
                                value: cookiedata.name,
                              })
                            );
                            dispatch(
                              UpdateContactRedux({
                                type: "email",
                                value: cookiedata.email,
                              })
                            );
                            dispatch(
                              UpdateContactRedux({
                                type: "number",
                                value: cookiedata.phoneNumber,
                              })
                            );
                            navigate("/create-resume");
                          }}
                        >
                          <item.template />
                        </div>
                      );
                    })} */}
                    {filteredTemplates.map((item, index) => (
                      <div
                        key={index}
                        className="resume-card-template border rounded overflow-auto cursor-pointer"
                        onClick={() => handleTemplateSelection(item.templateNo)}
                      >
                        <item.template />
                      </div>
                    ))}

                    {/* <div className="col-3 border rounded">
                        <ResumeTemplate2 />
                      </div>
                      <div className="col-3 border rounded">
                        <RT1non />
                      </div> */}
                  </>
                ) : (
                  <>
                    {CoverLetterTemplateJson?.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className=" border resume-card-template rounded cursor-pointer"
                          onClick={() => {
                            dispatch(setTemplateNo(item.templateNo));
                            dispatch(
                              setFrom({ type: "name", value: cookiedata?.name })
                            );
                            dispatch(
                              setFrom({
                                type: "email",
                                value: cookiedata?.email,
                              })
                            );
                            dispatch(
                              setFrom({
                                type: "number",
                                value: cookiedata?.phoneNumber,
                              })
                            );
                            dispatch(
                              setFrom({
                                type: "address",
                                value: cookiedata?.address2,
                              })
                            );
                            dispatch(
                              setCoverFields({
                                type: "template",
                                value: item.templateNo,
                              })
                            );
                            navigate("/create-cover-letter");
                          }}
                        >
                          <item.template />
                        </div>
                      );
                    })}
                  </>
                )}
              </div>

              {/* {CardData.map((data, index) => (
                <div onClick={() => { navigate("/create-resume") }}>
                  <Card type={data.type} image={data.image} key={index} />
                </div>

              ))} */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
