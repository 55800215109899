import { ThemeProvider as TP } from "styled-components";

const theme = {
  white: "#ffff",
  primary: "#521986",
  darkBlack: "rgba(16, 0, 43, 1)",
  lightGrey: "rgba(166, 160, 176, 1)",
  shadow1:
    "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)",
  white100: "rgba(255, 255, 255, 1)",
  blue100: "linear-gradient(90deg, #2E77AC 0%, #42ADC6 100%)",
  lightBlue: "  rgba(200, 231, 240, 1)",
  bluedark: "rgba(0, 56, 116, 1)",
  border20: "20px",
  slate: "#808285",
};

export const ThemeProvider = ({ children }) => (
  <TP theme={theme}>{children}</TP>
);
