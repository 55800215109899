import { setCoverFields } from "helper/redux/Slice/CoverSlice";
import React, { useState } from "react";
import { Form, FloatingLabel } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
export const ExpYears = () => {
  const dispatch = useDispatch()
  const ExpYear = useSelector(item => item.cover.totalExperience)
  return (
    <div className="create-resume-main">

      <h3>How Many years of experience do you have</h3>
      <p>
        {/* Et pellentesque scelerisque risus blandit quam tempus. Pellentesque
        sodales nec convallis sit ac. */}
      </p>
      <Form className="form-main">
        {/* <div className="row">
          <div className="col-lg-6">
            <FloatingLabel
              controlId="floatingInput"
              label="COMPANY NAME"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Google*"
              />
            </FloatingLabel>


          </div>

          <div className="col-lg-6">
          <FloatingLabel
              controlId="floatingPassword"
              label="JOB TITLE"
              className="mb-3"
            >
              <Form.Select aria-label="Default select example" style={{color:"#906cb2" , fontSize:"12px" , fontWeight:"600"}}>
            
                <option value="1" style={{color:"#906cb2"}}>INDIA,IN</option>
                <option value="2" style={{color:"#906cb2"}}>AMERICA,US</option>
              </Form.Select>
            </FloatingLabel>
          </div>
        </div> */}


        <div className=" years-circle overflow-auto gap-4">
          <span onClick={() => dispatch(setCoverFields({ type: "totalExperience", value: "<1" }))} style={{ background: `${ExpYear == "<1" ? "#521986" : ""}` }}>Less Then One</span>
          <span onClick={() => dispatch(setCoverFields({ type: "totalExperience", value: 1 }))} style={{ background: `${ExpYear == 1 ? "#521986" : ""}` }}>1</span>
          <span onClick={() => dispatch(setCoverFields({ type: "totalExperience", value: 2 }))} style={{ background: `${ExpYear == 2 ? "#521986" : ""}` }}>2</span>
          <span onClick={() => dispatch(setCoverFields({ type: "totalExperience", value: 3 }))} style={{ background: `${ExpYear == 3 ? "#521986" : ""}` }}>3</span>
          <span onClick={() => dispatch(setCoverFields({ type: "totalExperience", value: 4 }))} style={{ background: `${ExpYear == 4 ? "#521986" : ""}` }}>4</span>
          <span onClick={() => dispatch(setCoverFields({ type: "totalExperience", value: 5 }))} style={{ background: `${ExpYear == 5 ? "#521986" : ""}` }}>5</span>
          <span onClick={() => dispatch(setCoverFields({ type: "totalExperience", value: 6 }))} style={{ background: `${ExpYear == 6 ? "#521986" : ""}` }}>6</span>
          <span onClick={() => dispatch(setCoverFields({ type: "totalExperience", value: 7 }))} style={{ background: `${ExpYear == 7 ? "#521986" : ""}` }}>7</span>
          <span onClick={() => dispatch(setCoverFields({ type: "totalExperience", value: 8 }))} style={{ background: `${ExpYear == 8 ? "#521986" : ""}` }}>8</span>
          <span onClick={() => dispatch(setCoverFields({ type: "totalExperience", value: 9 }))} style={{ background: `${ExpYear == 9 ? "#521986" : ""}` }}>9</span>
          <span onClick={() => dispatch(setCoverFields({ type: "totalExperience", value: 10 }))} style={{ background: `${ExpYear == 10 ? "#521986" : ""}` }}>10+</span>
        </div>

        {
          ExpYear == 10 &&
          <FloatingLabel
            controlId="floatingInput"
            label="EXACT NUMBER OF YEARS"
            className="mb-3"
          >
            <Form.Control
              type="text"
              onChange={(e) => dispatch(setCoverFields({ type: "totalExperience", value: e.target.value }))}
              placeholder="EXAMPLE : 30"
            />
          </FloatingLabel>
        }


        {/* <button className="save-experience">Save To Experience List</button> */}
      </Form>


    </div>
  );
};
