import { UpdatelanguagesKnown, setlanguagesKnown } from 'helper/redux/Slice/ResumeSlice'
import React, { useEffect, useState } from 'react'
import { FloatingLabel, Form } from 'react-bootstrap'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'

function LanguagesKnown({ UpdateRedux, setUpdateRedux }) {
    const LanguageRedux = useSelector(item => item.resume.languagesKnown)
    const dispatch = useDispatch()
    const [languageKnownObj, setlanguageKnownObj] = useState({
        name: "",
        id: LanguageRedux?.length,
    })
    const handleChange = (e) => {
        const { value } = e.target
        setlanguageKnownObj({ ...languageKnownObj, name: value })
    }
    const handleSubmitToRedux = (e) => {
        e.preventDefault();
        if (!!UpdateRedux) {
            dispatch(UpdatelanguagesKnown(languageKnownObj, languageKnownObj?.index));
            setUpdateRedux(null);
            toast.success("Language Updated Successfully");
        } else {
            if (!!languageKnownObj?.name) {
                dispatch(setlanguagesKnown(languageKnownObj));
                toast.success("Language Saved Successfully");
            } else {
                return;
            }
        }
    };
    useEffect(() => {
        if (!!UpdateRedux?.name) {
            setlanguageKnownObj(UpdateRedux);
        }
    }, [UpdateRedux]);
    return (
        <Form className="form-main">
            <div className='d-flex gap-3 justify-content-between'>
                <FloatingLabel
                    controlId="floatingInput"
                    label="Language Known"
                    className="mb-3 w-100"
                >
                    <Form.Control
                        type="text"
                        value={languageKnownObj?.name}
                        name="name"
                        onChange={handleChange}
                        placeholder="Language Known"

                    />
                </FloatingLabel>
            </div>

            <button className="save-experience" onClick={handleSubmitToRedux}>
                Save to  Language List
            </button>
        </Form>
    )
}

export default LanguagesKnown