import React, { useState } from "react";

export const HobbyPre = () => {



    return (
        <div className="create-resume-main">
            <h3>Hobby</h3>
            <p>
                the section typically referred to as "Hobbies" provides an opportunity for you to showcase your personal interests, activities, or pastimes that are unrelated to your professional experience or qualifications. Including this section can give potential employers a glimpse into your personality, outside interests, and potential areas of shared connection. However, it's important to note that the inclusion of a "Hobbies" section is optional and its relevance may vary depending on the industry or job you're applying for.
            </p>
        </div>
    );
};
