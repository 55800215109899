import React from "react";
import { AiOutlineMail } from "react-icons/ai";
import { BsFillTelephoneFill } from "react-icons/bs";
import "./RT1.css";
import img1 from "../../../assets/image/profile.png";
import { useSelector } from "react-redux";

const RT1editable = ({ data }) => {
  let ResumeData;
  ResumeData = useSelector((item) => item.resume);
  if (!!data) {
    ResumeData = data;
  }

  const monthYear = (date) => {
    if (!date) {
      // Handle the case when date is undefined
      return "Unknown Date";
    }
    if (date.length === 7) {
      let month = date.slice(5, 7);
      let year = date.slice(0, 4);
      switch (month) {
        case "1":
          return `Jan-${year}`;
        case "2":
          return `Feb-${year}`;
        case "3":
          return `March-${year}`;
        case "4":
          return `April-${year}`;
        case "5":
          return `May-${year}`;
        case "6":
          return `June-${year}`;
        case "7":
          return `July-${year}`;
        case "8":
          return `Aug-${year}`;
        case "9":
          return `Sep-${year}`;
        case "10":
          return `Oct-${year}`;
        case "11":
          return `Nov-${year}`;
        case "12":
          return `Dec-${year}`;
        // break;
        default:
          return date;
        // break;
      }
    } else {
      let day = date.slice(8, 10);
      let month = date.slice(5, 7);
      let year = date.slice(0, 4);
      switch (month) {
        case "1":
          return `${day}-Jan-${year}`;
        case "2":
          return `${day}-Feb-${year}`;
        case "3":
          return `${day}-March-${year}`;
        case "4":
          return `${day}-April-${year}`;
        case "5":
          return `${day}-May-${year}`;
        case "6":
          return `${day}-June-${year}`;
        case "7":
          return `${day}-July-${year}`;
        case "8":
          return `${day}-Aug-${year}`;
        case "9":
          return `${day}-Sep-${year}`;
        case "10":
          return `${day}-Oct-${year}`;
        case "11":
          return `${day}-Nov-${year}`;
        case "12":
          return `${day}-Dec-${year}`;
        // break;
        default:
          return date;
        // break;
      }
    }
  };
  return (
    <div className="container-fluid d-flex flex-column resume-temp-1-wrapper align-items-center justify-content-center px-3">
      <section className="resume-temp-1-head w-100">
        <h1>{`${ResumeData?.contact?.title} ${ResumeData?.contact?.firstName}  ${ResumeData?.contact?.lastName}`}</h1>
        {/* <h2>Graphic Designer</h2> */}
        <div className="d-flex  resume-temp-1-email justify-content-between w-100">
          <div className="d-flex gap-2 align-items-center">
            <AiOutlineMail />
            {ResumeData?.contact?.email}
          </div>
          <div className="d-flex gap-2 align-items-center">
            <BsFillTelephoneFill />
            {ResumeData?.contact?.number}
          </div>
        </div>
      </section>

      <hr className="w-100" />

      <section className="d-flex col-12 d-flex resume-temp-1-summary justify-content-between align-items-center ">
        <div className="col-3">
          <img src={ResumeData?.contact?.picture ?? img1} alt="Profile" />
        </div>
        <div className="col-9">
          <p>{ResumeData?.summary} </p>
        </div>
      </section>

      <hr className="w-100" />
      <section className="d-flex col-12 resume-temp-1-content">
        <aside className="col-4">
          <div className="">
            {ResumeData?.education.length > 0 && (
              <>
                <h3>Education</h3>
                {ResumeData?.education?.map((item, id) => {
                  return (
                    <div key={id}>
                      <h6>{item?.name}</h6>
                      <p>{item?.institute}</p>
                      <p>{monthYear(item?.date)}</p>
                      <p>Score - {item?.gpa}</p>
                    </div>
                  );
                })}
              </>
            )}
          </div>

          <div className="">
            {ResumeData?.skills?.length > 0 && (
              <>
                <h3>Skills</h3>
                {ResumeData?.skills?.map((item, id) => {
                  return (
                    <div key={id}>
                      <p>{item?.name}</p>
                    </div>
                  );
                })}
              </>
            )}
          </div>

          <div className="">
            {ResumeData?.hobby?.length > 0 && (
              <>
                <h3>Hobby</h3>
                {ResumeData?.hobby?.map((item, id) => {
                  return (
                    <div key={id}>
                      <p>{item?.name}</p>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </aside>

        <span className=" partition-hr" />
        <aside className="col-7  px-sm-2">
          <div className="">
            {ResumeData?.experiences?.length > 0 && (
              <>
                <h3>Experience</h3>
                {ResumeData?.experiences?.map((item, id) => {
                  return (
                    <div key={id}>
                      <h5>{item?.role}</h5>
                      <strong>
                        {item?.company} | {monthYear(item?.start)} -
                        {monthYear(item?.end)}
                      </strong>
                      <div>{item?.description}</div>
                    </div>
                  );
                })}
              </>
            )}
          </div>

          <div className="">
            {ResumeData?.projects?.length > 0 && (
              <>
                <h3>Projects</h3>
                {ResumeData?.projects?.map((item, id) => {
                  return (
                    <div key={id}>
                      <h5>{item?.role}</h5>
                      <strong>
                        {item?.company} | {monthYear(item?.start)} -
                        {monthYear(item?.end)}
                      </strong>
                      <div>{item?.description}</div>
                    </div>
                  );
                })}
              </>
            )}
          </div>

          <div className="mb-4">
            {ResumeData?.involvement?.length > 0 && (
              <>
                <h3>Voluntary Work</h3>
                {ResumeData?.involvement?.map((item, id) => {
                  return (
                    <div key={id}>
                      <h5>{item?.role}</h5>
                      <strong>
                        {item?.company} | {monthYear(item?.start)} -
                        {monthYear(item?.end)}
                      </strong>
                      <div>{item?.description}</div>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </aside>
      </section>
      <hr className="w-100" />

      <section className="col-12 resume-temp-1-content">
        {ResumeData?.certification?.length > 0 && (
          <>
            <h3>Ceritificates</h3>
            {ResumeData?.certification?.map((item, id) => {
              return (
                <div key={id}>
                  <h5>{item?.name}</h5>
                  <strong>
                    {item?.org} | {monthYear(item?.date)}
                  </strong>
                  <div>{item?.proof}</div>
                </div>
              );
            })}
          </>
        )}
      </section>
    </div>
  );
};

export default RT1editable;
