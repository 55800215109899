import React from 'react';
import '../../styles/Resume Template/resumeT1.css'
import { FaUserAlt } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { setTemplateNo } from 'helper/redux/Slice/UtilsSlice';

const ResumeTemplate1 = ({ img }) => {
  const data = useSelector((item) => item?.resume)
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const monthYear = (date) => {
    if (date.length === 7) {
      let month = date.slice(5, 7);
      let year = date.slice(0, 4)
      switch (month) {
        case '1':
          return `Jan-${year}`;
        case '2':
          return `Feb-${year}`;
        case '3':
          return `March-${year}`;
        case '4':
          return `April-${year}`;
        case '5':
          return `May-${year}`;
        case '6':
          return `June-${year}`;
        case '7':
          return `July-${year}`;
        case '8':
          return `Aug-${year}`;
        case '9':
          return `Sep-${year}`;
        case '10':
          return `Oct-${year}`;
        case '11':
          return `Nov-${year}`;
        case '12':
          return `Dec-${year}`;
        // break;
        default:
          return date
        // break;
      }
    }
    else {
      let day = date.slice(8, 10)
      let month = date.slice(5, 7);
      let year = date.slice(0, 4)
      switch (month) {
        case '1':
          return `${day}-Jan-${year}`;
        case '2':
          return `${day}-Feb-${year}`;
        case '3':
          return `${day}-March-${year}`;
        case '4':
          return `${day}-April-${year}`;
        case '5':
          return `${day}-May-${year}`;
        case '6':
          return `${day}-June-${year}`;
        case '7':
          return `${day}-July-${year}`;
        case '8':
          return `${day}-Aug-${year}`;
        case '9':
          return `${day}-Sep-${year}`;
        case '10':
          return `${day}-Oct-${year}`;
        case '11':
          return `${day}-Nov-${year}`;
        case '12':
          return `${day}-Dec-${year}`;
        // break;
        default:
          return date
        // break;
      }
    }
  }
  return (
    <>
      <div className='container  d-flex flex-column rounded p-2 resume_container' onClick={() => {
        dispatch(setTemplateNo(0));
        navigate('/create-resume')
      }}>
        <div className='resume_head '>
          <div className='d-flex mb-2 align-items-center'>
            <h5>{`${data?.contact?.title ? data?.contact?.title : ""} ${data?.contact?.firstName ? data?.contact?.firstName : ""} ${data?.contact?.lastName ? data?.contact?.lastName : ""} `}
              <p>{`${data?.contact?.city ? data?.contact?.city : ""} `}  {`${data?.contact?.pincode ? data?.contact?.pincode : ""} `}  {`${data?.contact?.country ? data?.contact?.country : ""} `}</p>
            </h5>
            {
              data?.contact?.picture ?
                <img src={data?.contact?.picture} alt="portfolio" height={40} width={40} />
                : ""
            }
          </div>

          <p>Email: {data?.contact?.email || ""} | Phone: {data?.contact?.number || ""} </p>
          <div className='resume_head_position' style={{ wordBreak: "break-all" }}>
            {
              data?.summary ? data?.summary : ""
            }

          </div>
        </div>


        {
          data?.experiences?.length > 0 &&
          <div className='resume_experience_wrapper mt-1'>
            <h5>Experience</h5>
            {
              data?.experiences?.map((item, index) => {
                return <>
                  <div key={index} className="resume_experience my-1">
                    <div className='resume_experience_head'>
                      <h6 className='m-0'>{item?.company}</h6>
                      <p className='m-0'>{item?.location}</p>
                      <p className='m-0'>{monthYear(item?.start)} - {(!monthYear(item?.end) && item.isWorking) ? "Present" : monthYear(item?.end)}</p>
                    </div>
                    <div className="resume_experience_position">
                      <h6 className='m-0'>{item?.role}</h6>
                      <p className='m-0'>{item?.description}</p>
                    </div>
                  </div>
                </>
              })
            }
          </div>
        }

        {data?.education?.length > 0 &&
          <div className='resume_experience_wrapper mt-1'>
            <h5>Education</h5>
            {
              data?.education?.map((item, index) => {
                return <div key={index} className="resume_experience my-1">
                  <div className='resume_experience_head'>
                    <h6 className='m-0'>{item?.institute}</h6>
                    <p className='m-0'>{item?.location}</p>
                    <p className='m-0'>{monthYear(item?.date)}</p>
                  </div>
                  <div className="resume_experience_position">
                    <h6 className='m-0'>{item?.name}</h6>
                    <p className='m-0'>SGPA - {item?.gpa}</p>
                  </div>
                </div>
              })
            }
          </div>
        }


        {data?.projects.length > 0 &&
          <div className='resume_experience_wrapper mt-1'>
            <h5>Projects</h5>

            {
              data?.projects?.map((item, index) => {
                return <div key={index} className="resume_experience my-1">
                  <div className='resume_experience_head'>
                    <h6 className='m-0'>{item?.title}</h6>
                    <p className='m-0'>{monthYear(item?.start)} - {(!monthYear(item?.end) && item.isWorking) ? "Present" : monthYear(item?.end)}</p>
                    <p className='m-0'>Organisation - {item?.company} </p>
                    <p className='m-0'>{item?.description}</p>
                  </div>
                </div>
              })
            }
          </div>
        }
        {
          data?.skills?.length > 0 &&
          <div className='resume_experience_wrapper mt-1'>
            <h5>Skills</h5>
            {
              data?.skills?.map(item => {
                return <div className='d-flex justify-content-between resume_skill'>
                  <h6>{item?.name}</h6>
                  <div>
                    {
                      item?.level == "Beginner" ? <span></span> : item?.level == "Intermediate" ?
                        <>
                          <span></span>
                          <span></span>
                        </>
                        :
                        <>
                          <span></span>
                          <span></span>
                          <span></span>
                        </>
                    }


                  </div>
                </div>
              })
            }
          </div>
        }
        {
          data?.hobby?.length > 0 &&
          <div className='resume_experience_wrapper mt-1'>
            <h5>Hobbies</h5>
            <div className='resume_hobby justify-content-start'>
              {
                data?.hobby?.map((item, index) => {
                  return <span>{`${item?.name} ${data?.hobby?.length == index + 1 ? "" : " , "} `}  </span>
                })
              }
            </div>
          </div>
        }

      </div>
    </>
  );
}

export default ResumeTemplate1;
