import React from "react";
import Img from "../../assets/image/modal-img.png";
import star from "../../assets/image/star.png";
import arrow from "../../assets/image/arrowright.png";
import { Modal, Button } from "react-bootstrap";
const ModalBox = ({ show, setShow }) => {
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      size="md"
      className="promotion-modal"
    >
      <Modal.Body>
        <img src={Img} alt="img1" width="100%" />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose}>
          Premium Features &nbsp;
          <img src={star} alt="star" />
        </Button>
        <Button onClick={handleClose}>
          Continue Manually &nbsp; <img src={arrow} alt="arrow" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalBox;
