import React, { useRef, useState, useEffect } from "react";
import Profile from "../../assets/image/profile.png";
import { Form, FloatingLabel } from "react-bootstrap";
import axios from "axios";
import { useDispatch } from "react-redux";
import {
  UpdateContactRedux,
  setContactFields,
  setContactRedux,
  setCreateResume,
} from "helper/redux/Slice/ResumeSlice";
import { useSelector } from "react-redux";
import { store } from "helper/redux/store2";
import Countries from "../../components/JSON/countries.min.json";
import Modal from "react-bootstrap/Modal";
import { setdisableNext } from "helper/redux/Slice/UtilsSlice";
import { ChatGPTAPI } from "helper/api";
import { Toaster, toast } from "react-hot-toast";

const ContactPage = ({ handleResponsivePreviewShow, StepNextBtn }) => {
  const dispatch = useDispatch();
  const Contact = useSelector((item) => item?.resume?.contact);
  const [ContactFields, setContactFields] = useState({
    picture: "",
    firstName: "",
    lastName: "",
    number: "",
    email: "",
    title: "",
    city: "",
    address: "",
    pincode: "",
    country: "",
  });
  const [uploadState, setuploadState] = useState({
    status: false,
    percent: 0,
    link: "",
  });

  const inputRef = useRef();
  const handlefileRef = () => {
    inputRef.current.click();
  };

  const uploadIcon = async (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      const fileType = file.type;
      const fileSize = file.size / 1024 / 1024; // Convert bytes to MB
      // Check if the file is an image
      if (!fileType.startsWith("image/")) {
        alert("Please upload a valid image file.");
        return;
      }

      // Check if the file size is less than 5MB
      if (fileSize > 5) {
        alert("File size should be less than 5MB.");
        return;
      }
      const config = {
        onUploadProgress: (progress) => {
          const percentange = Math.round(
            (progress.loaded / progress.total) * 100
          );
          setuploadState({
            ...uploadState,
            percent: percentange,
            status: true,
          });
          if (percentange === 100) {
            // setuploadState({ ...uploadState, status: false });
          }
        },
      };
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      const rootUrl =
        process.env.NODE_ENV === "production"
          ? "https://uploader.viosa.in/api"
          : "https://uploader.viosa.in/api";
      setuploadState({ ...uploadState, status: true });
      axios
        .post(`${rootUrl}/v2/upload/other`, formData, config)
        .then((data) => {
          // setInput({ ...input, [e.target.name]: data.data.link });
          // dispatch(setContactFields({ type: "picture", value: data?.data?.link }))
          setContactFields({ ...ContactFields, picture: data?.data?.link });
          setuploadState({
            ...uploadState,
            link: data.data.link,
            status: false,
          });
        })
        .catch((error) => {
          alert("Try again");
          console.error(error);
          setuploadState({ ...uploadState, status: false });
        });
    }
  };

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setContactFields({ ...ContactFields, [name]: value });
  };

  const ResumeType = useSelector((item) => item.resume);

  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  //countries
  const [CountryData, setCountryData] = useState([]);
  const [CityData, setCityData] = useState([]);

  useEffect(() => {
    const data = Object.keys(Countries);
    setCountryData(data);
    // Set initial values for country and city
    const initialCountry = ContactFields?.country || "";
    const initialCity = ContactFields?.city || "";
    setCountry(initialCountry);
    setCity(initialCity);
  }, [ContactFields, Countries]);

  useEffect(() => {
    setCityData(Countries[country]);
  }, [country]);

  // photo modal
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //disable btn
  useEffect(() => {
    // if (!!Contact?.firstName && !!Contact?.number && !!Contact?.email && !!Contact.country) {
    //   dispatch(setdisableNext({ type: "contact", value: false }))
    // } else {
    //   dispatch(setdisableNext({ type: "contact", value: true }))
    // }
    setContactFields(Contact);
  }, []);

  // New state variables to track editability
  const [editable, setEditable] = useState(true);
  // const saveToRedux = () => {
  //   toast.success("Contact Saved Successfully");

  //   const data = {
  //     picture: ContactFields?.picture,
  //     firstName: ContactFields?.firstName,
  //     lastName: ContactFields?.lastName,
  //     number: ContactFields?.number,
  //     email: ContactFields?.email,
  //     title: ContactFields?.title,
  //     city: city,
  //     address: city + " , " + country,
  //     pincode: ContactFields?.pincode,
  //     country: country,
  //   };
  //   dispatch(setContactRedux(data));
  //   StepNextBtn();
  // };
  const handleSave = () => {
    // Add your logic to save to Redux or perform any save actions
    toast.success("Contact Saved Successfully");

    const data = {
      picture: ContactFields?.picture,
      firstName: ContactFields?.firstName,
      lastName: ContactFields?.lastName,
      number: ContactFields?.number,
      email: ContactFields?.email,
      title: ContactFields?.title,
      city: city,
      address: city + " , " + country,
      pincode: ContactFields?.pincode,
      country: country,
    };

    dispatch(setContactRedux(data));
    // Assuming StepNextBtn is a function you have defined for the next step
    // StepNextBtn();
    setEditable(false);
  };
  const DocType = useSelector((item) => item.types.doctype);

  return (
    <div className="create-resume-main">
      <Toaster />
      <div className="d-flex justify-content-between">
        <div className="d-flex w-100 justify-content-between align-items-center my-1">
          <h3>Contact </h3>
          <div>
            <button
              onClick={handleResponsivePreviewShow}
              className="align-self-start p-1 responsive-preview-btn d-inline-block d-lg-none"
            >
              Preview
            </button>
          </div>
        </div>
      </div>
      <div className="text-end pe-2 mb-2">
        <small>
          {DocType === "resume"
            ? !!ResumeType?.profession
              ? "Job Specific"
              : "Generic"
            : ""}
        </small>
      </div>
      {/* <p>
        Et pellentesque scelerisque risus blandit quam tempus. Pellentesque
        sodales nec convallis sit ac.
      </p> */}

      <Form className="form-main ">
        <div className=" d-flex flex-lg-row flex-column gap-4">
          {/* first block */}
          <div className="w-100">
            <div className="profile-img-main mb-3">
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "8px",
                }}
              >
                <div>
                  <div className="profit-image-container">
                    <img
                      src={ContactFields?.picture || Profile}
                      className="pe-2"
                      alt="profile"
                      width="100%"
                      height="182px"
                      style={{ objectFit: "contain" }}
                    />
                  </div>
                  <p
                    style={{
                      color: "red",
                      margin: "0px 10px 0px 10px",
                      fontSize: "12px",
                    }}
                  >
                    Image size should be below 5mb
                  </p>
                </div>

                <div className="profile-img">
                  {uploadState?.status ? (
                    <h5> {uploadState?.percent}% Uploading...</h5>
                  ) : (
                    <h5 onClick={handlefileRef} className="cursor-pointer mb-3">
                      Upload Photo
                      <input
                        onChange={uploadIcon}
                        type="file"
                        ref={inputRef}
                        className="d-none"
                      />
                    </h5>
                  )}

                  <h5
                    className="cursor-pointer mb-3"
                    onClick={() => {
                      // dispatch(UpdateContactRedux({ type: "picture", value: null }))
                      setContactFields({ ...ContactFields, picture: null });
                      setuploadState({ ...uploadState, link: null });
                    }}
                  >
                    Delete Photo
                  </h5>
                  <h5 onClick={handleShow} className="cursor-pointer">
                    View Photo
                  </h5>
                </div>
              </div>
            </div>

            <div
              className=""
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
              }}
            >
              <FloatingLabel
                label="Title"
                className="mb-3"
                style={{ width: "100%" }}
              >
                <Form.Select
                  aria-label="Default select example"
                  name="title"
                  onChange={onChangeHandler}
                  style={{
                    color: "black",
                    fontSize: "12px",
                    fontWeight: "600",
                    height: "50px",
                    borderBottom: "0.1px solid #ffa303",
                    border: "none",
                  }}
                >
                  <option value="">{"Select a title"}</option>
                  <option value="Mr.">Mr.</option>
                  <option value="Mrs.">Mrs.</option>
                  <option value="Ms.">Ms.</option>
                  <option value="Miss">Miss</option>
                  <option value="Prof.">Prof.</option>
                  <option value="Dr.">Dr.</option>
                </Form.Select>
              </FloatingLabel>

              <FloatingLabel
                label="Full Name"
                className="mb-3"
                style={{ width: "100%" }}
              >
                <Form.Control
                  type="text"
                  id="firstName"
                  value={ContactFields?.firstName}
                  onChange={onChangeHandler}
                  placeholder="Full Name"
                  name="firstName"
                  style={{ borderBottom: "0.1px solid #ffa303" }}
                // readOnly="true"
                />
              </FloatingLabel>
              <div>
                <p
                  style={{
                    color: "red",
                    margin: "0px 10px 0px 10px",
                    fontSize: "12px",
                  }}
                >
                  Disclaimer: Full name cannot be edited
                </p>
              </div>
            </div>

            {/* <div className="d-flex">
              <FloatingLabel label="Last Name" className="mb-3">
                <Form.Control
                  value={ContactFields?.lastName}
                  name="lastName"
                  id="lastName"
                  onChange={onChangeHandler}
                  type="text"
                  readOnly={!editable}
                  placeholder="Last Name"
                />
              </FloatingLabel>
            </div> */}
          </div>

          <div className="w-100 ">
            <FloatingLabel label="Email" className="mb-3">
              <Form.Control
                value={ContactFields?.email}
                onChange={onChangeHandler}
                type="text"
                key="email"
                name="email"
                id="email"
                placeholder="Email"
              />
            </FloatingLabel>
            {/* <FloatingLabel
              label="Email"
              className="mb-3">
              <Form.Control
                value={ContactFields?.email} onChange={onChangeHandler}
                type="text" key="email" name="email" id="email" placeholder="Email" />
            </FloatingLabel> */}
            <FloatingLabel label="Country" className="mb-3">
              <Form.Select
                aria-label="Default select example"
                name="country"
                onChange={onChangeHandler}
                value={country}
                style={{
                  color: "black",
                  fontSize: "12px",
                  fontWeight: "600",
                  height: "50px",
                  borderBottom: "0.1px solid #ffa303",
                  border: "none",
                }}
              >
                <option value="">{"Select Country"}</option>
                {CountryData?.map((country, icons) => (
                  <option value={country}>{country}</option>
                ))}
              </Form.Select>
            </FloatingLabel>
            <FloatingLabel label="City" className="mb-3">
              <Form.Select
                aria-label="Default select example"
                name="city"
                value={city}
                onChange={onChangeHandler}
                style={{
                  color: "black",
                  fontSize: "12px",
                  fontWeight: "600",
                  height: "50px",
                  borderBottom: "0.1px solid #ffa303",
                  border: "none",
                }}
              >
                <option value="">{"Select City"}</option>
                {CityData?.map((city, i) => (
                  <option key={i} value={city}>
                    {city}
                  </option>
                ))}
              </Form.Select>
            </FloatingLabel>

            {/* <FloatingLabel
              label="Address"
              className="mb-3"
              cols={20}
            >
              <Form.Control as="textarea" key="address" value={ContactFields?.address} onChange={onChangeHandler} type="text" placeholder="Address" id="address" name="address" style={{ height: "118px" }} />
            </FloatingLabel> */}

            <FloatingLabel label="Number" className="mb-3">
              <Form.Control
                value={ContactFields?.number}
                onChange={onChangeHandler}
                id="number"
                name="number"
                type="number"
                placeholder="Number"
              />
            </FloatingLabel>
            {/* 
              <FloatingLabel
                label="COUNTRY"
                className="mb-3"
              >
                <Form.Select
                  onChange={onChangeHandler}
                  name="country"
                  aria-label="Default select example" style={{ color: "#906cb2", fontSize: "12px", fontWeight: "600", height: "51px", border: "none" }}>
                  <option value="" >{!!ContactFields?.country ? "~" + ContactFields?.country + "~" : "Select a Country"}</option>
                  {
                    CountryData?.map((item, index) => {
                      return <option key={index} value={item}>{item}</option>
                    })
                  }
                </Form.Select>
              </FloatingLabel>

              <FloatingLabel
                label="City"
                className="mb-3"
              >
                <Form.Select name="city" disabled={!!ContactFields.country ? false : true} aria-label="Default select example" onChange={onChangeHandler} style={{ color: "#906cb2", fontSize: "12px", fontWeight: "600", height: "51px", border: "none" }}>
                  <option value="" >{!!ContactFields?.city ? "~" + ContactFields?.city + "~" : "Select a city"}</option>
                  {
                    CityData?.map((item) => {
                      return <option value={item}>{item}</option>
                    })
                  }
                </Form.Select>
              </FloatingLabel> */}

            <FloatingLabel label="Pincode" className="mb-3">
              <Form.Control
                type="number"
                key="pincode"
                name="pincode"
                id="pincode"
                value={ContactFields?.pincode}
                onChange={onChangeHandler}
                placeholder="Email"
              />
            </FloatingLabel>
          </div>
        </div>

        <button type="button" className="save-experience" onClick={handleSave}>
          Save{" "}
        </button>
      </Form>

      <Modal centered show={show} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Photo Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src={uploadState?.link || Contact?.picture || Profile}
            style={{ width: "100%", objectFit: "contain" }}
            alt="photo preview"
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ContactPage;
