import React, { useRef, useEffect, useState } from 'react';
import Plyr from "plyr-react";
import "plyr/dist/plyr.css";
import Recorder from './Recorder';

const VideoTestimonial = () => {

  const videoSrc = {
    type: "video",
    sources: [
      {
        src: "QFaFIcGhPoM",
        provider: "youtube",
        size: 720,
      }
    ]
  };
  return (
    <div>
      <div className='d-flex justify-content-between py-4'>
        <div className='VideoResume_targetjob pe-4'>
          <h2>Testimonial</h2>
          <p>Sapiente debitis ratione nemo deleniti atque dignissimos quasi blanditiis exercitationem officiis accusamus.</p>
          <Recorder />
        </div>
        <div className='col-4 d-flex flex-column gap-5'>
          <div className='rounded  overflow-hidden'>
            <Plyr source={videoSrc} />
            <div>
            </div>
          </div>

        </div>
      </div>


      {/* //editor */}

      <div className='videoResume_editor'>
        <p>Record a video to edit</p>
      </div>
    </div>
  );
}

export default VideoTestimonial;
