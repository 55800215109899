import React, { useState } from "react";
import "./RT4.css";
import {
  faEnvelope,
  faPhone,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const RT4non = () => {
  const [fontSize, setfontSize] = useState(0.7);
  const Style = {
    resumeWrapper: {
      width: "100%",
      padding: "15px 8px",
      minHeight: "100%",
      background: "#fff",
      overflow: "auto",
      containerType: "inline-size",
      wordSpacing: "-1px", // Adjusted for reduced word spacing
      color: "#000",
    },
    resumeHead: {
      fontSize: fontSize,
      color: "#2e959c",
    },
    title: {
      fontSize: `${fontSize * 5}cqw`,
      marginBottom: `${fontSize * 1}cqw`,
      color: "#2e959c",
      fontWeight: "700",
      // textTransform: "uppercase",
    },
    common: {
      fontSize: `${fontSize * 3}cqw`,
      fontFamily: "'Open Sans-Light', Helvetica",
      marginBottom: `${fontSize * 0.5}cqw`,
      wordBreak: "break-word",
      whiteSpace: "break-spaces",
      textAlign: "justify",
      wordSpacing: "-1px", // Adjusted for reduced word spacing
      color: "#000",
      marginRight: "10px",
      padding: "0px 5px",
      lineHeight: "10px",
    },
    profession: {
      color: "#000",
      fontFamily: "'Open Sans-Light', Helvetica",
      fontSize: `${fontSize * 3}cqw`,
      fontWeight: 300,
      textTransform: "uppercase",
    },
    li: {
      fontSize: `${fontSize * 3}cqw`,
      marginBottom: `${fontSize * 0}cqw`,
    },
    headings: {
      fontfamily: "'Open Sans', Helvetica",
      fontSize: `${fontSize * 4}cqw`,
      color: "#2e959c",
      fontWeight: "700",
      marginBottom: `${fontSize * 1}cqw`,
      marginRight: `15px`,
    },
    subHeadings: {
      fontSize: `${fontSize * 4}cqw`,
      fontWeight: "700",
      marginBottom: `${fontSize * 1}cqw`,
      color: "#000",
      padding: "0px 5px",
    },
    subHeading: {
      fontSize: `${fontSize * 3}cqw`,
      fontWeight: "700",
      marginBottom: `${fontSize * 1}cqw`,
      color: "#000",
      padding: "0px 5px",
    },
    eduheads: {
      fontSize: `${fontSize * 4}cqw`,
      fontWeight: "700",
      marginBottom: `${fontSize * 1}cqw`,
      color: "#000",
      padding: "0px 5px",
    },
    marginHR: {
      margin: `${fontSize * 2}cqw 0`,
      color: "#2e959c",
      borderTop: "3px solid",
      opacity: 1.25,
    },
    marginUl: {
      margin: `${fontSize * 0}cqw 0`,
      display: "flex",
      listStyletype: "none",
      flexDirection: "row",
      flexWrap: "wrap",
      padding: "0",
    },
    horizontallist: {
      display: "flex",
      listStyletype: "none",
      margin: 0,
      padding: 0,
    },
    marker: {
      margin: 0,
    },
    ul: {
      margin: 0,
      paddingLeft: "15px",
    },
  };
  const monthYear = (date) => {
    if (date.length === 7) {
      let month = date.slice(5, 7);
      let year = date.slice(0, 4);
      switch (month) {
        case "1":
          return `Jan-${year}`;
        case "2":
          return `Feb-${year}`;
        case "3":
          return `March-${year}`;
        case "4":
          return `April-${year}`;
        case "5":
          return `May-${year}`;
        case "6":
          return `June-${year}`;
        case "7":
          return `July-${year}`;
        case "8":
          return `Aug-${year}`;
        case "9":
          return `Sep-${year}`;
        case "10":
          return `Oct-${year}`;
        case "11":
          return `Nov-${year}`;
        case "12":
          return `Dec-${year}`;
        // break;
        default:
          return date;
        // break;
      }
    } else {
      let day = date.slice(8, 10);
      let month = date.slice(5, 7);
      let year = date.slice(0, 4);
      switch (month) {
        case "1":
          return `${day}-Jan-${year}`;
        case "2":
          return `${day}-Feb-${year}`;
        case "3":
          return `${day}-March-${year}`;
        case "4":
          return `${day}-April-${year}`;
        case "5":
          return `${day}-May-${year}`;
        case "6":
          return `${day}-June-${year}`;
        case "7":
          return `${day}-July-${year}`;
        case "8":
          return `${day}-Aug-${year}`;
        case "9":
          return `${day}-Sep-${year}`;
        case "10":
          return `${day}-Oct-${year}`;
        case "11":
          return `${day}-Nov-${year}`;
        case "12":
          return `${day}-Dec-${year}`;
        // break;
        default:
          return date;
        // break;
      }
    }
  };
  return (
    <div style={Style.resumeWrapper}>
      <div className="resume-container">
        <div className="resume-head d-flex justify-content-between">
          <div className="">
            {/* <img src={logo} width={100} alt="profile" /> */}
            <h1 style={Style.title}>Ajay Shah</h1>
          </div>
          <div class="yui-u">
            <div class="contact-info">
              <h2 style={Style.profession}>Full Stack Developer</h2>
            </div>
          </div>
        </div>
        <div
          style={{
            flex: 1,
            backgroundColor: "#2e959c",
            height: "0.5px",
          }}
        />
        <div className="resume-head d-flex justify-content-between">
          <div className="">
            <h4 style={Style.common}>
              <FontAwesomeIcon icon={faEnvelope} /> abc@email.com
            </h4>
          </div>
          <div class="yui-u">
            <div class="contact-info">
              <h4 style={Style.common}>
                <FontAwesomeIcon icon={faPhone} /> +91 9090900900
              </h4>
            </div>
          </div>
          <div class="yui-u">
            <div class="contact-info">
              <h4 style={Style.common}>
                <FontAwesomeIcon icon={faMapMarkerAlt} /> Mumbai, Maharshtra,
                India
              </h4>
            </div>
          </div>
        </div>
        <div
          className="mb-2"
          style={{
            flex: 1,
            backgroundColor: "#2e959c",
            height: "0.5px",
          }}
        />
        <div>
          <div>
            <div>
              <div class="yui-gf mb-2">
                <div class="yui-u first d-flex align-items-center">
                  <h2 style={Style.headings}>Summary</h2>
                  <div
                    style={{
                      flex: 1,
                      backgroundColor: "#2e959c",
                      height: "2px",
                    }}
                  />
                </div>
                <div class="yui-u">
                  <p style={Style.common}>
                    Progressively evolve cross-platform ideas before impactful
                    infomediaries. Energistically visualize tactical initiatives
                    before cross-media catalysts for change.
                  </p>
                </div>
              </div>

              <div class="yui-gf  mb-2">
                <div class="yui-u first d-flex align-items-center">
                  <h2 style={Style.headings} className="mb-0">
                    Skills
                  </h2>
                  <div
                    style={{
                      flex: 1,
                      backgroundColor: "#2e959c",
                      height: "2px",
                    }}
                  />
                </div>
                <div class="yui-u d-flex">
                  <div style={Style.marginUl}>
                    <ul style={Style.marginUl}>
                      <li style={Style?.common}>XHTML</li>
                      <li style={Style?.common}>CSS</li>
                      <li style={Style?.common}>Javascript</li>
                      <li style={Style?.common}>XHTML</li>
                    </ul>

                    <ul style={Style.marginUl}>
                      <li style={Style?.common}>CSS</li>
                      <li style={Style?.common}>XHTML</li>
                      <li style={Style?.common}>Javascript</li>
                      <li style={Style?.common}>CSS</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="yui-gf mb-2">
                <div class="yui-u first d-flex justify-content-between align-items-center">
                  <h2 style={Style.headings}>Experience</h2>
                  <div
                    style={{
                      flex: 1,
                      backgroundColor: "#2e959c",
                      height: "2px",
                    }}
                  />
                </div>
                <div class="yui-u ">
                  <div class="job">
                    <div className="d-flex justify-content-between">
                      <p style={Style.eduheads}>Senior Interface Designer</p>
                      <p style={Style.common}>May 2005 - June 2007</p>
                    </div>
                    <h2 style={Style?.subHeading}>
                      Facebook , <small>California</small>
                    </h2>
                    <p style={Style.common}>
                      Intrinsicly enable optimal core competencies through
                      corporate relationships. Phosfluorescently implement
                      worldwide vortals and client-focused imperatives.{" "}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p style={Style.eduheads}>Senior Interface Designer</p>
                    <p style={Style.common}>May 2005 - June 2007</p>
                  </div>
                  <h2 style={Style?.subHeading}>
                    Facebook , <small>California</small>
                  </h2>
                  <p style={Style.common}>
                    Intrinsicly enable optimal core competencies through
                    corporate relationships. Phosfluorescently implement
                    worldwide vortals and client-focused imperatives.{" "}
                  </p>
                </div>
              </div>

              <div class="yui-gf mb-2">
                <div class="yui-u first d-flex">
                  <h2 style={Style.headings}>Projects</h2>
                  <div
                    style={{
                      flex: 1,
                      backgroundColor: "#2e959c",
                      height: "2px",
                    }}
                  />
                </div>
                <div class="yui-u ">
                  <div class="job">
                    <h2 style={Style.subHeadings}>Website Development </h2>
                    <div className="d-flex gap-4">
                      <p style={Style.common}>May 2021 - Jun 2021</p>
                    </div>
                    <p style={Style.common}>
                      lorem ipsum lorem ipsum lorem ipsum
                    </p>
                    <h2 style={Style.subHeadings}>Social Media Application </h2>
                    <div className="d-flex gap-4">
                      <p style={Style.common}>May 2021 - Jun 2021</p>
                    </div>
                    <p style={Style.common}>
                      lorem ipsum lorem ipsum lorem ipsum
                    </p>
                  </div>
                </div>
              </div>
              <div class="yui-gf mb-2">
                <div class="yui-u first d-flex">
                  <h2 style={Style.headings}>Research Papers</h2>
                  <div
                    style={{
                      flex: 1,
                      backgroundColor: "#2e959c",
                      height: "2px",
                    }}
                  />
                </div>
                <div class="yui-u ">
                  <div class="job">
                    <h2 style={Style.subHeadings}>
                      Optimizing Web Application Performance Using Server-Side
                      Rendering
                    </h2>
                    <div className="d-flex gap-4">
                      <p style={Style.common}>May 2021 - Jun 2021</p>
                    </div>
                    <p style={Style.common}>
                      lorem ipsum lorem ipsum lorem ipsum
                    </p>
                    <h2 style={Style.subHeadings}>
                      AI-Driven Web Development: Enhancing User Experience{" "}
                    </h2>
                    <div className="d-flex gap-4">
                      <p style={Style.common}>May 2021 - Jun 2021</p>
                    </div>
                    <p style={Style.common}>
                      lorem ipsum lorem ipsum lorem ipsum
                    </p>
                  </div>
                </div>
              </div>

              <div class="yui-gf last mb-2">
                <div class="yui-u first d-flex align-items-center">
                  <h2 style={Style.headings}>Education</h2>
                  <div
                    style={{
                      flex: 1,
                      backgroundColor: "#2e959c",
                      height: "2px",
                    }}
                  />
                </div>
                <div className="">
                  <div class="yui-u d-flex justify-content-between">
                    <h2 style={Style.eduheads}>
                      Bachelors of Engineering - IT
                    </h2>
                    <p style={Style.common}>May 2021 </p>
                  </div>
                  <div class="yui-u d-flex justify-content-between mb-2">
                    <p style={Style.common}>Mumbai University, Mumbai</p>
                    <p style={Style.common}>SGPA - 9</p>
                    <p style={Style.common}>90%</p>
                  </div>
                </div>
              </div>

              <div class="yui-gf last mb-2">
                <div class="yui-u first d-flex align-items-center">
                  <h2 style={Style.headings}>Certificates</h2>
                  <div
                    style={{
                      flex: 1,
                      backgroundColor: "#2e959c",
                      height: "2px",
                    }}
                  />
                </div>
                <div>
                  <div class="yui-u d-flex justify-content-between">
                    <h2 style={Style.eduheads}>Full Stack Developer</h2>
                    <p style={Style.common}>2021-06-16</p>
                  </div>
                  <div class="yui-u">
                    <p style={Style.common}>SimpliLearn</p>
                  </div>
                </div>
              </div>

              <div class="yui-gf last mb-2">
                <div class="yui-u first d-flex align-items-center">
                  <h2 style={Style.headings}>Acheivements</h2>
                  <div
                    style={{
                      flex: 1,
                      backgroundColor: "#2e959c",
                      height: "2px",
                    }}
                  />
                </div>
                <div>
                  <div class="yui-u d-flex justify-content-between">
                    <div>
                      <h2 style={Style.subHeadings}>
                        First Price in Hackathon
                      </h2>
                    </div>
                    <div>
                      <p style={Style.common}>2017</p>
                    </div>
                  </div>
                  <div>
                    <p style={Style.common}>
                      Build a robot that flows the line. My robot was the
                      fastest and scored highest on all metrics.
                    </p>
                  </div>
                </div>
              </div>
              <div class="yui-gf last mb-2">
                <div class="yui-u first d-flex align-items-center">
                  <h2 style={Style.headings}>Voluntary</h2>
                  <div
                    style={{
                      flex: 1,
                      backgroundColor: "#2e959c",
                      height: "2px",
                    }}
                  />
                </div>
                <div>
                  <div class="yui-u d-flex justify-content-between">
                    <div>
                      <h2 style={Style.subHeadings}>Project Manager</h2>
                      <h2 style={Style.common}>Mumbai</h2>
                    </div>
                    <div>
                      <p style={Style.common}>2017 - Present</p>
                    </div>
                  </div>
                  <div>
                    <p style={Style.common}>
                      1. Project managed multiple initiatives and events for a
                      local NGO, including fundraising campaigns, organizational
                      restructuring, and volunteer recruitment efforts. 2.
                      Developed partnerships with local businesses and
                      organizations to support the goals of the NGO. 3.
                      Coordinated and supervised volunteer activities, ensuring
                      that tasks were completed in a timely and efficient
                      manner.
                    </p>
                  </div>
                </div>
              </div>

              <div class="yui-gf mb-2">
                <div class="yui-u first d-flex align-items-center">
                  <h2 style={Style.headings} className="mb-0">
                    Hobby
                  </h2>
                  <div
                    style={{
                      flex: 1,
                      backgroundColor: "#2e959c",
                      height: "2px",
                    }}
                  />
                </div>
                <div class="yui-u d-flex">
                  <div style={Style.marginUl}>
                    <ul style={Style?.ul}>
                      <li style={Style?.common}>Music</li>
                    </ul>
                    <ul style={Style?.ul}>
                      <li style={Style?.common}>Painting</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="yui-gf mb-2">
                <div class="yui-u first d-flex align-items-center">
                  <h2 style={Style.headings} className="mb-0">
                    Languages Known
                  </h2>
                  <div
                    style={{
                      flex: 1,
                      backgroundColor: "#2e959c",
                      height: "2px",
                    }}
                  />
                </div>
                <div class="yui-u d-flex">
                  <div style={Style.marginUl}>
                    <ul style={Style?.ul}>
                      <li style={Style?.common}>English</li>
                    </ul>
                    <ul style={Style?.ul}>
                      <li style={Style?.common}>Hindi</li>
                    </ul>
                    <ul style={Style?.ul}>
                      <li style={Style?.common}>Marathi</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="yui-gf mb-2">
                <div class="yui-u first d-flex align-items-center">
                  <h2 style={Style.headings} className="mb-0">
                    Links
                  </h2>
                  <div
                    style={{
                      flex: 1,
                      backgroundColor: "#2e959c",
                      height: "2px",
                    }}
                  />
                </div>
                <div class="yui-u d-flex">
                  <div style={Style.marginUl}>
                    <ul style={Style?.ul}>
                      <li style={Style?.common}>
                        https://linkedin.com/ajayshah213
                      </li>
                    </ul>
                    <ul style={Style?.ul}>
                      <li style={Style?.common}>
                        https://linkedin.com/ajayshah213
                      </li>
                    </ul>
                    <ul style={Style?.ul}>
                      <li style={Style?.common}>
                        https://linkedin.com/ajayshah213
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RT4non;
