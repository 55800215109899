import React, { useState } from 'react'
import { Form, FloatingLabel, ButtonGroup, Button } from 'react-bootstrap'
import Achievement from './Achievement'
import LanguagesKnown from './LanguagesKnown'
import AllLinks from './AllLinks'
import { useSelector } from 'react-redux'

function Others({ otherNavigationState, setotherNavigationState, UpdateRedux, setUpdateRedux, handleResponsivePreviewShow }) {

    const ResumeRedux = useSelector((item) => item.resume);
    const DocType = useSelector((item) => item.types.doctype);
    const handleUpdateNavigateState = (value) => {
        setotherNavigationState(value)
    }
    return (
        <div>
            <div className="create-resume-main">
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <div className="d-flex w-100 justify-content-between align-items-center my-1">

                        <ButtonGroup aria-label="Basic example" className="gap-2">
                            <Button style={{ background: `${otherNavigationState === 0 ? "#521986" : "lightgrey"}`, border: "none" }} className='' onClick={() => handleUpdateNavigateState(0)}>Achievements</Button>
                            <Button style={{ background: `${otherNavigationState === 1 ? "#521986" : "lightgrey"}`, border: "none" }} onClick={() => handleUpdateNavigateState(1)}>Languages Known</Button>
                            <Button style={{ background: `${otherNavigationState === 2 ? "#521986" : "lightgrey"}`, border: "none" }}
                                onClick={() => handleUpdateNavigateState(2)}>Links</Button>
                        </ButtonGroup>
                    </div>

                    <button
                        // onClick={handleResponsivePreviewShow}
                        className="align-self-start p-1 responsive-preview-btn d-inline-block d-lg-none"
                    >
                        Preview
                    </button>
                    <div className="text-end pe-2 ">
                        <small>
                            {DocType === "resume"
                                ? !!ResumeRedux?.profession
                                    ? "Job Specific"
                                    : "Generic"
                                : ""}
                        </small>
                    </div>
                </div>

                <div>
                    {
                        otherNavigationState === 0 ? <Achievement UpdateRedux={UpdateRedux}
                            setUpdateRedux={setUpdateRedux} /> : otherNavigationState === 1 ? <LanguagesKnown UpdateRedux={UpdateRedux}
                                setUpdateRedux={setUpdateRedux} /> : <AllLinks UpdateRedux={UpdateRedux}
                                    setUpdateRedux={setUpdateRedux} />
                    }
                </div>
            </div>
        </div>
    )
}

export default Others