import React, { useRef, useState } from 'react';
import './CT1.css'
import jsPDF from 'jspdf';
import { pdfFromReact } from "generate-pdf-from-react-html";

const CT1non = () => {
  const reportTemplateRef = useRef();
  const handleGeneratePdf = () => {
    const doc = new jsPDF({
      orientation: "portrait",
      unit: "px",
      format: [4, 2]
    });

    // Adding the fonts.
    // doc.setFont('Inter-Regular', 'normal');
    console.log(reportTemplateRef)
    doc.html(reportTemplateRef.current, {
      async callback(doc) {
        doc.save('document');
      },
    });
  };
  // console.log(reportTemplateRef.current.offsetWidth)


  const [fontSize, setfontSize] = useState(1);
  const Style = {
    coverWrapper: {
      width: "100%",
      minHeight: "100%",
      containerType: "inline-size",
      padding: "5px",
      // background: "#ffffff",
      padding: `${fontSize * 1}cqw`
    },
    coverHead: {
      fontSize: fontSize
    },
    title: {
      fontSize: `${fontSize * 5}cqw`,
      marginBottom: `${fontSize * 1}cqw`

    },
    common: {
      fontSize: `${fontSize * 3}cqw`,
      marginBottom: `${fontSize * 1}cqw`,
      whiteSpace: "pre-wrap",
      wordBreak: "break-word",
      textAlign: "justify"
    },
    li: {
      fontSize: `${fontSize * 3}cqw`,
      marginBottom: `${fontSize * 0}cqw`
    }
    ,
    headings: {
      fontSize: `${fontSize * 4}cqw`,
      fontWeight: "700",
      marginBottom: `${fontSize * 1}cqw`
    },
    subHeadings: {
      fontSize: `${fontSize * 3}cqw`,
      fontWeight: "700",
      marginBottom: `${fontSize * 1}cqw`
    },
    marginHR: {
      margin: `${fontSize * 2}cqw 0`
    },
    marginUl: {
      margin: `${fontSize * 0}cqw 0`
    }

  }
  return (
    <>

      {/* <button onClick={() =>
        pdfFromReact(".element-to-print", "My-file", "p", true, false)
      }>click</button> */}
      <div style={Style.coverWrapper} className='container-fluid element-to-print ' ref={reportTemplateRef}>
        <div className=' align-items-center d-flex flex-column'>
          <h1 className='text-center' style={Style.title}>Juvan Green Applicant</h1>
          <h3 className='text-center' style={Style.common}>123, Street XO, LA, USA</h3>
          <h4 className='text-center' style={Style.common}>8700061620 - demo@gmail.com</h4>
        </div>
        <aside className='align-self-start'>
          <p style={Style.common}>July 24, 2023</p>
          <p style={Style.common}>Recipent name</p>
          <p style={Style.common}>Position </p>
          <p style={Style.common}>department </p>
        </aside>

        <div >
          <p style={Style.common}>Dear Name</p>
          <p style={Style.common}  >I am writing to express my strong interest in the Software Engineer position at [Company Name] as advertised on [Source of Job Listing]. With my solid foundation in software development and a passion for crafting innovative solutions, I am confident in my ability to contribute to your team and help drive the success of your projects.</p>
          <p style={Style.common}>          I am a highly skilled software engineer with [number of years] of professional experience in the field. My expertise lies in [mention your areas of specialization, such as front-end development, backend systems, or full-stack development]. Throughout my career, I have successfully designed, developed, and maintained [mention notable software projects or applications you have worked on] by utilizing a combination of technical skills, problem-solving abilities, and a strong attention to detail.</p>
          <p style={Style.common}>   In my previous role at [Current/Previous Company], I played a key role in developing and implementing software solutions for a diverse range of clients. I actively participated in the entire software development lifecycle, from requirements gathering and analysis to coding, testing, and deployment. I am proficient in [list programming languages, frameworks, and tools relevant to the position]. Additionally, I have experience collaborating with cross-functional teams, including product managers, UX designers, and QA engineers, to ensure the delivery of high-quality software products..</p>
        </div>

        <br />
        <div className='align-self-start'>
          <p style={Style.common}>Sincerely,</p>
          <p style={Style.common}>Juvan Green</p>
        </div>
      </div>

    </>
  );
}

export default CT1non;
