import { setFieldFocus, setTo } from "helper/redux/Slice/CoverSlice";
import React, { useEffect, useState } from "react";
import { Form, FloatingLabel } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";

export const FromTo = ({ handleResponsivePreviewShow, SaveAndNext }) => {
  const dispatch = useDispatch();
  const CoverData = useSelector((item) => item.cover);
  const [ToFields, setToFields] = useState({
    name: "",
    position: "",
    address: "",
    email: "",
    companyName: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setToFields({ ...ToFields, [name]: value });
  };

  const saveToRedux = () => {
    dispatch(setTo(ToFields));
    SaveAndNext();
    toast.success("Recipient Saved Successfully");
  };

  useEffect(() => {
    if (!!CoverData?.to?.name || !!CoverData?.to?.position) {
      setToFields(CoverData?.to);
    }
  }, []);

  return (
    <div className="create-resume-main">
      <div className="d-flex justify-content-between align-items-center">
        <h3>Recipient </h3>
        <button
          onClick={handleResponsivePreviewShow}
          className="align-self-start p-1 responsive-preview-btn d-inline-block d-lg-none"
        >
          Preview
        </button>
      </div>
      <p>
        {/* Et pellentesque scelerisque risus blandit quam tempus. Pellentesque
        sodales nec convallis sit ac. */}
      </p>

      <Form className="form-main" onSubmit={saveToRedux}>
        <FloatingLabel
          label={<span className="required-label">Full Name</span>}
          className="mb-3"
        >
          <Form.Control
            id="name"
            required
            name="name"
            onChange={handleChange}
            value={ToFields?.name}
            type="text"
            placeholder="Full Name"
          />
        </FloatingLabel>
        <div className="row">
          <div className="col-lg-6 col-12">
            <FloatingLabel
              label={<span className="required-label">Position</span>}
              className="mb-3"
            >
              <Form.Control
                id="position"
                name="position"
                type="text"
                value={ToFields?.position}
                onChange={handleChange}
                placeholder="PHONE NUMBER"
              />
            </FloatingLabel>

            <FloatingLabel
              label={<span className="required-label">Office Address</span>}
              className="mb-3"
            >
              <Form.Control
                type="text"
                id="address"
                name="address"
                value={ToFields?.address}
                placeholder="RESIDENTIAL ADDRESS"
                onChange={handleChange}
              />
            </FloatingLabel>
          </div>
          <div className="col-lg-6 col-12">
            <FloatingLabel
              label={<span className="required-label">Email Address</span>}
              className="mb-3"
            >
              <Form.Control
                type="text"
                required
                id="email"
                name="email"
                value={ToFields?.email}
                placeholder="EMAIL ADDRESS"
                onChange={handleChange}
              />
            </FloatingLabel>
            <FloatingLabel
              label={<span className="required-label">Company Name</span>}
              className="mb-3"
            >
              <Form.Control
                type="text"
                id="companyName"
                name="companyName"
                onChange={handleChange}
                value={ToFields.companyName}
                placeholder="Company"
              />
            </FloatingLabel>
          </div>
        </div>
        <button className="save-experience" type="submit">
          Save Recipient
        </button>
      </Form>
    </div>
  );
};
