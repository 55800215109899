import { TargetJob } from 'components/CoverLetter/TargetJob';
import { Navbar } from 'components/Navbar/Navbar';
import { SidePanel } from 'components/Sidepanel/SidePanel';
import { StepProgress } from 'components/StepProgress/StepProgress';
import VideoAccomplishment from 'components/VideoResume/VideoAccomplishment';
import VideoClose from 'components/VideoResume/VideoClose';
import VideoDescription from 'components/VideoResume/VideoDescription';
import Description from 'components/VideoResume/VideoDescription';
import VideoIntroduction from 'components/VideoResume/VideoIntroduction';
import VideoPassion from 'components/VideoResume/VideoPassion';
import VideoProfessional from 'components/VideoResume/VideoProfessional';
import VideoTestimonial from 'components/VideoResume/VideoTestimonial';
import { SidePanelData } from 'contants/SidepanelData';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import '../styles/VideoResume/Videoresume.css'

const CreateVideo = () => {
  const [step, setStep] = useState(0);

  // step function
  const direcStepChange = (step) => {
    setStep(step);
  }


  return (
    <>
      <div className="d-flex">
        <SidePanel>
          <div className="px-4 py-4">
            {SidePanelData.map((item) => (
              <div key={item.id}>
                <NavLink to={item.path} className="sidebar__link" key={item.id}>
                  <div className="d-flex align-items-center py-2 wrapper__sidebar">
                    {item.icon}
                    <h6 className="sidebar__heading px-2 mt-2">{item.name}</h6>
                  </div>
                </NavLink>
              </div>
            ))}
          </div>
        </SidePanel>
        <div className="w-100">
          <Navbar />
          <div className='px-4'>
            <div>
              {
                step >= 1 &&
                <div className="step-progress-main ">
                  <StepProgress
                    step={step}
                    changeStepDirect={direcStepChange}
                    type={"video"}
                  />
                </div>
              }
              {
                step == 0 ? <VideoDescription setStep={setStep} /> :
                  step == 1 ? <VideoIntroduction setStep={setStep} /> :
                    step === 2 ? <VideoProfessional /> :
                      step === 3 ? <VideoPassion /> :
                        step === 4 ? <VideoAccomplishment /> :
                          step === 5 ? <VideoTestimonial /> :
                            <VideoClose />
              }
              {

                step >= 1 &&
                <div className='step-progres-button d-flex justify-content-end m-3'>

                  <button
                    onClick={() => setStep(step + 1)}
                  >
                    {
                      step < 6 ? "Next" : "Submit"
                    }
                  </button>
                </div>
              }
            </div>

          </div>
        </div>
      </div>
    </>
  );
}

export default CreateVideo;
