import { SidePanel } from "components/Sidepanel/SidePanel";
import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Navbar } from "components/Navbar/Navbar";
import { Form, FloatingLabel } from "react-bootstrap";
import Card from "components/Card/Card";
import { create_UUID } from "helper/uuid";
import { SidePanelData } from "contants/SidepanelData";
import "../../styles/dashboard.css";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { setDocType, setDocSubType } from "helper/redux/Slice/TypeSlice";
import Skeleton from "react-loading-skeleton";
import DashboardData from "./DashboardData";
import { GettingAiCredits, setReviewEdit, setTemplateNo, setTemplatePreview } from "helper/redux/Slice/UtilsSlice";
import ResumeTemplate1 from "components/ResumeTemplate/ResumeTemplate1";
import ResumeTemplate2 from "components/ResumeTemplate/ResumeTemplate2";
import CoverletterTemplate from "components/CoverLetterTemplate/CoverTemplate1/CoverletterTemplate";
import TemplateJson from "../../contants/ResumeTemplateJson";
import { ResetResumeTemplate, setContactFields, setCreateResume } from "helper/redux/Slice/ResumeSlice";
import ResumeTemplateJson from "../../contants/ResumeTemplateJson";
import CoverLetterTemplateJson from "contants/CoverLettertemplate";
import { ResetCoverLetter, setCoverFields, setFrom, setResetCoverLetter } from "helper/redux/Slice/CoverSlice";
import Cookies from "js-cookie";
import { getProfile } from "helper/api";
import { setUser } from "helper/redux/Slice/UserSlice";

export const Dashboard = () => {
  const types = useSelector((item) => item.types);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isModal, setIsModal] = useState(false);
  const [step, stepStep] = useState(1);
  const [templates, setTemplates] = useState(true);

  function nextStep() {
    if (step === 2) {
      navigate("/templates");
      return;
    }

    stepStep(step + 1);
  }

  function prevStep() {
    if (step === 1) {
      setIsModal(false);
      return;
    }
    stepStep(step - 1);
  }


  if (types.doctype === "cover") {
    dispatch(ResetCoverLetter())
  } else {
    dispatch(ResetResumeTemplate())
  }


  if (types.doctype === "") {
    dispatch(setDocType('resume'))
  }

  dispatch(setReviewEdit(false))

  let token
  if (!!localStorage.getItem('user2')) {
    token = JSON.parse(localStorage.getItem('user2'))
  } else {
    token = JSON.parse(Cookies.get('user'))
  }
  useEffect(() => {
    if (!!token) {
      const updateuser = async () => {
        try {

          console.log(token._id)
          const response = await getProfile(token?._id)
          dispatch(setUser(response.data.data));
        } catch (error) {
          console.log(error)
        }
      }
      updateuser()
    }
  }, [token])

  return (
    <>
      {isModal && (
        <div
          className="preview-modal-container"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.794)" }}
        >
          {/* {step === 1 && <div className="preview-modal-image-container">
          <img alt="preview-image-mpdal" src={previewTemplate}></img>

          <div >
            <button onClick={() => { handleIsModal("close") }} className="modal-close-button">X</button>

            <button className="preview-next-button" onClick={() => { navigate("./create-cover-letter") }}>Proceed</button>
          </div>

        </div>} */}

          {step === 1 && (
            <div className="modal-resumeType-container">
              <h3>Choose type of your resume</h3>
              <p>Update your account's email address and profile information</p>

              <div className="resumeType-button-container">
                <button>Job Specific Resume</button>
                <button>Generic Resume</button>
              </div>

              <div className="resumeType-controlButton-container">
                <button
                  onClick={() => {
                    prevStep();
                  }}
                >
                  Back
                </button>
                <button
                  onClick={() => {
                    nextStep();
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          )}

          {step === 2 && (
            <div className="modal-resumeType-container">
              <h3>Choose type of your resume</h3>
              <p>Update your account's email address and profile information</p>

              <div className="resumeType-fields-container">
                <div className="create-resume-main">
                  <Form className="form-main" style={{ border: "none" }}>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Field"
                      className="mb-3"
                    >
                      <Form.Control type="text" />
                    </FloatingLabel>

                    <FloatingLabel
                      controlId="floatingInput"
                      label="Job Field"
                      className="mb-3"
                    >
                      <Form.Control type="text" />
                    </FloatingLabel>

                    <FloatingLabel
                      controlId="floatingInput"
                      label="Desired Company"
                      className="mb-3"
                    >
                      <Form.Control type="text" />
                    </FloatingLabel>
                  </Form>
                </div>
              </div>

              <div className="resumeType-controlButton-container">
                <button
                  onClick={() => {
                    prevStep();
                  }}
                >
                  Back
                </button>
                <button
                  onClick={() => {
                    nextStep();
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          )}
        </div>
      )}



      <div className="d-flex col-12 ">
        <SidePanel />


        <div className="w-100 main-container-wrapper">
          <Navbar />

          <DashboardData setIsModal={setIsModal} setTemplates={setTemplates} />
          <div className="main-content  ">
            {/* <div className="template-title">
              <h4>
                Here are the <span>Best Templates</span> for you
              </h4>
              <p>They’re great for students with little experience </p>
            </div> */}
            {/* <div className="template-color-picker">
                <div className="" style={{ margin: "0 0 0 220px" }}>
                  <h6> Color</h6>
                  <span onClick={() => dispatch(setCreateResume({ type: "color", value: "#FACD48" }))} className="cursor-pointer" style={{ background: "#FACD48", border: `${ResumeData?.color === "#FACD48" ? `3px solid blue` : null}` }} />

                  <span onClick={() => dispatch(setCreateResume({ type: "color", value: "#0462F6" }))} className="cursor-pointer" style={{ background: "#0462F6", border: `${ResumeData?.color === "#0462F6" ? `3px solid blue` : null}` }} />

                  <span onClick={() => dispatch(setCreateResume({ type: "color", value: "#335C52" }))} className="cursor-pointer" style={{ background: "#335C52", border: `${ResumeData?.color === "#335C52" ? `3px solid blue` : null}` }} />

                  <span onClick={() => dispatch(setCreateResume({ type: "color", value: "#1A1A1A" }))} className="cursor-pointer" style={{ background: "#1A1A1A", border: `${ResumeData?.color === "#1A1A1A" ? `3px solid blue` : null}` }} />

                  <span onClick={() => dispatch(setCreateResume({ type: "color", value: "#EEEEEE" }))} className="cursor-pointer" style={{ background: "#EEEEEE", border: `${ResumeData?.color === "#EEEEEE" ? `3px solid blue` : null}` }} />

                  <span onClick={() => dispatch(setCreateResume({ type: "color", value: "#7C7682" }))} className="cursor-pointer" style={{ background: "#7C7682", border: `${ResumeData?.color === "#7C7682" ? `3px solid blue` : null}` }} />

                  <span onClick={() => dispatch(setCreateResume({ type: "color", value: "#FFBEBE" }))} className="cursor-pointer" style={{ background: "#FFBEBE", border: `${ResumeData?.color === "#FFBEBE" ? `3px solid blue` : null}` }} />

                  <span onClick={() => dispatch(setCreateResume({ type: "color", value: "#FFB960" }))} className="cursor-pointer" style={{ background: "#FFB960", border: `${ResumeData?.color === "#FFB960" ? `3px solid blue` : null}` }} />
                </div>
                <div className="" style={{ margin: "0" }}>
                  <button
                    className="changeTemplate-button"
                    onClick={() => {
                      setChangeTemplateDropdown(!changeTemplateDropdown);
                    }}
                  >
                    Change Templates{" "}
                    <AiOutlineDown
                      className="mx-2"
                      style={changeTemplateDropdown ? { rotate: "180deg" } : {}}
                    />
                    {changeTemplateDropdown && (
                      <div className="change-template-dropdown-container">
                        <p>All</p>
                        <p>Accountancy/Finance</p>
                        <p>IT/Tech</p>
                        <p>Art/Design</p>
                      </div>
                    )}
                  </button>
                </div>
              </div> */}
            {/* <div className="d-flex gap-4">
              {
                types.doctype === "resume" &&
                <>
                  {
                    ResumeTemplateJson?.map((item, index) => {
                      return <div key={index} className="col-3 border rounded cursor-pointer" onClick={() => {
                        dispatch(setTemplateNo(item.templateNo))
                        dispatch(setCreateResume({ type: "template", value: item.templateNo }))
                        dispatch(setContactFields({ type: "firstName", value: UserData.name }))
                        dispatch(setContactFields({ type: "email", value: UserData.email }))
                        dispatch(setContactFields({ type: "number", value: UserData.phoneNumber }))
                        navigate('/create-resume')
                      }}>
                        <item.template />
                      </div>

                    })
                  }


                </>
              }

            </div>

            <div className="d-flex gap-4">
              {
                types.doctype === "cover" &&
                <>
                  {
                    CoverLetterTemplateJson?.map((item, index) => {
                      return <div key={index} className="col-3 border rounded cursor-pointer" onClick={() => {
                        dispatch(setTemplateNo(item.templateNo))
                        dispatch(setCoverFields({ type: "template", value: item.templateNo }))
                        dispatch(setFrom({ type: "name", value: UserData.name }))
                        dispatch(setFrom({ type: "email", value: UserData.email }))
                        dispatch(setFrom({ type: "number", value: UserData.phoneNumber }))
                        navigate('/create-cover-letter')
                      }}>
                        <item.template />
                      </div>

                    })
                  }
                </>
              }

            </div> */}

            {/* <div
                className="select-card"
                onClick={() => {
                  types.doctype == "cover" ?
                    navigate("/create-cover-letter") :
                    navigate("/create-resume")

                }}
              >
                {CoverCardData?.length > 0 ?
                  CoverCardData?.map((data) => (
                    <Card
                      type={data.type}
                      Component={data?.Component}
                      key={create_UUID()}
                    />
                  ))
                  :
                  <>

                    <Skeleton height={320} width={210} style={{ margin: "20px 0" }} />
                    <Skeleton height={320} width={210} style={{ margin: "20px 0" }} />
                    <Skeleton height={320} width={210} style={{ margin: "20px 0" }} />
                    <Skeleton height={320} width={210} style={{ margin: "20px 0" }} />
                    <Skeleton height={320} width={210} style={{ margin: "20px 0" }} />
                  </>
                }
              </div> */}
          </div>
        </div>
      </div>
    </>
  );
};
